import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AOutlinedEducation: FC<ISocialIcon> = ({
  width = '8',
  height = '8',
  stroke = 'black',
  strokeWidth = '0',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75025 6.95528C6.58681 7.00246 6.41369 7.00246 6.25025 6.95528V6.35372L6.50025 6.10372L6.75025 6.35372V6.95528ZM7.63841 2.16464L4.58593 0.638398C4.21624 0.457774 3.78433 0.457774 3.41465 0.638398L0.361852 2.16464C0.139668 2.27526 -0.000332006 2.50214 0.000291994 2.75026C0.000291994 2.99964 0.139044 3.2237 0.362164 3.33557L1.25032 3.77964V5.25028C1.25032 6.09124 2.45848 6.75028 4.00032 6.75028C4.46345 6.75028 4.91968 6.68903 5.31968 6.5734L5.18062 6.09278C4.82531 6.19591 4.41718 6.25029 4.0003 6.25029C2.67438 6.25029 1.7503 5.7231 1.7503 5.25029V4.02965L3.4147 4.86213C3.59688 4.95275 3.7972 4.99994 4.00032 5.00025C4.20251 5.00025 4.4047 4.95213 4.58595 4.86213L5.61219 4.349L5.38875 3.90181L4.36219 4.41494V4.41463C4.13375 4.5265 3.86688 4.5265 3.63844 4.41463L0.585956 2.88839C0.533456 2.86245 0.50002 2.80901 0.500332 2.75026C0.500332 2.6912 0.533145 2.63839 0.585956 2.61213L3.63876 1.08557H3.63844C3.86688 0.973701 4.13376 0.973701 4.3622 1.08557L7.41468 2.61213C7.46718 2.63776 7.50061 2.69151 7.5003 2.75026C7.5003 2.8087 7.46717 2.86245 7.41468 2.88839L6.48308 3.35433L4.08244 2.51433L3.91744 2.9862L6.25024 3.80276V5.64684L5.75024 6.14684V7.27996L5.88836 7.34902C5.9193 7.36464 6.19992 7.50058 6.50024 7.50058C6.80055 7.50058 7.08086 7.36464 7.11211 7.34902L7.25024 7.27996V6.14684L6.75024 5.64684V3.77996L7.63864 3.33558C7.86051 3.22527 8.00082 2.9984 8.0002 2.75058C8.0002 2.50121 7.86144 2.27652 7.63832 2.16496L7.63841 2.16464Z"
        fill={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
