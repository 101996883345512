import { FC } from 'react';
import Box from '@material-ui/core/Box';
import useTranslation from 'next-translate/useTranslation';
import { useCvTwoLayoutBack } from 'helpers';
import { OCVProps, previewType } from '../../index';

const PREMIUM_0021_BACK: FC<OCVProps> = (props) => {
  const { t } = useTranslation('common');
  const [
    isShowOtherPage,
    renderedLeft,
    renderedRight,
    idLeft,
    idRight,
    contentLeft,
    contentRight,
    preview,
    savedCvColour,
    fontSizeRatio,
  ] = useCvTwoLayoutBack({
    breakComponent: <Box key="too-large">{t('common.contentTooLong')}</Box>,
    props,
  });

  return (
    <>
      <Box minHeight="54.164em" height="54.164em" position="relative" display="flex" fontSize={fontSizeRatio}>
        <Box
          minHeight="54.164em"
          height="54.164em"
          width="100%"
          display="flex"
          flexDirection="column"
          position="relative"
          color="#71726E"
        >
          <Box display="flex" flex={1} minHeight="54.164em" height="54.164em">
            <Box width="50%" maxHeight="100%" height="100%" bgcolor="#171818" id={idLeft} color="#FFFFFF">
              <Box height="1.6em" />
              {renderedLeft}
            </Box>

            <Box width="50%" id={idRight} maxHeight="100%" height="100%">
              <Box height="1.6em" />
              {renderedRight}
            </Box>
          </Box>
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0021_BACK
            preview={preview}
            savedCvColour={savedCvColour}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </>
  );
};

export default PREMIUM_0021_BACK;
