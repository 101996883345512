import Box, { BoxProps } from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import DeleteIcon from 'public/assets/svg/delete.svg';
import EditIcon from 'public/assets/svg/edit.svg';
import DuplicateIcon from 'public/assets/svg/duplicate.svg';
import DragIcon from 'public/assets/svg/drag.svg';

interface IActionIcon {
  onClick?: () => void;
  type: 'delete' | 'edit' | 'duplicate' | 'drag';
}

const actionTypes = {
  delete: DeleteIcon,
  edit: EditIcon,
  duplicate: DuplicateIcon,
  drag: DragIcon,
};

export const ActionIcon: FC<IActionIcon & BoxProps> = ({ type, onClick, ...props }) => {
  const SVG = actionTypes[type];
  const { t } = useTranslation('common');
  return (
    <Tooltip title={t(`action.${type}`)} placement="top">
      <Box {...props} component="span" className="u-pointer" onClick={onClick}>
        <SVG />
      </Box>
    </Tooltip>
  );
};
