import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, getLanguageLevel, useCVHelper, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import {
  ActiveColors,
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
  ProfileReference,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AAddress,
  ADribbble,
  AEmail,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  ASkype,
  AWebsite,
} from 'components/atoms/icons';

import PREMIUM_0025_BACK from './back';
import { OCVProps, previewType } from '../../index';

const SkillItem: FC<{
  title: string;
  point: number;
  theme: string;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
}> = ({ title, point, theme, isShowProficiency, activeColors }) => {
  if (isShowProficiency) {
    const width = `${(point * 100) / 5}%`;
    return (
      <Box p=".5em 1.6em" {...activeColors}>
        <Box mb=".4em">
          <Box fontSize="0.5em" color={activeColors.color || 'white'} width="100%" className="u-text-uppercase">
            {title}
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          height=".4em"
          position="relative"
          border={`1px solid ${theme}`}
        >
          <Box
            bgcolor={theme}
            width={`calc(${width} + 2px)`}
            height="calc(100% + 2px)"
            position="absolute"
            top="-1px"
            left="-1px"
          />
        </Box>
      </Box>
    );
  }
  return (
    <Box
      display="flex"
      gridGap=".2em"
      alignItems="center"
      color={activeColors.color || 'white'}
      {...activeColors}
      p=".5em 1.6em"
      width="100%"
    >
      <Box bgcolor={theme} width=".2em" height=".1em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities, company, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      gridGap=".2em"
      p=".5em 1.6em"
      {...activeColors}
      width="100%"
    >
      <Box color="#484545" fontSize=".5em" width="100%">{`${joinDate} - ${leaveDate}`}</Box>
      <Box color="#484545" fontSize=".5em" fontWeight={700} className="u-text-uppercase" width="100%">
        {role}
      </Box>
      <Box color="#484545" fontSize=".5em" mb=".2em" width="100%">{`${company}, ${location}`}</Box>
      <AQuillContent
        color="#757575"
        textAlign="start"
        fontWeight={400}
        fontSize=".5em"
        content={activities}
        mt=".2em"
        pl="4%"
      />
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, to_year, finished, from_year, degree } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box fontWeight={400} p=".5em 1.6em" {...activeColors} display="flex" flexDirection="column" gridGap=".2em">
      <Box fontSize=".5em" color="#484545">
        {`${joinDate} - ${leaveDate}`}
      </Box>
      <Box fontWeight={700} fontSize=".6em" color="#484545">
        {degree}
      </Box>
      <Box fontSize=".5em" color="#484545">
        {school}
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ img: string; link: string; type: string; activeColors: ActiveColors }> = ({
  img,
  link,
  activeColors,
}) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    Instagram: AOutlinedInstagram,
    LinkedIn: AOutlinedLinkedin,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG = componentIcons[img];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="80%" height="80%" />;
  };
  return (
    <Box
      width="calc(50% - 0.4em)"
      display="inline-flex"
      gridGap=".5em"
      alignItems="center"
      color="#ffffff"
      {...activeColors}
    >
      <Box borderRadius="50%" bgcolor="#272826" className="u-flex-center" width=".5em" height=".5em">
        <Box width="100%" height="100%" className="u-flex-center">
          {img && svgIcons()}
        </Box>
      </Box>
      <Box fontWeight={400} fontSize=".5em" flex={1} whiteSpace="pre-line">
        {link}
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string; activeColors: ActiveColors }> = ({
  type,
  link,
  activeColors,
}) => {
  const componentIcons = {
    email: AEmail,
    phone: APhone,
    address: AAddress,
    Skype: ASkype,
  };
  const svgIcon = () => {
    const SVG = componentIcons[type];
    return <SVG fill={activeColors.color || 'white'} strokeWidth="0" width=".5em" height=".5em" />;
  };
  return (
    <Box
      display="flex"
      gridGap=".3em"
      alignItems="center"
      fontWeight={400}
      px="1.6em"
      color="#ffffff"
      {...activeColors}
      width={1}
    >
      <Box>{type && svgIcon()}</Box>
      <Box fontSize=".5em">{link}</Box>
    </Box>
  );
};

const SubTitle: FC<{ name: string; activeColors: ActiveColors } & BoxProps> = ({ name, activeColors, ...props }) => {
  return (
    <Box color="#FFFFFF" px="2em" fontSize=".8em" whiteSpace="nowrap" {...props} fontWeight={700} {...activeColors}>
      {name}
    </Box>
  );
};

const LineCircles: FC<{ right?: boolean; rightCircle?: boolean; activeColors: ActiveColors } & BoxProps> = ({
  right = false,
  rightCircle = false,
  activeColors,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection={rightCircle ? 'row' : 'row-reverse'} alignItems="center" width="100%" {...props}>
      <Box width="100%" borderBottom=".15em solid" />
      <Box width=".2em" />
      <Box
        p=".2em"
        justifyContent="flex-end"
        width=".4em"
        height=".4em"
        bgcolor={right ? '#333333' : activeColors.color || '#FFFFFF'}
        borderRadius="50%"
      />
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage; activeColors: ActiveColors }> = ({
  language,
  activeColors,
}) => {
  const { name, speaking, writing } = language;
  return (
    <Box
      fontWeight={400}
      color="#ffffff"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      gridGap=".4em"
      p="0em 1.6em .6em"
      {...activeColors}
    >
      <Box fontSize=".6em" fontWeight={600} flex={1}>
        {name}
      </Box>
      <Box display="flex" alignItems="center" width="max-content" fontSize=".5em" whiteSpace="pre">
        {`Write: ${getLanguageLevel(writing)} | Spoken: ${getLanguageLevel(speaking)}`}
      </Box>
    </Box>
  );
};

const UserCertification: FC<{ data?: ProfileCertificate; activeColors: ActiveColors }> = ({ data, activeColors }) => {
  const { certificate, date, organizer, expiration_date, does_not_expire } = data;
  const joinDate = getYearOrExample(date);
  const leaveDate = does_not_expire ? 'Present' : getYearOrExample(expiration_date, '2020');
  return (
    <Box fontWeight={400} display="flex" flexDirection="column" gridGap=".2em" p=".5em 1.6em" {...activeColors}>
      <Box fontWeight={400} fontSize=".5em">
        {organizer} | {`${joinDate} - ${leaveDate}`}
      </Box>
      <Box fontWeight={700} fontSize=".6em" color="#333333">
        {certificate}
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  cvReference,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap=".3em"
      color="#ffffff"
      minWidth="40%"
      p=".5em 1.6em"
      width={1}
      {...activeColors}
    >
      <Box fontSize=".6em" fontWeight={700}>
        {name}
      </Box>
      <Box fontSize=".5em" fontWeight={400}>
        {position} | {company}
      </Box>
      <Box fontStyle="italic" fontSize=".5em" fontWeight={400}>
        <Box component="span" mr=".2em">
          Phone:
        </Box>
        {mobile}
      </Box>
      <Box fontStyle="italic" fontSize=".5em" fontWeight={400}>
        <Box component="span" mr=".2em">
          Email:
        </Box>
        {email}
      </Box>
    </Box>
  );
};

const PREMIUM_0025: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, certifications, languages, reference, interest } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    skype,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'Linda';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userSkype = skype ?? 'username skype';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie sed risus porta in elit rhoncus.
Purus scelerisque curabitur enim in turpis. Sed condimentum eu diam lorem.`;

  const userRole = role || 'Marketing';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';

  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(3).fill({
    school: 'University of Tech',
    degree: 'Bachelor Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'Figma',
    name: 'Figma',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;
  const userInterest = interest ?? ['Football', 'Photography', 'Basketball', 'Swimming', 'Singing', 'Music'];
  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'facebook' },
    { type: 'Instagram', link: 'instagram' },
    { type: 'LinkedIn', link: 'linkedin' },
    { type: 'Twitter', link: 'twitter' },
    { type: 'Github', link: 'github' },
  ];
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'Skype', link: userSkype },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(2).fill({
    role: 'ENTER YOUR JOB TITLE HERE',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2016',
    to_year: '01-01-2018',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyCertifications: ProfileCertificate[] = Array(2).fill({
    certificate_id: 'Certification ID',
    date: '2018-02-02',
    organizer: 'Organizer Name',
    certificate: 'ENTER CERTIFICATION HERE',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium.',
  });
  const userCertifications = certifications ?? dummyCertifications;

  const dummyReference: ProfileReference[] = Array(1).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    company: 'Company / Organization Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e8e8e8', color: 'black' } : {};
  };

  const [fontSizeRatio, cvColor, t] = useCVHelper(props);

  const renderPhoto = () => {
    if (!isShowAvatar) return [];
    return [
      <Box key="profile" p="1.2em 10% 0.4em" {...activeColors(1)}>
        <Box className="u-flex-center" mb=".16em">
          <Box height="6em" minWidth="6em" maxWidth="6em">
            <img height="100%" width="100%" src={userAvatar} alt="" className="u-border-rounded o-base-cv__avatar" />
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box
        key="summary"
        {...activeColors(1)}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        alignItems="start"
        width="100%"
        p="1em 1.6em"
      >
        <Box width="100%" display="flex" alignItems="center">
          <LineCircles right rightCircle activeColors={activeColors(1)} />

          <SubTitle name="About Me" fontWeight={700} activeColors={activeColors(1)} color="#484545" />
          <LineCircles right activeColors={activeColors(1)} />
        </Box>
        <AQuillContent
          color="#484545"
          textAlign="left"
          content={userAboutMe}
          fontWeight={400}
          lineHeight="1.5em"
          fontSize=".5em"
          mt="1em"
        />
      </Box>,
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skills" width="100%" p="1em 1.6em 0" {...activeColors(4)}>
        <Box width="100%" display="flex" alignItems="center">
          <LineCircles rightCircle activeColors={activeColors(4)} />
          <SubTitle name="Skills" fontWeight={700} activeColors={activeColors(4)} />
          <LineCircles activeColors={activeColors(4)} />
        </Box>
      </Box>,
      ...userSkills.map((item, idx) => (
        <SkillItem
          isShowProficiency={isShowProficiency}
          key={idx + item.name}
          title={item.name}
          point={item.points}
          theme={cvColor}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const renderInterest = () => {
    if (isEmpty(userInterest)) return [];
    return [
      <Box key="interests" p=".6em 8% .8em" {...activeColors(5)}>
        <Box width="100%" display="flex" alignItems="center">
          <LineCircles rightCircle activeColors={activeColors(5)} />
          <SubTitle name="Interests" fontWeight={700} activeColors={activeColors(5)} />
          <LineCircles activeColors={activeColors(5)} />
        </Box>
      </Box>,
      ...userInterest.map((item, index) => (
        <Box
          key={index + item}
          p=".2em 8%"
          display="flex"
          gridGap=".4em"
          alignItems="center"
          color="#ffffff"
          {...activeColors(5)}
          width={1}
        >
          <Box bgcolor="currentColor" width=".2em" height=".2em" borderRadius="50%" />
          <Box fontSize=".5em">{item}</Box>
        </Box>
      )),
    ];
  };

  const renderContact = () => {
    if (isEmpty(contacts)) return [];
    return [
      <Box key="contact" p="1em 1.6em .5em" {...activeColors(1)}>
        <Box width="100%">
          <Box width="100%" display="flex" alignItems="center">
            <LineCircles rightCircle activeColors={activeColors(1)} />
            <SubTitle name="Contact" fontWeight={700} activeColors={activeColors(1)} />
            <LineCircles activeColors={activeColors(1)} />
          </Box>
        </Box>
      </Box>,
      ...contacts.map(
        (item, idx) =>
          !!item.link.length && (
            <UserContactsBox
              key={`${item.type}${idx}`}
              type={item.type}
              link={item.link}
              activeColors={activeColors(1)}
            />
          ),
      ),
    ];
  };

  const renderLanguages = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="languages" {...activeColors(5)} width="100%" p="1em 1.6em .5em">
        <Box width="100%" display="flex" alignItems="center">
          <LineCircles rightCircle activeColors={activeColors(5)} />
          <SubTitle name="Languages" fontWeight={700} activeColors={activeColors(5)} />
          <LineCircles activeColors={activeColors(5)} />
        </Box>
      </Box>,
      ...userLanguages.map((item, index) => (
        <UserLanguageInfo key={`${item.name}${index}`} language={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderReference = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box key="references" {...activeColors(5)} width="100%" p="1.5em 1.6em 0">
        <Box width="100%" display="flex" alignItems="center">
          <LineCircles rightCircle activeColors={activeColors(5)} />
          <SubTitle name="References" fontWeight={700} activeColors={activeColors(5)} />
          <LineCircles activeColors={activeColors(5)} />
        </Box>
      </Box>,
      ...userReference.map((item, index) => (
        <UserReference key={`${item.name}${index}`} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderSocialMedias = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box
        key="socialMedias"
        display="flex"
        width="100%"
        p="1em 1.6em"
        justifyContent="space-between"
        flexWrap="wrap"
        gridGap=".8em"
        {...activeColors(1)}
      >
        {userSocialMediaShowed.map((item, idx) => (
          <UserSocialBox
            key={`${item.type}${idx}`}
            type={item.type}
            img={item.type}
            link={item.link}
            activeColors={activeColors(1)}
          />
        ))}
      </Box>,
    ];
  };

  const renderName = () => {
    return [
      <Box
        key="name"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bgcolor={cvColor}
        {...activeColors(1)}
        paddingX="1.5em"
      >
        <Box mt="1.3em" width="100%">
          <Box
            fontSize="1.7em"
            fontWeight={700}
            color="#212121"
            textAlign="center"
          >{`${userFirstName} ${userLastName}`}</Box>
          <Box width="100%" bgcolor="#484545" height=".1em" />
          <Box width="100%" mb=".5em" color="#4E4B4B" fontSize=".7em" fontWeight={700} textAlign="center" pt=".4em">
            {userRole}
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderExperiences = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experiences" {...activeColors(2)} p="1em 1.6em 0">
        <Box>
          <Box width="100%" display="flex" alignItems="center">
            <LineCircles right rightCircle activeColors={activeColors(2)} />
            <SubTitle name="Work Experience" color="#484545" activeColors={activeColors(2)} />
            <LineCircles right activeColors={activeColors(2)} />
          </Box>
        </Box>
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`${item.companyInfo}${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderEducations = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="educations" {...activeColors(3)} p=".5em 1.6em 0.5em">
        <Box>
          <Box width="100%" display="flex" mt=".1em" alignItems="center">
            <LineCircles right rightCircle activeColors={activeColors(3)} />
            <SubTitle name="Education" color="#484545" activeColors={activeColors(3)} />
            <LineCircles right activeColors={activeColors(3)} />
          </Box>
        </Box>
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderCertifications = () => {
    if (isEmpty(userCertifications)) return [];
    return [
      <Box key="certifications" {...activeColors(5)} p="1em 1.6em 0">
        <Box width="100%" display="flex" mt=".1em" alignItems="center">
          <LineCircles right rightCircle activeColors={activeColors(5)} />
          <SubTitle name="Certification" color="#484545" activeColors={activeColors(5)} />
          <LineCircles right activeColors={activeColors(5)} />
        </Box>
      </Box>,
      ...userCertifications.map((item, index) => (
        <UserCertification key={`${item.certificate}${index}`} data={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [
      ...renderName(),
      ...renderSummary(),
      ...renderExperiences(),
      ...renderEducations(),
      ...renderCertifications(),
    ],
    rightComponents: [
      ...renderPhoto(),
      ...renderContact(),
      ...renderSkills(),
      ...renderInterest(),
      ...renderLanguages(),
      ...renderReference(),
      ...renderSocialMedias(),
    ],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont}>
      <Box minHeight="54.164em" height="54.164em" display="flex" position="relative" color="#71726E" width="100%">
        <Box flex={50} maxHeight="100%" height="100%" id={idLeft} overflow="hidden" fontSize={fontSizeRatio}>
          {renderedLeft}
          <Box height="1.6em" />
        </Box>
        <Box
          maxHeight="100%"
          height="100%"
          id={idRight}
          overflow="hidden"
          bgcolor="#171818"
          flex={50}
          fontSize={fontSizeRatio}
        >
          {renderedRight}
          <Box height="1.6em" />
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0025_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0025;
