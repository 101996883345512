import { FC } from 'react';
import Box from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { formatDate } from 'utils/date';
import { getCVAvatar, getTime, useCVHelper, useCvOneLayout } from 'helpers';
import { ActiveColors, ProfileEducation, ProfileExperience } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import MCvWatermark from 'components/molecules/MCvWatermark';
import FREE_0002_BACK from './back';
import { OCVProps, previewType } from '../../index';

const box6em = (title: string) => (
  <Box component="span" fontSize="0.6em">
    {title}
  </Box>
);

const ProgessBox: FC<{ title: string; percent: number; color: string; activeColors: ActiveColors }> = ({
  title,
  percent,
  color,
  activeColors,
}) => {
  return (
    <Box display="inline-flex" alignItems="baseline" width="calc(38.3em/3)" p="0.4em 0 0.4em 1.2em" {...activeColors}>
      <Box fontSize="0.6em" width="100%">
        {title}
      </Box>
      <Box minWidth="50%" display="flex" alignItems="center" justifyContent="flex-end" color={color} pr="2em">
        <Box display="flex" lineHeight="1.6em">
          {Array.from(Array(5), (_, index) => (
            <Box
              key={index}
              width="0.4em"
              height="0.4em"
              m="0.2em"
              border={`1px solid ${color}`}
              bgcolor={index <= percent / 20 ? color : ''}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
const SkillItem: FC<{
  title: string;
  point: number;
  isShowProficiency: boolean;
  color: string;
  activeColors: ActiveColors;
}> = ({ title, point, isShowProficiency, color, activeColors }) => {
  if (isShowProficiency) {
    return <ProgessBox title={title} percent={(point * 100) / 5} color={color} activeColors={activeColors} />;
  }
  return (
    <Box
      display="inline-flex"
      gridGap=".2em"
      alignItems="center"
      width="calc(54.164em/3)"
      p="0.4em 0 0.4em 1.2em"
      {...activeColors}
    >
      <Box bgcolor={color} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserLinkInfo: FC<{ title: string; info: string; isHalf?: boolean }> = ({ title, info, isHalf = false }) => {
  return (
    <Box
      display="flex"
      mt="0.8em"
      flexGrow={1}
      lineHeight="0.817em"
      {...(isHalf && info.length <= 15 ? { width: 'calc(50% - 0.5em)' } : {})}
    >
      <Box minWidth="3.6em" display="flex" justifyContent="space-between">
        {box6em(title)}
        <Box fontSize="0.6em" fontWeight={400} pr="0.2em">
          :
        </Box>
      </Box>
      {box6em(info)}
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, company, activities } = cvExperience;
  const { time } = getTime(cvExperience);
  return (
    <Box display="flex" lineHeight="1.6em" p="0.4em 1.2em" {...activeColors}>
      <Box minWidth="7em" color="#757575">
        {box6em(time)}
      </Box>
      <Box flexGrow={1}>
        <Box fontWeight={600} letterSpacing="0.04em">
          <Box fontSize="0.6em">
            <b>{role}</b> at {company}
          </Box>
        </Box>
        <Box className="u-pre-line" mt="0.1em">
          <AQuillContent content={activities} fontSize="0.6em" />
        </Box>
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, degree, activities } = cvEducation;
  const { time } = getTime(cvEducation);
  return (
    <Box display="flex" lineHeight="1.6em" p="0.4em 1.2em" {...activeColors}>
      <Box minWidth="7em" color="#757575">
        {box6em(time)}
      </Box>
      <Box flexGrow={1}>
        <Box fontWeight={600} letterSpacing="0.04em">
          <Box fontSize="0.6em">
            <b>{school}</b> - {degree}
          </Box>
        </Box>
        <Box className="u-pre-line" mt="0.1em">
          <AQuillContent content={activities} fontSize="0.6em" />
        </Box>
      </Box>
    </Box>
  );
};

const FREE_0002: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    preview = previewType.cvDemoThumbnail,
    isActive = 0,
    textSize,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills } = cvInfo || { basicInfo: {} };
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    date_of_birth,
    skype,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const userAvatar = getCVAvatar(avatar, preview);
  const fullName = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : 'Jhon Dee';
  const userEmail = cv_email || 'jhondee@network.com';
  const userAboutMe =
    summary ??
    `Front End Developer is to express my interest in the role posted on your website for an experienced, detailed-oriented, front-end developer. As you'll see, I have six years of hands-on experience efficiently coding websites and applications using modern HTML, CSS, React and JavaScript. Building state-of-the-art, easy to use, user-friendly websites and applications is truly a passion of mine and I am confident I would be an excellent addition to your organization.`;
  let userDateOfBirth = date_of_birth === undefined ? '16 July 1994' : date_of_birth;
  userDateOfBirth = userDateOfBirth ? formatDate(userDateOfBirth, 'DD MMMM YYYY') : userDateOfBirth;
  const userRole = role || 'Front End Developer';
  const userAddress = address || 'Melbourne Street Number 4';
  const userCity = city || 'California, New York City';
  const userCountry = country || 'US';
  const userPhoneNumber = mobile_number || '667-0890-231';
  const userSkype = skype ?? 'User Skype';
  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'User Facebook' },
    { type: 'Instagram', link: 'User Instagram' },
    { type: 'LinkedIn', link: 'User LinkedIn' },
    { type: 'Twitter', link: 'User Twitter' },
    { type: 'Website', link: 'User Website' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, [(e) => e.link.length], ['asc']);

  const dummyExperiences = Array(2).fill({
    role: 'Front End Developer',
    company: 'Google',
    location: 'San Francisco',
    from_year: '01-01-2019',
    to_year: 'present',
    finished: false,
    activities: `- Responsible for creating, improving and developing IT Projects.
- Make the appearance according to the project or product requirements signed.
- Perform iteration and bug fixing for application iterations. QA test with a QA teams`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(1).fill({
    school: 'University Name',
    degree: 'Degree Name',
    grade: '4.75',
    major: 'Front End Developer',
    from_year: '01-05-2019',
    to_year: 'present',
    finished: false,
    activities: `- Responsible for creating, improving and developing IT Projects.
- Make the appearance according to the project or product requirements.`,
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#CCEAFC' } : {};
  };

  const renderProfile = () => {
    return [
      <Box key="profile" {...activeColors(1)}>
        <Box p="1.3em 1.2em 0 1.2em">
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            {isShowAvatar && (
              <Box height="4em" minWidth="4em" maxWidth="4em" mr="1em">
                <img
                  height="100%"
                  width="100%"
                  src={userAvatar}
                  alt=""
                  className="u-border-rounded o-base-cv__avatar"
                />
              </Box>
            )}
            <Box flex={1}>
              <Box fontWeight={700} lineHeight="1.907em" mt="0.5em">
                <Box fontSize="1.2em">{fullName}</Box>
              </Box>
              <Box lineHeight="1.6em" letterSpacing="0.04em" mb="0.4em">
                <Box fontSize="1em">{userRole}</Box>
              </Box>
            </Box>
          </Box>
          {userDateOfBirth && <UserLinkInfo title="Date Of Birth" info={userDateOfBirth as string} />}
          <Box display="flex" gridGap="1em">
            <Box minWidth="33%">
              <UserLinkInfo title="Phone" info={userPhoneNumber} />
              <UserLinkInfo title="Email" info={userEmail} />
              <UserLinkInfo title="Address" info={`${userAddress}, ${userCity}, ${userCountry}`} />
            </Box>
            <Box flexGrow={1}>
              <Box display="flex" flexWrap="wrap" width="100%" gridColumnGap="1em">
                {!!userSkype.length && <UserLinkInfo title="Skype" info={userSkype} isHalf />}
                {userSocialMediaShowed.map((item) => (
                  <UserLinkInfo key={item.type} title={item.type} info={item.link} isHalf />
                ))}
              </Box>
            </Box>
          </Box>
          {userAboutMe.trim() && (
            <>
              <Box fontWeight={600} mt="1.2em">
                <Box fontSize="1em">About me</Box>
              </Box>
              <Box mt="0.4em" pb="0.4em" className="u-pre-line" lineHeight="0.817em">
                <AQuillContent content={userAboutMe} fontSize="0.6em" />
              </Box>
            </>
          )}
        </Box>
      </Box>,
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experience_header" p="0.4em 1.2em 0" {...activeColors(2)}>
        <Box fontSize="1em" fontWeight={600}>
          Work Experience
        </Box>
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo
          key={`experience-${index}${item.companyInfo}`}
          cvExperience={item}
          activeColors={activeColors(2)}
        />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education_header" p="0.4em 1.2em 0" {...activeColors(3)}>
        <Box lineHeight="1.6em" fontWeight={600}>
          <Box fontSize="1em">Education</Box>
        </Box>
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`education-${index}${item.school}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skills_header" p="0.4em 1.2em 0" {...activeColors(4)}>
        <Box lineHeight="1.6em" fontWeight={600}>
          <Box fontSize="1.2em">Skills</Box>
        </Box>
      </Box>,
      <Box key="skills" width="100%" maxWidth="100%" mt="0.4em">
        {userSkills.map(({ name, points }, index) => (
          <SkillItem
            key={`skill${name}${index}${points}`}
            title={name}
            point={points}
            isShowProficiency={isShowProficiency}
            color={cvColor}
            activeColors={activeColors(4)}
          />
        ))}
      </Box>,
    ];
  };

  const [isShowOtherPage, rendered, pageId, content] = useCvOneLayout({
    components: [...renderProfile(), ...renderExperience(), ...renderEducation(), ...renderSkills()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize}>
      <Box minHeight="54.164em" height="52.164em" position="relative">
        <Box id={pageId} height="100%" overflow="hidden" fontSize={fontSizeRatio}>
          {rendered}
          <Box height="2.6em" fontSize={textSize} />
        </Box>
        <MCvWatermark />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize="1.5em">{t('common.pageBreak')}</Box>
            </Box>
          )}
          <FREE_0002_BACK
            textSize={textSize}
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContent={content}
          />
        </>
      )}
    </Box>
  );
};

export default FREE_0002;
