import { FC } from 'react';
import Box from '@material-ui/core/Box';
import useTranslation from 'next-translate/useTranslation';
import { useCvTwoLayoutBack } from 'helpers';
import { OCVProps, previewType } from '../../index';

const PREMIUM_0017_BACK: FC<OCVProps> = (props) => {
  const { t } = useTranslation('common');
  const [
    isShowOtherPage,
    renderedLeft,
    renderedRight,
    idLeft,
    idRight,
    contentLeft,
    contentRight,
    preview,
    savedCvColour,
    fontSizeRatio,
  ] = useCvTwoLayoutBack({
    breakComponent: <Box key="too-large">{t('common.contentTooLong')}</Box>,
    props,
  });

  return (
    <>
      <Box position="relative" minHeight="54.164em" height="54.164em">
        <Box height="54.164em" overflow="hidden" display="flex" flexDirection="column">
          <Box position="relative">
            <Box display="flex" height="54.164em">
              <Box height="100%" id={idLeft} overflow="hidden" flex={1} fontSize={fontSizeRatio}>
                <Box height=".6em" />
                {renderedLeft}
              </Box>
              <Box minWidth="52%" maxWidth="52%" id={idRight} height="100%" overflow="hidden" fontSize={fontSizeRatio}>
                <Box height=".6em" />
                {renderedRight}
              </Box>
            </Box>
            <Box width="100%" height="1.2em" position="absolute" bottom={0} bgcolor={savedCvColour} />
          </Box>
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0017_BACK
            preview={preview}
            savedCvColour={savedCvColour}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </>
  );
};

export default PREMIUM_0017_BACK;
