import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AAddress: FC<ISocialIcon> = ({
  fill = 'black',
  width = '14',
  height = '16',
  stroke = 'black',
  strokeWidth = '0',
  pl = '2',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ paddingLeft: pl }}
    >
      <path
        d="M10.1048 10.2548L9.31352 11.0374C8.73027 11.6099 7.97351 12.3458 7.04295 13.2456C6.46146 13.8078 5.5389 13.8077 4.9575 13.2454L2.6301 10.9815C2.33759 10.6943 2.09274 10.4521 1.89552 10.2548C-0.371423 7.9879 -0.371423 4.31247 1.89552 2.04553C4.16246 -0.221413 7.83789 -0.221413 10.1048 2.04553C12.3718 4.31247 12.3718 7.9879 10.1048 10.2548ZM7.66698 6.33332C7.66698 5.41277 6.92072 4.66652 6.00018 4.66652C5.07963 4.66652 4.33338 5.41277 4.33338 6.33332C4.33338 7.25387 5.07963 8.00012 6.00018 8.00012C6.92072 8.00012 7.66698 7.25387 7.66698 6.33332Z"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
