import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { AppState } from 'redux-saga/interfaces';
import { CvInfo } from 'components/organisms/Onboarding/interface';
import FREE_0001 from './free/FREE_0001';
import FREE_0002 from './free/FREE_0002';
import FREE_0003 from './free/FREE_0003';
import FREE_0006 from './free/FREE_0006';
import FREE_0007 from './free/FREE_0007';

import PREMIUM_0001 from './premiums/PREMIUM_0001';
import PREMIUM_0002 from './premiums/PREMIUM_0002';
import PREMIUM_0003 from './premiums/PREMIUM_0003';
import PREMIUM_0004 from './premiums/PREMIUM_0004';
import PREMIUM_0005 from './premiums/PREMIUM_0005';
import PREMIUM_0006 from './premiums/PREMIUM_0006';
import PREMIUM_0007 from './premiums/PREMIUM_0007';
import PREMIUM_0008 from './premiums/PREMIUM_0008';
import PREMIUM_0009 from './premiums/PREMIUM_0009';
import PREMIUM_0010 from './premiums/PREMIUM_0010';
import PREMIUM_0011 from './premiums/PREMIUM_0011';
import PREMIUM_0012 from './premiums/PREMIUM_0012';
import PREMIUM_0013 from './premiums/PREMIUM_0013';
import PREMIUM_0014 from './premiums/PREMIUM_0014';
import PREMIUM_0015 from './premiums/PREMIUM_0015';
import PREMIUM_0016 from './premiums/PREMIUM_0016';
import PREMIUM_0017 from './premiums/PREMIUM_0017';
import PREMIUM_0018 from './premiums/PREMIUM_0018';
import PREMIUM_0019 from './premiums/PREMIUM_0019';
import PREMIUM_0020 from './premiums/PREMIUM_0020';
import PREMIUM_0021 from './premiums/PREMIUM_0021';
import PREMIUM_0022 from './premiums/PREMIUM_0022';
import PREMIUM_0023 from './premiums/PREMIUM_0023';
import PREMIUM_0024 from './premiums/PREMIUM_0024';
import PREMIUM_0025 from './premiums/PREMIUM_0025';

export enum previewType {
  cvDemoThumbnail = 7,
  onListMyCVs = 9.164, // 351px / 38.3 em
  onColorPickerPopup = 10,
  onCvCheck = 11,
  preview = 16,
  sharedCv = 20,
  printPreview = 9.4,
  printPreviewDialog = 21,
  downloadCvImg = 64.752, // 2480px / 38.3 em
}

export interface OCVProps {
  cvInfo?: CvInfo;
  preview: previewType;
  isActive?: number;
  textSize?: number;
  savedCvCode?: string;
  savedCvColour?: string;
  fontSizeRatio?: number;

  overflowedContent?: unknown[];
  overflowedContentLeft?: unknown[];
  overflowedContentRight?: unknown[];

  savedCvFont?: string;
  savedCvFontSize?: number;
  isShowAvatar?: boolean;
  isShowProficiency?: boolean;
}

const Components = {
  FREE_0001,
  FREE_0002,
  FREE_0003,
  FREE_0006,
  FREE_0007,
  PREMIUM_0001,
  PREMIUM_0002,
  PREMIUM_0003,
  PREMIUM_0004,
  PREMIUM_0005,
  PREMIUM_0006,
  PREMIUM_0007,
  PREMIUM_0008,
  PREMIUM_0009,
  PREMIUM_0010,
  PREMIUM_0011,
  PREMIUM_0012,
  PREMIUM_0013,
  PREMIUM_0014,
  PREMIUM_0015,
  PREMIUM_0016,
  PREMIUM_0017,
  PREMIUM_0018,
  PREMIUM_0019,
  PREMIUM_0020,
  PREMIUM_0021,
  PREMIUM_0022,
  PREMIUM_0023,
  PREMIUM_0024,
  PREMIUM_0025,
};

const OCV: FC<OCVProps> = (props) => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    cvSelected,
    cvSettings: { currentCvFont },
  } = useSelector((state: AppState) => state);
  const {
    cvInfo,
    preview,
    isActive,
    savedCvCode = null,
    savedCvColour = null,
    savedCvFont = null,
    savedCvFontSize = null,
    isShowAvatar,
    isShowProficiency,
  } = props;

  const cvCode = savedCvCode || cvSelected.code;

  if (typeof Components[cvCode] !== 'undefined') {
    let size = preview;
    if (preview === previewType.sharedCv && isSmDown) {
      size = savedCvFontSize * 0.75;
    }
    const content = React.createElement(Components[cvCode], {
      key: cvCode,
      cvInfo,
      preview,
      isActive,
      textSize: size,
      savedCvColour,
      savedCvFont,
      savedCvFontSize,
      isShowAvatar,
      isShowProficiency,
    });

    return React.createElement('div', { style: { fontFamily: savedCvFont || currentCvFont }, code: cvCode }, content);
  }

  return React.createElement(() => <div>The component {cvCode} has not been created yet.</div>, {
    key: cvCode,
  });
};

export default OCV;
