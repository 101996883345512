import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import styled from '@emotion/styled';

import { getCVAvatar, getLanguageLevel } from 'helpers';
import { getYearOrExample } from 'utils/date';
import { AppState, ProfileEducation, ProfileExperience, ProfileLanguage } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AEmail,
  AHome,
  AMobilePhone,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedTwitter,
} from 'components/atoms/icons';

import { OCVProps, previewType } from '../index';

const SkillItem: FC<{ title: string; point: number; theme: string; isShowProficiency: boolean }> = ({
  title,
  point,
  theme,
  isShowProficiency,
}) => {
  const percent = (point / 5) * 100;
  if (isShowProficiency) {
    return <CircularProgressWithLabel name={title} value={percent} theme={theme} />;
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center">
      <Box bgcolor={theme} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number; name: string; theme: string }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', width: '3.5em', height: '3.5em' }}>
      <CircularProgress
        style={{ zIndex: 3, position: 'relative', color: props.theme, width: '3.5em', height: '3.5em' }}
        variant="determinate"
        {...props}
      />
      <CircularProgress
        style={{ zIndex: 1, position: 'absolute', color: '#B8CFCD', width: '3.5em', height: '3.5em' }}
        variant="determinate"
        {...props}
        value={100}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
        }}
        className="u-flex-center"
      >
        <Box
          className="u-flex-center"
          flexDirection="column"
          bgcolor="transparent"
          width="70%"
          height="70%"
          borderRadius="50%"
        >
          <Box fontSize=".5em">{props.name}</Box>
        </Box>
      </Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience }> = ({ cvExperience }) => {
  const { role, from_year, to_year, finished, activities, company } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box fontWeight={400} display="flex" flexDirection="column" gridGap=".4em">
      <Box fontWeight={700} fontSize=".8em">{`${company} - ${role}`}</Box>
      <AQuillContent fontWeight={400} fontSize=".6em" content={activities} />
      <Box fontSize=".6em">{`${joinDate} - ${leaveDate}`}</Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation }> = ({ cvEducation }) => {
  const { school, to_year, finished, from_year, activities, degree } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box fontWeight={400} display="flex" flexDirection="column" gridGap=".4em">
      <Box fontWeight={700} fontSize=".8em">{`${school} - ${degree}`}</Box>
      <AQuillContent fontWeight={400} fontSize=".6em" content={activities} />
      <Box fontSize=".6em">{`${joinDate} - ${leaveDate}`}</Box>
    </Box>
  );
};

const UserSocialBox: FC<{ img: string; theme: string; link: string }> = ({ img, theme, link }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    Instagram: AOutlinedInstagram,
  };

  const svgIcons = () => {
    const SVG = componentIcons[img];
    return <SVG fill="#132A39" stroke="#132A39" strokeWidth="0" width="100%" height="100%" />;
  };
  return (
    <Box display="flex" gridGap=".4em" alignItems="center">
      <Box borderRadius="50%" bgcolor={theme} className="u-flex-center" width="1.6em" height="1.6em">
        <Box width="60%" height="60%">
          {img && svgIcons()}
        </Box>
      </Box>
      <Box fontWeight={400} fontSize=".8em">
        {link}
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const componentIcons = {
    email: AEmail,
    phone: AMobilePhone,
    address: AHome,
  };
  const svgIcon = () => {
    const SVG = componentIcons[type];
    return <SVG fill="#132A39" strokeWidth="0" width="1.2em" height="1.2em" />;
  };
  return (
    <Box display="flex" gridGap=".8em" alignItems="center" fontWeight={400}>
      <Box>{type && svgIcon()}</Box>
      <Box fontSize=".7em">{link}</Box>
    </Box>
  );
};

const SubTitle: FC<{ name: string; color: string } & BoxProps> = ({ name, color, ...props }) => {
  return (
    <Box
      color="#132A39"
      width="100%"
      py=".2em"
      pl="1.6em"
      fontWeight={400}
      bgcolor={color}
      p=".5em 1.5em .5em .5em"
      {...props}
      style={{ borderBottomRightRadius: '1em', borderTopRightRadius: '1em' }}
      whiteSpace="nowrap"
    >
      <Box fontSize="1.2em">{name}</Box>
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage }> = ({ language }) => {
  const { name, speaking, writing } = language;
  return (
    <Box fontWeight={400} display="flex" justifyContent="space-between">
      <Box fontSize="0.6em">{name}</Box>
      <Box fontSize=".5em">
        Write: {getLanguageLevel(writing)} &bull; Spoken: {getLanguageLevel(speaking)}
      </Box>
    </Box>
  );
};

const PREMIUM_0013: FC<OCVProps> = (props) => {
  const {
    cvSettings: { currentCvColor, currentCvFontSize },
  } = useSelector((state: AppState) => state);
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvColour = null,
    savedCvFontSize,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, languages } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const fontSize = Math.round(((savedCvFontSize || currentCvFontSize) / 16) * textSize);
  const cvColor = savedCvColour || currentCvColor;
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'MARK SMITH';
  const userLastName = last_name || ' ';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userAboutMe =
    summary ??
    `Viverra augue nisi, vestibulum sit nec. Facilisi duis a, vitae pellentesque nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. `;

  const userRole = role || 'WEB DEVELOPER';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';

  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(1).fill({
    school: 'University of Tech',
    degree: 'Bachelor Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(4).fill({
    skill: 'PHP',
    name: 'PHP',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'marksmith' },
    { type: 'Instagram', link: 'marksmith' },
    { type: 'Twitter', link: 'marksmith' },
  ];
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    // { type: 'Skype', link: userSkype },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  let userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);
  const ignoredSocialMedia = ['Website', 'LinkedIn', 'Github', 'Dribbble'];
  userSocialMediaShowed = userSocialMediaShowed.filter((item) => !ignoredSocialMedia.includes(item.type));

  const dummyExperiences = Array(1).fill({
    role: 'Junior Data',
    company: 'Tech Company',
    location: 'Location',
    from_year: '01-01-2016',
    to_year: '01-01-2018',
    finished: false,
    activities: `Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  return (
    <Box className="o-base-cv" fontSize={textSize}>
      <Box
        minHeight="54.164em"
        height="100%"
        display="flex"
        flexDirection="column"
        position="relative"
        fontSize={fontSize || 'inherit'}
        bgcolor="#132A39"
        color="white"
      >
        <Box display="flex" position="relative" zIndex="3" pb="1.6em">
          <Box flex={52}>
            <Box p="1.6em" bgcolor={isActive === 1 ? '#e8e8e8' : ''} height="12.4em">
              <Box
                fontSize="1.8em"
                fontWeight={400}
                className="u-text-uppercase"
              >{`${userFirstName} ${userLastName}`}</Box>
              <Box fontSize=".8em" fontWeight={400} m=".4em 0 1.6em 0" className="u-text-uppercase">
                {userRole}
              </Box>
              {!!userSocialMediaShowed.length && (
                <Box display="flex" flexWrap="wrap" flexDirection="row" gridGap=".8em">
                  {userSocialMediaShowed.map((item, idx) => (
                    <Box
                      display="flex"
                      flexDirection="column"
                      flex={idx === 2 ? 1 : undefined}
                      flexBasis={idx === 2 ? '100%' : undefined}
                      key={idx}
                    >
                      <UserSocialBox theme={cvColor} img={item.type} link={item.link} />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            {userAboutMe.trim() && (
              <Box p="1.6em 1.7em 1.2em 0" bgcolor={isActive === 1 ? '#e8e8e8' : ''}>
                <SubTitle name="About Me" color={cvColor} />
                <Box m=".8em 0 0 1.6em">
                  <AQuillContent content={userAboutMe} fontWeight={400} fontSize=".8em" />
                </Box>
              </Box>
            )}
            {!!userEducation.length && (
              <Box mt=".8em" p="0 1.7em 1.2em 0" bgcolor={isActive === 3 ? '#e8e8e8' : ''}>
                <SubTitle name="Education" color={cvColor} />
                <Box display="flex" flexDirection="column" gridGap=".8em" m=".8em 0 0 1.6em">
                  {userEducation.map((item, index) => (
                    <UserEducationInfo key={index} cvEducation={item} />
                  ))}
                </Box>
              </Box>
            )}
            {!!userExperiences.length && (
              <Box mt=".8em" p="0 1.7em 1.2em 0" bgcolor={isActive === 2 ? '#e8e8e8' : ''}>
                <SubTitle name="Experience" color={cvColor} />
                <Box display="flex" flexDirection="column" gridGap=".8em" m=".8em 0 0 1.6em">
                  {userExperiences.map((item, index) => (
                    <UserExperienceInfo key={index} cvExperience={item} />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          <Box flex={48} color="#132A39">
            {isShowAvatar && (
              <Box p="1.6em 1.8em .9em 1.6em" bgcolor={isActive === 1 ? '#e8e8e8' : ''}>
                <RotatedBox top="2em" left="5em" height="8em" width="8em" position="relative" rotate={45}>
                  <Box
                    position="absolute"
                    top="5%"
                    left="67%"
                    height="7.2em"
                    minWidth="7.2em"
                    maxWidth="7.2em"
                    style={{ transform: 'rotate(-45deg) translate(-50%, -50%)' }}
                  >
                    <img
                      height="100%"
                      width="100%"
                      src={userAvatar}
                      alt="profile-pict"
                      className="u-border-rounded o-base-cv__avatar"
                    />
                  </Box>
                </RotatedBox>
              </Box>
            )}
            <Box p={`${isShowAvatar ? '1.6em' : '12em'} 1.8em .9em 1.6em`} bgcolor={isActive === 1 ? '#e8e8e8' : ''}>
              <SubTitle name="Contact" color="transparent" mt="2em" />
              <Box m=".8em 0 0 1.6em" display="flex" flexDirection="column" gridGap=".6em">
                {contacts.map((item, idx) => (
                  <UserContactsBox key={idx} type={item.type} link={item.link} />
                ))}
              </Box>
            </Box>
            {!!userSkills.length && (
              <Box p="1.6em 1.8em .9em 1.6em" bgcolor={isActive === 4 ? '#e8e8e8' : ''}>
                <SubTitle name="Skills" color="transparent" />
                <Box m=".8em 0 0 1.6em" display="flex" flexWrap="wrap" gridGap="1.7em">
                  {userSkills.map((item, idx) => (
                    <SkillItem
                      key={idx}
                      title={item.name}
                      point={item.points}
                      theme="#132A39"
                      isShowProficiency={isShowProficiency}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {!!userLanguages.length && (
              <Box p="1.6em 1.8em .9em 1.6em" bgcolor={isActive === 5 ? '#e8e8e8' : ''}>
                <SubTitle name="Language" color="transparent" />
                <Box m=".8em 0 0 1.6em" display="flex" flexDirection="column" gridGap=".6em">
                  {userLanguages.map((item, index) => (
                    <UserLanguageInfo key={index} language={item} />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box width="29.2em" height="12.4em" position="absolute" zIndex="1" top={0}>
          <TopDecoration color={cvColor} w="100%" h="100%" />
        </Box>
        <Box width="18.6em" height="54.163em" position="absolute" zIndex="1" top={0} right={0}>
          <RightDecoration color={cvColor} w="100%" h="100%" />
        </Box>
        <Box width="19.8em" height="1.6em" position="absolute" zIndex="1" bottom={0} left={0}>
          <BottomDecoration color={cvColor} w="100%" h="100%" />
        </Box>
      </Box>
    </Box>
  );
};

export default PREMIUM_0013;

const RotatedBox = styled(Box)<{ rotate: number }>`
  width: 12em;
  border-radius: 1em;
  height: 12em;
  background-color: #b8cfcd;
  transform: ${(props) => `rotate(${props.rotate}deg);`};
`;

const TopDecoration: FC<{ w: string; h: string; color: string }> = ({ color, w, h }) => {
  return (
    <svg width={w} height={h} viewBox="0 0 292 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.709 84.687C171.419 101.872 148.426 111.335 123.957 111.335H0V124H123.957C151.622 124 178.166 113.084 197.723 93.6425L292 0H273.967L188.709 84.687Z"
        fill={color}
      />
    </svg>
  );
};

const RightDecoration: FC<{ w: string; h: string; color: string }> = ({ color, w, h }) => {
  return (
    <svg width={w} height={h} viewBox="0 0 186 542" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186 0V541.63H30.7432L6.22479 516.779C2.24056 512.743 0 507.274 0 501.561V193.418C0 165.433 10.973 138.596 30.514 118.813L147.784 0H186Z"
        fill={color}
      />
    </svg>
  );
};

const BottomDecoration: FC<{ w: string; h: string; color: string }> = ({ color, w, h }) => {
  return (
    <svg width={w} height={h} viewBox="0 0 198 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198 16H0V0H175.752C179.244 0 182.595 1.32347 185.067 3.67865L198 16Z"
        fill={color}
      />
    </svg>
  );
};
