import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { getCVAvatar, getLanguageLevel, useCVHelper, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import {
  ActiveColors,
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  ADribbble,
  AEmail,
  AGithub,
  AHome,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  ASkype,
  AWebsite,
} from 'components/atoms/icons';
import PREMIUM_0015_BACK from './back';
import { OCVProps, previewType } from '../../index';

const SkillItem: FC<{
  title: string;
  point: number;
  theme: string;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
  fontSizeRatio: number;
}> = ({ title, point, theme, isShowProficiency, activeColors, fontSizeRatio }) => {
  if (isShowProficiency) {
    const size = Math.ceil(fontSizeRatio * 0.4);
    return (
      <Box display="flex" justifyContent="space-between" color="#272726" p=".4em 8%" {...activeColors}>
        <Box fontSize=".5em">{title}</Box>
        <Box display="flex" gridGap=".36em" minWidth="max-content">
          {Array.from(Array(5), (_, index) => (
            <Box
              key={`${title}${index}`}
              minWidth={size}
              width={size}
              height={size}
              m="0.2em"
              borderRadius="100%"
              bgcolor={index + 1 <= point ? '#737874' : '#c9c9c9'}
            />
          ))}
        </Box>
      </Box>
    );
  }
  return (
    <Box alignItems="center" display="flex" gridGap=".2em" p=".8em 8% .5em 8%" {...activeColors}>
      <Box bgcolor={theme} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities, company } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box p=".4em 8%" fontWeight={400} display="flex" flexDirection="column" gridGap=".4em" {...activeColors}>
      <Box fontWeight={700} fontSize=".6em" color="#272826">
        {role}
      </Box>
      <Box fontSize=".6em" color="#272826">
        {company} &bull; {`${joinDate} - ${leaveDate}`}
      </Box>
      <AQuillContent fontWeight={400} fontSize=".5em" content={activities} />
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, to_year, finished, from_year, activities, degree } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box p=".4em 8%" fontWeight={400} display="flex" flexDirection="column" gridGap=".4em" {...activeColors}>
      <Box fontWeight={700} fontSize=".6em" color="#272826">
        {degree}
      </Box>
      <Box fontSize=".6em" color="#272826">
        {school} &bull; {`${joinDate} - ${leaveDate}`}
      </Box>
      <AQuillContent mt=".8em" fontWeight={400} fontSize=".5em" content={activities} />
    </Box>
  );
};

const UserSocialBox: FC<{ img: string; link: string }> = ({ img, link }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    Instagram: AOutlinedInstagram,
    LinkedIn: AOutlinedLinkedin,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG = componentIcons[img];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="60%" height="60%" />;
  };
  return (
    <Box width="40%" display="flex" gridGap=".4em" alignItems="center" color="#272826">
      <Box borderRadius="50%" bgcolor="#272826" className="u-flex-center" width=".8em" height=".8em">
        <Box width="100%" height="100%" className="u-flex-center">
          {img && svgIcons()}
        </Box>
      </Box>
      <Box fontWeight={400} fontSize=".5em">
        {link}
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string; activeColors: ActiveColors }> = ({
  type,
  link,
  activeColors,
}) => {
  const componentIcons = {
    email: AEmail,
    phone: APhone,
    address: AHome,
    Skype: ASkype,
  };
  const svgIcon = () => {
    const SVG = componentIcons[type];
    return <SVG fill="#132A39" strokeWidth="0" width=".8em" height=".8em" />;
  };
  return (
    <Box
      display="flex"
      color="#494946"
      gridGap=".8em"
      p=".2em 8%"
      alignItems="center"
      fontWeight={400}
      {...activeColors}
    >
      <Box>{type && svgIcon()}</Box>
      <Box fontSize=".6em">{link}</Box>
    </Box>
  );
};

const SubTitle: FC<{ name: string; padding?: string; width?: string } & BoxProps> = ({
  name,
  padding = 0,
  width = '100%',
  ...props
}) => {
  return (
    <Box color="#272826" width={width} whiteSpace="nowrap" {...props} pb=".4em" borderBottom="1px solid #272826">
      <Box fontSize=".8em" fontWeight={400} pl={padding}>
        {name}
      </Box>
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage }> = ({ language }) => {
  const { name, speaking, writing } = language;
  return (
    <Box fontWeight={400} width="40%" color="#272726" flexDirection="column" gridGap=".4em" display="flex">
      <Box fontSize=".5em" fontWeight={700}>
        {name}
      </Box>
      <Box fontSize=".5em">Write: {getLanguageLevel(writing)}</Box>
      <Box fontSize=".5em">Spoken: {getLanguageLevel(speaking)}</Box>
    </Box>
  );
};

const UserCertification: FC<{ data?: ProfileCertificate; activeColors: ActiveColors }> = ({ data, activeColors }) => {
  const { certificate, date, certificate_id, organizer } = data;
  const year = moment(date, 'YYYY-MM-DD').year();

  return (
    <Box p=".4em 8%" fontWeight={400} display="flex" flexDirection="column" gridGap=".4em" {...activeColors}>
      <Box fontWeight={700} fontSize=".6em" color="#272826">
        {certificate}
      </Box>
      <Box
        fontSize=".6em"
        color="#272826"
        dangerouslySetInnerHTML={{
          __html: `${organizer}${certificate_id && ` &bull; ${certificate_id}`} &bull; ${year}`,
        }}
      />
    </Box>
  );
};

const PREMIUM_0015: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, certifications, languages } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    skype,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'MARK SMITH';
  const userLastName = last_name || ' ';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userSkype = skype ?? 'username skype';
  const userAboutMe =
    summary ??
    `Viverra augue nisi, vestibulum sit nec. Facilisi duis a, vitae pellentesque nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. `;

  const userRole = role || 'WEB DEVELOPER';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';

  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University of Tech',
    degree: 'Bachelor Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'PHP',
    name: 'PHP',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: '@SARA' },
    { type: 'Instagram', link: '@SARA' },
    { type: 'LinkedIn', link: '@SARA' },
    { type: 'Twitter', link: '@SARA' },
    { type: 'Website', link: 'sara.com' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'Skype', link: userSkype },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(3).fill({
    role: 'Junior Data',
    company: 'Tech Company',
    location: 'Location',
    from_year: '01-01-2016',
    to_year: '01-01-2018',
    finished: false,
    activities: `Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;
  const dummyCertifications: ProfileCertificate[] = Array(4).fill({
    certificate_id: 'Certification ID',
    date: '2021-02-02',
    organizer: 'AWS',
    certificate: 'AWS Certification Professional',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium.',
  });
  const userCertifications = certifications ?? dummyCertifications;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e8e8e8' } : {};
  };

  const renderProfile = () => {
    return [
      <Box key="profile" p="1.6em 8% 0" {...activeColors(1)}>
        {isShowAvatar && (
          <Box height="8em" minWidth="8em" maxWidth="8em" margin="auto">
            <img
              height="100%"
              width="100%"
              src={userAvatar}
              alt="profile-pict"
              className="u-border-rounded o-base-cv__avatar"
            />
          </Box>
        )}
        <Box
          pt=".7em"
          fontSize="1.8em"
          fontWeight={400}
          className="u-text-uppercase"
          textAlign="center"
          color="#272826"
        >{`${userFirstName} ${userLastName}`}</Box>
        <Box width="100%" bgcolor="#272826" height=".1em" />
        <Box color="#494946" textAlign="center" fontSize=".8em" fontWeight={400} p=".4em 0 1.6em 0">
          {userRole}
        </Box>
        {userAboutMe.trim() && (
          <Box>
            <SubTitle name="ABOUT ME" textAlign="center" />
            <Box mt=".4em">
              <AQuillContent content={userAboutMe} fontWeight={400} fontSize=".5em" />
            </Box>
          </Box>
        )}
      </Box>,
    ];
  };

  const renderContacts = () => {
    if (isEmpty(contacts)) return [];
    return [
      <Box key="contacts" p="1.2em 8% 0" {...activeColors(1)}>
        <SubTitle name="CONTACT" textAlign="center" pb=".8em" />
      </Box>,
      ...contacts.map(
        (item, idx) =>
          !!item.link.length && (
            <UserContactsBox key={idx} type={item.type} link={item.link} activeColors={activeColors(1)} />
          ),
      ),
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skills" p=".8em 8% 0" {...activeColors(4)}>
        <SubTitle name="SKILLS" textAlign="center" pb=".8em" />
      </Box>,
      ...userSkills.map((item, index) => (
        <SkillItem
          isShowProficiency={isShowProficiency}
          key={`${item.name}${index}`}
          title={item.name}
          point={item.points}
          theme={cvColor}
          fontSizeRatio={fontSizeRatio}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const renderLanguages = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="languages" p=".4em 8% .5em" {...activeColors(5)}>
        <SubTitle name="LANGUAGE" textAlign="center" mb=".8em" />
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" gridGap=".6em">
          {userLanguages.map((item, index) => (
            <UserLanguageInfo key={`${item.name}${index}`} language={item} />
          ))}
        </Box>
      </Box>,
    ];
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box
        key="socialMedia"
        display="flex"
        p=".4em 8% 1em"
        flexWrap="wrap"
        justifyContent="space-between"
        flexDirection="row"
        gridGap=".8em"
        {...activeColors(1)}
      >
        {userSocialMediaShowed.map((item, idx) => (
          <UserSocialBox key={`${item.type}${idx}`} img={item.type} link={item.link} />
        ))}
      </Box>,
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experiences" p="1.6em 8% .5em" {...activeColors(2)}>
        <SubTitle name="WORK EXPERIENCE" padding=".8em" width="60%" />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`${item.companyInfo}${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="educations" p=".4em 8% .5em" {...activeColors(3)}>
        <SubTitle name="EDUCATION" padding=".8em" width="60%" />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderCertification = () => {
    if (isEmpty(userCertifications)) return [];
    return [
      <Box key="certifications" p=".4em 8% .5em" bgcolor={isActive === 5 ? '#e8e8e8' : ''} {...activeColors(5)}>
        <SubTitle name="CERTIFICATION" padding=".8em" width="60%" />
      </Box>,
      ...userCertifications.map((item, index) => (
        <UserCertification key={`${item.certificate}${index}`} data={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [
      ...renderProfile(),
      ...renderContacts(),
      ...renderSkills(),
      ...renderLanguages(),
      ...renderSocialMedia(),
    ],
    rightComponents: [...renderExperience(), ...renderEducation(), ...renderCertification()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont}>
      <Box minHeight="54.164em" height="54.164em" display="flex" position="relative" fontSize={fontSizeRatio}>
        <Box
          minWidth="42.8%"
          maxWidth="42.8%"
          maxHeight="100%"
          height="100%"
          id={idLeft}
          overflow="hidden"
          color="#71726E"
          bgcolor="#F8F7F5"
          fontSize={fontSizeRatio}
        >
          {renderedLeft}
          <Box height="1.6em" />
        </Box>
        <Box flexGrow={1} maxHeight="100%" height="100%" id={idRight} overflow="hidden" fontSize={fontSizeRatio}>
          {renderedRight}
          <Box height="1.6em" />
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0015_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0015;
