/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import Box from '@material-ui/core/Box';
import useTranslation from 'next-translate/useTranslation';
import { useCvTwoLayoutBack } from 'helpers';
import { OCVProps, previewType } from '../../index';

const PREMIUM_0024_BACK: FC<OCVProps> = (props) => {
  const { t } = useTranslation('common');
  const [
    isShowOtherPage,
    _renderedLeft,
    renderedRight,
    _idLeft,
    idRight,
    contentLeft,
    contentRight,
    preview,
    savedCvColour,
    fontSizeRatio,
  ] = useCvTwoLayoutBack({
    breakComponent: <Box key="too-large">{t('common.contentTooLong')}</Box>,
    props,
  });

  return (
    <>
      <Box height="54.164em" overflow="hidden" position="relative" display="flex">
        <Box minWidth="27.15%" maxWidth="27.15%" maxHeight="100%" height="100%" overflow="hidden" />
        <Box bgcolor={savedCvColour} maxHeight="100%" height="100%" display="flex" flexGrow={1} flexDirection="row">
          <Box width="8.5em" maxHeight="100%" height="100%" />
          <Box maxHeight="100%" height="100%" id={idRight} fontSize={fontSizeRatio} minWidth="70%" maxWidth="70%">
            {renderedRight}
          </Box>
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0024_BACK
            preview={preview}
            savedCvColour={savedCvColour}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </>
  );
};

export default PREMIUM_0024_BACK;
