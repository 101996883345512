import { FC } from 'react';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, getTime, useCVHelper, useCvTwoLayout } from 'helpers';
import { ActiveColors, ProfileCertificate, ProfileEducation, ProfileExperience } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import PREMIUM_0001_BACK from './back';
import { OCVProps, previewType } from '../../index';

const box6em = (title: string) => (
  <Box component="span" fontSize="0.6em" width="100%">
    {title}
  </Box>
);

const ProgessBox: FC<{ title: string; percent: number; activeColors: ActiveColors }> = ({
  title,
  percent,
  activeColors,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gridGap=".2em"
      alignItems="center"
      p=".6em 10%"
      {...activeColors}
    >
      <Box className="u-text-uppercase" textAlign="left" width="40%">
        <Box fontSize="0.6em" width="100%">
          {title}
        </Box>
      </Box>
      <Box width="60%" border="1px solid white" py="1px" pl=".4em">
        <Box width=".4em" height=".4em" bgcolor="white" ml={`calc(${percent}% - .4em)`} />
      </Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{
  cvExperience?: ProfileExperience;
  isHiddenLine?: boolean;
  activeColors: ActiveColors;
}> = ({ cvExperience, isHiddenLine = false, activeColors }) => {
  const { role, company, activities, location } = cvExperience;
  const { time } = getTime(cvExperience);
  const companyInfo = `${company} / ${location}`;
  return (
    <Box p=".4em 8%" {...activeColors}>
      <Box lineHeight="0.817em">{box6em(time)}</Box>
      <Box lineHeight="1.089em" mt="0.2em" fontWeight={600}>
        {box8em(role)}
      </Box>
      <Box mt="0.2em">{box6em(companyInfo)}</Box>
      <Box lineHeight="0.9em" mt="0.4em" fontStyle="italic">
        <AQuillContent content={activities} fontSize="0.6em" />
      </Box>
      {!isHiddenLine && <Box mt="0.8em" borderBottom="1px dashed #E0E0E0" />}
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; isHiddenLine?: boolean; activeColors: ActiveColors }> = ({
  cvEducation,
  isHiddenLine = false,
  activeColors,
}) => {
  const { school, degree, activities } = cvEducation;
  const { time } = getTime(cvEducation);
  return (
    <Box p=".4em 8%" {...activeColors}>
      <Box lineHeight="0.817em">{box6em(time)}</Box>
      <Box lineHeight="1.089em" mt="0.2em" fontWeight={600} className="u-text-uppercase">
        {box8em(school)}
      </Box>
      <Box mt="0.2em">{box6em(degree)}</Box>
      <Box lineHeight="0.9em" mt="0.4em" fontStyle="italic">
        <AQuillContent content={activities} fontSize="0.6em" />
      </Box>
      {isHiddenLine ? '' : <Box mt="0.8em" borderBottom="1px dashed #E0E0E0" />}
    </Box>
  );
};

const box8em = (title: string) => (
  <Box component="span" fontSize="0.8em" width="100%">
    {title}
  </Box>
);

const UserSideInfo: FC<{ info: string; img: string; isHiddenLine?: boolean }> = ({
  info,
  img,
  isHiddenLine = false,
}) => {
  return (
    <Box mt="1em" className="o-premium-0001__sideinfo">
      <img src={img} alt="" />
      <Box mb="0.1em" className="u-text-uppercase">
        {box8em(info)}
      </Box>
      {!isHiddenLine && <Box height="1px" width="100%" bgcolor="white" mt="0.4em" />}
    </Box>
  );
};

const CvTitle: FC<{ title: string }> = ({ title }) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box fontWeight={700} lineHeight="1.362em" letterSpacing={1}>
          <Box fontSize="1em" width="100%">
            {title}
          </Box>
        </Box>
        <Box height="1px" width="1.6em" bgcolor="#9E9E9E" />
      </Box>
      <Box width="2.4em" height="0.2em" bgcolor="black" mt="0.2em" />
    </>
  );
};

const SkillItem: FC<{ title: string; point: number; isShowProficiency: boolean; activeColors: ActiveColors }> = ({
  title,
  point,
  isShowProficiency,
  activeColors,
}) => {
  if (isShowProficiency) {
    return <ProgessBox title={title} percent={(point * 100) / 5} activeColors={activeColors} />;
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" mt="1.2em 0" textAlign="left" {...activeColors}>
      <Box bgcolor="white" width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserCertification: FC<{ data: ProfileCertificate; activeColors: ActiveColors }> = ({ data, activeColors }) => {
  const { date, certificate, organizer } = data;
  const year = moment(date, 'YYYY-MM-DD').year();
  return (
    <Box display="flex" flexDirection="column" gridGap=".2em" p=".8em 10%" textAlign="left" {...activeColors}>
      <Box fontSize=".5em" fontWeight={400}>
        {year}
      </Box>
      <Box fontSize=".6em" fontWeight={700}>
        {certificate}
      </Box>
      <Box fontSize=".5em" fontWeight={400}>
        {organizer}
      </Box>
    </Box>
  );
};

const PREMIUM_0001: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    preview = previewType.cvDemoThumbnail,
    isActive = 0,
    textSize,
    isShowAvatar = true,
    savedCvFont,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, certifications } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    skype,
    role,
    summary,
    mobile_number,
    address,
    city,
    country,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'BESSIE MOSCISKI';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'YOURMAILID@EXAMPLE.COM';
  const userAddress = address ?? 'STREET ADDRESS';
  const userCity = city || 'CITY STATE';
  const userCountry = country || 'COUNTRY';

  const userAboutMe =
    summary ||
    `Front End Developer is to express my interest in the job posted on your website for an experienced, detailed-oriented, front-end developer. As you'll see, I have six years of hands-on experience efficiently coding websites and applications using modern HTML, CSS, React and JavaScript. Building state-of-the-art, easy to use, user-friendly websites and applications is truly a passion of mine and I am confident I would be an excellent addition to your organization.`;

  const userRole = role || 'Candidate Role';
  const userPhoneNumber = mobile_number || '667-0890-231';
  const userSkype = skype ?? 'jhon Dee';
  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University / College / Institute Name',
    degree: 'TITLE HERE',
    grade: '3.3',
    major: 'MAJOR',
    from_year: '01-01-2015',
    to_year: '01-01-2018',
    finished: true,
    activities: null,
  });
  const userEducation = education ?? dummyEducation;

  const dummyExperiences = Array(2).fill({
    role: 'JOB POSITION / TITLE HERE',
    company: 'Company / Institute Name',
    location: 'Location',
    from_year: '01-01-2015',
    to_year: '01-01-2018',
    finished: true,
    activities:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget.',
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyCertifications: ProfileCertificate[] = Array(2).fill({
    date: '2021-02-02',
    organizer: 'Organization / Institute',
    certificate: 'Enter Certification Here',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis.',
  });
  const userCertifications = certifications ?? dummyCertifications;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#5e5e5e', color: 'white' } : {};
  };

  const renderProfile = () => {
    return [
      <Box key="profile" p="1.2em 10% 0.4em" {...activeColors(1)}>
        {isShowAvatar && (
          <Box className="u-flex-center" mb="1.2em">
            <Box height="6em" minWidth="6em" maxWidth="6em">
              <img height="100%" width="100%" src={userAvatar} alt="" className="u-border-rounded o-base-cv__avatar" />
            </Box>
          </Box>
        )}
        <Box key={cvColor} display="flex" alignItems="center" flexDirection="column" className="u-text-uppercase">
          <Box position="relative" fontWeight={600} letterSpacing="0.05em" width="100%">
            <Box p="0em 1em" zIndex={2} position="relative">
              <Box fontSize="1em" bgcolor={isActive === 1 ? '#5e5e5e' : cvColor}>
                {`${userFirstName} ${userLastName}`}
              </Box>
            </Box>
            <Box
              className="o-premium-0001__name-line"
              position="absolute"
              height="1px"
              width="100%"
              bgcolor="white"
              maxWidth="120%"
            />
          </Box>
          <Box>{box8em(userRole)}</Box>
          <Box display="inline-flex" mb="0.2em" height="0.15em" width="2em" bgcolor="white" />
        </Box>
        <UserSideInfo img="/assets/svg/phone_ring.svg" info={userPhoneNumber} />
        <UserSideInfo img="/assets/svg/mail_box_open.svg" info={userEmail} />
        {!!userSkype.length && <UserSideInfo img="/assets/svg/skype_logo.svg" info={userSkype} />}
        <UserSideInfo img="/assets/svg/address_cv.svg" info={`${userAddress}, ${userCity}, ${userCountry}`} />
      </Box>,
    ];
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box key="summary" p="1.6em 8% 0.8em" {...activeColors(1)}>
        <CvTitle title="PROFILE" />
        <Box lineHeight="0.817em" mt="0.8em">
          <Box className="u-pre-line">
            <AQuillContent content={userAboutMe} fontSize="0.6em" />
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="userExperiences" p=".8em 8% .4em" {...activeColors(2)}>
        <CvTitle title="WORK EXPERIENCE" />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo
          key={`${item.companyInfo}${index}`}
          cvExperience={item}
          activeColors={activeColors(2)}
          isHiddenLine={index === userExperiences.length - 1}
        />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="userEducation" p=".8em 8% .4em" {...activeColors(3)}>
        <CvTitle title="EDUCATION" />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo
          key={`${item.school}${index}`}
          cvEducation={item}
          activeColors={activeColors(3)}
          isHiddenLine={index === userEducation.length - 1}
        />
      )),
    ];
  };

  const renderCertification = () => {
    if (isEmpty(userCertifications)) return [];
    return [
      <Box key="userCertifications" p="0.8em 10% 0em" {...activeColors(5)}>
        <Box textAlign="left" fontWeight={600} fontSize=".9em" lineHeight="1.35em">
          CERTIFICATION
        </Box>
      </Box>,
      ...userCertifications.map((item, index) => (
        <UserCertification key={index} data={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="userSkills" p=".8em 10%" {...activeColors(4)}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt="0.8em">
          <Box fontWeight={600}>Software Skills</Box>
          <Box height="1px" width="1.6em" bgcolor="white" />
        </Box>
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          key={`skill${name}${index}${points}`}
          title={name}
          point={points}
          activeColors={activeColors(4)}
          isShowProficiency={isShowProficiency}
        />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderProfile(), ...renderSkills(), ...renderCertification()],
    rightComponents: [...renderSummary(), ...renderEducation(), ...renderExperience()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont || 'inherit'}>
      <Box minHeight="54.164em" height="54.164em" position="relative" display="flex" className="o-premium-0001">
        <Box
          fontSize={fontSizeRatio || 'inherit'}
          maxHeight="100%"
          height="100%"
          minWidth="42.8%"
          maxWidth="42.8%"
          id={idLeft}
          overflow="hidden"
          textAlign="center"
          color="white"
          bgcolor={cvColor}
        >
          {renderedLeft}
          <Box height="1.6em" />
        </Box>
        <Box
          flexGrow={1}
          id={idRight}
          height="100%"
          overflow="hidden"
          fontSize={fontSizeRatio || 'inherit'}
          maxHeight="100%"
        >
          {renderedRight}
          <Box height="1.6em" />
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0001_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0001;
