export const DEFAULT_LANGUAGE = 'en';

export const SOCIAL_MEDIAS = ['LinkedIn', 'Twitter', 'Facebook', 'Website', 'Instagram', 'Github', 'Dribbble'];

export const NATIONALITIES = [
  'Afghan',
  'Albanian',
  'Algerian',
  'American',
  'Andorran',
  'Angolan',
  'Antiguans',
  'Argentinean',
  'Armenian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahamian',
  'Bahraini',
  'Bangladeshi',
  'Barbadian',
  'Barbudans',
  'Batswana',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Beninese',
  'Bhutanese',
  'Bolivian',
  'Bosnian',
  'Brazilian',
  'British',
  'Bruneian',
  'Bulgarian',
  'Burkinabe',
  'Burmese',
  'Burundian',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Cape Verdean',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Colombian',
  'Comoran',
  'Congolese',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Cypriot',
  'Czech',
  'Danish',
  'Djibouti',
  'Dominican',
  'Dutch',
  'East Timorese',
  'Ecuadorean',
  'Egyptian',
  'Emirian',
  'Equatorial Guinean',
  'Eritrean',
  'Estonian',
  'Ethiopian',
  'Fijian',
  'Filipino',
  'Finnish',
  'French',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Greek',
  'Grenadian',
  'Guatemalan',
  'Guinea-Bissauan',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Herzegovinian',
  'Honduran',
  'Hungarian',
  'I-Kiribati',
  'Icelander',
  'Indian',
  'Indonesian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Israeli',
  'Italian',
  'Ivorian',
  'Jamaican',
  'Japanese',
  'Jordanian',
  'Kazakhstani',
  'Kenyan',
  'Kittian and Nevisian',
  'Kuwaiti',
  'Kyrgyz',
  'Laotian',
  'Latvian',
  'Lebanese',
  'Liberian',
  'Libyan',
  'Liechtensteiner',
  'Lithuanian',
  'Luxembourger',
  'Macedonian',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Maldivian',
  'Malian',
  'Maltese',
  'Marshallese',
  'Mauritanian',
  'Mauritian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monacan',
  'Mongolian',
  'Moroccan',
  'Mosotho',
  'Motswana',
  'Mozambican',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'New Zealander',
  'Ni-Vanuatu',
  'Nicaraguan',
  'Nigerian',
  'Nigerien',
  'North Korean',
  'Northern Irish',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Panamanian',
  'Papua New Guinean',
  'Paraguayan',
  'Peruvian',
  'Polish',
  'Portuguese',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Saint Lucian',
  'Salvadoran',
  'Samoan',
  'San Marinese',
  'Sao Tomean',
  'Saudi',
  'Scottish',
  'Senegalese',
  'Serbian',
  'Seychellois',
  'Sierra Leonean',
  'Singaporean',
  'Slovakian',
  'Slovenian',
  'Solomon Islander',
  'Somali',
  'South African',
  'South Korean',
  'Spanish',
  'Sri Lankan',
  'Sudanese',
  'Surinamer',
  'Swazi',
  'Swedish',
  'Swiss',
  'Syrian',
  'Taiwanese',
  'Tajik',
  'Tanzanian',
  'Thai',
  'Togolese',
  'Tongan',
  'Trinidadian or Tobagonian',
  'Tunisian',
  'Turkish',
  'Tuvaluan',
  'Ugandan',
  'Ukrainian',
  'Uruguayan',
  'Uzbekistani',
  'Venezuelan',
  'Vietnamese',
  'Welsh',
  'Yemenite',
  'Zambian',
  'Zimbabwean',
];

export const YEAR_OF_EXPERIENCE = [
  { id: 0, name: '<1' },
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
  { id: 8, name: '8' },
  { id: 9, name: '9' },
  { id: 10, name: '10' },
  { id: 11, name: '11' },
  { id: 12, name: '12' },
  { id: 13, name: '13' },
  { id: 14, name: '14' },
  { id: 15, name: '15' },
  { id: 16, name: '16' },
  { id: 17, name: '17' },
  { id: 18, name: '18' },
  { id: 19, name: '19' },
  { id: 20, name: '20' },
  { id: 21, name: '21' },
  { id: 22, name: '22' },
  { id: 23, name: '23' },
  { id: 24, name: '24' },
  { id: 25, name: '25' },
  { id: 26, name: '26' },
  { id: 27, name: '27' },
  { id: 28, name: '28' },
  { id: 29, name: '29' },
  { id: 30, name: '30' },
  { id: 31, name: '30+' },
];

export const seo = {
  '/': {
    title: 'UrbanCV: Premium CV & Fast Resume Builder. Try for Free',
    desc:
      'Try UrbanCV free & powerful CV builder to create stunning resume masterpieces in minutes. Get started with our elegant & creative templates used by millions around the world.',
    image: 'home.jpg',
  },
  '/sample-cv': {
    title: 'UrbanCV: Free Top CV Templates from every industries & sectors',
    desc:
      'Get access to the best & top CV templates from a wide range of industries and sectors from Tech, Law, Education, IT, Marketing, Finance, Retail, and many more',
    image: 'sample-cv.jpg',
  },
  '/cv-builder': {
    title: 'UrbanCV: Professional CV Templates with Creative Designs',
    desc:
      'Build your professional CV and resumes with our curated, original & beautiful templates. Designed for the modern jobs & career by our creative designers.',
    image: 'cv-builder.jpg',
  },
  '/pricing': {
    title: 'UrbanCV: Simple & Affordable Pricing for the best CV',
    desc:
      'With an affordable price for building the best CV & resume, UrbanCV empowers everyone to own a professional and stunning CV. Free CV and Resume available.',
    image: 'pricing.jpg',
  },
  '/about': {
    title: 'UrbanCV: Mission & Vision',
    desc:
      'UrbanCV was created because we saw a lack of proper CV & resumes from job applicants. Thus, we create the simplest, fastest & most affordable CV builder.',
    image: 'about.jpg',
  },
  '/login': {
    title: 'UrbanCV: Login Page',
    desc: 'To login into your UrbanCV account to start building your CV & Resume',
    image: 'login.jpg',
  },
  '/register': {
    title: 'UrbanCV: Register Page',
    desc: 'To register for your UrbanCV account & start building your CV & Resume',
    image: 'register.jpg',
  },
  '/terms-and-conditions': {
    title: 'UrbanCV: Terms & Conditions',
    desc: 'Please read our terms & conditions to keep UrbanCV a conducive website for all.',
    image: 'terms-and-conditions.jpg',
  },
  '/contact-us': {
    title: 'UrbanCV: Contact Us Page',
    desc:
      'If you have any inquiries on our CV and Resumes, feel free to fill in the contact form and we will get back shortly',
    image: 'contact-us.jpg',
  },
  '/privacy-policy': {
    title: 'UrbanCV: Privacy Policy',
    desc:
      'Your privacy is of utmost importance. Read this to be sure that your privacy will be protected when using our website.',
    image: 'privacy-policy.jpg',
  },
};

export const seoId = {
  '/': {
    title: 'UrbanCV: CV Premium & Pembuat Resume Cepat. Coba gratis',
    desc:
      'Coba pembuat CV gratis & tangguh UrbanCV untuk membuat mahakarya resume yang menakjubkan dalam hitungan menit. Mulailah dengan template elegan & kreatif kami yang digunakan oleh jutaan orang di seluruh dunia.',
    image: 'home.jpg',
  },
  '/sample-cv': {
    title: 'UrbanCV: Template CV Top Gratis dari setiap industri & sektor',
    desc:
      'Dapatkan akses ke template CV terbaik & teratas dari berbagai industri dan sektor mulai dari Teknologi, Hukum, Pendidikan, TI, Pemasaran, Keuangan, Ritel, dan banyak lagi',
    image: 'sample-cv.jpg',
  },
  '/cv-builder': {
    title: 'UrbanCV: Template CV Profesional dengan Desain Kreatif',
    desc:
      'Bangun CV dan resume profesional Anda dengan template kami yang dikuratori, asli & cantik. Dirancang untuk pekerjaan & karir modern oleh desainer kreatif kami.',
    image: 'cv-builder.jpg',
  },
  '/pricing': {
    title: 'UrbanCV: Harga Sederhana & Terjangkau untuk CV terbaik',
    desc:
      'Dengan harga terjangkau untuk membuat CV & resume terbaik, UrbanCV memberdayakan setiap orang untuk memiliki CV yang profesional dan memukau. CV dan Resume gratis tersedia.',
    image: 'pricing.jpg',
  },
  '/about': {
    title: 'UrbanCV: Misi & Visi',
    desc:
      'UrbanCV dibuat karena kami melihat kurangnya CV & resume yang tepat dari pelamar kerja. Jadi, kami membuat pembuat CV paling sederhana, tercepat & paling terjangkau.',
    image: 'about.jpg',
  },
  '/login': {
    title: 'UrbanCV: Halaman Login',
    desc: 'Untuk masuk ke akun UrbanCV Anda untuk mulai membuat CV & Resume Anda',
    image: 'login.jpg',
  },
  '/register': {
    title: 'UrbanCV: Halaman Daftar',
    desc: 'Untuk mendaftar akun UrbanCV Anda & mulai membuat CV & Resume Anda',
    image: 'register.jpg',
  },
  '/terms-and-conditions': {
    title: 'UrbanCV: Syarat & Ketentuan',
    desc:
      'Silakan baca syarat & ketentuan kami untuk menjaga agar UrbanCV tetap menjadi situs web yang kondusif untuk semua.',
    image: 'terms-and-conditions.jpg',
  },
  '/contact-us': {
    title: 'UrbanCV: Hubungi Kami',
    desc:
      'Jika Anda memiliki pertanyaan tentang CV dan Resume kami, jangan ragu untuk mengisi formulir kontak dan kami akan segera kembali',
    image: 'contact-us.jpg',
  },
  '/privacy-policy': {
    title: 'UrbanCV: Kebijakan Privasi',
    desc:
      'Privasi Anda adalah yang paling penting. Baca ini untuk memastikan bahwa privasi Anda akan terlindungi saat menggunakan situs web kami.',
    image: 'privacy-policy.jpg',
  },
};

export const occupationList = [
  'A/B tester',
  'Animator',
  'Artist',
  'Biochemist',
  'C-level Executive Officers',
  'Computer analyst',
  'Computer repair technician',
  'Data analyst',
  'Data entry clerk',
  'Database administrator',
  'Ecologist',
  'Engineer',
  'Fundraising',
  'Geographer',
  'Government',
  'Graphics Designer',
  'Hardware engineer',
  'Homemaker/Housewife',
  'Human Resource Executive',
  'Information systems technician',
  'Lyricist',
  'Network analyst',
  'Photographer',
  'Political scientist',
  'Programmer',
  'Recruiter (External)',
  'Rider',
  'Security Guard',
  'Sociologist',
  'Software architect',
  'Application analyst',
  'Architect',
  'Baker',
  'Business analyst',
  'Chemist',
  'Computer operator',
  'Computer scientist',
  'Data designer',
  'Data scientist',
  'Driver',
  'Economist',
  'Geographer',
  'Geologist',
  'Graphics Designer',
  'Hairstylist',
  'Historian',
  'Human Resource Director',
  'Human Resource Manager',
  'Interior designer',
  'Make-up artist',
  'Network administrator',
  'Oceanographer',
  'Physicist',
  'Product manager',
  'Project manager',
  'Recruiter (Internal)',
  'Security engineer',
  'Self-employed',
  'Software analyst',
  'Software design',
  'Software project manager',
  'Software test engineer (Tester)',
  'Support technician (Help Desk)',
  'Systems analyst',
  'Unemployed',
  'UXUI Designer',
  'Web designer',
  'Website administrator',
  'Software engineer',
  'Writer',
  'Software quality analyst',
  'Solution architect',
  'System administrator',
  'Test engineer',
  'Sales',
  'Urban planner',
  'Visual designer',
  'Web developer',
  'Wedding planner',
  'Operation Manager',
  'Musician',
];

export const causesList = [
  'Animal Welfare',
  'Arts and Culture',
  'Children',
  'Civil Rights and Social Action',
  'Economic Empowerment',
  'Education',
  'Environment',
  'Health',
  'Human Rights',
  'Disaster and Humanitarian Relief',
  'Politics',
  'Poverty Alleviation',
  'Science and Technology',
  'Social Services',
  'Veteran Support',
];
