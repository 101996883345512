import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { getCVAvatar, getTime, useCVHelper, useCvTwoLayout } from 'helpers';
import {
  ActiveColors,
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileReference,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  ADribbble,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  AWebsite,
  ISocialIcon,
} from 'components/atoms/icons';
import PREMIUM_0006_BACK from './back';
import { OCVProps, previewType } from '../../index';

const SkillItem: FC<{ title: string; percent: number; isShowProficiency: boolean; activeColors: ActiveColors }> = ({
  title,
  percent,
  isShowProficiency,
  activeColors,
}) => {
  if (isShowProficiency) {
    const width = `${(percent * 100) / 5}%`;
    return (
      <Box p=".4em 8%" textAlign="left" {...activeColors}>
        <Box mb=".4em">
          <Box fontSize="0.5em" width="100%" className="u-text-uppercase">
            {title}
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          height=".45em"
          position="relative"
          border="1px solid white"
        >
          <Box
            bgcolor="white"
            width={`calc(${width} + 2px)`}
            height="calc(100% + 2px)"
            position="absolute"
            top="-1px"
            left="-1px"
          />
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" p=".4em 8%" {...activeColors}>
      <Box bgcolor="white" width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, major } = cvEducation;
  const { time } = getTime(cvEducation);
  return (
    <Box p=".6em 8%" {...activeColors}>
      <Box className="u-text-uppercase" fontWeight={600} fontSize=".6em">
        {major}
      </Box>
      <Box mt=".4em" fontWeight={600} fontSize=".5em">
        {school} | {time}
      </Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, company, activities, location } = cvExperience;
  const { time } = getTime(cvExperience);
  const companyInfo = `${company}, ${location}`;
  return (
    <Box p=".6em 8%" fontWeight={400} {...activeColors}>
      <Box fontSize="0.6em">{role}</Box>
      <Box fontSize="0.5em" mt=".4em" color="#757575">
        {companyInfo} / {time}
      </Box>
      <Box mt="0.8em">
        <AQuillContent content={activities} fontSize="0.6em" />
      </Box>
    </Box>
  );
};

const UserCertification: FC<{ data: ProfileCertificate; activeColors: ActiveColors }> = ({ data, activeColors }) => {
  const { date, certificate, organizer } = data;
  const year = moment(date, 'YYYY-MM-DD').year();

  return (
    <Box p=".4em 8%" display="flex" flexDirection="column" gridGap=".2em" {...activeColors}>
      <Box fontSize=".6em" fontWeight={600}>
        {certificate}
      </Box>
      <Box fontSize=".5em" fontWeight={400}>
        {organizer} | {year}
      </Box>
    </Box>
  );
};

const SubTitle: FC<BoxProps> = ({ children, color, ...props }) => {
  return (
    <Box textAlign="left" fontWeight={600} {...props}>
      <Box pb=".2em" fontSize=".8em" whiteSpace="nowrap" color={color}>
        {children}
      </Box>
      <Box width="3.2em" height=".1em" bgcolor={color} />
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  return (
    <Box display="flex" gridGap=".4em" textAlign="left">
      <Box fontWeight={700} fontSize=".5em" className="u-text-uppercase" whiteSpace="nowrap" minWidth="4.6em">
        {type}:
      </Box>
      <Box className="u-word-break-all" fontSize=".5em">
        {link}
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ info: string; img: string; activeColors: ActiveColors }> = ({ info, img, activeColors }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    LinkedIn: AOutlinedLinkedin,
    Instagram: AOutlinedInstagram,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG: FC<ISocialIcon> = componentIcons[img];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="60%" height="60%" />;
  };

  return (
    <Box p=".4em 8%" display="flex" gridGap=".4em" alignItems="center" {...activeColors}>
      <Box width="1.5em" height="1.5em" className="u-flex-center">
        {img && svgIcons()}
      </Box>
      <Box textAlign="left" color="white">
        <Box fontWeight={600} fontSize="0.6em" className="u-text-uppercase">
          {img}
        </Box>
        <Box fontSize="0.5em" mt=".4em">
          {info}
        </Box>
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  cvReference,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box display="flex" p=".8em 8% .4em" flexDirection="column" gridGap=".4em" textAlign="left" {...activeColors}>
      <Box fontSize=".6em" fontWeight={700}>
        {name}
      </Box>
      <Box fontSize=".5em" fontWeight={400}>
        {position} | {company}
      </Box>
      <Box fontStyle="italic" display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box fontSize=".5em" fontWeight={400}>
          <Box component="span" mr=".2em">
            Phone :
          </Box>
          {mobile}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          <Box component="span" mr=".2em">
            Email :
          </Box>
          {email}
        </Box>
      </Box>
    </Box>
  );
};

const PREMIUM_0006: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;

  const { basicInfo, experience, education, skills, certifications, reference } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    skype,
    address,
    role,
    summary,
    mobile_number,
    socialMedia,
    city,
    country,
  } = basicInfo as CvBasicInfo;

  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'NELA GERHOLD';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'YOURMAILID@EXAMPLE.COM';

  const dummyCertifications: ProfileCertificate[] = Array(2).fill({
    date: '2021-02-02',
    organizer: 'Organization / Institute',
    certificate: 'Enter Certification Here',
    description: '',
  });
  const userCertifications = certifications ?? dummyCertifications;

  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium. Risus hac purus blandit pharetra magna morbi faucibus sed amet. Viverra augue nisi, vestibulum sit nec. Facilisi duis a, vitae pellentesque nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium. Risus hac purus blandit pharetra magna morbi faucibus sed amet. Viverra augue nisi, vestibulum sit nec. Facilisi duis a, vitae pellentesque nulla.`;

  const userRole = role || 'FRONT END DEVELOPER';
  const userAddress = address || '123 Full Street';
  const userCity = city || 'State, City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '667-0890-231';
  const userSkype = skype ?? 'username Skype';

  const contacts = [
    { type: 'Phone', link: userPhoneNumber },
    { type: 'Email', link: userEmail },
    { type: 'Skype', link: userSkype },
    { type: 'Address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  let userSocialMedia = socialMedia || [
    { type: 'LinkedIn', link: 'your username' },
    { type: 'Facebook', link: 'your username' },
    { type: 'Twitter', link: 'your username' },
    { type: 'Instagram', link: 'your username' },
    { type: 'Website', link: 'www.yourwebsite.com' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];

  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }
  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(2).fill({
    role: 'ENTER YOUR JOB TITLE HERE',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2015',
    to_year: '01-01-2018',
    finished: false,
    activities:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget.',
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University / College / Institute',
    degree: 'ENTER YOUR MASTER DEGREE',
    grade: '3.3',
    major: 'MAJOR',
    from_year: '01-01-2015',
    to_year: '01-01-2020',
    finished: true,
    activities: null,
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(2).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 4,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const dummyReference: ProfileReference[] = Array(1).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    company: 'Company / Organization Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#b7b7b7', color: 'black' } : {};
  };

  const [fontSizeRatio, cvColor, t] = useCVHelper(props);

  const renderProfile = () => {
    return [
      <Box key="profile">
        {isShowAvatar && (
          <Box className="u-flex-center" p="1.6em 8% .4em" {...activeColors(1)}>
            <Box height="4.8em" minWidth="4.8em" maxWidth="4.8em">
              <img height="100%" width="100%" src={userAvatar} alt="" className="u-border-rounded o-base-cv__avatar" />
            </Box>
          </Box>
        )}
        <Box
          borderBottom={`.8em solid ${isActive === 1 ? '#b7b7b7' : '#4D4D4F'}`}
          borderLeft={`.4em solid ${isActive === 1 ? '#b7b7b7' : cvColor}`}
          borderRight={`.4em solid ${isActive === 1 ? '#b7b7b7' : cvColor}`}
          mx="auto"
          width=".8em"
        />
        <Box
          bgcolor={isActive === 1 ? '#b7b7b7' : '#4D4D4F'}
          py="1.2em"
          className="u-text-uppercase"
          textAlign="center"
          color="white"
        >
          <Box fontWeight={700} fontSize="1em">{`${userFirstName} ${userLastName}`}</Box>
          <Box mt=".8em" fontWeight={400} fontSize=".6em">
            {userRole}
          </Box>
        </Box>
        <Box bgcolor={isActive === 1 ? '#b7b7b7' : cvColor} p="1.2em 8% .4em">
          <SubTitle color="white">CONTACTS</SubTitle>
          <Box p="0.8em 0 0" display="flex" flexDirection="column" gridGap=".2em">
            {contacts.map(
              (item, idx) => !!item.link.length && <UserContactsBox key={idx} type={item.type} link={item.link} />,
            )}
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderSummary = () => {
    return [
      <Box key="summary" p="0.9em 8%" bgcolor={isActive === 1 ? '#b7b7b7' : '#E0E0E0'}>
        <SubTitle mb=".4em" color="black">
          ABOUT ME
        </SubTitle>
        <Box>
          <AQuillContent content={userAboutMe} fontSize="0.5em" />
        </Box>
      </Box>,
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skills" p="0.6em 8%" {...activeColors(4)}>
        <SubTitle color="white">SKILLS</SubTitle>
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          key={`skill${name}${index}${points}`}
          title={name}
          percent={points}
          isShowProficiency={isShowProficiency}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box key="SocialMedia" p=".8em 8% .6em" {...activeColors(1)}>
        <SubTitle color="white">SOCIAL MEDIA</SubTitle>
      </Box>,
      ...userSocialMediaShowed.map((item) => (
        <UserSocialBox key={item.type} img={item.type} info={item.link} activeColors={activeColors(1)} />
      )),
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experience" p="0.6em 8% 0" {...activeColors(2)}>
        <SubTitle color="black">WORK EXPERIENCE</SubTitle>
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`${item.companyInfo}${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education" p="0.6em 8% 0" {...activeColors(3)}>
        <SubTitle color="black">EDUCATION</SubTitle>
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderCertification = () => {
    if (isEmpty(userCertifications)) return [];
    return [
      <Box key="certifications" p="0.6em 8% .4em" {...activeColors(5)}>
        <SubTitle color="black">CERTIFICATION</SubTitle>
      </Box>,
      ...userCertifications.map((item, index) => (
        <UserCertification key={`${item.certificate}${index}`} data={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderReference = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box key="reference" p="0.6em 8% 0" {...activeColors(5)}>
        <SubTitle color="white">REFERENCES</SubTitle>
      </Box>,
      ...userReference.map((item, index) => (
        <UserReference key={`${item.position}${index}`} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderProfile(), ...renderSkills(), ...renderSocialMedia(), ...renderReference()],
    rightComponents: [...renderSummary(), ...renderExperience(), ...renderEducation(), ...renderCertification()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont || 'inherit'}>
      <Box minHeight="54.164em" height="54.164em" position="relative" display="flex">
        <Box
          minWidth="46%"
          maxWidth="46%"
          height="100%"
          id={idLeft}
          maxHeight="100%"
          overflow="hidden"
          textAlign="center"
          color="white"
          bgcolor={cvColor}
          fontSize={fontSizeRatio || 'inherit'}
        >
          {renderedLeft}
        </Box>
        <Box
          flexGrow={1}
          maxHeight="100%"
          height="100%"
          id={idRight}
          overflow="hidden"
          fontSize={fontSizeRatio || 'inherit'}
        >
          {renderedRight}
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0006_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0006;
