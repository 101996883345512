import { FC } from 'react';
import Box from '@material-ui/core/Box';
import useTranslation from 'next-translate/useTranslation';

import MCvWatermark from 'components/molecules/MCvWatermark';
import { useCvOneLayoutBack } from 'helpers';
import { OCVProps, previewType } from '../../index';

const FREE_0002_BACK: FC<OCVProps> = (props) => {
  const { t } = useTranslation('common');
  const [isShowOtherPage, pageId, fontSizeRatio, preview, rendered, content, textSize] = useCvOneLayoutBack({
    breakComponent: <Box key="too-large">{t('common.contentTooLong')}</Box>,
    props,
  });

  return (
    <>
      <Box minHeight="54.164em" height="54.164em" position="relative">
        <Box id={pageId} height="100%" overflow="hidden" fontSize={fontSizeRatio}>
          <Box height="1em" fontSize={textSize} />
          {rendered}
          <Box height="1em" fontSize={textSize} />
        </Box>
        <MCvWatermark />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize="1.5em">{t('common.pageBreak')}</Box>
            </Box>
          )}
          <FREE_0002_BACK
            preview={preview}
            fontSizeRatio={fontSizeRatio}
            overflowedContent={content}
            textSize={textSize}
          />
        </>
      )}
    </>
  );
};

export default FREE_0002_BACK;
