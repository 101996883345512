import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AOutlinedLinkedin: FC<ISocialIcon> = ({
  fill = 'black',
  width = '8',
  height = '8',
  stroke = 'black',
  strokeWidth = '.01em',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke={stroke}
        fill={fill}
        strokeWidth={strokeWidth}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.958844 1.78777C1.55672 1.78777 1.92903 1.3902 1.92903 0.893889C1.91785 0.386435 1.55672 8.2016e-05 0.970192 8.2016e-05C0.383495 8.2016e-05 0 0.386435 0 0.893889C0 1.3902 0.372147 1.78777 0.94767 1.78777H0.958844ZM1.81615 7.66675V2.49378H0.101403V7.66675H1.81615Z"
      />
      <path
        stroke={stroke}
        fill={fill}
        strokeWidth={strokeWidth}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.76514 7.66675H4.48007V4.77799C4.48007 4.62333 4.49126 4.46882 4.53656 4.35844C4.66049 4.04951 4.94269 3.72951 5.41642 3.72951C6.03715 3.72951 6.28524 4.20409 6.28524 4.89932V7.66675H7.99997V4.70066C7.99997 3.11179 7.15399 2.3724 6.02565 2.3724C5.1005 2.3724 4.69437 2.89103 4.46873 3.24408H4.48007V2.49378H2.76514C2.78766 2.97912 2.76514 7.66675 2.76514 7.66675Z"
      />
    </svg>
  );
};
