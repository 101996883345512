import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AOutlinedFacebook: FC<ISocialIcon> = ({ fill = 'black', width = '5', height = '8', stroke = 'black' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.89666 4.37348H2.8162V4.45394V7.52185H1.70242V4.45394V4.37348H1.62196H0.644183V3.3087H1.62196H1.70242V3.22824V2.32098C1.70242 1.81193 1.85777 1.42877 2.11738 1.17286C2.37726 0.916678 2.75058 0.779193 3.20331 0.779193C3.58035 0.779193 3.91092 0.804629 4.07286 0.81971V1.77187H3.50395C3.23796 1.77187 3.05721 1.83427 2.94711 1.96497C2.83963 2.09257 2.8162 2.26675 2.8162 2.44716V3.22824V3.3087H2.89666H4.01986L3.88405 4.37348H2.89666Z"
        stroke={stroke}
        fill={fill}
        strokeWidth=".01em"
      />
    </svg>
  );
};
