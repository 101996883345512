import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';

import useTranslation from 'next-translate/useTranslation';
import { getCVAvatar, getLanguageLevel, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import {
  ActiveColors,
  AppState,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
  ProfileReference,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  ADribbble,
  AEmail,
  AGithub,
  AHome,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  ASkype,
  AWebsite,
} from 'components/atoms/icons';
import { ISocialIcon } from 'components/atoms/icons/ASkype';
import PREMIUM_0024_BACK from './back';
import { OCVProps, previewType } from '../../index';

const SkillItem: FC<{
  title: string;
  point: number;
  theme: string;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
  fontSizeRatio: number;
}> = ({ title, point, theme, activeColors, isShowProficiency, fontSizeRatio }) => {
  if (isShowProficiency) {
    const size = Math.ceil(fontSizeRatio * 0.4);
    const margin = Math.ceil(fontSizeRatio * 0.1);
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        color="#272726"
        p=".3em 8% .3em 6%"
        gridGap=".3em"
        {...activeColors}
      >
        <Box fontSize=".5em">
          {title} {fontSizeRatio}
        </Box>
        <Box display="flex" gridGap={margin} minWidth="max-content">
          {Array.from(Array(10), (_, index) => (
            <Box
              key={`${title}${index}`}
              minWidth={size}
              width={size}
              height={size}
              m={margin}
              borderRadius="100%"
              bgcolor={index <= point * 2 ? '#212121' : undefined}
              border={index > point * 2 ? '1px solid #212121' : undefined}
            />
          ))}
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" p=".4em 8% .4em 6%" {...activeColors}>
      <Box bgcolor={theme} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities, company, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box fontWeight={400} p=".4em 8% .4em 6%" {...activeColors}>
      <Box fontSize=".6em">{`${joinDate} - ${leaveDate} | ${company}, ${location}`}</Box>
      <Box mt=".4em">
        <Box color="#3B5159" fontSize=".6em" className="u-text-uppercase" whiteSpace="nowrap" fontWeight={700}>
          {role}
        </Box>
      </Box>
      <Box mt=".4em">
        <AQuillContent fontSize=".6em" content={activities} />
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, to_year, finished, from_year, degree, major } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box fontWeight={400} p=".4em 8% .4em 6%" {...activeColors}>
      <Box color="#212121" fontSize=".6em">{`${joinDate} - ${leaveDate}`}</Box>
      <Box mt=".4em" fontSize=".6em" fontWeight={700} lineHeight="1.4em">
        {`${degree} of ${major} at ${school}`}
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ type: string; link: string; index: number; activeColors: ActiveColors }> = ({
  type,
  link,
  index,
  activeColors,
}) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    LinkedIn: AOutlinedLinkedin,
    Instagram: AOutlinedInstagram,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG: FC<ISocialIcon> = componentIcons[type];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="60%" height="60%" />;
  };
  return (
    <Box
      display="inline-flex"
      py=".3em"
      pl={!(index % 2) ? '6%' : '2%'}
      gridGap=".5em"
      alignItems="center"
      width="50%"
      pr={!(index % 2) ? '2%' : '8%'}
      {...activeColors}
    >
      <Box borderRadius="50%" bgcolor="#000000" className="u-flex-center" minWidth=".8em" width=".8em" height=".8em">
        {type && svgIcons()}
      </Box>
      <Box fontWeight={400} fontSize=".4em">
        {link}
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const componentIcons = {
    email: AEmail,
    phone: APhone,
    address: AHome,
    Skype: ASkype,
    Website: AWebsite,
  };
  const svgIcon = () => {
    const SVG = componentIcons[type];
    return <SVG fill="#132A39" strokeWidth="0" width=".8em" height=".8em" />;
  };
  return (
    <Box display="flex" color="#494946" gridGap=".8em" alignItems="center" fontWeight={400}>
      <Box>{type && svgIcon()}</Box>
      <Box fontSize=".72em">{link}</Box>
    </Box>
  );
};

const SubTitle: FC<{ name: string } & BoxProps> = ({ name }) => {
  return (
    <Box flex={1} fontSize=".8em" color="#2E3130">
      <b>{name}</b>
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage; activeColors: ActiveColors }> = ({
  language,
  activeColors,
}) => {
  const { name, speaking, writing } = language;
  return (
    <Box fontWeight={400} display="flex" justifyContent="space-between" p=".2em 8% .4em 6%" {...activeColors} width={1}>
      <Box fontSize=".5em">{name}</Box>
      <Box display="flex" fontSize=".5em" whiteSpace="pre">
        {`Written: ${getLanguageLevel(writing)}     Spoken: ${getLanguageLevel(speaking)}`}
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  cvReference,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      gridGap=".6em"
      minWidth="40%"
      p=".4em 8% .4em 6%"
      {...activeColors}
    >
      <Box display="flex" flexDirection="column" gridGap=".2em" flex={1}>
        <Box fontSize=".5em" fontWeight={700}>
          {name}
        </Box>
        <Box fontSize=".5em" fontWeight={400} mt=".4em">
          {position}, {company}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gridGap=".2em" flex={1} maxWidth="50%">
        <Box fontSize=".5em" fontWeight={400} whiteSpace="break-spaces" className="u-word-break-all">
          {`Phone :  ${mobile}`}
        </Box>
        <Box fontSize=".5em" fontWeight={400} whiteSpace="break-spaces" className="u-word-break-all" mt=".4em">
          {`Email :  ${email}`}
        </Box>
      </Box>
    </Box>
  );
};

const PREMIUM_0024: FC<OCVProps> = (props) => {
  const { t } = useTranslation('common');
  const {
    cvSettings: { currentCvColor, currentCvFontSize },
  } = useSelector((state: AppState) => state);
  const {
    cvInfo,
    preview = previewType.cvDemoThumbnail,
    isActive = 0,
    textSize,
    savedCvColour = null,
    isShowAvatar = true,
    savedCvFontSize,
    savedCvFont,
    isShowProficiency = true,
  } = props;

  const cvColor = savedCvColour || currentCvColor;
  const { basicInfo, experience, education, skills, languages, reference } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const fontSizeRatio = Math.round(((savedCvFontSize || currentCvFontSize) / 16) * textSize);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'MICHELLE BELLE';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'youremail@domain.com';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. At placerat nibh donec blandit sit egestas urna. Et purus lacus blandit faucibus velit sapien amet. Ultricies eget imperdiet volutpat suscipit quam venenatis maecenas sagittis ipsum. Orci vitae feugiat in bibendum erat.`;

  const userRole = role || 'GRAPHIC DESIGNER';
  const userAddress = address || 'Street Avenue 90';
  const userCity = city || 'California, New York City';
  const userCountry = country || 'US';
  const userPhoneNumber = mobile_number || '+1 407 5175897';

  const dummyLanguages = [{ name: 'English', speaking: 5, writing: 3 }];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University Name',
    degree: 'Master’s degree',
    grade: '4.75',
    major: 'Visual Design',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      'Lorem ipsum enunc pulvinati ncidunt. quis pul vinar mellu Lorem ipsum dolor: sit amet consectet gelit. it ellentesque eleife ornare ',
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(4).fill({
    skill: 'Adobe Photoshop',
    name: 'Adobe Photoshop',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  let userSocialMedia = socialMedia || [
    { type: 'Instagram', link: '@michellebelle' },
    { type: 'Facebook', link: '@michellebelle' },
    { type: 'LinkedIn', link: '@michellebelle' },
    { type: 'Twitter', link: '@michellebelle' },
    { type: 'Website', link: 'yourwebsite.com' },
    { type: 'Github', link: '@michellebelle' },
    { type: 'Dribbble', link: '@michellebelle' },
  ];
  const contacts = [
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
    { type: 'email', link: userEmail },
    { type: 'phone', link: userPhoneNumber },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(2).fill({
    role: 'JOB TITLE HERE',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyReference: ProfileReference[] = Array(1).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    company: 'Company / Organization Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e8e8e8', color: 'black' } : {};
  };

  const renderPhoto = () => {
    if (!isShowAvatar) return [];
    return [
      <Box key="photo" {...activeColors(1)}>
        <Box display="flex" justifyContent="center" pt="1em" mt="3.5em">
          <Box height="6.4em" minWidth="6.4em" maxWidth="6.4em">
            <img
              height="100%"
              width="100%"
              src={userAvatar}
              alt="profile-pict"
              className="u-border-rounded o-base-cv__avatar"
            />
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderContacts = () => {
    return [
      <Box position="relative" key="contact" p=".4em 3.2em" {...activeColors(1)}>
        <Box mt=".7em" display="flex" flexDirection="column" gridGap=".5em">
          {contacts.map((item, idx) => (
            <UserContactsBox key={idx + item.type} type={item.type} link={item.link} />
          ))}
        </Box>
      </Box>,
    ];
  };

  const renderProfile = () => {
    return [
      <Box key="profile" className="u-text-uppercase" p="1.5em 3.2em .4em" {...activeColors(1)}>
        <Box textAlign="left" mb=".4em" fontSize="2em" lineHeight="1.4em">
          {userFirstName} {userLastName}
        </Box>
        <Box fontWeight={400} mt=".4em" fontSize="1.2em">
          {userRole}
        </Box>
      </Box>,
    ];
  };

  const renderLanguages = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="userLanguages" {...activeColors(5)} p=".4em 8% .4em 6%">
        <SubTitle name="LANGUAGES" />
      </Box>,
      ...userLanguages.map((item, index) => (
        <UserLanguageInfo key={`${item.name}${index}`} language={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box key="about-me" {...activeColors(1)} p="1.6em 8% .2em 6%">
        <SubTitle name="ABOUT ME" whiteSpace="nowrap" />
        <Box mt=".7em">
          <AQuillContent color="#212121" content={userAboutMe} fontWeight={400} fontSize=".5em" />
        </Box>
      </Box>,
    ];
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      ...userSocialMediaShowed.map(({ type, link }, idx) => (
        <UserSocialBox key={idx + type} type={type} link={link} index={idx} activeColors={activeColors(1)} />
      )),
    ];
  };

  const renderEducation = () => {
    if (!userEducation.length) return [];
    return [
      <Box key="education" p=".4em 2.6em .4em 6%" bgcolor={isActive === 3 ? '#e8e8e8' : ''}>
        <SubTitle name="EDUCATION" whiteSpace="nowrap" />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderExperience = () => {
    if (!userExperiences.length) return [];
    return [
      <Box key="experience" p=".4em 2.6em .4em 6%" bgcolor={isActive === 2 ? '#e8e8e8' : ''}>
        <SubTitle name="WORK EXPERIENCE" whiteSpace="nowrap" />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`${item.companyInfo}${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderSkills = () => {
    if (!userSkills.length) return [];
    return [
      <Box key="skills" p=".4em 2.6em .4em 6%" bgcolor={isActive === 4 ? '#e8e8e8' : ''}>
        <SubTitle name="SKILLS" whiteSpace="nowrap" />
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          theme={cvColor}
          isShowProficiency={isShowProficiency}
          key={`${name}${index}`}
          title={name}
          point={points}
          activeColors={activeColors(4)}
          fontSizeRatio={fontSizeRatio}
        />
      )),
    ];
  };

  const renderReference = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box key="references" p=".4em 8% .4em 6%" {...activeColors(5)}>
        <SubTitle name="REFERENCES" />
      </Box>,
      ...userReference.map((item, index) => (
        <UserReference key={`${item.name}${index}`} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderPhoto(), ...renderProfile(), ...renderContacts()],
    rightComponents: [
      ...renderSummary(),
      ...renderSocialMedia(),
      ...renderEducation(),
      ...renderExperience(),
      ...renderSkills(),
      ...renderLanguages(),
      ...renderReference(),
    ],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont}>
      <Box minHeight="54.164em" height="54.164em" position="relative" display="flex">
        <Box minWidth="27.15%" maxWidth="27.15%" maxHeight="100%" height="100%" overflow="hidden" />
        <Box
          alignSelf="center"
          bgcolor="#F7F8F8"
          ml="4%"
          width="45%"
          maxWidth="45%"
          height="60%"
          maxHeight="60%"
          position="absolute"
          id={idLeft}
          fontSize={textSize}
        >
          {renderedLeft}
          <Box height="1.6em" />
        </Box>
        <Box bgcolor={cvColor} maxHeight="100%" height="100%" display="flex" flexGrow={1} flexDirection="row">
          <Box width="8.5em" maxHeight="100%" height="100%" />
          <Box maxHeight="100%" height="100%" id={idRight} fontSize={fontSizeRatio} minWidth="70%" maxWidth="70%">
            {renderedRight}
            <Box height="1.6em" />
          </Box>
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0024_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0024;
