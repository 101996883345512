/* eslint-disable react/no-children-prop */
import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import MTextField from 'components/molecules/MTextField';
import { ProfileLanguage } from 'redux-saga/interfaces';
import { CvLanguageError } from '../interface';

interface IAdditionalLanguage {
  languageList: { id: number; name: string }[];
  values: ProfileLanguage;
  errors: CvLanguageError;
  handleLanguageChange: (prop: keyof ProfileLanguage) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const newLanguage: ProfileLanguage = {
  name: '',
  language: 0,
  speaking: 0,
  writing: 0,
};

export const languageErrors: CvLanguageError = {
  language: false,
  speaking: false,
  writing: false,
};

export const languageScale = [
  { id: 1, level: 'Low' },
  { id: 2, level: 'Below Average' },
  { id: 3, level: 'Average' },
  { id: 4, level: 'Good' },
  { id: 5, level: 'Excellent' },
];

const AdditionalLanguage: FC<IAdditionalLanguage> = ({
  languageList,
  values,
  errors,
  handleLanguageChange: handleChange,
}) => {
  const { t: commonT } = useTranslation('common');

  const helperText = (prop) => {
    return !errors[prop] ? '' : values[prop] ? '' : commonT('helperText.fieldRequired');
  };

  const renderMenuItem = (item, index) => (
    <MenuItem key={index} value={item.id}>
      {item.level}
    </MenuItem>
  );

  return (
    <>
      <Box fontSize={18} fontWeight={600} mb={14}>
        {commonT('onboardingSteps.languages')}
      </Box>

      <MTextField
        id="language"
        select
        title={commonT('onboardingSteps.language')}
        error={errors.language}
        value={values.language}
        onChange={handleChange('language')}
        placeholder={commonT('onboardingSteps.chooseLanguage')}
        helperText={helperText('language')}
        children={languageList?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      />
      <Box display="flex" gridGap={16}>
        <Box width="50%">
          <MTextField
            id="writing"
            select
            disabled={!values.language}
            title={commonT('onboardingSteps.write')}
            error={errors.writing}
            value={values.writing}
            onChange={handleChange('writing')}
            placeholder={commonT('onboardingSteps.select')}
            helperText={helperText('writing')}
            children={languageScale.map((item, index) => renderMenuItem(item, index))}
          />
        </Box>
        <Box width="50%">
          <MTextField
            id="speaking"
            select
            disabled={!values.language}
            title={commonT('onboardingSteps.spoken')}
            error={errors.speaking}
            value={values.speaking}
            onChange={handleChange('speaking')}
            placeholder={commonT('onboardingSteps.select')}
            helperText={helperText('speaking')}
            children={languageScale.map((item, index) => renderMenuItem(item, index))}
          />
        </Box>
      </Box>
    </>
  );
};

export default AdditionalLanguage;
