import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';

import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, getTime, useCVHelper, useCvTwoLayout } from 'helpers';
import { ActiveColors, ProfileEducation, ProfileExperience } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import MCvWatermark from 'components/molecules/MCvWatermark';
import AQuillContent from 'components/atoms/AQuillContent';
import { formatDate } from 'utils/date';
import PREMIUM_0007_BACK from './back';
import { OCVProps, previewType } from '../../index';

const color7 = '#757575 !important';
const textColor7 = '#484545 !important';

const numToLevel = (point) => {
  if (point >= 3.5) {
    return 'Advanced';
  }
  if (point >= 2.5) {
    return 'Middle';
  }
  return 'Basic';
};

const box6em = (title: string) => (
  <Box component="span" fontSize="0.6em" width="100%">
    {title}
  </Box>
);

const UserExperienceInfo: FC<{ cvExperience: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, company, activities, location } = cvExperience;
  const { time } = getTime(cvExperience);
  const companyInfo = `${company}, ${location}`;
  return (
    <Box lineHeight="1.6em" p=".3em 1em .3em" {...activeColors}>
      <Box display="flex">
        <Box flex={6}>
          <Box pr="1em">
            <Box lineHeight="1em" fontWeight={600} className="u-text-uppercase">
              {box6em(role)}
            </Box>
            <Box lineHeight="1em" color="#757575">
              {box6em(`${companyInfo}`)}
            </Box>
            <Box lineHeight="1em" color="#757575">
              {box6em(`${time}`)}
            </Box>
          </Box>
        </Box>
        <Box flex={6}>
          <Box lineHeight="1em">
            <AQuillContent color={textColor7} content={activities} fontSize="0.6em" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, activities, major, degree } = cvEducation;
  const { time } = getTime(cvEducation);
  return (
    <Box lineHeight="1.6em" p=".4em 1em" {...activeColors}>
      <Box display="flex">
        <Box flex={6}>
          <Box pr="1em">
            <Box lineHeight="1em" fontWeight={600} className="u-text-uppercase">
              {box6em(`${degree} | ${major}`)}
            </Box>
            <Box lineHeight="1em" color="#757575">
              {box6em(`${school}`)}
            </Box>
            <Box lineHeight="1em" color="#757575">
              {box6em(`${time}`)}
            </Box>
          </Box>
        </Box>
        <Box flex={6}>
          <Box lineHeight="1em">
            <AQuillContent color={textColor7} content={activities} fontSize="0.6em" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SkillItem: FC<{ title: string; point: number; isShowProficiency: boolean; activeColors: ActiveColors }> = ({
  title,
  point,
  isShowProficiency,
  activeColors,
}) => {
  if (isShowProficiency) {
    return (
      <Box fontSize=".6em" display="flex" gridGap="1em" p=".4em 1.6em" justifyContent="space-between" {...activeColors}>
        <Box fontWeight="600" minWidth="10em">
          {title}
        </Box>
        <Box color={textColor7} whiteSpace="pre">
          {numToLevel((point * 100) / 5)}
        </Box>
      </Box>
    );
  }
  return (
    <Box fontSize=".6em" fontWeight="600" {...activeColors}>
      {title}
    </Box>
  );
};

const SubTitle: FC<BoxProps & { mBottom?: string }> = ({ mBottom = '1em', children, ...props }) => {
  return (
    <>
      <Box {...props} className="zzz" fontSize="1em" fontWeight={600} mb="0.3em" pt=".7em">
        {children}
      </Box>
      <Box height="1px" width="100%" bgcolor={color7} mb={mBottom} />
    </>
  );
};

const UserSocialMediaInfo: FC<{ type: string; link: string; activeColors: ActiveColors }> = ({
  type,
  link,
  activeColors,
}) => {
  return (
    <Box display="flex" p=".4em 1em" {...activeColors}>
      <Box display="flex" justifyContent="space-between" width="8em" minWidth="8em" fontSize="0.6em">
        <Box component="span" fontWeight={600}>
          {type}
        </Box>
        <Box>:</Box>
      </Box>
      <Box color={color7} fontSize="0.6em">
        {link}
      </Box>
    </Box>
  );
};

const PREMIUM_0007: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
    savedCvFont,
  } = props;
  const { basicInfo, experience, education, skills } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    date_of_birth,
    cv_email,
    address,
    city,
    country,
    skype,
    role,
    summary,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'JHON DEE';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'yourmail@domain.com';
  const userAboutMe =
    summary ||
    `ILorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem.`;
  let userDateOfBirth = date_of_birth === undefined ? '16 July 1994' : date_of_birth;
  userDateOfBirth = userDateOfBirth ? formatDate(userDateOfBirth, 'DD MMMM YYYY') : '';
  const userRole = role || 'Digital Marketer';
  const userSkype = skype ?? 'username skype';
  const userAddress = address || 'Melbourne Street Number 4';
  const userCity = city || 'California, New York City';
  const userCountry = country || 'US';
  const userPhoneNumber = mobile_number || '+67-0890-231';

  const contacts = [
    { 'Date of birth': `${userDateOfBirth}` },
    { Address: `${userAddress}, ${userCity}, ${userCountry}` },
    { Phone: `${userPhoneNumber}` },
    { Email: `${userEmail}` },
    { Skype: `${userSkype}` },
  ];
  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'www.facebook.com/username' },
    { type: 'Instagram', link: 'www.instagram.com/username' },
    { type: 'LinkedIn', link: 'www.linkedin.com/username' },
    { type: 'Twitter', link: 'www.twitter.com/username' },
    { type: 'Website', link: 'www.your-website.com' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];

  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, [(e) => e.link.length], ['asc']);

  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University / College / Institute',
    degree: 'Master',
    grade: null,
    major: 'Computer Science',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lorem arcu mattis et.',
  });
  const userEducation = education ?? dummyEducation;

  const dummyExperiences = Array(2).fill({
    role: 'ENTER JOB TITLE HERE',
    company: 'Company / Institute Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#CCEAFC' } : {};
  };

  const renderSummary = () => {
    return [
      <Box key="summary" p=".0em 1em 0.5em" {...activeColors(1)}>
        {userAboutMe.trim() && (
          <Box key="social" py="0.3em">
            <SubTitle color={cvColor} mBottom=".9em">
              ABOUT ME
            </SubTitle>
            <AQuillContent color={textColor7} content={userAboutMe} fontSize="0.6em" />
          </Box>
        )}
        <SubTitle color={cvColor}>CONTACT</SubTitle>
        <Box fontSize="0.6em">
          <Box display="flex" flexDirection="column" gridGap="0.81em">
            {contacts.map((contact) => {
              return Object.entries(contact).map(
                (itemContact, idx) =>
                  !!itemContact[1].length && (
                    <Box display="flex" key={idx}>
                      <Box display="flex" width="8em" justifyContent="space-between">
                        <Box component="span" fontWeight={600}>
                          {itemContact[0]}
                        </Box>
                        <Box>:</Box>
                      </Box>
                      <Box
                        flex={1}
                        component="span"
                        display="inline"
                        ml=".2em"
                        dangerouslySetInnerHTML={{ __html: itemContact[1] }}
                        color={textColor7}
                      />
                    </Box>
                  ),
              );
            })}
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderSocial = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box key="social" p="0.3em 1em .3em" {...activeColors(1)}>
        <SubTitle color={cvColor} mBottom="0.3em">
          SOCIAL MEDIA
        </SubTitle>
      </Box>,
      ...userSocialMediaShowed.map((e, index) => (
        <UserSocialMediaInfo key={e.type + index} type={e.type} link={e.link} activeColors={activeColors(1)} />
      )),
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experience" p=".3em 1em .3em" {...activeColors(2)}>
        <SubTitle color={cvColor} mBottom="0.3em">
          WORK EXPERIENCE
        </SubTitle>
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo
          activeColors={activeColors(2)}
          key={`experience-${index}${item.companyInfo}`}
          cvExperience={item}
        />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education" p="0em 1em .3em" {...activeColors(3)}>
        <SubTitle color={cvColor} mBottom="0.3em">
          EDUCATION
        </SubTitle>
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`education${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="userSkills" p="0em 1em .3em" {...activeColors(4)}>
        <SubTitle color={cvColor} mBottom="0.3em">
          SKILLS
        </SubTitle>
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          key={`skill${name}${index}${points}`}
          title={name}
          point={points}
          activeColors={activeColors(4)}
          isShowProficiency={isShowProficiency}
        />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderSummary(), ...renderSkills(), ...renderSocial()],
    rightComponents: [...renderExperience(), ...renderEducation()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont}>
      <Box
        minHeight="54.164em"
        height="54.164em"
        position="relative"
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <Box height="10.5em" className="u-text-uppercase" p="1em 1em 0 1em" width="100%" {...activeColors(1)}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            {isShowAvatar && (
              <Box height="5.8em" minWidth="5.8em" width="5.8em" mr="1.45em">
                <img
                  height="100%"
                  width="100%"
                  src={userAvatar}
                  alt=""
                  className="u-border-rounded o-base-cv__avatar"
                />
              </Box>
            )}
            <Box flex={1}>
              <Box fontWeight={700} letterSpacing="0.05em">
                <Box fontSize="1.5em">{`${userFirstName} ${userLastName}`}</Box>
              </Box>
            </Box>
          </Box>
          <Box py=".5em" borderTop={1} borderBottom={1} borderColor={color7} lineHeight="1.36em" mt="1.09em">
            <Box fontSize="0.8em" color={color7}>
              {userRole}
            </Box>
          </Box>
        </Box>
        <Box height="43.664em" display="flex">
          <Box minWidth="42.8%" maxWidth="42.8%" height="100%" id={idLeft} overflow="hidden" fontSize={fontSizeRatio}>
            {renderedLeft}
            <Box height="1em" fontSize={textSize} />
          </Box>
          <Box flexGrow={1} height="100%" id={idRight} overflow="auto" fontSize={fontSizeRatio}>
            {renderedRight}
            <Box height="2.6em" fontSize={textSize} />
          </Box>
        </Box>
        <MCvWatermark />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height={30} bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0007_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
            textSize={textSize}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0007;
