import { FC } from 'react';
import Box from '@material-ui/core/Box';
import useTranslation from 'next-translate/useTranslation';

import MCvWatermark from 'components/molecules/MCvWatermark';
import { useCvTwoLayoutBack } from 'helpers';
import { OCVProps, previewType } from '../../index';

const FREE_0007_BACK: FC<OCVProps> = (props) => {
  const { t } = useTranslation('common');
  const [
    isShowOtherPage,
    renderedLeft,
    renderedRight,
    idLeft,
    idRight,
    contentLeft,
    contentRight,
    preview,
    savedCvColour,
    fontSizeRatio,
    textSize,
  ] = useCvTwoLayoutBack({
    breakComponent: <Box key="too-large">{t('common.contentTooLong')}</Box>,
    props,
  });

  return (
    <>
      <Box minHeight="54.164em" height="54.164em" position="relative">
        <Box height="54.164em" overflow="hidden" display="flex">
          <Box
            width="42.8%"
            minWidth="42.8%"
            maxHeight="100%"
            height="100%"
            id={idLeft}
            overflow="hidden"
            p="1em 0 0.5em"
            fontSize={fontSizeRatio}
          >
            {renderedLeft}
          </Box>
          <Box
            flexGrow={1}
            maxHeight="100%"
            height="100%"
            id={idRight}
            overflow="hidden"
            p="1em 0 0"
            fontSize={fontSizeRatio}
          >
            {renderedRight}
            <Box height="2.6em" fontSize={textSize} />
          </Box>
        </Box>
        <MCvWatermark />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height={30} bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <FREE_0007_BACK
            preview={preview}
            savedCvColour={savedCvColour}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
            textSize={textSize}
          />
        </>
      )}
    </>
  );
};

export default FREE_0007_BACK;
