import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import { getCVAvatar, useCVHelper, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import { ActiveColors, ProfileEducation, ProfileExperience } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AAddress,
  AContactMe,
  ADribbble,
  AEmail,
  AGithub,
  AOutlinedEducation,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedSkills,
  AOutlinedSocialMedia,
  AOutlinedTwitter,
  APhone,
  AProfile,
  ASkype,
  AWebsite,
  AWork,
  ISocialIcon,
} from 'components/atoms/icons';
import PREMIUM_0005_BACK from './back';
import { OCVProps, previewType } from '../../index';

const SkillItem: FC<{
  title: string;
  point: number;
  isShowProficiency: boolean;
  index: number;
  activeColors: ActiveColors;
}> = ({ title, point, isShowProficiency, index, activeColors }) => {
  if (isShowProficiency) {
    const percent = (point * 100) / 5;
    return (
      <Box p={`${index ? '0' : '.8em'} 1.5em .8em`} {...activeColors}>
        <Box display="flex" justifyContent="space-between" mb=".3em" textAlign="left">
          <Box fontSize="0.5em" width="100%">
            {title}
          </Box>
        </Box>
        <Box minWidth="8em" display="flex" alignItems="center" position="relative" height="0.4em">
          <Box height="1px" bgcolor="#484545" width="100%" />
          <Box
            height="0.4em"
            width="0.4em"
            bgcolor="white"
            borderRadius="50%"
            border="1px solid #212121"
            position="absolute"
            top={0}
            left={`${(percent * 72) / 1000}em`}
          />
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" p={`${index ? '0' : '.8em'} 1.5em 1.2em`} {...activeColors}>
      <Box bgcolor="black" width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, company, from_year, to_year, finished, activities, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box display="flex" gridGap=".8em" p=".4em 1.5em 1.2em" {...activeColors}>
      <Box minWidth="3.2em">
        <Box
          pb=".2em"
          whiteSpace="nowrap"
          borderBottom="1px solid #484545"
          fontSize="0.5em"
        >{`${joinDate} - ${leaveDate}`}</Box>
      </Box>
      <Box>
        <Box fontWeight={700} className="u-text-uppercase" fontSize="0.6em" color="#212121">
          {role}
        </Box>
        <Box mt=".8em" fontSize=".5em" color="#9E9E9E">
          {`${company} | ${location}`}
        </Box>
        <AQuillContent mt=".8em" fontWeight={400} content={activities} fontSize="0.6em" />
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, to_year, finished, major, degree, from_year, activities } = cvEducation;
  const joinDate = getYearOrExample(from_year, '2020');
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2021');

  return (
    <Box display="flex" gridGap=".8em" p=".4em 1.5em .8em" {...activeColors}>
      <Box minWidth="3.2em">
        <Box
          pb=".2em"
          whiteSpace="nowrap"
          borderBottom="1px solid #484545"
          fontSize="0.5em"
        >{`${joinDate} - ${leaveDate}`}</Box>
      </Box>
      <Box>
        <Box fontWeight={700} className="u-text-uppercase" fontSize="0.6em" color="#212121">
          {`${degree} ${major}`}
        </Box>
        <Box mt=".8em" fontSize=".5em" color="#9E9E9E">
          {school}
        </Box>
        <AQuillContent mt=".8em" fontWeight={400} content={activities} fontSize="0.6em" fontStyle="italic" />
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ info: string; img: string; activeColors: ActiveColors }> = ({ info, img, activeColors }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    LinkedIn: AOutlinedLinkedin,
    Instagram: AOutlinedInstagram,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG: FC<ISocialIcon> = componentIcons[img];
    return <SVG strokeWidth="0" width="100%" height="100%" />;
  };

  return (
    <Box display="flex" gridGap=".6em" alignItems="center" p=".4em 1.5em .8em" {...activeColors}>
      <Box width=".5em" height=".5em" className="u-flex-center">
        {img && svgIcons()}
      </Box>

      <Box>
        <Box textAlign="center" fontSize="0.5em">
          {info}
        </Box>
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const contact = {
    Phone: APhone,
    Email: AEmail,
    Address: AAddress,
    Skype: ASkype,
  };

  const Icon: FC<ISocialIcon> = contact[type];
  return (
    <Box py=".4em" display="flex" gridGap=".9em" alignItems="center">
      <Box width=".8em" height=".8em" className="u-flex-center">
        <Icon
          height="100%"
          width="100%"
          fill={type === 'Skype' ? '#212121' : 'white'}
          textFill="white"
          stroke="#212121"
          strokeWidth=".5px"
        />
      </Box>
      <Box className="u-word-break-all" dangerouslySetInnerHTML={{ __html: link }} fontSize=".5em" textAlign="left" />
    </Box>
  );
};

const SubTitle: FC<{ svg: FC<ISocialIcon>; cvColor: string } & BoxProps> = ({ svg, children, cvColor, ...props }) => {
  const SVG = svg;
  return (
    <Box
      display="flex"
      alignItems="baseline"
      justifyContent="space-between"
      pb=".2em"
      borderBottom="1px solid #484545"
      fontWeight={600}
      {...props}
    >
      <Box fontSize=".8em" whiteSpace="nowrap" color={cvColor}>
        {children}
      </Box>
      <Box width=".8em" height=".8em">
        <SVG height="100%" width="100%" fill="white" strokeWidth=".5px" stroke="#212121" />
      </Box>
    </Box>
  );
};

const PREMIUM_0005: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    skype,
    socialMedia,
  } = basicInfo as CvBasicInfo;

  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'DANIEL CHEVAL';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'yourmail@domain.com';
  const userSkype = skype ?? 'username skype';
  const userAboutMe =
    summary ??
    `Lacus maecenas leo etiam in commodo vitae eget vitae. Enim convallis vel lectus egestas at eget. Risus, quisque nullam ac diam, molestie. Lacus maecenas leo etiam in commodo vitae eget vitae. Enim convallis vel lectus egestas at eget. Risus, quisque nullam ac diam, molestie.`;

  const userRole = role || 'CHIEF INFORMATION OFFICER';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State, City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+123 4567 8910';

  let userSocialMedia = socialMedia || [
    { type: 'LinkedIn', link: 'your username' },
    { type: 'Facebook', link: 'your username' },
    { type: 'Twitter', link: 'your username' },
    { type: 'Instagram', link: 'your username' },
    { type: 'Website', link: 'www.yourwebsite.com' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];
  const contacts = [
    { type: 'Phone', link: userPhoneNumber },
    { type: 'Email', link: userEmail },
    { type: 'Skype', link: userSkype },
    { type: 'Address', link: `${userAddress}<br/>${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }
  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);
  const dummyExperiences = Array(3).fill({
    role: 'Business Owner',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University / College / Institute',
    degree: 'Master',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2020',
    to_year: '01-01-2021',
    finished: false,
    activities:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In felis lacus volutpat mi varius cras venenatis. Placerat donec purus egestas augue amet, amet nunc. Pellentesque pellentesque ac velit lobortis ipsum et. Commodo velit vel proin amet auctor phasellus.',
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(2).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#d2d2d2', color: 'black' } : {};
  };

  const [fontSizeRatio, cvColor, t] = useCVHelper(props);

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box key="summary" p="1em 1.5em 0" {...activeColors(1)}>
        <SubTitle cvColor={cvColor} svg={AProfile}>
          PROFILE
        </SubTitle>
        <Box p=".8em 0 1.2em" borderBottom="1px solid #484545">
          <AQuillContent content={userAboutMe} fontSize=".5em" />
        </Box>
      </Box>,
    ];
  };

  const renderContact = () => {
    return [
      <Box key="contact" p=".2em 1.5em 0" {...activeColors(1)}>
        <SubTitle cvColor={cvColor} svg={AContactMe}>
          CONTACT
        </SubTitle>
        <Box p=".8em 0px" borderBottom="1px solid #484545">
          {contacts.map(
            (item, idx) => !!item.link.length && <UserContactsBox key={idx} type={item.type} link={item.link} />,
          )}
        </Box>
      </Box>,
    ];
  };

  const renderEducation = () => {
    if (!userEducation.length) return [];
    return [
      <Box key="education" p="1em 1.5em .4em" {...activeColors(3)}>
        <SubTitle cvColor={cvColor} svg={AOutlinedEducation}>
          EDUCATION
        </SubTitle>
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
      <Box key="education-line" bgcolor="rgb(72, 69, 69)" m=".4em 1.5em 0" height="1px" />,
    ];
  };

  const renderExperience = () => {
    if (!userEducation.length) return [];
    return [
      <Box key="experience" p=".4em 1.5em .8em" {...activeColors(2)}>
        <SubTitle cvColor={cvColor} svg={AWork}>
          WORK EXPERIENCE
        </SubTitle>
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`${item.companyInfo}${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderSkills = () => {
    if (!userSkills.length) return [];
    return [
      <Box key="skills" p=".2em 1.5em 0" {...activeColors(4)}>
        <SubTitle cvColor={cvColor} svg={AOutlinedSkills}>
          SKILLS
        </SubTitle>
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          key={`skill${name}${index}${points}`}
          title={name}
          point={points}
          index={index}
          isShowProficiency={isShowProficiency}
          activeColors={activeColors(4)}
        />
      )),
      <Box key="skill-line" bgcolor="rgb(72, 69, 69)" m="0 1.5em" height="1px" />,
    ];
  };

  const renderSocialMedia = () => {
    if (!userSocialMediaShowed.length) return [];
    return [
      <Box key="SocialMedia" p=".2em 1.5em .4em" {...activeColors(1)}>
        <SubTitle cvColor={cvColor} svg={AOutlinedSocialMedia}>
          SOCIAL MEDIA
        </SubTitle>
      </Box>,
      ...userSocialMediaShowed.map((item, index) => (
        <UserSocialBox key={item.type + index} img={item.type} info={item.link} activeColors={activeColors(1)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderSummary(), ...renderContact(), ...renderSkills(), ...renderSocialMedia()],
    rightComponents: [...renderEducation(), ...renderExperience()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont || 'inherit'}>
      <Box minHeight="54.164em" height="54.164em" fontSize={fontSizeRatio || 'inherit'}>
        <Box display="flex" height="8.1em">
          <Box minWidth="11.8em" maxWidth="11.8em" maxHeight="54.164em" overflow="hidden" textAlign="center">
            <Box p="1.5em 0 0 1.5em" {...activeColors(1)}>
              <Box height="6.6em" border="1px solid #484545" borderLeft="none" className="u-flex-center">
                {isShowAvatar && (
                  <Box height="3.8em" width="4.6em" bgcolor="#DADADA" className="u-flex-center">
                    <Box height="3.6em" width="3.6em">
                      <img
                        height="100%"
                        width="100%"
                        src={userAvatar}
                        alt="profile-pict"
                        className="u-border-rounded o-base-cv__avatar"
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box flexGrow={1} overflow="hidden">
            <Box p="1.5em 1.5em 0 0" {...activeColors(1)}>
              <Box
                border="1px solid #484545"
                borderLeft="none"
                borderRight="none"
                display="flex"
                pl="1.6em"
                flexDirection="column"
                justifyContent="center"
                height="6.6em"
              >
                <Box fontSize="1.2em" fontWeight={700} mb=".2em" className="u-text-uppercase" letterSpacing=".06em">
                  {userFirstName} {userLastName}
                </Box>
                <Box fontSize=".65em" whiteSpace="nowrap" className="u-text-uppercase" letterSpacing=".06em">
                  {userRole}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" height="46.064em" position="relative">
          <Box
            minWidth="11.8em"
            maxWidth="11.8em"
            id={idLeft}
            overflow="hidden"
            textAlign="center"
            height="44.564em"
            borderRight="1px solid #484545"
          >
            {renderedLeft}
          </Box>
          <Box flexGrow={1} id={idRight} height="44.564em" overflow="hidden">
            {renderedRight}
          </Box>
          <Box
            height="1px"
            bgcolor="#484545"
            width="calc(100% - 3em)"
            position="absolute"
            bottom="1.5em"
            left="1.5em"
          />
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0005_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0005;
