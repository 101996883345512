import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { FC, ReactElement } from 'react';
import {
  ADribbble,
  AEducation,
  AEmail,
  AGithub,
  AHome,
  AInterest,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  AProfile,
  ASkype,
  ASocialMedia,
  AWebsite,
  AWork,
} from 'components/atoms/icons';
import { ISocialIcon } from 'components/atoms/icons/ASkype';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import { getCVAvatar, getLanguageLevel, useCVHelper, useCvTwoLayout } from 'helpers';
import {
  ActiveColors,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
  ProfileReference,
} from 'redux-saga/interfaces';
import { getYearOrExample } from 'utils/date';
import PREMIUM_0016_BACK from './back';
import { OCVProps, previewType } from '../../index';

const UserContactsBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const componentIcons = {
    email: AEmail,
    phone: APhone,
    address: AHome,
    Skype: ASkype,
  };
  const svgIcon = () => {
    const SVG = componentIcons[type];
    return <SVG fill="#000000" strokeWidth="0" width="1.2em" height="1.2em" />;
  };
  return (
    <Box fontWeight={400} fontSize=".7em" display="flex" gridGap="1em" alignItems="center">
      {type && svgIcon()}
      <Box className="u-word-break-all" dangerouslySetInnerHTML={{ __html: link }} />
    </Box>
  );
};

const SubTitle: FC<{ name: string; right?: boolean; theme?: string; icon?: ReactElement } & BoxProps> = ({
  name,
  icon,
}) => {
  return (
    <Box display="flex" gridGap=".56em" alignItems="center">
      <Box width="1.2em" height="1.2em" borderRadius="50%" border=".05em solid black" className="u-flex-center">
        {icon}
      </Box>
      <Box flex={1} fontSize=".8em">
        {name}
      </Box>
    </Box>
  );
};

const SkillItem: FC<{
  title: string;
  activeColors: ActiveColors;
  pointSize: number;
}> = ({ title, activeColors, pointSize }) => {
  return (
    <Box width="calc(50% - 0.2em)" display="flex" gridGap=".3em" alignItems="center" {...activeColors}>
      <Box bgcolor="black" width={pointSize} minWidth={pointSize} height={pointSize} borderRadius="50%" />
      <Box fontSize=".5em" flex={1}>
        {title}
      </Box>
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage; activeColors: ActiveColors }> = ({
  language,
  activeColors,
}) => {
  const { name, speaking, writing } = language;
  return (
    <Box
      fontWeight={400}
      fontSize=".5em"
      display="flex"
      gridGap=".5em"
      justifyContent="space-between"
      p=".4em 8%"
      {...activeColors}
    >
      <Box flex={1}>{name}</Box>
      <Box whiteSpace="pre" flex={1}>
        Written: {getLanguageLevel(writing)} | Spoken: {getLanguageLevel(speaking)}
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  cvReference,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box display="flex" flexDirection="column" gridGap=".6em" minWidth="40%" p=".6em 9%" {...activeColors}>
      <Box display="flex" flexDirection="column" gridGap=".2em">
        <Box fontSize=".5em" fontWeight={700}>
          {name}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          {position}, {company}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gridGap=".2em">
        <Box fontSize=".5em" fontWeight={400}>
          <Box component="span" mr=".2em">
            Phone :
          </Box>
          {mobile}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          <Box component="span" mr=".2em">
            Email :
          </Box>
          {email}
        </Box>
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ type: string; link: string; index: number; activeColors: ActiveColors }> = ({
  type,
  link,
  activeColors,
}) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    LinkedIn: AOutlinedLinkedin,
    Instagram: AOutlinedInstagram,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG: FC<ISocialIcon> = componentIcons[type] || componentIcons.Website;
    return <SVG fill="white" stroke="white" strokeWidth="0" width="60%" height="60%" />;
  };
  return (
    <Box display="flex" gridGap=".5em" alignItems="center" p=".3em 9%" {...activeColors}>
      <Box borderRadius="50%" bgcolor="#000000" className="u-flex-center" width="1em" height="1em">
        {type && svgIcons()}
      </Box>
      <Box fontWeight={400} fontSize=".4em">
        {link}
      </Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities, company, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box fontWeight={400} p=".6em 9%" {...activeColors}>
      <Box fontSize=".6em">{`${joinDate} - ${leaveDate} | ${company}, ${location}`}</Box>
      <Box mt=".4em">
        <Box fontSize=".6em" className="u-text-uppercase" fontWeight={700}>
          {role}
        </Box>
      </Box>
      <Box mt=".8em">
        <AQuillContent color="#3B5159" fontSize=".6em" content={activities} />
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, to_year, finished, from_year, degree } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box fontWeight={400} p=".3em 9%" {...activeColors}>
      <Box fontSize=".6em">{`${joinDate} - ${leaveDate}`}</Box>
      <Box mt=".4em">
        <Box fontSize=".6em" fontWeight={700} className="u-text-uppercase">
          {`${degree} at ${school}`}
        </Box>
      </Box>
    </Box>
  );
};

const SkillSvg = ({ width = '5', height = '7' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99512 3.34035C4.97196 3.15754 4.87889 2.99789 4.73049 2.893C3.55488 2.04886 3.16458 0.916997 3.03891 0.314637L3.02519 0.251031C3.02519 0.251031 2.99731 0 2.67649 0V0.251031L2.68121 2.6512C2.68121 2.92963 2.79744 3.20344 3.01575 3.44523C3.11783 3.55011 3.1693 3.72789 3.1693 3.96546C3.1693 4.32562 2.87164 4.61795 2.5045 4.62258H2.4955C2.12836 4.61795 1.83113 4.32562 1.83113 3.96546C1.83113 3.72789 1.88217 3.55011 1.98425 3.44523C2.20256 3.20344 2.31879 2.92963 2.31879 2.6512L2.32351 0.251031V0C2.00312 0 1.97524 0.251031 1.97524 0.251031L1.96109 0.314637C1.83585 0.916997 1.44555 2.04886 0.269935 2.893C0.121107 2.99789 0.028463 3.15754 0.00487352 3.34035C-0.0182871 3.52738 0.0421892 3.7144 0.163139 3.86057C0.288378 4.00631 0.423051 4.11583 0.553436 4.22071C0.892696 4.49451 1.21309 4.75947 1.34776 5.63984H1.09214C1.02737 5.63984 0.971616 5.69459 0.971616 5.76325V6.87657C0.971616 6.94523 1.02737 7 1.09214 7H3.90787C3.97306 7 4.02881 6.94523 4.02881 6.87657V5.76325C4.02881 5.69459 3.97306 5.63984 3.90787 5.63984H3.65224C3.78692 4.75947 4.10773 4.49451 4.44699 4.22071C4.57695 4.11583 4.71162 4.00631 4.83729 3.86057C4.95781 3.7144 5.01829 3.52738 4.99512 3.34035Z"
        fill="#000000"
      />
    </svg>
  );
};

const LanguageSvg = ({ width = '6', height = '6' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99797 2.95137C2.84549 2.95137 2.7225 2.83782 2.7225 2.69799C2.7225 2.55774 2.84549 2.44419 2.99797 2.44419C3.14998 2.44419 3.27343 2.55774 3.27343 2.69799C3.27343 2.83782 3.14998 2.95137 2.99797 2.95137ZM1.56075 2.95137C1.40874 2.95137 1.28528 2.83782 1.28528 2.69799C1.28528 2.55774 1.40874 2.44419 1.56075 2.44419C1.71322 2.44419 1.83668 2.55774 1.83668 2.69799C1.83668 2.83782 1.71322 2.95137 1.56075 2.95137ZM4.5333 2.44419C4.68531 2.44419 4.80877 2.55774 4.80877 2.69799C4.80877 2.83782 4.68531 2.95137 4.5333 2.95137C4.38129 2.95137 4.25783 2.83782 4.25783 2.69799C4.25783 2.55774 4.38129 2.44419 4.5333 2.44419ZM6 2.75944C6 1.04128 4.29284 -0.309962 2.35398 0.0620595C1.21987 0.279425 0.303182 1.12305 0.0673306 2.16582C-0.336656 3.94923 1.13235 5.5191 2.99981 5.5191C3.25086 5.5191 3.4927 5.48689 3.72533 5.43308C3.77231 5.42206 3.82114 5.42629 3.86444 5.44663L5.03633 5.99196C5.09068 6.01738 5.15471 5.97924 5.15241 5.92331L5.10634 4.79666C5.10404 4.74666 5.12477 4.69877 5.16254 4.66276C5.67893 4.16786 6 3.49967 6 2.75944Z"
        fill="#000000"
      />
    </svg>
  );
};

const ReferenceSvg = ({ width = '5', height = '5' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.11055 0.299133C0.953519 0.299133 0.824609 0.428038 0.824609 0.585073V0.899133H0.774609C0.610154 0.899133 0.474609 1.03468 0.474609 1.19913C0.474609 1.36359 0.610154 1.49913 0.774609 1.49913H0.824609V2.19913H0.774609C0.610154 2.19913 0.474609 2.33468 0.474609 2.49913C0.474609 2.66359 0.610154 2.79913 0.774609 2.79913H0.824609V3.49913H0.774609C0.610154 3.49913 0.474609 3.63468 0.474609 3.79913C0.474609 3.96359 0.610154 4.09913 0.774609 4.09913H0.824609V4.41163C0.823633 4.56944 0.953514 4.69913 1.11055 4.69913H4.23865C4.39607 4.69913 4.52459 4.56866 4.52459 4.41163V0.585083C4.52459 0.428053 4.39568 0.299143 4.23865 0.299143L1.11055 0.299133ZM0.774609 1.09913H1.07461C1.13183 1.09913 1.17461 1.14191 1.17461 1.19913C1.17461 1.25636 1.13184 1.29913 1.07461 1.29913H0.774609C0.717384 1.29913 0.674609 1.25636 0.674609 1.19913C0.674609 1.14191 0.717383 1.09913 0.774609 1.09913ZM2.67461 1.09913C3.00488 1.09913 3.27461 1.36886 3.27461 1.69913C3.27461 2.02941 3.00488 2.29913 2.67461 2.29913C2.34433 2.29913 2.07461 2.02941 2.07461 1.69913C2.07461 1.36886 2.34433 1.09913 2.67461 1.09913ZM0.774609 2.39913H1.07461C1.13183 2.39913 1.17461 2.44191 1.17461 2.49913C1.17461 2.55636 1.13184 2.59913 1.07461 2.59913H0.774609C0.717384 2.59913 0.674609 2.55636 0.674609 2.49913C0.674609 2.44191 0.717383 2.39913 0.774609 2.39913ZM2.24961 2.39913H3.09961C3.44316 2.39913 3.72461 2.67433 3.72461 3.01473V3.69913C3.72461 3.75148 3.67695 3.79913 3.62461 3.79913H1.72461C1.67226 3.79913 1.62461 3.75148 1.62461 3.69913V3.01473C1.62461 2.6743 1.90605 2.39913 2.24961 2.39913ZM0.774609 3.69913H1.07461C1.13183 3.69913 1.17461 3.74191 1.17461 3.79913C1.17461 3.85636 1.13184 3.89913 1.07461 3.89913H0.774609C0.717384 3.89913 0.674609 3.85636 0.674609 3.79913C0.674609 3.74191 0.717383 3.69913 0.774609 3.69913Z"
        fill="#000000"
      />
    </svg>
  );
};

const PREMIUM_0016: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
  } = props;
  const { basicInfo, experience, education, skills, languages, interest, reference } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    socialMedia,
    skype,
  } = basicInfo as CvBasicInfo;

  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'HENRY';
  const userLastName = last_name || 'SILLY';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userSkype = skype ?? 'jhon Dee';
  const userAboutMe =
    summary ??
    `My Name Is Henry Silly Lorem Ipsum is simply dummy text of the into electronic typesetting, remaining essentially unchanged. It was popularized in the printer took a galley of type and scrambled it to make a type printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown men book. It has survived not only ﬁve centuries, but also the leap`;

  const userRole = role || 'GRAPHIC DESIGNER';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+021 345 678 900';

  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'UNIVERSITY NAME',
    degree: 'Degree',
    grade: '4.75',
    major: 'graphic design',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      'Lorem ipsum enunc pulvinati ncidunt. quis pul vinar mellu Lorem ipsum dolor: sit amet consectet gelit. it ellentesque eleife ornare ',
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(6).fill({
    skill: 'PHP',
    name: 'PHP',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;
  const userInterest = interest ?? [
    'Project Management',
    'Graphic Design',
    'Computer Language',
    'Mobile & Cloud Solution',
    'Event Planning',
  ];

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'facebook.com/username' },
    { type: 'Instagram', link: 'instagram.com/username' },
    { type: 'LinkedIn', link: 'linkedin.com/username' },
    { type: 'Twitter', link: 'twitter.com/username' },
    { type: 'Website', link: 'Henrysilly.com' },
    { type: 'Github', link: 'username' },
    { type: 'Dribble', link: 'username' },
  ];

  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'address', link: `${userAddress}<br />${userCity}, ${userCountry}` },
    { type: 'Skype', link: userSkype },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(5).fill({
    role: 'Business Owner',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;
  const dummyReference: ProfileReference[] = Array(1).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    email: 'youremail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const pointSize = Math.ceil(fontSizeRatio * 0.2);

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#adcfff', color: '#000' } : {};
  };

  const renderAboutMe = () => {
    return [
      <Box key="about-me" p="0 9% .6em 9%" {...activeColors(1)}>
        <SubTitle
          name="ABOUT ME"
          whiteSpace="nowrap"
          icon={<AProfile fill="#000000" width="60%" height="60%" stroke="" />}
        />
        <Box mt=".7em">
          <AQuillContent content={userAboutMe} fontWeight={400} fontSize=".5em" />
        </Box>
      </Box>,
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="userSkills" p="0 9% .6em 9%" {...activeColors(4)}>
        <SubTitle name="SKILLS" icon={<SkillSvg width="60%" height="60%" />} />
        <Box mt=".8em" display="flex" flexWrap="wrap" gridGap=".4em" justifyContent="space-between">
          {userSkills.map((item, idx) => (
            <SkillItem
              key={`${item.name}${idx}`}
              title={item.name}
              activeColors={activeColors(4)}
              pointSize={pointSize}
            />
          ))}
        </Box>
      </Box>,
    ];
  };

  const renderLanguages = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="userLanguages" {...activeColors(5)} p=".6em 9%">
        <SubTitle name="LANGUAGES" icon={<LanguageSvg width="60%" height="60%" />} />
      </Box>,
      ...userLanguages.map((item, index) => (
        <UserLanguageInfo key={index + item.name} language={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderInterest = () => {
    if (isEmpty(userInterest)) return [];
    return [
      <Box key="interests" {...activeColors(5)} p=".6em 9%">
        <SubTitle name="INTERESTS" icon={<AInterest width="60%" height="60%" />} />
        <Box mt=".8em" display="flex" flexWrap="wrap" gridGap=".4em" justifyContent="space-between">
          {userInterest.map((item, index) => (
            <Box width="45%" key={index} display="flex" gridGap=".5em" alignItems="center">
              <Box bgcolor="black" width={pointSize} minWidth={pointSize} height={pointSize} borderRadius="50%" />
              <Box fontSize=".4em">{item}</Box>
            </Box>
          ))}
        </Box>
      </Box>,
    ];
  };

  const renderReference = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box key="userReference" p=".2em 9%" {...activeColors(5)}>
        <SubTitle name="REFERENCES" icon={<ReferenceSvg width="60%" height="60%" />} />
      </Box>,
      ...userReference.map((item, index) => (
        <UserReference key={index} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box key="userSocialMediaShowed" {...activeColors(1)} p=".6em 9%">
        <SubTitle name="SOCIAL MEDIA" icon={<ASocialMedia fill="#000000" width="60%" height="60%" />} />
      </Box>,
      ...userSocialMediaShowed.map((item, idx) => (
        <UserSocialBox
          key={item.type + idx}
          type={item?.type}
          link={item?.link}
          index={idx}
          activeColors={activeColors(1)}
        />
      )),
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="userExperiences" {...activeColors(2)} p=".6em 9% .3em">
        <SubTitle name="WORK EXPERIENCE" icon={<AWork fill="#000000" width="60%" height="60%" stroke="" />} />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={index} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="educations" {...activeColors(3)} p=".6em 9% .3em">
        <SubTitle name="EDUCATION" icon={<AEducation fill="#000000" width="60%" height="60%" stroke="" />} />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    rightComponents: [...renderAboutMe(), ...renderEducation(), ...renderExperience()],
    leftComponents: [
      ...renderSkills(),
      ...renderLanguages(),
      ...renderInterest(),
      ...renderReference(),
      ...renderSocialMedia(),
    ],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} bgcolor="#fff" fontFamily={savedCvFont}>
      <Box minHeight="54.164em" height="54.164em" display="flex" position="relative">
        {isShowAvatar && (
          <Box
            fontSize={textSize}
            p=".6em"
            left="45%"
            top="3.8em"
            position="absolute"
            height="9.2em"
            minWidth="9.2em"
            maxWidth="9.2em"
            className="u-border-rounded"
            style={{ transform: 'translate(-50%, 0)' }}
            zIndex="100"
            bgcolor={isActive === 1 ? '#e8e8e8' : 'white'}
            {...activeColors(1)}
          >
            <img
              height="100%"
              width="100%"
              src={userAvatar}
              alt="profile-pict"
              className="u-border-rounded o-base-cv__avatar"
            />
          </Box>
        )}
        <Box
          fontSize={textSize}
          alignItems="center"
          gridGap="1.3em"
          position="absolute"
          top="1.3em"
          left="1.6em"
          right="1.6em"
          height="1.4em"
          display="flex"
        >
          <Box height=".4em" flex={40} bgcolor="#2F444C" />
          <Box width="fit-content" whiteSpace="pre">
            HELLO, I'M
          </Box>
          <Box height=".4em" bgcolor="#2F444C" flex={10} />
        </Box>
        <Box
          {...activeColors(1)}
          display="flex"
          position="absolute"
          top="4.6em"
          left="0"
          right="0"
          minHeight="7.6em"
          fontSize={textSize}
        >
          <Box minWidth="45%" minHeight="100%">
            <Box
              display="flex"
              flexDirection="column"
              gridGap="1em"
              borderLeft="1.8em solid #97D0C6"
              p=".4em 5em .4em .8em"
              height="100%"
              justifyContent="center"
            >
              {contacts.map(
                ({ link, type }, idx) =>
                  !!link.length && <UserContactsBox key={idx + type + link} type={type} link={link} />,
              )}
            </Box>
          </Box>
          <Box
            minWidth="55%"
            className="u-text-uppercase u-flex-center"
            flexDirection="column"
            bgcolor={isActive === 1 ? '#e8e8e8' : '#97D0C6'}
            {...activeColors(1)}
          >
            <Box p=".4em .8em .4em 5em">
              <Box mb=".2em" fontSize="2em">
                {userFirstName} {userLastName}
              </Box>
              <Box fontWeight={400} fontSize="1.2em">
                {userRole}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          position="absolute"
          left="1.6em"
          right="1.6em"
          bottom=".8em"
          height=".4em"
          bgcolor="#2F444C"
          fontSize={textSize}
        />
        <Box
          id={idLeft}
          minWidth="45%"
          bgcolor={cvColor}
          pb="1.2em"
          maxHeight="100%"
          height="100%"
          fontSize={fontSizeRatio}
        >
          <Box height="14em" fontSize={textSize} />
          {renderedLeft}
          <Box height="1em" fontSize={textSize} />
        </Box>
        <Box id={idRight} minWidth="55%" pb="1.2em" maxHeight="100%" height="100%" fontSize={fontSizeRatio}>
          <Box height="14em" fontSize={textSize} />
          {renderedRight}
          <Box height="1em" fontSize={textSize} />
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0016_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0016;
