import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, useCVHelper, useCVThreeLayout, useCvTwoLayout } from 'helpers';
import { ActiveColors, ProfileEducation, ProfileExperience, ProfileReference } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import { getYearOrExample } from 'utils/date';
import AQuillContent from 'components/atoms/AQuillContent';
import { ISocialIcon } from 'components/atoms/icons/ASkype';
import {
  AAddress,
  ADribbble,
  AEmail,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
} from 'components/atoms/icons';
import PREMIUM_0007_BACK from './back';
import { OCVProps, previewType } from '../../index';

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, company, from_year, to_year, finished, activities, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box p=".2em 1.6em .2em" {...activeColors}>
      <Box fontWeight={600} fontSize="0.65em" mb=".2em">
        {role}
      </Box>
      <Box fontSize="0.5em" lineHeight="1" color="#9E9E9E" mb=".5em">
        {company} | {location} | {joinDate} - {leaveDate}
      </Box>
      <AQuillContent content={activities} fontSize="0.5em" color="#484545" />
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const contact = {
    Phone: APhone,
    Email: AEmail,
    Address: AAddress,
  };

  const Icon: FC<ISocialIcon> = contact[type];
  return (
    <Box px="1.1em" display="flex" gridGap=".4em" alignItems="center">
      <Box width=".8em" height=".8em" className="u-flex-center">
        <Icon height="100%" width="100%" fill="#212121" />
      </Box>
      <Box fontSize=".5em" whiteSpace="no-wrap">
        {link}
      </Box>
    </Box>
  );
};

const SubTitle: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box fontSize=".9em" mb=".8em" fontWeight={700} {...props}>
      {children}
    </Box>
  );
};

const ProgessBox: FC<{ title: string; percent: number; color: string }> = ({ title, percent, color }) => {
  return (
    <Box>
      <Box fontSize="0.5em" width="100%" mb=".2em">
        {title}
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        height="0.45em"
        position="relative"
        border={`1px solid ${color}`}
      >
        <Box
          bgcolor={color}
          width={`calc(${percent}% + 2px)`}
          height="calc(100% + 2px)"
          position="absolute"
          top="-1px"
          left="-1px"
        />
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  cvReference,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box p=".2em 1.6em .8em" display="flex" flexDirection="column" gridGap=".7em" minWidth="40%" {...activeColors}>
      <Box display="flex" flexDirection="column" gridGap=".2em">
        <Box fontSize=".6em" fontWeight={600}>
          {name}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          {position}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          {company}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gridGap=".2em">
        <Box fontSize=".5em" fontWeight={400}>
          <Box component="span" fontWeight={700} mr=".2em">
            P |
          </Box>
          {mobile}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          <Box component="span" fontWeight={700} mr=".2em">
            E |
          </Box>
          {email}
        </Box>
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, major, degree, grade } = cvEducation;
  return (
    <Box p=".2em 1.6em" display="flex" flexDirection="column" gridGap=".3em" {...activeColors}>
      <Box fontWeight={600} className="u-text-uppercase" fontSize="0.6em" color="#484545">
        {`${degree} ${major}`}
      </Box>
      <Box color="#484545" fontSize="0.5em">
        {school}
      </Box>
      <Box color="#484545" fontSize="0.5em">
        Grade: {grade}
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{
  img: string;
  info: string;
  index: number;
  activeColors: ActiveColors;
}> = ({ img, info, activeColors }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    LinkedIn: AOutlinedLinkedin,
    Instagram: AOutlinedInstagram,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG: FC<ISocialIcon> = componentIcons[img];
    return <SVG strokeWidth="0" width="100%" height="100%" />;
  };
  return (
    <Box display="flex" alignItems="center" gridGap=".9em" p=".3em 10%" {...activeColors} width={1}>
      <Box width="1em" height="1em" className="u-flex-center">
        {img && svgIcons()}
      </Box>
      <Box flex={1}>
        <Box fontSize="0.6em" className="u-text-uppercase" mb=".2em">
          {img}
        </Box>
        <Box fontWeight={400} fontSize="0.5em">
          {info}
        </Box>
      </Box>
    </Box>
  );
};

const SkillItem: FC<{
  title: string;
  point: number;
  isShowProficiency: boolean;
  color: string;
  activeColors: ActiveColors;
}> = ({ title, point, isShowProficiency, color, activeColors }) => {
  if (isShowProficiency) {
    return <ProgessBox title={title} percent={(point * 100) / 5} color={color} />;
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" {...activeColors}>
      <Box bgcolor={color} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const PREMIUM_0007: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, reference } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'MATHEW HYATT';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'yourmail@domain.com';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque neque morbi non mattis sit. Lacus maecenas leo etiam in commodo vitae eget vitae. Enim convallis vel lectus egestas at eget. Risus, quisque nullam ac diam, molestie. Lacus maecenas leo etiam in commodo vitae eget vitae. Enim convallis vel lectus egestas at eget. Risus, quisque nullam ac diam, molestie.`;
  const [mockId, mockHeight] = useCVThreeLayout([userAboutMe]);
  const userRole = role || 'Interior Designer';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+123 4567 8910';

  let userSocialMedia = socialMedia || [
    { type: 'LinkedIn', link: 'linkedin/username' },
    { type: 'Facebook', link: 'facebook.com/username' },
    { type: 'Twitter', link: 'twitter.com/username' },
    { type: 'Instagram', link: 'instagram/username' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];
  const contacts = [
    { type: 'Phone', link: userPhoneNumber },
    { type: 'Email', link: userEmail },
    { type: 'Address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  let userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);
  const ignoredSocialMedia = ['Website'];
  userSocialMediaShowed = userSocialMediaShowed.filter((item) => !ignoredSocialMedia.includes(item.type));

  const signColor = '#d2d2d2';
  const dummyReference: ProfileReference[] = Array(2).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    company: 'Company / Organization Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const dummyExperiences = Array(5).fill({
    role: 'Business Owner',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(4).fill({
    school: 'University / College / Institute',
    degree: 'Master',
    grade: '4.75',
    major: 'computer science',
    from_year: '01-01-2020',
    to_year: '01-01-2021',
    finished: false,
    activities: '',
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#d2d2d2', color: 'black' } : {};
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box
        key="userSocialMediaShowed"
        p=".2em 1.6em .2em"
        bgcolor={isActive === 1 ? signColor : ''}
        {...activeColors(1)}
      >
        <SubTitle>SOCIAL</SubTitle>
      </Box>,
      ...userSocialMediaShowed.map((item, idx) => (
        <UserSocialBox
          key={item.type + idx}
          img={item.type}
          info={item.link}
          index={idx}
          activeColors={activeColors(1)}
        />
      )),
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="userSkills" p=".8em 1.6em .8em" {...activeColors(4)}>
        <SubTitle>SKILLS</SubTitle>
        <Box display="flex" flexDirection="column" gridGap=".7em">
          {userSkills.map(({ name, points }, index) => (
            <SkillItem
              key={`skill${name}${index}${points}`}
              title={name}
              point={points}
              isShowProficiency={isShowProficiency}
              color={cvColor}
              activeColors={activeColors(4)}
            />
          ))}
        </Box>
      </Box>,
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="educations" p=".2em 1.6em" {...activeColors(3)}>
        <SubTitle>EDUCATION</SubTitle>
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`education${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box p=".2em 1.6em .2em" key="userExperiences" {...activeColors(2)}>
        <SubTitle>WORK EXPERIENCE</SubTitle>
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`experience${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderReference = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box p=".8em 1.6em .2em" key="userReference" {...activeColors(5)}>
        <SubTitle>REFERENCE</SubTitle>
      </Box>,
      ...userReference.map((item, index) => (
        <UserReference key={`reference${index}`} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    rightComponents: [...renderExperience(), ...renderReference()],
    leftComponents: [...renderSocialMedia(), ...renderSkills(), ...renderEducation()],
    props,
  });

  return (
    <Box
      className="o-base-cv o-base-cv__multiple__page"
      bgcolor="#fff"
      fontSize={textSize}
      fontFamily={savedCvFont}
      overflow="hidden"
    >
      <Box minHeight="54.164em" height="54.164em" display="flex" flexDirection="column">
        <Box id={mockId} fontSize={fontSizeRatio}>
          <Box p="1.5em 1.5em 0" bgcolor={isActive === 1 ? signColor : ''} {...activeColors(1)}>
            <Box
              pb=".8em"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gridGap="1em"
              borderBottom={`1px solid ${cvColor}`}
            >
              {isShowAvatar && (
                <Box height="3em" width="3em">
                  <img
                    height="100%"
                    width="100%"
                    src={userAvatar}
                    alt="profile-pict"
                    className="u-border-rounded o-base-cv__avatar"
                  />
                </Box>
              )}
              <Box display="inline-flex" flexDirection="column">
                <Box fontSize="1.2em" fontWeight={700} className="u-text-uppercase" whiteSpace="nowrap">
                  {`${userFirstName} ${userLastName}`}
                </Box>
                <Box fontSize=".6em" className="u-text-uppercase" whiteSpace="nowrap">
                  {userRole}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box pb=".4em" fontSize={textSize} bgcolor={isActive === 1 ? signColor : ''} {...activeColors(1)}>
            <Box p="1em 1.5em 0" display="flex" justifyContent="center">
              {contacts.map((item, idx) => (
                <UserContactsBox key={idx} type={item.type} link={item.link} />
              ))}
            </Box>
            {userAboutMe.trim() && (
              <Box p="1em 4.8em 0">
                <Box fontSize=".7em" fontWeight={700} textAlign="center" m="0 auto .3em">
                  PROFILE STATEMENT
                </Box>
                <AQuillContent content={userAboutMe} textAlign="center" fontSize=".5em" />
              </Box>
            )}
          </Box>
        </Box>
        {!!mockHeight && (
          <Box display="flex" flex={1} height={`calc(54.164em - ${mockHeight}px)`}>
            <Box id={idLeft} pb="1.2em" height="100%" maxHeight="100%" flex={2} fontSize={fontSizeRatio}>
              {renderedLeft}
              <Box height="1.2em" />
            </Box>
            <Box
              id={idRight}
              flex={3}
              pb="1.2em"
              height="100%"
              maxHeight="100%"
              fontSize={fontSizeRatio}
              borderLeft={`1px solid ${cvColor}`}
            >
              {renderedRight}
              <Box height="1.2em" />
            </Box>
          </Box>
        )}
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0007_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0007;
