import Box from '@material-ui/core/Box';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import { useCvTwoLayoutBack } from 'helpers';
import { OCVProps, previewType } from '../../index';

const PREMIUM_0009_BACK: FC<OCVProps> = (props) => {
  const { t } = useTranslation('common');
  const [
    isShowOtherPage,
    renderedLeft,
    renderedRight,
    idLeft,
    idRight,
    contentLeft,
    contentRight,
    preview,
    savedCvColour,
    fontSizeRatio,
  ] = useCvTwoLayoutBack({
    breakComponent: <Box key="too-large">{t('common.contentTooLong')}</Box>,
    props,
  });

  return (
    <>
      <Box minHeight="54.164em" height="54.164em" position="relative" display="flex" flexDirection="column">
        <Box>
          <Box width="100%" height="2.7em">
            <img width="100%" height="100%" src="/assets/cv_9_decoration.png" alt="" />
          </Box>
          <Box display="flex" flex={1} height="51.454em">
            <Box flex={2} id={idLeft} fontSize={fontSizeRatio}>
              {renderedLeft}
              <Box height="1em" />
            </Box>
            <Box flex={1} borderLeft=".13em solid #484545" id={idRight} fontSize={fontSizeRatio}>
              {renderedRight}
              <Box height="1em" />
            </Box>
          </Box>
          <Box width="100%" height="1em" bottom={0} position="absolute" bgcolor={savedCvColour} />
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0009_BACK
            preview={preview}
            savedCvColour={savedCvColour}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </>
  );
};

export default PREMIUM_0009_BACK;
