import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AOutlinedTwitter: FC<ISocialIcon> = ({
  fill = 'black',
  width = '9',
  height = '7',
  stroke = 'black',
  strokeWidth = '.01em',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke={stroke}
        fill={fill}
        strokeWidth={strokeWidth}
        d="M7.40586 1.61306C7.23012 1.66469 7.09624 1.65609 7.09624 1.65609L7.1046 1.62166C7.2636 1.49257 7.67364 1.07948 7.72385 0.890141C7.74895 0.80408 7.71548 0.778261 7.71548 0.778261L7.19665 1.00202L6.76151 1.19996C6.46025 0.872929 6.00837 0.666382 5.49791 0.666382C4.59414 0.666382 3.85774 1.32905 3.85774 2.13803C3.85774 2.28433 3.88285 2.55973 3.92469 2.69743C3.92469 2.74046 3.92469 2.6544 3.92469 2.69743C3.33891 2.68022 2.59414 2.43924 1.93305 2.08639C0.619247 1.40651 0.535567 0.933172 0.535567 0.933172C0.326362 1.15693 0.225942 1.90566 0.435147 2.49088C0.569039 2.86094 1.02929 3.19658 1.02929 3.19658C1.02929 3.19658 0.820085 3.19658 0.61088 3.12773C0.393307 3.05028 0.317993 2.98143 0.317993 2.98143C0.19247 3.29986 0.443515 3.87647 0.88703 4.28956C1.14644 4.53053 1.61506 4.67684 1.61506 4.67684L0.878661 4.69405C0.83682 5.43418 2.38494 5.87309 2.38494 5.87309C1.93305 6.23455 1.40586 6.4497 0.845189 6.4497C0.552302 6.4497 0.267782 6.40667 0 6.32061C0.74477 6.94885 1.69875 7.38777 2.74477 7.32752C5.50628 7.18122 7.06276 4.73708 7.13808 2.33597V2.34458C7.13808 2.34458 7.2636 2.27573 7.54812 1.99173C7.82427 1.70772 8 1.37208 8 1.37208C8 1.37208 7.58159 1.57003 7.40586 1.61306Z"
      />
    </svg>
  );
};
