import { FC } from 'react';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import { getCVAvatar } from 'helpers';

import { AppState, ProfileEducation, ProfileExperience } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';

import { getYearOrExample } from 'utils/date';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  ADribbble,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  ISocialIcon,
} from 'components/atoms/icons';
import { OCVProps, previewType } from '../index';

const tempSummary = `A proven Software Engineering Manager/Software Developer, who quickly and successfully responds to ever-changing environments and situations, consistently achieves goals within critical project deadlines and is able to leverage an eye for detail, ensuring success and quality. Consistently recognized and tasked to improve organizational effectiveness and efficiency through leadership.`;

const tempActivities = `- Gathered and elicit requirements using appropriate agile framework/tools/techniques
- Engaged with senior story author/Product Owner to understand the scope of features
- Developed tier coding business layer coding interface development service development
creation of stored procedures etc as applicable for the work package/project
- Understood all competency layers (e.g. UI platforms methodologies etc) and
raise clarifications/issues/concerns regarding work output.`;

const tempUserFirstName = 'MONAH';
const tempEmail = 'mail@example.com';
const tempRole = 'ENGINEERING MANAGER';
const tempUserAddress = 'Street, Address';
const tempUserCity = 'State/City';
const tempUserCountry = 'Country';
const tempPhone = '+62 8909 1234';

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation }> = ({ cvEducation }) => {
  const { school, degree, major, grade } = cvEducation;

  return (
    <Box>
      <Box fontWeight="bold">{`${degree} degree in ${major}`}</Box>
      <Box fontStyle="italic">{school}</Box>
      <Box>{`Grade: ${grade}`}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience }> = ({ cvExperience }) => {
  const { role, company, from_year, to_year, finished, activities, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'present' : getYearOrExample(to_year);

  return (
    <Box display="flex" flexDirection="column" gridGap=".36em">
      <Box fontWeight="bold">{role}</Box>
      <Box display="flex" color="#757575" fontSize=".8em">
        <Box pr="1em">{company}</Box>
        <Box px="1em" borderColor="#E0E0E0 !important" borderLeft={1} borderRight={1}>
          {location}
        </Box>
        <Box px="1em">{`${joinDate} - ${leaveDate}`}</Box>
      </Box>
      <AQuillContent content={activities} />
    </Box>
  );
};

const UserSocialBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    LinkedIn: AOutlinedLinkedin,
    Instagram: AOutlinedInstagram,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG: FC<ISocialIcon> = componentIcons[type];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="60%" height="60%" />;
  };
  return (
    <Box display="flex" alignItems="center" gridGap=".3em">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        bgcolor="black"
        width="1.5em"
        height="1.5em"
      >
        {type && svgIcons()}
      </Box>
      <p>{link}</p>
    </Box>
  );
};

const PREMIUM_0002: FC<OCVProps> = (props) => {
  const {
    cvSettings: { currentCvFontSize },
  } = useSelector((state: AppState) => state);
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFontSize,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
  } = props;
  const { basicInfo, experience, education, skills } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const fontSize = Math.round(((savedCvFontSize || currentCvFontSize) / 16) * textSize);

  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || tempUserFirstName;
  const userLastName = last_name || '';
  const userEmail = cv_email || tempEmail;
  const userAboutMe = summary ?? tempSummary;
  const userRole = role || tempRole;
  const userAddress = address ?? tempUserAddress;
  const userCity = city ?? tempUserCity;
  const userCountry = country ?? tempUserCountry;

  const userPhoneNumber = mobile_number || tempPhone;

  let userSocialMedia = socialMedia || [
    { type: 'LinkedIn', link: 'linkedin.com/Monah-Henriette' },
    { type: 'Facebook', link: 'facebook.com/Monah-Henriette' },
    { type: 'Twitter', link: 'twitter.com/Monah-Henriette' },
    { type: 'Instagram', link: 'instagram.com/Monah-Henriette' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];

  const dummyExperiences = Array(2).fill({
    role: 'Engineering Manager',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: 'present',
    finished: false,
    activities: tempActivities,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University / College / Institute',
    degree: "Master's",
    grade: '3.3',
    major: 'Computer Science',
    from_year: null,
    to_year: null,
    finished: null,
    activities: null,
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(10).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  let userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);
  const ignoredSocialMedia = ['Website'];
  userSocialMediaShowed = userSocialMediaShowed.filter((item) => !ignoredSocialMedia.includes(item.type));

  return (
    <Box className="o-base-cv" fontSize={textSize}>
      <Box height="100%" minHeight="54.164em" position="relative" fontSize={fontSize || 'inherit'}>
        <Box display="flex">
          <Box flex={4}>
            <Box bgcolor={isActive === 1 ? '#e8f2ff' : ''} pt="1.25em" pl="1em">
              <Box borderBottom={1} borderColor="#E0E0E0 !important" display="flex" alignItems="center">
                {isShowAvatar && (
                  <Box width="4em" height="4em" mr=".5em">
                    <img
                      height="100%"
                      width="100%"
                      src={userAvatar}
                      alt="avatar"
                      className="u-border-rounded o-base-cv__avatar"
                    />
                  </Box>
                )}
                <Box className="u-text-uppercase" fontWeight="bold" fontSize="4em">{`${
                  userFirstName && userFirstName[0]
                }${userLastName && userLastName[0]}`}</Box>
              </Box>
            </Box>
          </Box>
          <Box
            flex={8}
            position="relative"
            display="flex"
            flexDirection="column"
            bgcolor={isActive === 1 ? '#e8f2ff' : ''}
          >
            <Box pt="1.25em" mr="1em" flex={1} borderBottom={1} borderColor="#E0E0E0 !important">
              <Box>
                <Box display="flex" alignItems="center">
                  <Box pl="1.5em" borderLeft={1} borderColor="#E0E0E0 !important">
                    <Box fontStyle="uppercase" fontWeight="bold" fontSize="0.72em">
                      <Box fontSize="1.8em">{userFirstName}</Box>
                      <Box fontSize="1.8em">{userLastName}</Box>
                      <Box>{userRole}</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className="u-center-self"
              position="absolute"
              left="0"
              bottom="0"
              bgcolor="black"
              width=".55em"
              height=".55em"
              borderRadius="50%"
            />
          </Box>
        </Box>
        <Box display="flex">
          {userAboutMe.trim() && (
            <Box flex={4}>
              <Box bgcolor={isActive === 1 ? '#e8f2ff' : ''} minHeight="10em" height="100%" px="1em" pt="1em">
                <Box mb="0.72em" fontWeight="bold">
                  ABOUT ME
                </Box>
                <AQuillContent fontSize="0.72em" content={userAboutMe} />
              </Box>
            </Box>
          )}
          <Box flex={4} display="flex" flexDirection="column">
            <Box flex={1} bgcolor={isActive === 1 ? '#e8f2ff' : ''} height="100%" pt="1em">
              <Box height="100%" borderLeft={1} px="1em" minHeight="10em" borderColor="#E0E0E0 !important">
                <Box mb="0.72em" fontWeight="bold">
                  CONTACTS
                </Box>
                <Box fontSize="0.72em" display="flex" flexDirection="column" gridGap="0.72em">
                  <Box>{`${userAddress}, ${userCity}, ${userCountry}`}</Box>
                  <Box className="u-word-break-all">
                    <Box display="inline" fontWeight="bold">
                      Phone
                    </Box>
                    : {userPhoneNumber}
                  </Box>
                  <Box className="u-word-break-all">
                    <Box display="inline" fontWeight="bold">
                      Email
                    </Box>
                    : {userEmail}
                  </Box>
                  {userSocialMediaShowed.map((item, index) => (
                    <UserSocialBox key={index} link={item.link} type={item.type} />
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box flex={4}>
            {!!userEducation.length && (
              <Box bgcolor={isActive === 3 ? '#e8f2ff' : ''} minHeight="10em" height="100%" pt="1em">
                <Box px="1em" height="100%" borderLeft={1} borderColor="#E0E0E0 !important">
                  <Box mb="0.72em" fontWeight="bold">
                    EDUCATION
                  </Box>
                  <Box display="flex" flexDirection="column" gridGap="0.72em" fontSize="0.72em">
                    {userEducation.map((item, index) => (
                      <UserEducationInfo key={index} cvEducation={item} />
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box>
          {!!userExperiences.length && (
            <Box bgcolor={isActive === 2 ? '#e8f2ff' : ''} px="1em">
              <Box mt="1em" py=".4em" fontWeight="bold" borderColor="#E0E0E0 !important" borderTop={1} borderBottom={1}>
                EXPERIENCE
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gridGap=".72em"
                py=".72em"
                borderColor="#E0E0E0 !important"
                borderBottom={1}
                fontSize="0.72em"
              >
                {userExperiences.map((item, index) => (
                  <UserExperienceInfo key={index} cvExperience={item} />
                ))}
              </Box>
            </Box>
          )}
        </Box>
        {!!userSkills.length && (
          <Box>
            <Box bgcolor={isActive === 4 ? '#e8f2ff' : ''} pb="1em" px="1em">
              <Box py=".4em" fontWeight="bold">
                SKILLS
              </Box>
              <Box
                fontSize=".72em"
                display="flex"
                height="fit-content"
                maxHeight="calc(1em*6.3)"
                flexDirection="column"
                flexWrap="wrap"
                pl=".6em"
              >
                {userSkills.map((item, index) => (
                  <li key={index} style={{ flex: '1', height: '1em' }}>
                    {item.name}
                  </li>
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PREMIUM_0002;
