import { FC } from 'react';
import Box from '@material-ui/core/Box';
import useTranslation from 'next-translate/useTranslation';
import MCvWatermark from 'components/molecules/MCvWatermark';
import { useCvTwoLayoutBack } from 'helpers';
import { OCVProps, previewType } from '../../index';

const FREE_0006_BACK: FC<OCVProps> = (props) => {
  const { t } = useTranslation('common');
  const [
    isShowOtherPage,
    renderedLeft,
    renderedRight,
    idLeft,
    idRight,
    contentLeft,
    contentRight,
    preview,
    savedCvColour,
    fontSizeRatio,
    textSize,
  ] = useCvTwoLayoutBack({
    breakComponent: <Box key="too-large">{t('common.contentTooLong')}</Box>,
    props,
  });

  return (
    <>
      <Box minHeight="54.164em" height="54.164em" position="relative">
        <Box height="54.164em" overflow="hidden" display="flex">
          <Box p="1.5em 0em 1.5em 1.5em" height="54.164em" minWidth="48%" maxWidth="48%">
            <Box
              border="1px solid #9E9E9E"
              maxHeight="100%"
              height="100%"
              fontSize={fontSizeRatio}
              id={idLeft}
              overflow="hidden"
            >
              <Box>{renderedLeft}</Box>
            </Box>
          </Box>
          <Box p="1.5em 1.5em 0 0" flexGrow={1} id={idRight} height="100%" fontSize={fontSizeRatio}>
            {renderedRight}
            <Box height="2.6em" fontSize={textSize} />
          </Box>
        </Box>
        <MCvWatermark />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <FREE_0006_BACK
            preview={preview}
            savedCvColour={savedCvColour}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
            textSize={textSize}
          />
        </>
      )}
    </>
  );
};

export default FREE_0006_BACK;
