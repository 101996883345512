import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AEducation: FC<ISocialIcon> = ({ fill = 'black', width = '8', height = '6' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.353972 5.58564C0.332409 5.57252 0.318347 5.55033 0.314909 5.52564C0.286159 5.3297 0.286159 5.13064 0.314909 4.93502C0.388034 4.46314 0.603037 2.8291 0.791157 2.39126C0.80397 2.36157 0.797095 2.36938 0.797095 2.36938C0.797095 2.36938 0.718657 2.34251 0.663967 2.32344C0.609279 2.30438 0.655529 2.32126 0.578343 2.29219L0.0799027 2.11251L0.0802152 2.11282C0.0327152 2.09501 0.00146484 2.04969 0.00146484 1.99907C0.00146484 1.94844 0.0327152 1.90313 0.0802152 1.88531L3.9555 0.333154C3.98425 0.322217 4.01581 0.322217 4.04456 0.333154L7.92424 1.87907C7.97174 1.89782 8.00236 1.94439 8.0008 1.99532C7.99924 2.04626 7.96611 2.09095 7.91767 2.10688C7.02295 2.40626 4.52079 3.23312 4.05863 3.37344C4.02301 3.38438 1.64799 2.65907 1.36111 2.56816C1.33955 2.5616 1.2833 2.53691 1.16424 2.48472C0.92237 3.2041 1.14924 5.11632 1.05518 5.3744C1.04643 5.3969 1.04486 5.42159 1.05018 5.44534C1.07362 5.54721 1.05612 5.57659 1.0033 5.60096H1.00361C0.797679 5.70378 0.554239 5.69815 0.353615 5.58596L0.353972 5.58564ZM1.16493 2.48316V2.48254V2.48316ZM1.16618 2.47941V2.4791V2.47941ZM1.16681 2.47754V2.47722V2.47754ZM1.16681 2.47628L1.16712 2.47503L1.16681 2.47628ZM3.55215 5.12256C3.15653 5.09631 2.76746 5.00787 2.39967 4.86038C2.18092 4.78007 1.98561 4.64663 1.83186 4.47194C1.75092 4.37694 1.70623 4.25663 1.70561 4.13163V3.00259C1.70467 2.99134 1.70467 2.97977 1.70561 2.96852L1.97561 3.05196C2.63561 3.25446 3.29529 3.45759 3.95465 3.66103C3.98402 3.66915 4.01465 3.66915 4.04371 3.66103L5.59091 3.18478L6.24247 2.98353C6.26216 2.97791 6.28216 2.97384 6.30247 2.97103V4.10447C6.30247 4.33822 6.18372 4.50041 6.0106 4.63166C5.72466 4.84884 5.39216 4.95572 5.04812 5.03259V5.0329C4.70594 5.10666 4.35687 5.14384 4.00652 5.14384C3.85496 5.14384 3.70339 5.13697 3.55214 5.12259L3.55215 5.12256Z"
        fill={fill}
      />
    </svg>
  );
};
