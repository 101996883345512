/* eslint-disable max-len */
import Box from '@material-ui/core/Box';
import isEmpty from 'lodash/isEmpty';
import { FC } from 'react';
import AQuillContent from 'components/atoms/AQuillContent';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import { getCVAvatar, getTime, useCVHelper, useCVThreeLayout, useCvTwoLayout } from 'helpers';
import { ActiveColors, ProfileEducation, ProfileExperience } from 'redux-saga/interfaces';
import { formatDate } from 'utils/date';
import PREMIUM_0004_BACK from './back';
import { OCVProps, previewType } from '../../index';

const box6em = (title: string) => (
  <Box component="span" fontSize="0.6em" width="100%">
    {title}
  </Box>
);

const ProgessBox: FC<{ title: string; percent: number; color: string }> = ({ title, percent, color }) => {
  return (
    <Box display="flex" mb="0.2em" alignItems="center" height="1.8em">
      <Box flexGrow={1} fontSize="0.6em" width="100%">
        {title}
      </Box>
      <Box minWidth="8em" display="flex" alignItems="center" position="relative" height="0.4em">
        <Box height="0.05em" bgcolor="white" width="100%" />
        <Box
          height="0.4em"
          width="0.8em"
          bgcolor={color}
          position="absolute"
          top={0}
          left={`${(percent * 72) / 1000}em`}
        />
      </Box>
    </Box>
  );
};

const SkillItem: FC<{
  title: string;
  percent: number;
  color: string;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
}> = ({ title, percent, color, isShowProficiency, activeColors }) => {
  if (isShowProficiency) {
    return <ProgessBox title={title} percent={percent} color={color} />;
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" height="1.8em" {...activeColors}>
      <Box bgcolor="white" width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, company, activities, location } = cvExperience;
  const { time } = getTime(cvExperience);
  return (
    <Box position="relative" mb="1.6em" {...activeColors}>
      <Box
        className="u-flex-center o-premium-0004__rotate"
        position="absolute"
        height="1em"
        width="3.5em"
        bgcolor="#474D52"
        color="white"
        left="-3.4em"
        top="2.2em"
        lineHeight="0.545em"
        style={{ transform: 'rotate(270deg)' }}
      >
        <Box fontSize="0.4em">{time}</Box>
      </Box>
      <Box lineHeight="1.089em" fontWeight={700} letterSpacing="0.1em">
        <Box fontSize="0.8em" width="100%">
          {role}
        </Box>
      </Box>
      <Box lineHeight="0.817em" mt="0.4em">
        {box6em(`${company} | ${location}`)}
      </Box>
      <Box lineHeight="0.817em" mt="0.8em">
        <AQuillContent content={activities} fontSize="0.6em" />
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, from_year, to_year, finished, degree, major } = cvEducation;
  const joinDate = from_year ? formatDate(from_year, 'MMMM YYYY') : 'July 2012 ';
  const leaveDate = !finished ? 'Present' : to_year ? formatDate(to_year, 'MMMM YYYY') : 'Feb 2014';
  return (
    <Box p="0em 1.6em .8em" {...activeColors}>
      <Box lineHeight="0.817em">{box6em(school)}</Box>
      <Box lineHeight="1.089em" mt="0.2em">
        <Box fontSize="0.8em">{`${major} / ${degree}`}</Box>
      </Box>
      <Box lineHeight="0.817em" mt="0.2em">
        {box6em(`${joinDate} - ${leaveDate}`)}
      </Box>
    </Box>
  );
};

const CVTitle: FC<{ title: string; img: string; color?: string }> = ({ title, img, color }) => {
  return (
    <Box>
      <Box display="flex">
        <Box
          width="1.4em"
          height="1.4em"
          minWidth="1.4em"
          className="u-flex-center"
          bgcolor={color || '#484545'}
          mr="0.4em"
        >
          <img src={img} alt="" width="85%" height="85%" />
        </Box>
        <Box fontWeight={600} fontSize="1em" display="flex" alignItems="center" className="u-text-uppercase">
          {title}
        </Box>
      </Box>
      <Box bgcolor={color || '#212121'} height="1px" width="100%" />
    </Box>
  );
};

const UserLinkInfo: FC<{ info: string; img: string; color?: string }> = ({ info, img, color = '#F97967' }) => {
  return (
    <Box display="flex" mt="0.8em">
      <Box minWidth="1.4em" height="1.4em" className="u-flex-center" bgcolor={color} mr="0.4em">
        <img src={img} alt="" width="85%" height="85%" />
      </Box>
      <Box fontWeight={600} fontSize="0.6em" display="flex" alignItems="center">
        {info}
      </Box>
    </Box>
  );
};

const PREMIUM_0004: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
  } = basicInfo as CvBasicInfo;

  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'BESSIE MOSCISKI';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'jhondee@network.com';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In felis lacus volutpat mi varius cras venenatis.
    Placerat donec purus egestas augue amet, amet nunc. Pellentesque pellentesque ac velit lobortis ipsum et.
    Commodo velit vel proin amet auctor phasellus.`;

  const [mockId, mockHeight] = useCVThreeLayout([userAboutMe]);
  const userRole = role || 'ASSISTANT DEPARTMENT';
  const userAddress = address ?? 'Melbourne Street Number 4';
  const userCity = city || 'California, New York City';
  const userCountry = country || 'US';
  const userCountryCode = mobile_number || '667-0890-231';

  const dummyExperiences = Array(3).fill({
    role: 'ENTER YOUR JOB TITLE HERE',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In felis lacus volutpat mi varius cras venenatis. Placerat donec purus egestas augue amet, amet nunc. Pellentesque pellentesque ac velit lobortis ipsum et. Commodo velit vel proin amet auctor phasellus.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University Name',
    degree: 'Degree Name',
    grade: '4.75',
    major: 'Your Subject',
    from_year: '01-05-2012',
    to_year: '01-02-2014',
    finished: true,
    activities:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In felis lacus volutpat mi varius cras venenatis. Placerat donec purus egestas augue amet, amet nunc. Pellentesque pellentesque ac velit lobortis ipsum et. Commodo velit vel proin amet auctor phasellus.',
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e8f2ff', color: 'black' } : {};
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="userSkills" p="0.8em 1.6em 0.8em" {...activeColors(4)}>
        <CVTitle title="SKILLS" img="/assets/svg/premium_skill.svg" color={cvColor} />
        <Box pt="0.6em">
          {userSkills.map(({ name, points }, index) => (
            <SkillItem
              key={`skill${name}${index}${points}`}
              title={name}
              percent={(points * 100) / 5}
              color={cvColor}
              isShowProficiency={isShowProficiency}
              activeColors={activeColors(4)}
            />
          ))}
        </Box>
      </Box>,
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="educations" p="0.8em 1.6em 0.8em" {...activeColors(3)}>
        <CVTitle title="EDUCATION" img="/assets/svg/premium_education.svg" color={cvColor} />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={index} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="userExperiences" p="1.6em 1.6em 0.8em" bgcolor={isActive === 2 ? '#e8f2ff' : ''} {...activeColors(2)}>
        <CVTitle title="WORK EXPERIENCE" img="/assets/svg/premium_work_experience.svg" />
      </Box>,
      ...userExperiences.map((item, index) => (
        <Box
          key={index}
          p="0.8em 1.6em 0.8em"
          ml="1.6em"
          borderLeft="1px solid #474D52"
          bgcolor={isActive === 2 ? '#e8f2ff' : ''}
          {...activeColors(2)}
        >
          <UserExperienceInfo key={index} cvExperience={item} activeColors={activeColors(2)} />
        </Box>
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    rightComponents: [...renderExperience()],
    leftComponents: [...renderEducation(), ...renderSkills()],
    props,
  });

  return (
    <Box
      className="o-base-cv o-base-cv__multiple__page"
      bgcolor="#fff"
      fontSize={textSize}
      fontFamily={savedCvFont}
      overflow="hidden"
    >
      <Box position="relative" minHeight="54.164em" height="54.164em" display="flex" flexDirection="column">
        <Box id={mockId} fontSize={fontSizeRatio}>
          <Box bgcolor={cvColor} height="1.6em">
            <Box
              position="absolute"
              width={0}
              height={0}
              borderLeft="1em solid transparent"
              borderRight="1em solid transparent"
              borderTop={`1.4em solid ${cvColor}`}
              left="6em"
              top="0.8em"
            />
          </Box>

          <Box p="0em 1.6em 1em" minHeight="fit-content" bgcolor={isActive === 1 ? '#e8f2ff' : ''}>
            <Box display="flex" mb="1em">
              {isShowAvatar && (
                <Box minWidth="10em" pt="1.4em">
                  <Box height="4.5em" minWidth="4.5em" maxWidth="4.5em">
                    <img
                      height="100%"
                      width="100%"
                      src={userAvatar}
                      alt=""
                      className="u-border-rounded o-base-cv__avatar"
                    />
                  </Box>
                </Box>
              )}
              <Box flex={1}>
                <Box fontWeight={700} letterSpacing="0.1em" pt="1.4em">
                  <Box fontSize="1.5em">{userFirstName}</Box>
                  <Box fontSize="1.5em">{userLastName}</Box>
                </Box>
                <Box lineHeight="1.226em" letterSpacing="0.1em" className="u-text-uppercase" mb="0.6em">
                  <Box fontSize="0.6em">{userRole}</Box>
                </Box>
              </Box>
            </Box>

            {userAboutMe.trim() && (
              <>
                <CVTitle title="SUMMARY" img="/assets/svg/summary.svg" />
                <Box lineHeight="1.089em" mt="0.8em">
                  <AQuillContent content={userAboutMe} fontSize="0.8em" />
                </Box>
              </>
            )}
          </Box>
        </Box>
        {!!mockHeight && (
          <Box display="flex" flexGrow={1}>
            <Box display="flex" flex={1} height={`calc(54.164em - ${mockHeight}px)`}>
              <Box
                id={idLeft}
                pb="1.2em"
                flex={2}
                height="100%"
                maxHeight="100%"
                fontSize={fontSizeRatio}
                minWidth="42.8%"
                width="42.8%"
                color="white"
                bgcolor="#474D52"
              >
                <Box
                  p="1.6em 1.6em 0.8em"
                  color={isActive === 1 ? 'black' : ''}
                  bgcolor={isActive === 1 ? '#e8f2ff' : ''}
                >
                  <CVTitle title="contact" img="/assets/svg/contact.svg" color={cvColor} />
                  <UserLinkInfo info={userCountryCode} img="/assets/svg/premium_phone.svg" color={cvColor} />
                  <UserLinkInfo info={userEmail} img="/assets/svg/mail_box.svg" color={cvColor} />
                  <UserLinkInfo
                    info={`${userAddress}, ${userCity}, ${userCountry}`}
                    img="/assets/svg/premium_address.svg"
                    color={cvColor}
                  />
                </Box>
                {renderedLeft}
                <Box height="1.2em" />
              </Box>

              <Box
                id={idRight}
                flex={3}
                pb="1.2em"
                height="100%"
                maxHeight="100%"
                display="flex"
                flexDirection="column"
                fontSize={fontSizeRatio}
              >
                {renderedRight}
                <Box height="1.2em" />
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0004_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0004;
