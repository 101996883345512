import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, useCVHelper, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import { ActiveColors, ProfileEducation, ProfileExperience } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AAddress,
  AContact,
  ADribbble,
  AEducation,
  AEmail,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  AProfile,
  ASkills,
  ASkype,
  ASocialMedia,
  AWork,
  ISocialIcon,
} from 'components/atoms/icons';
import PREMIUM_0010_BACK from './back';
import { OCVProps, previewType } from '../../index';

const ProgessBox: FC<{ title: string; point: number; activeColors: ActiveColors; fontSizeRatio: number }> = ({
  title,
  point,
  activeColors,
  fontSizeRatio,
}) => {
  const pointSize = Math.ceil(fontSizeRatio * 0.25);
  const boxSize = Math.ceil(fontSizeRatio * 0.4);

  return (
    <Box
      p=".8em 8% 1.2em"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="1.5em"
      {...activeColors}
    >
      <Box fontSize="0.72em" display="flex" alignItems="center">
        <Box bgcolor="black" minWidth={pointSize} width={pointSize} height={pointSize} borderRadius="50%" mr=".5em" />
        <Box>{title}</Box>
      </Box>
      <Box minWidth="50%" display="flex" alignItems="center" justifyContent="flex-end" color="black" pr="2em">
        <Box display="flex" lineHeight="1.6em">
          {Array.from(Array(5), (_, index) => (
            <Box
              key={index}
              minWidth={boxSize}
              width={boxSize}
              height={boxSize}
              m="0.2em"
              border="1px solid black"
              bgcolor={index < point / 20 ? 'black' : ''}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const SkillItem: FC<{
  title: string;
  point: number;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
  fontSizeRatio: number;
}> = ({ title, point, isShowProficiency, activeColors, fontSizeRatio }) => {
  if (isShowProficiency) {
    return (
      <ProgessBox title={title} point={(point * 100) / 5} activeColors={activeColors} fontSizeRatio={fontSizeRatio} />
    );
  }
  return (
    <Box p=".2em 2em .5em" fontSize="0.72em" display="flex" alignItems="center" {...activeColors}>
      <Box bgcolor="black" width=".25em" height=".25em" borderRadius="50%" mr=".5em" />
      <Box>{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, company, from_year, to_year, finished, activities, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box p=".5em 8% 1em" display="flex" flexDirection="column" gridGap=".6em" {...activeColors}>
      <Box fontSize="0.72em" fontWeight={400} color="#484545">
        {joinDate} - {leaveDate}
      </Box>

      <Box fontWeight={700} fontSize="0.8em">
        {role}
      </Box>
      <Box fontSize="0.72em" color="#484545" lineHeight="1">
        {company} | {location}
      </Box>
      <Box>
        <AQuillContent content={activities} fontSize="0.72em" color="#757575" />
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, to_year, finished, major, degree, grade } = cvEducation;
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box p=".5em 8% 1em" display="flex" flexDirection="column" gridGap=".4em" {...activeColors}>
      <Box fontWeight="bold" className="u-text-uppercase" fontSize="0.8em" lineHeight="1.4">
        {`${degree} ${major}`}
      </Box>
      <Box fontSize="0.72em">{`${leaveDate} / ${school}`}</Box>
      <Box fontSize="0.72em">{`Grade: ${grade}`}</Box>
    </Box>
  );
};

const UserSocialBox: FC<{ info: string; img: string; theme: string; activeColors: ActiveColors }> = ({
  info,
  img,
  theme,
  activeColors,
}) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    LinkedIn: AOutlinedLinkedin,
    Instagram: AOutlinedInstagram,
    Github: AGithub,
    Dribbble: ADribbble,
    skype: ASkype,
    email: AEmail,
    phone: APhone,
    address: AAddress,
  };

  const svgIcons = () => {
    const SVG = componentIcons[img];
    return <SVG fill="#212121" textFill={theme} width="100%" height="100%" />;
  };
  return (
    <Box display="flex" alignItems="center" p=".4em 8%" {...activeColors}>
      <Box flex={1.5}>
        <Box width="1em" height="1em" display="flex" alignItems="center">
          {img && svgIcons()}
        </Box>
      </Box>
      <Box flex={10.5}>
        <Box className="u-word-break-all" dangerouslySetInnerHTML={{ __html: info }} fontSize="0.72em" />
      </Box>
    </Box>
  );
};

const SubTitle: FC<{ name: string; icon: FC<ISocialIcon> } & BoxProps> = ({ name, icon, ...props }) => {
  const SVG = icon;
  return (
    <Box display="flex" alignItems="center" height="auto" p=".45em 0 0.8em" {...props}>
      <Box mr="1.2em" fontWeight={700} fontSize="1em" whiteSpace="nowrap">
        {name}
      </Box>
      <Box flex={1} mr="0.5em" bgcolor="#484545" height="0.09em" />
      <Box position="relative">
        <Box width="0.72em" height="0.72em" bgcolor="white" border={1} borderColor="#212121" className="u-rotate-90" />
        <Box display="flex" width=".5em" height=".5em" position="absolute" className="u-center-content">
          <SVG width="100%" height="100%" fill="#212121" />
        </Box>
      </Box>
    </Box>
  );
};

const PREMIUM_0010: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    skype,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'WILLIAM';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'yourmail@domain.com';
  const userSkype = skype ?? 'username skype';
  const userAboutMe =
    summary ??
    `Viverra augue nisi, vestibulum sit nec. Facilisi duis a, vitae pellentesque nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. `;

  const userRole = role || 'Business Owner';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+123 4567 8910';

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'facebook.com/username' },
    { type: 'Instagram', link: 'instagram.com/username' },
    { type: 'LinkedIn', link: 'linkedin.com/username' },
    { type: 'Twitter', link: 'twitter.com/username' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'skype', link: userSkype },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  let userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);
  const ignoredSocialMedia = ['Website'];
  userSocialMediaShowed = userSocialMediaShowed.filter((item) => !ignoredSocialMedia.includes(item.type));

  const dummyExperiences = Array(2).fill({
    role: 'Business Owner',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(3).fill({
    school: 'University / College Name',
    degree: 'Master',
    grade: '4.75',
    major: 'business management',
    from_year: '',
    to_year: '01-01-2020',
    finished: true,
    activities: '',
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e8e8e8' } : {};
  };

  const renderProfile = () => {
    return [
      <Box
        key="profile"
        p="1.36em 8% .8em 8%"
        {...activeColors(1)}
        display="flex"
        justifyContent={isShowAvatar ? 'space-between' : 'center'}
        alignItems="start"
        fontSize={textSize}
      >
        {isShowAvatar && (
          <Box height="5.5em" minWidth="5.5em" maxWidth="5.5em">
            <img
              height="100%"
              width="100%"
              src={userAvatar}
              alt="profile-pict"
              className="u-border-rounded o-base-cv__avatar"
            />
          </Box>
        )}
        <Box mr="1em" width="5em" height="5em" position="relative" maxHeight="fit-content">
          <Box
            fontSize="2em"
            className="u-center-content u-scale-3"
            lineHeight="0.8em"
            fontWeight={700}
            position="absolute"
          >
            {userFirstName.charAt(0).toUpperCase()}
          </Box>
          <Box
            fontSize="0.81em"
            className="u-center-content"
            position="absolute"
            width="7em"
            textAlign="center"
            fontWeight={600}
            bgcolor={cvColor}
            {...activeColors(1)}
          >
            {userLastName}
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box key="summary" p="0 8% 1em" {...activeColors(1)}>
        <SubTitle icon={AProfile} name="PROFILE" />
        <AQuillContent content={userAboutMe} fontSize="0.72em" />
      </Box>,
    ];
  };

  const renderContact = () => {
    return [
      <Box key="contact" p=".4em 8% 0" {...activeColors(1)}>
        <SubTitle icon={AContact} name="CONTACT ME" />
      </Box>,
      ...contacts.map(
        (item) =>
          !!item.link.length && (
            <UserSocialBox
              theme={cvColor}
              key={item.type}
              img={item.type}
              info={item.link}
              activeColors={activeColors(1)}
            />
          ),
      ),
    ];
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box key="socialMedia" p=".4em 8% 0" {...activeColors(1)}>
        <SubTitle icon={ASocialMedia} name="SOCIAL MEDIA" />
        <Box display="flex" flexDirection="column" flexWrap="wrap" gridGap="0.7em" />
      </Box>,
      ...userSocialMediaShowed.map((item) => (
        <UserSocialBox
          theme={cvColor}
          key={item.type}
          img={item.type}
          info={item.link}
          activeColors={activeColors(1)}
        />
      )),
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skills" px="1.36em" {...activeColors(4)}>
        <SubTitle icon={ASkills} name="SKILLS" />
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          key={`skill${name}${index}${points}`}
          title={name}
          point={points}
          isShowProficiency={isShowProficiency}
          activeColors={activeColors(4)}
          fontSizeRatio={fontSizeRatio}
        />
      )),
    ];
  };

  const renderName = () => {
    return [
      <Box
        key="name"
        p="1.36em"
        height="7em"
        bgcolor="#2A2C2E"
        {...activeColors(1)}
        color={isActive === 1 ? 'black' : 'white'}
      >
        <Box fontSize="1.4em" fontWeight={700} letterSpacing="0.09em" className="u-text-uppercase">
          <p>{userFirstName}</p>
          <p>{userLastName}</p>
        </Box>
        <Box mt="1.2em" fontSize="0.72em" fontWeight={400} display="flex" alignItems="center">
          <Box mr="0.72em" whiteSpace="nowrap" fontWeight={100} lineHeight="1em">
            {userRole}
          </Box>
          <Box width="2.5em" height="0.5em" bgcolor="white" />
        </Box>
      </Box>,
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experiences" p=".5em 8% .4em" {...activeColors(2)}>
        <SubTitle pb="0" icon={AWork} name="WORK EXPERIENCE" />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`${item.companyInfo}${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education" p=".7em 8% .3em" {...activeColors(3)}>
        <SubTitle pb="0" icon={AEducation} name="EDUCATION" />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [
      ...renderProfile(),
      ...renderSummary(),
      ...renderContact(),
      ...renderSocialMedia(),
      ...renderSkills(),
    ],
    rightComponents: [...renderName(), ...renderExperience(), ...renderEducation()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont || 'inherit'}>
      <Box minHeight="54.164em" height="54.164em" display="flex" position="relative">
        <Box
          id={idLeft}
          flex={1}
          bgcolor={cvColor}
          maxWidth="47%"
          minWidth="47%"
          overflow="hidden"
          height="100%"
          fontSize={fontSizeRatio || 'inherit'}
        >
          {renderedLeft}
          <Box height="1.6em" />
        </Box>
        <Box id={idRight} flex={1} overflow="hidden" height="100%" fontSize={fontSizeRatio || 'inherit'}>
          {renderedRight}
          <Box height="1.6em" />
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0010_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0010;
