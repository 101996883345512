import { FC } from 'react';
import Box from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';

import { getCVAvatar, useCVHelper, useCvTwoLayout } from 'helpers';
import { formatDate, getYearOrExample } from 'utils/date';
import {
  ActiveColors,
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileReference,
  ProfileVolunteer,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AAddress,
  ADribbble,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  AWebsite,
} from 'components/atoms/icons';
import PREMIUM_0008_BACK from './back';
import { OCVProps, previewType } from '../../index';

const box6em = (title: string) => (
  <Box component="span" fontSize="0.6em" width="100%">
    {title}
  </Box>
);

const SkillItem: FC<{
  title: string;
  point: number;
  isShowProficiency: boolean;
  color: string;
  activeColors: ActiveColors;
}> = ({ title, point, isShowProficiency, color, activeColors }) => {
  if (isShowProficiency) {
    return (
      <Box display="flex" gridGap=".2em" alignItems="center" height="1.8em" p=".4em 8%" {...activeColors}>
        <Box flex={4} fontSize="0.6em" width="100%">
          {title}
        </Box>
        <Box flex={6} display="flex" alignItems="center" height="0.4em" position="relative" border="1px solid white">
          <Box height="100%" bgcolor="white" width={`${(point * 100) / 5}%`} position="absolute" top={0} left={0} />
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" height="1.2em" p=".4em 8%" {...activeColors}>
      <Box bgcolor={color} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{
  cvExperience?: ProfileExperience;
  isShowLineBottom?: boolean;
  activeColors: ActiveColors;
}> = ({ cvExperience, isShowLineBottom = false, activeColors }) => {
  const { role, company, from_year, to_year, finished, activities } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'now' : getYearOrExample(to_year);
  return (
    <Box display="flex" p="0 8%" {...activeColors}>
      <Box display="flex" flexDirection="column" mr="0.3em">
        <Box width="2.2em" height="2.2em" borderRadius="50%" border="1px solid #212121" className="u-flex-center">
          <Box
            height="1.6em"
            width="1.6em"
            borderRadius="50%"
            bgcolor="#212121"
            color="white"
            fontWeight={700}
            lineHeight="0.681em"
            flexDirection="column"
            className="u-flex-center"
          >
            <Box fontSize="0.5em" width="100%" textAlign="center">
              {joinDate}
            </Box>
            <Box fontSize="0.5em" width="100%" textAlign="center">
              {leaveDate}
            </Box>
          </Box>
        </Box>
        <Box flexGrow={1} display="flex" flexDirection="column" alignItems="center">
          {!!isShowLineBottom && (
            <>
              <Box width="1px" height="50%" bgcolor="#212121" />
              <Box width="0.5em" height="0.5em" bgcolor="#212121" borderRadius={30} />
              <Box width="1px" height="50%" bgcolor="#212121" />
            </>
          )}
        </Box>
      </Box>
      <Box>
        <Box lineHeight="1.089em" fontWeight={700}>
          <Box fontSize="0.8em">{role}</Box>
        </Box>
        <Box lineHeight="0.817em" mt="0.4em" color="#757575" fontStyle="italic">
          {box6em(company)}
        </Box>
        <Box lineHeight="0.817em" mt=".8em" mb="1.2em">
          <AQuillContent content={activities} fontSize="0.6em" />
        </Box>
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, from_year, to_year, finished, major } = cvEducation;
  const joinDate = getYearOrExample(from_year, '2012');
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2014');

  return (
    <Box lineHeight="0.817em" p=".4em 8%" {...activeColors}>
      <Box display="flex">
        <Box minWidth="4em" height="1.2em" pl="0.6em" bgcolor="white" lineHeight="1.6em" className="u-flex-center">
          <Box fontSize="0.6em" fontWeight={700} color="#212121" width="100%">
            {joinDate} - {leaveDate}
          </Box>
        </Box>
        <Box
          width={0}
          height={0}
          borderLeft="0.6em solid white"
          borderTop="0.6em solid transparent"
          borderBottom="0.6em solid transparent"
        />
      </Box>
      <Box fontWeight={700} lineHeight="1.6em" mt="0.4em">
        {box6em(major)}
      </Box>
      <Box lineHeight="0.817em">{box6em(school)}</Box>
    </Box>
  );
};

const CVTitle: FC<{ title: string; isShowLine?: boolean }> = ({ title, isShowLine = false }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box fontSize="1em" fontWeight={700} lineHeight="1.362em" letterSpacing="0.1em" className="u-text-uppercase">
        {title}
      </Box>
      {isShowLine ? <Box ml="0.8em" height="0.1em" bgcolor="currentColor" width="2em" /> : ''}
    </Box>
  );
};

const UserLinkInfo: FC<{ title: string; info: string; img: string }> = ({ title, info, img }) => {
  return (
    <Box display="flex" alignItems="center" mb="0.2em">
      <Box width="0.8em" height="0.8em" bgcolor="#303642" mr="0.4em" className="u-flex-center">
        <img src={img} alt="" className="o-premium-0008__imginfo" />
      </Box>
      <Box fontWeight={700} mr="0.4em">
        <Box fontSize="0.6em" className="u-text-uppercase u-word-no-wrap">
          {title}:
        </Box>
      </Box>
      <Box fontSize="0.6em">{info}</Box>
    </Box>
  );
};

const UserSocialBox: FC<{
  title: string;
  info: string;
  img: string;
  isSocial?: boolean;
}> = ({ title, info, img, isSocial = true }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    Instagram: AOutlinedInstagram,
    LinkedIn: AOutlinedLinkedin,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
    Address: AAddress,
  };

  const svgIcons = () => {
    const SVG = componentIcons[img];
    return <SVG strokeWidth="0" width="60%" height="60%" />;
  };

  return (
    <Box display="flex">
      <Box width="2.4em" height="2.4em" border="1px solid #484545" className="u-flex-center" mr="0.8em">
        {img && svgIcons()}
      </Box>
      <Box>
        <Box lineHeight="1.6em" fontWeight={700} className={isSocial ? `u-text-uppercase` : ''}>
          <Box fontSize="0.6em">{title}</Box>
        </Box>
        <Box fontSize="0.6em" fontStyle={isSocial ? `italic` : 'normal'}>
          {info}
        </Box>
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  cvReference,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box display="flex" flexDirection="column" gridGap=".2em" color="white" p=".6em 8%" {...activeColors}>
      <Box display="flex" flexDirection="column" gridGap=".3em">
        <Box fontSize=".6em" fontWeight={600}>
          {name}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          {position} | {company}
        </Box>
      </Box>
      <Box fontWeight={400} display="flex" flexDirection="column" gridGap=".2em">
        <Box fontSize=".5em" display="flex">
          <Box flex={2} component="span">
            Phone :
          </Box>
          <Box flex={5}>{mobile}</Box>
        </Box>
        <Box fontSize=".5em" display="flex">
          <Box flex={2} component="span">
            Email :
          </Box>
          <Box flex={5}>{email}</Box>
        </Box>
      </Box>
    </Box>
  );
};

const UserVolunteer: FC<{ cvVolunteer?: ProfileVolunteer; activeColors: ActiveColors }> = ({
  cvVolunteer,
  activeColors,
}) => {
  const { organisation, role, causes, start_date, end_date } = cvVolunteer;
  const startDate = formatDate(start_date, 'DD-MM-YYYY');
  const endDate = formatDate(end_date, 'DD-MM-YYYY');
  return (
    <Box display="flex" flexDirection="column" gridGap=".4em" color="white" p=".4em 8%" {...activeColors}>
      <Box display="flex" flexDirection="column" gridGap=".3em">
        <Box fontSize=".6em" fontWeight={600}>
          {organisation}
        </Box>
        <Box fontSize=".5em" fontWeight={600}>
          {role} | {causes}
        </Box>
      </Box>
      <Box fontWeight={400} fontSize=".5em">
        {startDate} - {endDate}
      </Box>
    </Box>
  );
};

const UserCertification: FC<{ data?: ProfileCertificate; activeColors: ActiveColors }> = ({ data, activeColors }) => {
  const { certificate, date, organizer, expiration_date, does_not_expire } = data;
  const fromYear = getYearOrExample(date, '2012');
  const toYear = does_not_expire ? 'Present' : getYearOrExample(expiration_date);

  return (
    <Box fontWeight={400} display="flex" flexDirection="column" gridGap=".2em" p=".4em 8%" {...activeColors}>
      <Box fontWeight={700} fontSize=".6em" color="##333333">
        {certificate}
      </Box>
      <Box fontSize=".5em" color="##333333">
        {organizer} | {fromYear} - {toYear}
      </Box>
    </Box>
  );
};

const PREMIUM_0008: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, reference, volunteers, certifications, interest } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    skype,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const fullName = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : 'Candidate Name';
  const userEmail = cv_email || 'jhondee@network.com';
  const userSkype = skype ?? 'jhondee@network.com';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus hac purus blandit pharetra magna morbi faucibus sed amet. Viverra augue nisi, vestibulum sit nec. Facilisi duis a, vitae pellentesque nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`;

  const userRole = role || 'Front End Developer';
  const userAddress = address || '123 Street, Full Address';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '667-0890-231';

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'User Facebook' },
    { type: 'Instagram', link: 'User Instagram' },
    { type: 'LinkedIn', link: 'User LinkedIn' },
    { type: 'Twitter', link: 'User Twitter' },
    { type: 'Website', link: 'User Website' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']).slice(0, 3);
  const dummyReference: ProfileReference[] = Array(1).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    company: 'Company / Organization Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const dummyExperiences = Array(2).fill({
    role: 'Computer Software Developer',
    company: 'Company Name | Institute Name',
    location: 'none',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(1).fill({
    school: 'University / College / Institute',
    degree: '',
    grade: null,
    major: 'Enter Your Master Degree Here',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities: '',
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;
  const userInterest = interest ?? [
    'Project Management',
    'Graphic Design',
    'Computer Language',
    'Mobile & Cloud Solution',
    'Event Planning',
  ];

  const dummyVolunteers: ProfileVolunteer[] = Array(1).fill({
    organisation: 'Google',
    role: 'Graphic Designer',
    causes: 'Social Media Content',
    start_date: '01-01-2018',
    end_date: '01-01-2020',
    description: '',
  });
  const userVolunteers = volunteers ?? dummyVolunteers;

  const dummyCertifications: ProfileCertificate[] = Array(4).fill({
    certificate_id: 'Certification ID',
    date: '2018-02-02',
    expiration_date: '2021-02-02',
    organizer: 'AWS',
    certificate: 'AWS Certification Professional',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium.',
  });
  const userCertifications = certifications ?? dummyCertifications;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e6e6e6', color: 'black' } : {};
  };

  const renderSummary = () => {
    if (!userAboutMe) return [];
    return [
      <Box key="summary" p="1.6em 8% 0.8em 8%" {...activeColors(1)}>
        <CVTitle title="ABOUT" />
        <Box mt="0.8em" lineHeight="0.817em">
          <AQuillContent content={userAboutMe} fontSize="0.6em" />
        </Box>
      </Box>,
    ];
  };

  const renderInterest = () => {
    if (isEmpty(userInterest)) return [];
    return [
      <Box key="interests" p=".6em 8% .4em" {...activeColors(5)}>
        <CVTitle title="INTERESTS" />
      </Box>,
      ...userInterest.map((item, index) => (
        <Box key={index + item} p=".4em 8%" display="flex" gridGap=".4em" alignItems="center" {...activeColors(5)}>
          <Box bgcolor="currentColor" width=".2em" height=".2em" borderRadius="50%" />
          <Box fontSize=".4em">{item}</Box>
        </Box>
      )),
    ];
  };

  const renderVolunteers = () => {
    if (isEmpty(userVolunteers)) return [];
    return [
      <Box key="volunteers" p=".8em 8% .4em" {...activeColors(5)}>
        <CVTitle title="VOLUNTEER" />
      </Box>,
      ...userVolunteers.map((item, index) => (
        <UserVolunteer key={index + item.role + item.end_date} cvVolunteer={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderReferences = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box key="reference" p="1em 8% 0" {...activeColors(5)}>
        <CVTitle title="REFERENCE" />
      </Box>,
      ...userReference.map((item, idx) => (
        <UserReference key={idx + item.company + item.email} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skills" p="1em 8% .4em" {...activeColors(4)}>
        <CVTitle title="SKILLS" />
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          key={index + name}
          title={name}
          point={points}
          isShowProficiency={isShowProficiency}
          color={cvColor}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experience" p="0.8em 8%" {...activeColors(2)}>
        <CVTitle title="EMPLOYMENT HISTORY" />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo
          key={`${item.companyInfo}${index}`}
          cvExperience={item}
          isShowLineBottom={index < userExperiences.length - 1}
          activeColors={activeColors(2)}
        />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education" p="1.6em 8% 0.4em 8%" {...activeColors(3)}>
        <CVTitle title="EDUCATION" />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderCertification = () => {
    if (isEmpty(userCertifications)) return [];
    return [
      <Box key="certification" p=".4em 8% .4em" {...activeColors(5)}>
        <CVTitle title="CERTIFICATIONS" />
      </Box>,
      ...userCertifications.map((item, index) => (
        <UserCertification key={`${item.certificate}${index}`} data={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderSummary(), ...renderExperience(), ...renderCertification()],
    rightComponents: [
      ...renderEducation(),
      ...renderSkills(),
      ...renderReferences(),
      ...renderInterest(),
      ...renderVolunteers(),
    ],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont || 'inherit'}>
      <Box
        minHeight="54.164em"
        height="54.164em"
        className="o-premium-0008"
        position="relative"
        display="flex"
        flexDirection="column"
        fontSize={textSize}
      >
        <Box height="10.94em" minHeight="10.94em" display="flex" flexDirection="column">
          <Box display="flex" flex={1}>
            <Box
              {...activeColors(1)}
              display="flex"
              alignContent="center"
              alignItems="flex-start"
              pl="1.6em"
              flexGrow={1}
              pt="1.6em"
            >
              {isShowAvatar && (
                <Box height="3.5em" maxWidth="3.5em" minWidth="3.5em" mr="1.2em">
                  <img
                    height="100%"
                    width="100%"
                    src={userAvatar}
                    alt=""
                    className="u-border-rounded o-base-cv__avatar"
                  />
                </Box>
              )}
              <Box className="u-text-uppercase" pt="0.2em">
                <Box component="span" fontWeight={700} letterSpacing="0.1em">
                  <Box fontSize="1em">{fullName}</Box>
                </Box>
                <Box mt="0.5em">
                  <Box fontSize="0.6em">{userRole}</Box>
                </Box>
              </Box>
            </Box>
            <Box minWidth="42.8%" p="1.6em 8% 0em" bgcolor={cvColor} {...activeColors(1)}>
              <Box display="flex" alignItems="center" mb="0.2em">
                <Box fontSize="1em" fontWeight={700} lineHeight="1.362em" letterSpacing="0.1em">
                  CONTACTS
                </Box>
              </Box>
              <UserLinkInfo title="PHONE" info={userPhoneNumber} img="/assets/svg/premium_phone.svg" />
              <UserLinkInfo title="EMAIL" info={userEmail} img="/assets/svg/mail_box.svg" />
              {!!userSkype.length && <UserLinkInfo title="SKYPE" info={userSkype} img="/assets/svg/skype.svg" />}
            </Box>
          </Box>
          <Box minHeight="0.4em" bgcolor={cvColor} />
          {!!userSocialMediaShowed.length && (
            <Box display="flex" justifyContent="space-between" p="0.8em" {...activeColors(1)}>
              {userSocialMediaShowed.map((item) => (
                <UserSocialBox key={item.type} img={item.type} isSocial title={item.type} info={item.link} />
              ))}
              <UserSocialBox
                img="Address"
                isSocial={false}
                title="Address"
                info={`${userAddress}, ${userCity}, ${userCountry}`}
              />
            </Box>
          )}
          <Box minHeight="0.4em" bgcolor={cvColor} />
        </Box>
        <Box height="calc(100% - 10.94em)" display="flex">
          <Box height="100%" flex={1} id={idLeft} fontSize={fontSizeRatio}>
            {renderedLeft}
            <Box height="1em" />
          </Box>
          <Box
            height="100%"
            minWidth="42.8%"
            maxWidth="42.8%"
            bgcolor="#303642"
            color="white"
            id={idRight}
            fontSize={fontSizeRatio}
          >
            {renderedRight}
            <Box height="1em" />
          </Box>
        </Box>
        <Box height="0.8em" width="100%" position="absolute" bottom={0} left={0} zIndex={1} bgcolor={cvColor} />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0008_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0008;
