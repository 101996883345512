import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AProfile: FC<ISocialIcon> = ({ fill = 'black', width = '8', height = '10', stroke = 'black' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.43762C5.13906 4.43762 6.06256 3.51418 6.06256 2.37506C6.06256 1.23594 5.13912 0.3125 4 0.3125C2.86088 0.3125 1.93744 1.23594 1.93744 2.37506C1.93744 3.51418 2.86088 4.43762 4 4.43762ZM4 4.81262C1.92904 4.81262 0.25 6.49166 0.25 8.56262V9.68762H7.75V8.56262C7.75 6.49166 6.07096 4.81262 4 4.81262Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};
