import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const APhone: FC<ISocialIcon> = ({
  fill = 'black',
  width = '8',
  height = '8',
  stroke = 'black',
  strokeWidth = '0',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.22991 1.09646L3.98055 1.90197C4.30766 2.25299 4.38764 2.77667 4.17829 3.19666L3.59573 4.3654C3.83457 4.85072 4.18552 5.28647 4.64859 5.67263C5.11167 6.0588 5.61524 6.33566 6.15931 6.50322L7.09856 5.75768C7.45458 5.47508 7.95238 5.46975 8.33383 5.74444L9.22247 6.38438C9.66588 6.70369 9.82706 7.30819 9.59955 7.79859L9.35573 8.32413C9.11305 8.84724 8.61752 9.1853 8.05488 9.2116C6.72658 9.27368 5.22773 8.56817 3.55833 7.09505C1.88651 5.6198 0.964633 4.18742 0.792713 2.79792C0.720378 2.21324 0.948099 1.64377 1.39133 1.30096L1.83833 0.955217C2.2572 0.631243 2.85271 0.691686 3.22991 1.09646Z"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
