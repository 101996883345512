import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const ASocialMedia: FC<ISocialIcon> = ({ fill = 'black', width = '4', height = '4' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.6875 3.5625H1.0625C0.959064 3.5625 0.875 3.64656 0.875 3.75C0.875 3.78438 0.902969 3.8125 0.9375 3.8125H2.8125C2.84703 3.8125 2.875 3.78438 2.875 3.75C2.875 3.64656 2.79093 3.5625 2.6875 3.5625Z"
        fill={fill}
      />
      <path d="M3.1875 2.4375H3.31218V2.56217H3.1875V2.4375Z" fill={fill} />
      <path
        d="M1.49758 3.1875C1.49039 3.27906 1.45789 3.36531 1.4043 3.4375H2.34554C2.29195 3.36531 2.25945 3.27906 2.25226 3.1875H1.49758Z"
        fill={fill}
      />
      <path
        d="M1.43748 1.18752H2.625V1.37314C2.645 1.37096 2.66422 1.36533 2.68109 1.35408C2.84172 1.24705 2.9375 1.06799 2.9375 0.875V0.6875H0.6875V2.5625H1.4375L1.43748 1.18752ZM1.06248 0.812518H2.87496V0.937518H1.06248V0.812518ZM1.24998 1.25004V1.62504H0.87498V1.25004H1.24998ZM0.81246 0.812518H0.93746V0.937518H0.81246V0.812518ZM1.31246 2.43752H0.81246V2.31252H1.31246V2.43752ZM1.31246 2.18752H0.81246V2.06252H1.31246V2.18752ZM1.31246 1.93752H0.81246V1.81252H1.31246V1.93752Z"
        fill={fill}
      />
      <path
        d="M2.87279 2.43796C2.81918 2.43796 2.76604 2.42939 2.71508 2.4124L2.625 2.38232V2.56263H3.06138V2.43796L2.87279 2.43796Z"
        fill={fill}
      />
      <path
        d="M2.9375 0.463436C2.9375 0.377344 2.98891 0.303436 3.0625 0.269844V0.25C3.0625 0.215625 3.03453 0.1875 3 0.1875H0.75C0.715469 0.1875 0.6875 0.215625 0.6875 0.25V0.5625H2.9375V0.463436ZM0.9375 0.437498H0.8125V0.312498H0.9375V0.437498ZM1.1875 0.437498H1.0625V0.312498H1.1875V0.437498ZM1.4375 0.437498H1.3125V0.312498H1.4375V0.437498Z"
        fill={fill}
      />
      <path
        d="M3.56218 0.99921C3.56218 0.918013 3.50981 0.849444 3.4375 0.82373V1.18622H3.56218V0.99921Z"
        fill={fill}
      />
      <path
        d="M3.4375 2.5625C3.4375 2.63141 3.38141 2.6875 3.3125 2.6875H0.4375C0.368592 2.6875 0.3125 2.63141 0.3125 2.5625V1.0625C0.3125 0.993592 0.368592 0.9375 0.4375 0.9375H0.5625V0.8125H0.375C0.271564 0.8125 0.1875 0.896564 0.1875 1V2.875C0.1875 2.97843 0.271564 3.0625 0.375 3.0625H3.375C3.47844 3.0625 3.5625 2.97843 3.5625 2.875V2.43748H3.4375V2.5625ZM0.5625 2.9375H0.4375V2.8125H0.5625V2.9375ZM0.8125 2.9375H0.6875V2.8125H0.8125V2.9375ZM1.0625 2.9375H0.9375V2.8125H1.0625V2.9375Z"
        fill={fill}
      />
      <path d="M0.4375 1.0625H0.5625V2.5625H0.4375V1.0625Z" fill={fill} />
      <path
        d="M3.87451 1.68752C3.87451 1.61845 3.81858 1.56252 3.74951 1.56252C3.81858 1.56252 3.87451 1.50658 3.87451 1.43752C3.87451 1.36845 3.81858 1.31252 3.74951 1.31252H3.43701C3.36795 1.31252 3.31201 1.25658 3.31201 1.18752V0.640158C3.31201 0.55047 3.27639 0.464378 3.21295 0.400938C3.19639 0.384375 3.17389 0.375 3.15045 0.375C3.10154 0.375 3.06201 0.414531 3.06201 0.463436V0.874996C3.06201 1.10922 2.94498 1.32812 2.74998 1.45796C2.70889 1.4853 2.66076 1.49999 2.61139 1.49999H2.49951V1.37499H2.18701V1.31249H1.56201V2.56249H2.18701V2.37499H2.49951V2.24999H2.62451L2.7542 2.29327C2.79248 2.30592 2.83264 2.31249 2.87295 2.31249H3.74951C3.81858 2.31249 3.87451 2.25655 3.87451 2.18749C3.87451 2.11842 3.81858 2.06249 3.74951 2.06249C3.81858 2.06249 3.87451 2.00655 3.87451 1.93749C3.87451 1.86842 3.81858 1.81249 3.74951 1.81249C3.81858 1.81249 3.87451 1.75655 3.87451 1.68749V1.68752Z"
        fill={fill}
      />
    </svg>
  );
};
