import moment from 'moment';
export function getTimestamp() {
  return new Date().valueOf();
}
export function getYear(date) {
  return moment(date).format('YYYY');
}
export function getYearOrExample(date) {
  var example = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY';
  return date ? getYear(date) : example;
}
export function formatDate(date, format) {
  return moment(date).format(format);
}
export function formatDateWithDefault(date, currentFormat, format) {
  return moment(date, currentFormat).format(format);
}