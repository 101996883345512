import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AWork: FC<ISocialIcon> = ({
  fill = 'black',
  width = '8',
  height = '6',
  stroke = 'black',
  strokeWidth = '0',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50098 3.6784L7.50098 3.24996V5.74996H0.500977V3.24996L3.50098 3.6784V4.24996H4.50098V3.6784ZM6.25098 1.25H7.50098V2.75L4.50098 3.17844V2.75H3.50098V3.17844L0.500977 2.75V1.25H1.75098V0.25H6.25098V1.25ZM5.75098 0.75H2.25098V1.25H5.75098V0.75Z"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
