import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import styled from '@emotion/styled';
import moment from 'moment';
import { getCVAvatar, getLanguageLevel, useCVHelper, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import {
  ActiveColors,
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  ADribbble,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  AWebsite,
} from 'components/atoms/icons';
import PREMIUM_0011_BACK from './back';
import { OCVProps, previewType } from '../../index';

const SkillItem: FC<{
  title: string;
  point: number;
  theme: string;
  isShowProficiency: boolean;
}> = ({ title, point, theme, isShowProficiency }) => {
  const percent = (point / 5) * 100;
  if (isShowProficiency) {
    return (
      <CircularProgressWithLabel
        name={title.length > 15 ? `${title.slice(0, 12)}...` : title}
        value={percent}
        theme={theme}
      />
    );
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center">
      <Box bgcolor={theme} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number; name: string; theme: string }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', width: '3.6em', height: '3.6em' }}>
      <CircularProgress
        style={{ color: props.theme, width: '3.6em', height: '3.6em' }}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
        }}
        className="u-flex-center"
      >
        <Box
          className="u-flex-center"
          flexDirection="column"
          bgcolor="#E4E4E3"
          width="70%"
          height="70%"
          borderRadius="50%"
        >
          <Box color={props.theme} fontSize=".6em">
            {props.value}%
          </Box>
          <Box fontSize=".5em" textAlign="center">
            {props.name}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; theme: string; activeColors: ActiveColors }> = ({
  cvExperience,
  theme,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box {...activeColors}>
      <Timeline color={theme}>
        <TimelineItem color={theme}>
          <Box display="flex" fontWeight={400} fontSize=".6em">
            <Box flex={2}>{`${joinDate}-${leaveDate}`}</Box>
            <Box flex={7} display="flex" flexDirection="column" gridGap=".5em">
              <Box color={theme} className="u-text-uppercase">
                | {`${role}`}
              </Box>
              <AQuillContent content={activities} />
            </Box>
          </Box>
        </TimelineItem>
      </Timeline>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; theme: string; activeColors: ActiveColors }> = ({
  cvEducation,
  theme,
  activeColors,
}) => {
  const { school, to_year, finished, from_year, activities } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box {...activeColors}>
      <Timeline color={theme}>
        <TimelineItem color={theme}>
          <Box display="flex" mt="-.1em" fontWeight={400} fontSize=".6em">
            <Box flex={2}>{`${joinDate}-${leaveDate}`}</Box>
            <Box flex={7} display="flex" flexDirection="column" gridGap=".5em">
              <Box color={theme} className="u-text-uppercase">
                | {`${school}`}
              </Box>
              <AQuillContent content={activities} />
            </Box>
          </Box>
        </TimelineItem>
      </Timeline>
    </Box>
  );
};

const UserSocialBox: FC<{ img: string; theme: string; link: string; activeColors: ActiveColors }> = ({
  img,
  theme,
  link,
  activeColors,
}) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    LinkedIn: AOutlinedLinkedin,
    Instagram: AOutlinedInstagram,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };
  const svgIcons = () => {
    const SVG = componentIcons[img];
    return <SVG fill="white" stroke="white" textFill="white" strokeWidth="0" width="60%" height="60%" />;
  };
  return (
    <Box width="50%" display="inline-flex" alignItems="center" gridGap=".4em" {...activeColors} p=".4em 0 .4em 2% ">
      <Box borderRadius="50%" bgcolor={theme} className="u-flex-center" minWidth="1.2em" width="1.2em" height="1.2em">
        {img && svgIcons()}
      </Box>
      <Box fontWeight={400} fontSize=".8em">
        {link}
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string; color: string }> = ({ type, link, color }) => {
  return (
    <Box textAlign="center" fontWeight={400} fontSize=".6em">
      <Box color={color}>{type}</Box>
      <Box mt=".4em">{link}</Box>
    </Box>
  );
};

const SubTitle: FC<{ name: string; color: string } & BoxProps> = ({ name, color, ...props }) => {
  return (
    <Box
      color="white"
      width="fit-content"
      fontWeight={400}
      bgcolor={color}
      fontSize=".6em"
      p=".5em 1.5em .5em .5em"
      {...props}
      style={{ borderBottomRightRadius: '1em', borderTopRightRadius: '1em' }}
      whiteSpace="nowrap"
    >
      {name}
    </Box>
  );
};

const UserCertification: FC<{ data: ProfileCertificate; color: string; activeColors: ActiveColors }> = ({
  data,
  color,
  activeColors,
}) => {
  const { date, certificate, organizer, description } = data;
  const year = moment(date, 'YYYY-MM-DD').year();

  return (
    <Box fontWeight={400} display="flex" flexDirection="column" gridGap=".4em" p=".2em 10% .4em" {...activeColors}>
      <Box fontSize=".6em" color={color}>
        {certificate}
      </Box>
      <Box fontSize=".5em">
        {organizer} | {year}
      </Box>
      <AQuillContent fontSize=".5em" content={description} />
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage; color: string; activeColors: ActiveColors }> = ({
  language,
  color,
  activeColors,
}) => {
  const { name, speaking, writing } = language;
  return (
    <Box
      fontWeight={400}
      display="flex"
      flexDirection="column"
      gridGap=".4em"
      justifyContent="space-between"
      p=".2em 10% .4em"
      {...activeColors}
    >
      <Box fontSize="0.6em" color={color}>
        {name}
      </Box>
      <Box fontSize=".5em">Write: {getLanguageLevel(writing)}</Box>
      <Box fontSize=".5em">Spoken: {getLanguageLevel(speaking)}</Box>
    </Box>
  );
};

const PREMIUM_0011: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, certifications, languages } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    skype,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'MICHAEL LARSSON';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userSkype = skype ?? 'username skype';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium. Risus hac purus blandit pharetra magna morbi faucibus sed amet. Viverra augue nisi, vestibulum sit nec. Facilisi duis a, vitae pellentesque nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. `;

  const userRole = role || 'Business Owner';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';

  const dummyCertifications: ProfileCertificate[] = Array(2).fill({
    date: '2021-02-02',
    organizer: 'AWS',
    certificate: 'AWS Certification Professional',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium.',
  });
  const userCertifications = certifications ?? dummyCertifications;
  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(1).fill({
    school: 'UNIVERSITY NAME',
    degree: 'Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(4).fill({
    skill: 'PHP',
    name: 'PHP',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'username' },
    { type: 'Instagram', link: 'username' },
    { type: 'LinkedIn', link: 'username' },
    { type: 'Twitter', link: 'username' },
    { type: 'Website', link: 'your-website.com' },
    { type: 'Github', link: 'username' },
  ];
  const contacts = [
    { type: 'Phone', link: userPhoneNumber },
    { type: 'E-mail', link: userEmail },
    { type: 'Skype', link: userSkype },
    { type: 'Address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(2).fill({
    role: 'Business Owner',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#a7d5e5', color: '#000' } : {};
  };

  const renderProfile = () => {
    return [
      <Box key="profile" p="1.6em" fontSize={textSize}>
        <Box
          p=".8em"
          width="12em"
          height="fit-content"
          borderRadius="100em"
          bgcolor="white"
          {...activeColors(1)}
          border={`1px solid ${cvColor}`}
        >
          <Box height="10.4em" minWidth="10.4em" maxWidth="10.4em">
            <img
              height="100%"
              width="100%"
              src={userAvatar}
              alt="profile-pict"
              className="u-border-rounded o-base-cv__avatar"
              hidden={!isShowAvatar}
            />
          </Box>
          <Box my="1em" alignItems="center" display="flex" flexDirection="column" gridGap="1.2em">
            {contacts.map(
              (item, idx) =>
                !!item.link.length && <UserContactsBox key={idx} type={item.type} link={item.link} color={cvColor} />,
            )}
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderCertification = () => {
    if (isEmpty(userCertifications)) return [];
    return [
      <Box key="certification" p=".4em 1.6em" {...activeColors(5)}>
        <SubTitle name="CERTIFICATION" color={cvColor} />
      </Box>,
      ...userCertifications.map((item, index) => (
        <UserCertification
          key={`${item.certificate}${index}`}
          data={item}
          color={cvColor}
          activeColors={activeColors(5)}
        />
      )),
    ];
  };

  const renderLanguages = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="languages" p="1.2em 10% .4em" {...activeColors(5)}>
        <SubTitle name="LANGUAGE" color={cvColor} />
      </Box>,
      ...userLanguages.map((item, index) => (
        <UserLanguageInfo key={`${item.name}${index}`} language={item} color={cvColor} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderName = () => {
    return [
      <Box
        key="name"
        width="100%"
        p="1.6em .2em .2em .2em"
        minHeight="10.7em"
        color="white"
        bgcolor={cvColor}
        {...activeColors(1)}
        fontSize={textSize}
      >
        <Box fontWeight={400} fontSize="1.4em" lineHeight="1.68em" className="u-text-uppercase">
          {userFirstName}
        </Box>
        <Box fontWeight={400} fontSize="1.4em" lineHeight="1.68em" className="u-text-uppercase">
          {userLastName}
        </Box>
        <Box mb=".5em" bgcolor="white" width="11.2em" height="1px" />
        <Box mb=".5em" fontWeight={400} fontSize="1em" className="u-text-line">
          {userRole}
        </Box>
      </Box>,
    ];
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box key="summary" width="100%" p="1.2em 10% 0.7em 0" {...activeColors(1)}>
        <Box display="flex" gridGap=".2em" alignItems="center">
          <Box width=".5em" height="1px" bgcolor={cvColor} />
          <SubTitle name="ABOUT ME" color={cvColor} />
        </Box>
        <Box m=".6em 2% 0 .7em">
          <AQuillContent content={userAboutMe} fontWeight={400} fontSize=".5em" />
        </Box>
      </Box>,
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education" width="100%" p="1.2em 10% 0.7em 0" {...activeColors(3)}>
        <Box display="flex" gridGap=".2em" alignItems="center">
          <Box width=".5em" height="1px" bgcolor={cvColor} />
          <SubTitle name="EDUCATION" color={cvColor} />
        </Box>
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo
          key={`${item.school}${index}`}
          cvEducation={item}
          theme={cvColor}
          activeColors={activeColors(3)}
        />
      )),
    ];
  };

  const renderSkill = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skill" width="100%" p="1.2em 10% .7em 0" {...activeColors(4)}>
        <Box display="flex" gridGap=".2em" alignItems="center">
          <Box width=".5em" height="1px" bgcolor={cvColor} />
          <SubTitle name="SKILLS" color={cvColor} />
        </Box>
        <Box m="1em 2% 0 0.7em" display="flex" flexWrap="wrap" gridGap="1.2em">
          {userSkills.map(({ name, points }, index) => (
            <SkillItem
              key={`skill${name}${index}${points}`}
              title={name}
              point={points}
              theme={cvColor}
              isShowProficiency={isShowProficiency}
            />
          ))}
        </Box>
      </Box>,
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experience" width="100%" p="1.2em 10% 0.7em 0" {...activeColors(2)}>
        <Box display="flex" gridGap=".2em" alignItems="center">
          <Box width=".5em" height="1px" bgcolor={cvColor} />
          <SubTitle name="WORK EXPERIENCE" color={cvColor} />
        </Box>
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo
          key={`${item.companyInfo}${index}`}
          cvExperience={item}
          theme={cvColor}
          activeColors={activeColors(2)}
        />
      )),
    ];
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box key="social-media" height="1em" />,
      ...userSocialMediaShowed.map((item, idx) => (
        <UserSocialBox
          key={idx + item.link}
          theme={cvColor}
          img={item.type}
          link={item.link}
          activeColors={activeColors(1)}
        />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderProfile(), ...renderCertification(), ...renderLanguages()],
    rightComponents: [
      ...renderName(),
      ...renderSummary(),
      ...renderEducation(),
      ...renderSkill(),
      ...renderExperience(),
      ...renderSocialMedia(),
    ],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont}>
      <Box
        minHeight="54.164em"
        height="100%"
        position="relative"
        display="flex"
        flexDirection="column"
        className="o-premium-0011"
      >
        <Box width="100%" height="10.7em" bgcolor={cvColor} position="absolute" top={0} />
        <Box position="relative">
          <Box display="flex" pb="1.4em" height="54.164em">
            <Box
              flex={40}
              height="100%"
              id={idLeft}
              overflow="hidden"
              fontSize={fontSizeRatio}
              borderRight={`1px solid ${cvColor}`}
            >
              {renderedLeft}
              <Box height="1.6em" />
            </Box>
            <Box flex={60} height="100%" id={idRight} overflow="hidden" fontSize={fontSizeRatio}>
              {renderedRight}
              <Box height="1.6em" />
            </Box>
          </Box>
        </Box>
        <Box height="1.4em" width="100%" bgcolor={cvColor} position="absolute" bottom={0} />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0011_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0011;

const Timeline = styled('div')`
  margin: 0 2% 0 0.7em;
  display: flex;
  grid-gap: 0.4em;
  flex-direction: column;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: ${(props) => props.color};
    top: 0;
    bottom: 0;
    left: 0;
  }
`;

const TimelineItem = styled('div')`
  padding: 0 10% 0.5em 1.2em;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 0.5em;
    height: 0.5em;
    left: 0;
    transform: translateX(-34%);
    background-color: ${(props) => props.color};
    top: 0;
    border-radius: 50%;
    z-index: 1;
  }
`;
