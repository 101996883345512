import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, getLanguageLevel, useCVHelper, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import {
  ActiveColors,
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
  ProfileReference,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AAddress,
  AContactMe,
  ADribbble,
  AEducation,
  AEmail,
  AGithub,
  AHome,
  AMobilePhone,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  AProfile,
  ASkills,
  ASkype,
  AWebsite,
  AWork,
  ISocialIcon,
} from 'components/atoms/icons';

import PREMIUM_0022_BACK from './back';
import { OCVProps, previewType } from '../../index';

const SkillItem: FC<{
  title: string;
  point: number;
  theme: string;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
}> = ({ title, point, theme, isShowProficiency, activeColors }) => {
  if (isShowProficiency) {
    const width = `${(point * 100) / 5}%`;
    return (
      <Box p=".5em 1.6em" {...activeColors}>
        <Box mb=".4em">
          <Box fontSize="0.5em" color={activeColors.color || 'white'} width="100%" className="u-text-uppercase">
            {title}
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          height=".4em"
          position="relative"
          border={`1px solid ${theme}`}
        >
          <Box
            bgcolor={theme}
            width={`calc(${width} + 2px)`}
            height="calc(100% + 2px)"
            position="absolute"
            top="-1px"
            left="-1px"
          />
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" {...activeColors} p=".5em 1.6em">
      <Box bgcolor={theme} width=".2em" height=".1em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities, company, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box display="flex" alignItems="flex-start" flexDirection="column" gridGap=".4em" p=".5em 1.6em" {...activeColors}>
      <Box color="#484545" fontSize=".5em" fontWeight={400}>{`${joinDate} - ${leaveDate}`}</Box>
      <Box color="#484545" fontSize=".5em" fontWeight={400}>{`${company}, ${location}`}</Box>
      <Box color="#484545" fontSize=".5em" fontWeight={700}>
        {role}
        <Box width="100%" mb=".3em" mt=".1em" borderBottom=".15em solid" borderColor="white" />
      </Box>
      <AQuillContent color="#757575" textAlign="start" fontWeight={400} fontSize=".5em" content={activities} />
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, to_year, finished, from_year, degree } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box fontWeight={400} display="flex" flexDirection="row" alignItems="end" px="1.6em" {...activeColors}>
      <Box display="flex" flexDirection="row" width="100%" justifyContent="flex-start" alignItems="flex-start">
        <Box fontSize=".5em" color="#484545" width="25%">
          {`${joinDate} - ${leaveDate}`}
        </Box>
        <Box display="flex" mr="1.2em" flexDirection="column" justifyContent="center" alignItems="flex-end">
          <Box width=".3em" mt=".1em" height=".3em" borderRadius="50%" bgcolor="#484545" />
          <Box
            height="2em"
            width="1.2em"
            display="flex"
            justifyContent="flex-end"
            borderRight=".12em solid"
            borderColor="#484545"
            mr=".1em"
          />
        </Box>
        <Box width="100%">
          <Box fontWeight={700} fontSize=".6em" color="#484545">
            {degree}
          </Box>
          <Box fontSize=".5em" color="#484545">
            {school}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ img: string; link: string; type: string; activeColors: ActiveColors }> = ({
  img,
  link,
  activeColors,
}) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    Instagram: AOutlinedInstagram,
    LinkedIn: AOutlinedLinkedin,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG = componentIcons[img];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="80%" height="80%" />;
  };
  return (
    <Box
      width="50%"
      display="inline-flex"
      gridGap=".5em"
      alignItems="center"
      color="#ffffff"
      {...activeColors}
      p=".4em 1.6em"
    >
      <Box borderRadius="50%" bgcolor="#272826" className="u-flex-center" width=".5em" height=".5em">
        <Box width="100%" height="100%" className="u-flex-center">
          {img && svgIcons()}
        </Box>
      </Box>
      <Box>
        <Box fontWeight={400} fontSize=".5em">
          {link}
        </Box>
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string; activeColors: ActiveColors }> = ({
  type,
  link,
  activeColors,
}) => {
  const componentIcons = {
    email: AEmail,
    phone: APhone,
    address: AAddress,
    Skype: ASkype,
  };
  const svgIcon = () => {
    const SVG = componentIcons[type];
    return <SVG fill={activeColors.color || 'white'} strokeWidth="0" width=".5em" height=".5em" />;
  };
  return (
    <Box
      display="flex"
      gridGap=".3em"
      alignItems="center"
      fontWeight={400}
      px="1.6em"
      color="#ffffff"
      {...activeColors}
    >
      <Box>{type && svgIcon()}</Box>
      <Box fontSize=".5em">{link}</Box>
    </Box>
  );
};

const SubTitle: FC<{ name: string; activeColors: ActiveColors } & BoxProps> = ({ name, activeColors, ...props }) => {
  return (
    <Box color="#FFFFFF" fontSize=".8em" whiteSpace="nowrap" {...props} fontWeight={700} {...activeColors}>
      {name}
    </Box>
  );
};

const LineIcons: FC<{ icon: FC<ISocialIcon>; right?: boolean } & BoxProps> = ({ icon, right = false, ...props }) => {
  const SVG = icon;
  return (
    <Box display="flex" alignItems="center" width="100%" {...props}>
      <Box width="100%" borderBottom=".15em solid" borderColor={right ? '#333333' : '#FFFFFF'} />
      <Box display="flex" justifyContent="flex-end">
        <Box
          display="flex"
          width=".8em"
          height=".8em"
          p=".2em"
          bgcolor={right ? '#333333' : '#FFFFFF'}
          borderRadius="50%"
        >
          <SVG width="100%" height="100%" fill={right ? '#FFFFFF' : '#212121'} />
        </Box>
      </Box>
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage; activeColors: ActiveColors }> = ({
  language,
  activeColors,
}) => {
  const { name, speaking, writing } = language;
  return (
    <Box
      fontWeight={400}
      color="#ffffff"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      p="0em 1.6em .6em"
      {...activeColors}
    >
      <Box fontSize=".6em" fontWeight={600}>
        {name}
      </Box>
      <Box display="flex" alignItems="center">
        <Box fontSize=".5em" mr=".2em">
          Write: {getLanguageLevel(writing)} {'| '}
        </Box>
        <Box fontSize=".5em">Spoken: {getLanguageLevel(speaking)}</Box>
      </Box>
    </Box>
  );
};

const UserCertification: FC<{ data?: ProfileCertificate; activeColors: ActiveColors }> = ({ data, activeColors }) => {
  const { certificate, date, organizer, expiration_date, does_not_expire } = data;
  const joinDate = getYearOrExample(date);
  const leaveDate = does_not_expire ? 'Present' : getYearOrExample(expiration_date, '2020');

  return (
    <Box fontWeight={400} display="flex" flexDirection="column" gridGap=".2em" p=".5em 1.6em" {...activeColors}>
      <Box fontWeight={400} fontSize=".5em">
        {`${joinDate} - ${leaveDate}`}
      </Box>
      <Box fontWeight={400} fontSize=".5em">
        {organizer}
      </Box>

      <Box fontWeight={700} fontSize=".6em" color="#333333">
        {certificate}
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  cvReference,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap=".3em"
      color="#ffffff"
      minWidth="40%"
      p=".5em 1.6em"
      {...activeColors}
    >
      <Box fontSize=".6em" fontWeight={700}>
        {name}
      </Box>
      <Box fontSize=".5em" fontWeight={400}>
        {position} | {company}
      </Box>

      <Box fontStyle="italic" fontSize=".5em" fontWeight={400}>
        <Box component="span" mr=".2em">
          Phone:
        </Box>
        {mobile}
      </Box>
      <Box fontStyle="italic" fontSize=".5em" fontWeight={400}>
        <Box component="span" mr=".2em">
          Email:
        </Box>
        {email}
      </Box>
    </Box>
  );
};

const PREMIUM_0022: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, certifications, languages, reference } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    skype,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'Herminia Lowe';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userSkype = skype ?? 'username skype';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet. Molestie sed risus porta in elit rhoncus.
Purus scelerisque curabitur enim in turpis. Sed condimentum eu diam lorem.`;

  const userRole = role || 'DIRECTOR OF MARKETING';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';

  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(3).fill({
    school: 'University of Tech',
    degree: 'Bachelor Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'Javascript',
    name: 'Javascript',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;
  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'facebook' },
    { type: 'Instagram', link: 'instagram' },
    { type: 'LinkedIn', link: 'linkedin' },
    { type: 'Twitter', link: 'twitter' },
    { type: 'Github', link: 'github' },
  ];
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'Skype', link: userSkype },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(2).fill({
    role: 'Junior Data',
    company: 'Tech Company',
    location: 'Location',
    from_year: '01-01-2016',
    to_year: '01-01-2018',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyCertifications: ProfileCertificate[] = Array(2).fill({
    certificate_id: 'Certification ID',
    date: '2021-02-02',
    organizer: 'AWS',
    certificate: 'AWS Certification Professional',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium.',
  });
  const userCertifications = certifications ?? dummyCertifications;

  const dummyReference: ProfileReference[] = Array(1).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    company: 'Company / Organization Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e8e8e8', color: 'black' } : {};
  };

  const renderPhoto = () => {
    if (!isShowAvatar) return [];
    return [
      <Box key="photo" {...activeColors(1)}>
        <Box display="flex" justifyContent="center" pt="1.2em">
          <Box height="4.5em" minWidth="4.5em" maxWidth="5.5em">
            <img
              height="100%"
              width="100%"
              src={userAvatar}
              alt="profile-pict"
              className="u-border-rounded o-base-cv__avatar"
            />
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box
        key="summary"
        {...activeColors(1)}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        alignItems="start"
        width="100%"
        p="1em 1.6em"
      >
        <SubTitle name="About Me" fontWeight={700} activeColors={activeColors(1)} />
        <Box width="100%" display="flex" alignItems="center">
          <LineIcons icon={AProfile} />
        </Box>
        <AQuillContent
          color={activeColors(1).color || '#FFFFFF'}
          textAlign="left"
          content={userAboutMe}
          fontWeight={400}
          lineHeight="1.5em"
          fontSize=".5em"
          mt="1em"
        />
      </Box>,
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];

    return [
      <Box key="skills" width="100%" p="1em 1.6em 0em" {...activeColors(4)}>
        <SubTitle name="Skills" fontWeight={700} activeColors={activeColors(4)} />
        <Box width="100%" display="flex" alignItems="center">
          <LineIcons icon={ASkills} />
        </Box>
      </Box>,
      ...userSkills.map((item, idx) => (
        <SkillItem
          isShowProficiency={isShowProficiency}
          key={idx + item.name}
          title={item.name}
          point={item.points}
          theme={cvColor}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const renderContact = () => {
    if (isEmpty(contacts)) return [];
    return [
      <Box key="contact" p="1em 1.6em .5em" {...activeColors(1)}>
        <Box width="100%">
          <SubTitle name="Contact" fontWeight={700} activeColors={activeColors(1)} />
          <Box width="100%" display="flex" alignItems="center">
            <LineIcons icon={AContactMe} />
          </Box>
        </Box>
      </Box>,
      ...contacts.map(
        (item, idx) =>
          !!item.link.length && (
            <UserContactsBox
              key={`${item.type}${idx}`}
              type={item.type}
              link={item.link}
              activeColors={activeColors(1)}
            />
          ),
      ),
    ];
  };

  const renderLanguages = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="languages" {...activeColors(5)} width="100%" p="1em 1.6em .5em" mt="1em">
        <SubTitle name="Languages" fontWeight={700} activeColors={activeColors(5)} />
        <Box width="100%" display="flex" alignItems="center">
          <LineIcons icon={AMobilePhone} />
        </Box>
      </Box>,
      ...userLanguages.map((item, index) => (
        <UserLanguageInfo key={`${item.name}${index}`} language={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderReference = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box key="references" {...activeColors(5)} width="100%" p="1.5em 1.6em 0">
        <SubTitle name="References" fontWeight={700} activeColors={activeColors(5)} />
        <Box width="100%" display="flex" alignItems="center">
          <LineIcons icon={AHome} />
        </Box>
      </Box>,
      ...userReference.map((item, index) => (
        <UserReference key={`${item.name}${index}`} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderSocialMedias = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box
        key="socialMedias"
        display="flex"
        width="100%"
        p="1em 1.6em 0"
        justifyContent="space-between"
        flexWrap="wrap"
        {...activeColors(1)}
      />,
      ...userSocialMediaShowed.map((item, idx) => (
        <UserSocialBox
          key={`${item.type}${idx}`}
          type={item.type}
          img={item.type}
          link={item.link}
          activeColors={activeColors(1)}
        />
      )),
    ];
  };

  const renderName = () => {
    return [
      <Box
        key="name"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        {...activeColors(1)}
      >
        <Box mt="1.3em" width="100%">
          <Box
            fontSize="1.7em"
            fontWeight={700}
            color="#212121"
            textAlign="center"
          >{`${userFirstName} ${userLastName}`}</Box>
          <Box
            bgcolor={cvColor}
            borderTop=".15em solid #4E4B4B"
            borderBottom=".15em solid #4E4B4B"
            width="100%"
            height="1.8em"
            mt="0.5em"
            display="flex"
            justifyContent="center"
            alignItems="center"
            {...activeColors(1)}
          >
            <Box color="#4E4B4B" fontSize=".7em" fontWeight={700}>
              {userRole}
            </Box>
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderExperiences = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experiences" {...activeColors(2)} p="1em 1.6em 0em">
        <Box>
          <SubTitle name="Work Experience" color="#484545" activeColors={activeColors(2)} />
          <Box width="100%" display="flex" alignItems="center">
            <LineIcons icon={AWork} right />
          </Box>
        </Box>
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`${item.companyInfo}${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderEducations = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="educations" {...activeColors(3)} p=".5em 1.6em 0.5em">
        <Box>
          <SubTitle name="Education" color="#484545" activeColors={activeColors(3)} />
          <Box width="100%" display="flex" mt=".1em" alignItems="center">
            <LineIcons icon={AEducation} right />
          </Box>
        </Box>
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderCertifications = () => {
    if (isEmpty(userCertifications)) return [];
    return [
      <Box key="certifications" {...activeColors(5)} p="1em 1.6em 0em">
        <SubTitle name="Certification" color="#484545" activeColors={activeColors(5)} />
        <Box width="100%" display="flex" mt=".1em" alignItems="center">
          <LineIcons icon={AEmail} right />
        </Box>
      </Box>,
      ...userCertifications.map((item, index) => (
        <UserCertification key={`${item.certificate}${index}`} data={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [
      ...renderPhoto(),
      ...renderSummary(),
      ...renderSkills(),
      ...renderContact(),
      ...renderLanguages(),
      ...renderReference(),
      ...renderSocialMedias(),
    ],
    rightComponents: [...renderName(), ...renderExperiences(), ...renderEducations(), ...renderCertifications()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont || 'inherit'}>
      <Box minHeight="54.164em" height="54.164em" display="flex" position="relative" color="#71726E" width="100%">
        <Box
          maxHeight="100%"
          height="100%"
          id={idLeft}
          overflow="hidden"
          bgcolor="#171818"
          flex={50}
          fontSize={fontSizeRatio || 'inherit'}
        >
          {renderedLeft}
          <Box height="1.6em" />
        </Box>
        <Box
          flex={50}
          maxHeight="100%"
          height="100%"
          id={idRight}
          overflow="hidden"
          fontSize={fontSizeRatio || 'inherit'}
        >
          {renderedRight}
          <Box height="1.6em" />
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0022_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0022;
