import { FC } from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, useCVHelper, useCvOneLayout, getTime } from 'helpers';
import { ActiveColors, ProfileEducation, ProfileExperience } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import MCvWatermark from 'components/molecules/MCvWatermark';
import AQuillContent from 'components/atoms/AQuillContent';
import { formatDate } from 'utils/date';
import FREE_0001_BACK from './back';
import { OCVProps, previewType } from '../../index';

const box6em = (title: string) => (
  <Box component="span" fontSize="0.6em" width="100%" whiteSpace="pre">
    {title}
  </Box>
);

const ProgessBox: FC<{ title: string; percent: number; color: string; activeColors: ActiveColors }> = ({
  title,
  percent,
  color,
  activeColors,
}) => {
  return (
    <Box display="flex" alignContent="center" p="0 1.2em" {...activeColors}>
      <Box flexGrow={1} fontWeight="700">
        {box6em(title)}
      </Box>
      <Box minWidth="40%" display="flex" flexDirection="column" justifyContent="center" color="red">
        <Box height="0.4em" borderRadius="0.4em" minHeight="4px" bgcolor="#e9e9e9">
          <Box height="0.4em" borderRadius="0.4em" minHeight="4px" width={`${percent}%`} bgcolor={color} />
        </Box>
      </Box>
    </Box>
  );
};

const UserLinkInfo: FC<{ title: string; info: string; isHalf?: boolean }> = ({ title, info, isHalf = false }) => {
  return (
    <Box
      display="flex"
      mt="0.8em"
      flexGrow={1}
      lineHeight="0.817em"
      {...(isHalf && info.length <= 15 ? { width: 'calc(50% - .5em)' } : {})}
    >
      <Box fontWeight="bold" minWidth="3.6em" display="flex" justifyContent="space-between">
        {box6em(title)}
        <Box fontSize="0.6em" fontWeight={400} pr="0.2em">
          :
        </Box>
      </Box>
      <Box component="span" flex={1} fontSize="0.6em">
        {info}
      </Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, company, activities } = cvExperience;
  const { time } = getTime(cvExperience);

  return (
    <Box p="0.3em 1.2em" {...activeColors}>
      <Box fontSize="0.6em" width="100%">
        {`${time} - `}
        <b>{role}</b> - {company}
      </Box>
      <Box p=".6em 0px 0em .6em" className="u-pre-line">
        <AQuillContent content={activities} fontSize="0.6em" />
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, degree, activities } = cvEducation;
  const { time } = getTime(cvEducation);

  return (
    <Box lineHeight="0.817em" p="0.3em 1.2em" {...activeColors}>
      <Box fontSize="0.6em" width="100%">
        {`${time} - `}
        <b>{school}</b> - {degree}
      </Box>
      <Box m=".6em 0px .6em .6em" className="u-pre-line">
        <AQuillContent content={activities} fontSize="0.6em" />
      </Box>
    </Box>
  );
};

const SkillItem: FC<{
  title: string;
  point: number;
  isShowProficiency: boolean;
  color: string;
  activeColors: ActiveColors;
}> = ({ title, point, isShowProficiency, color, activeColors }) => {
  if (isShowProficiency) {
    return <ProgessBox title={title} percent={(point * 100) / 5} color={color} activeColors={activeColors} />;
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" {...activeColors} px="1.2em" width={1}>
      <Box bgcolor={color} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em" flex={1}>
        {title}
      </Box>
    </Box>
  );
};

const FREE_0001: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    preview = previewType.cvDemoThumbnail,
    isActive = 0,
    textSize,
    isShowProficiency = true,
    savedCvFont,
    isShowAvatar = true,
  } = props;
  const { basicInfo, experience, education, skills } = cvInfo || { basicInfo: {} };
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    date_of_birth,
    skype,
    country,
    city,
    role,
    summary,
    mobile_number,
    socialMedia,
    address,
  } = basicInfo as CvBasicInfo;
  const userAvatar = getCVAvatar(avatar, preview);
  const fullName = first_name || last_name ? `${first_name || ''} ${last_name || ''}` : 'Candidate Name';
  const userEmail = cv_email || 'jhondee@network.com';
  let userDateOfBirth = date_of_birth === undefined ? '16 July 1994' : date_of_birth;
  userDateOfBirth = userDateOfBirth ? formatDate(userDateOfBirth, 'DD MMMM YYYY') : '';
  const userAboutMe =
    summary ||
    `Front End Developer is to express my interest in the job posted on your website for an experienced, detailed-oriented, front-end developer. As you'll see, I have six years of hands-on experience efficiently coding websites and applications using modern HTML, CSS, React and JavaScript. Building state-of-the-art, easy to use, user-friendly websites and applications is truly a passion of mine and I am confident I would be an excellent addition to your organization.`;

  const userRole = role || 'Candidate Role';
  const userAddress = address || 'Melbourne Street Number 4';
  const userCity = city || 'California, New York City';
  const userCountry = country || 'US';
  const userPhoneNumber = mobile_number || '667-0890-231';
  const userSkype = skype ?? 'jhon Dee';

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'User Facebook' },
    { type: 'Instagram', link: 'User Instagram' },
    { type: 'LinkedIn', link: 'User LinkedIn' },
    { type: 'Twitter', link: 'User Twitter' },
    { type: 'Website', link: 'User Website' },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const dummyExperiences = Array(2).fill({
    role: 'Front End Developer',
    company: 'Google',
    location: 'San Francisco',
    from_year: '01-01-2019',
    to_year: 'present',
    finished: false,
    activities: `- Responsible for creating, improving and developing IT Projects. Than collaboration with a team of designers
- Make the appearance according to the project or product requirements signed with Scrum Master.
- Perform iteration and bug fixing for application iterations. QA test with a QA teams to find out what the error`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University Name',
    degree: 'Degree Name',
    grade: '4.75',
    major: 'Front End Developer',
    from_year: '01-05-2019',
    to_year: 'present',
    finished: false,
    activities: `- Responsible for creating, improving and developing IT Projects. Than collaboration with a team of designers
- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Habitant ultricies egestas mattis adipiscing integer sed`,
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const userSocialMediaShowed = orderBy(userSocialMedia, [(e) => e.link.length], ['asc']);

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#CCEAFC' } : {};
  };

  const renderProfile = () => {
    return [
      <Box key="profile" {...activeColors(1)} display="flex" p="1.3em 1.2em 0 1.2em" flexWrap="wrap" width="100%">
        <Box display="flex" width={1} alignItems="center">
          {isShowAvatar && (
            <Box height="4em" minWidth="4em" maxWidth="4em" mr="0.5em">
              <img height="100%" width="100%" src={userAvatar} alt="" className="u-border-rounded o-base-cv__avatar" />
            </Box>
          )}
          <Box flex={1}>
            <Box fontWeight={600} lineHeight="1.907em">
              <Box fontSize="1.4em">{fullName}</Box>
            </Box>
            <Box lineHeight="1.6em" mt="0.1em" letterSpacing="0.04em">
              <Box fontSize="0.8em">{userRole}</Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" width="100%" mb="0.6em" gridGap="1em">
          {userDateOfBirth && (
            <Box minWidth="calc(100%/3)" maxWidth="calc(100%/3)">
              <UserLinkInfo title="Date Of Birth" info={userDateOfBirth as string} />
            </Box>
          )}
          <Box>
            <Box display="flex" mt="0.8em" flexGrow={1} lineHeight="0.817em">
              <Box fontWeight="bold" minWidth="3.6em" display="flex" justifyContent="space-between">
                {box6em('Address')}
                <Box fontSize="0.6em" fontWeight={400} pr="0.2em">
                  :
                </Box>
              </Box>
              <Box fontSize="0.6em">{`${userAddress}, ${userCity}, ${userCountry}`}</Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" width="100%" gridGap="1em">
          <Box minWidth="calc(100%/3)" maxWidth="calc(100%/3)">
            <Box mb="-0.6em" lineHeight="1.6em" letterSpacing="0.1em" color="#757575">
              {box6em('Contact Person')}
            </Box>
            <UserLinkInfo title="Phone" info={userPhoneNumber} />
            <UserLinkInfo title="Email" info={userEmail} />
            {!!userSkype.length && <UserLinkInfo title="Skype" info={userSkype} />}
          </Box>
          {!!userSocialMediaShowed.length && (
            <Box flex={1} minWidth="calc(100% - 100%/3)" maxWidth="(100% - 100%/3)">
              <Box mb="-0.6em" lineHeight="1.6em" letterSpacing="0.1em" color="#757575">
                {box6em('Social Media')}
              </Box>
              <Box display="flex" flexWrap="wrap" width="100%" gridColumn="1em">
                {userSocialMediaShowed.map((item) => (
                  <UserLinkInfo key={item.type} title={item.type} info={item.link} isHalf />
                ))}
              </Box>
            </Box>
          )}
        </Box>
        <Box mt="0.8em" pb="0.3em" className="u-pre-line">
          <AQuillContent content={userAboutMe} fontSize="0.6em" />
        </Box>
      </Box>,
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experience_header" p="0.3em 1.2em 0.3em" {...activeColors(2)}>
        <Box lineHeight="1.6em" fontWeight={700} letterSpacing="0.2em">
          <Box fontSize="1em">Experience</Box>
        </Box>
        <Divider className="o-free-0001__divider" variant="middle" />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo
          key={`experience-${index}${item.companyInfo}`}
          cvExperience={item}
          activeColors={activeColors(2)}
        />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education_header" p="0.6em 1.2em 0.6em" {...activeColors(3)}>
        <Box lineHeight="1.6em" fontWeight={700} letterSpacing="0.2em">
          <Box fontSize="1em">Education</Box>
        </Box>
        <Divider className="o-free-0001__divider" variant="middle" />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`education-${index}${item.school}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skills" p="0.3em 1.2em 0em" {...activeColors(4)}>
        <Box lineHeight="1.6em" fontWeight={700} letterSpacing="0.2em">
          <Box fontSize="1em">Skills</Box>
        </Box>
        <Divider className="o-free-0001__divider" variant="middle" />
        <Box height="0.3em" />
      </Box>,
      ...userSkills.map((item, index) => (
        <SkillItem
          key={`skill-${index}-${item.name}`}
          title={item.name}
          point={item.points}
          activeColors={activeColors(4)}
          isShowProficiency={isShowProficiency}
          color={cvColor}
        />
      )),
    ];
  };

  const [isShowOtherPage, rendered, pageId, content] = useCvOneLayout({
    components: [...renderProfile(), ...renderExperience(), ...renderEducation(), ...renderSkills()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont}>
      <Box className="o-free-0001" minHeight="54.164em" height="54.164em" position="relative">
        <Box id={pageId} height="100%" overflow="hidden" fontSize={fontSizeRatio}>
          {rendered}
          <Box height="2.6em" width="100%" fontSize={textSize} />
        </Box>
        <MCvWatermark />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize="1.5em">{t('common.pageBreak')}</Box>
            </Box>
          )}
          <FREE_0001_BACK
            textSize={textSize}
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContent={content}
          />
        </>
      )}
    </Box>
  );
};

export default FREE_0001;
