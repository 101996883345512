import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AContact: FC<ISocialIcon> = ({ fill = 'black', width = '4', height = '4' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.57627 0.833008H0.73627C0.427518 0.833008 0.17627 1.08426 0.17627 1.39301V3.23301C0.17627 3.54176 0.427518 3.79301 0.73627 3.79301H2.57627C2.88502 3.79301 3.13627 3.54176 3.13627 3.23301V1.39301C3.13627 1.08426 2.88502 0.833008 2.57627 0.833008ZM1.65627 1.33301C1.89924 1.33301 2.09627 1.53004 2.09627 1.77301C2.09627 2.01598 1.89924 2.21301 1.65627 2.21301C1.4133 2.21301 1.21627 2.01598 1.21627 1.77301C1.21627 1.53004 1.4133 1.33301 1.65627 1.33301ZM2.49627 3.31301H0.81627V3.01301C0.81627 2.74785 1.03111 2.47301 1.29627 2.47301H2.01627C2.28143 2.47301 2.49627 2.74785 2.49627 3.01301V3.31301Z"
        fill={fill}
      />
    </svg>
  );
};
