import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const ASkillsSecond: FC<ISocialIcon> = ({ fill = 'black', width = '10', height = '10' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.14315 3.79253C8.81504 3.51643 8.31291 3.54945 8.02847 3.88851L7.85693 4.09107L9.07016 5.10825L9.23361 4.90693C9.51493 4.57287 9.47936 4.07362 9.14315 3.79253Z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.21761 8.07927C5.19453 8.17588 5.22822 8.27811 5.30494 8.34168C5.37917 8.40463 5.48459 8.42271 5.57941 8.38219L6.54812 7.96271C6.6604 7.9141 6.76083 7.83933 6.83943 7.74584L6.92987 7.63864L5.71664 6.62146L5.62618 6.72866C5.54758 6.82215 5.49145 6.93435 5.46275 7.05277L5.21761 8.07927Z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.05713 6.21885L7.27039 7.23602L8.7294 5.51083L7.51614 4.49426L6.05713 6.21885Z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.03025 1.62737L6.02722 0.675692C5.93241 0.585945 5.81575 0.532948 5.69287 0.498047V2.04056H7.25981C7.22738 1.88412 7.14752 1.73893 7.03025 1.62737Z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.84522 7.33262H1.7331C1.58776 7.33262 1.46924 7.21547 1.46924 7.069C1.46924 6.92378 1.58776 6.80599 1.7331 6.80599H3.84522C3.99118 6.80599 4.10908 6.92378 4.10908 7.069C4.10908 7.21547 3.99118 7.33262 3.84522 7.33262ZM1.7331 5.2229H4.90065C5.04661 5.2229 5.16451 5.34134 5.16451 5.48655C5.16451 5.63302 5.04661 5.75078 4.90065 5.75078H1.7331C1.58776 5.75078 1.46924 5.63302 1.46924 5.48655C1.46924 5.34134 1.58776 5.2229 1.7331 5.2229ZM1.7331 3.64045H5.9567C6.10266 3.64045 6.22056 3.75889 6.22056 3.90411C6.22056 4.05057 6.10266 4.16834 5.9567 4.16834H1.7331C1.58776 4.16834 1.46924 4.05057 1.46924 3.90411C1.46924 3.75889 1.58776 3.64045 1.7331 3.64045ZM1.7331 2.04054H4.37294C4.5189 2.04054 4.63679 2.15836 4.63679 2.3042C4.63679 2.45004 4.5189 2.56847 4.37294 2.56847H1.7331C1.58776 2.56847 1.46924 2.45004 1.46924 2.3042C1.46924 2.15836 1.58776 2.04054 1.7331 2.04054ZM6.75888 8.44576L5.78891 8.86645C5.5157 8.98425 5.19944 8.94248 4.96552 8.74678C4.7341 8.55232 4.63367 8.25004 4.70416 7.95649L4.9493 6.93061C4.99609 6.73241 5.09028 6.54668 5.22127 6.39024L7.27661 3.95957V2.56847H5.429C5.28303 2.56847 5.16451 2.45004 5.16451 2.3042V0.45813H1.20539C0.768746 0.45813 0.413818 0.812737 0.413818 1.24964V8.66952C0.413818 9.1058 0.768746 9.46042 1.20539 9.46042H6.48442C6.92106 9.46042 7.27661 9.1058 7.27661 8.66952V8.04626L7.24231 8.08613C7.11256 8.2407 6.94539 8.36474 6.75888 8.44576Z"
      />
    </svg>
  );
};
