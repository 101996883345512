import { FC } from 'react';
import Box from '@material-ui/core/Box';
import useTranslation from 'next-translate/useTranslation';
import { useCvTwoLayoutBack } from 'helpers';
import { OCVProps, previewType } from '../../index';

const PREMIUM_0008_BACK: FC<OCVProps> = (props) => {
  const { t } = useTranslation('common');
  const [
    isShowOtherPage,
    renderedLeft,
    renderedRight,
    idLeft,
    idRight,
    contentLeft,
    contentRight,
    preview,
    savedCvColour,
    fontSizeRatio,
  ] = useCvTwoLayoutBack({
    breakComponent: <Box key="too-large">{t('common.contentTooLong')}</Box>,
    props,
  });

  return (
    <>
      <Box height="54.164em" display="flex" overflow="hidden" position="relative">
        <Box height="100%" id={idLeft} overflow="hidden" py=".4em" flex={1} fontSize={fontSizeRatio}>
          {renderedLeft}
        </Box>
        <Box
          flexGrow={1}
          minWidth="42.8%"
          maxWidth="42.8%"
          height="100%"
          id={idRight}
          overflow="hidden"
          bgcolor="#303642"
          color="white"
          fontSize={fontSizeRatio}
        >
          {renderedRight}
        </Box>
        <Box height="0.8em" width="100%" position="absolute" bottom={0} left={0} zIndex={1} bgcolor={savedCvColour} />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0008_BACK
            preview={preview}
            savedCvColour={savedCvColour}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </>
  );
};

export default PREMIUM_0008_BACK;
