import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AMobilePhone: FC<ISocialIcon> = ({
  fill = 'black',
  width = '8',
  height = '12',
  stroke = 'black',
  strokeWidth = '.01em',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.04581 6.75372C7.04581 7.34544 6.60045 7.82569 6.05025 7.82569H1.94975C1.40051 7.82569 0.954193 7.34544 0.954193 6.75372V3.40447C0.954193 2.81171 1.40051 2.33146 1.94975 2.33146H6.05025C6.60045 2.33146 7.04581 2.81171 7.04581 3.40447V6.75372ZM4.00047 11.0197C3.61187 11.0197 3.29831 10.6822 3.29831 10.2644C3.29831 9.84566 3.61187 9.50812 4.00047 9.50812C4.38715 9.50812 4.70265 9.84566 4.70265 10.2644C4.70265 10.6822 4.38715 11.0197 4.00047 11.0197ZM6.74859 1.02822V0.702125C6.74859 0.313549 6.45714 0 6.09643 0H5.99158C5.63183 0 5.34038 0.313549 5.34038 0.702125V1.023H1.30721C0.584834 1.023 0 1.65328 0 2.43043V10.5916C0 11.3697 0.584834 12 1.30721 12H6.69279C7.4142 12 8 11.3697 8 10.5916V2.43043C8 1.67411 7.44404 1.06051 6.74859 1.02822Z"
        stroke={stroke}
        fill={fill}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
