import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AInterest: FC<ISocialIcon> = ({ fill = 'black', width = '9', height = '9', stroke = 'black' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke={stroke}
        strokeWidth=".01em"
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.38972 0.274032C4.73582 -0.239381 3.8177 0.0215628 3.4063 0.619142C3.09275 1.07364 3 1.39767 3 1.39767C3 1.39767 2.90725 1.07364 2.59412 0.619142C2.18229 0.0215628 1.26419 -0.239381 0.610277 0.274032C-0.714983 1.31348 0.364793 3.15252 1.51307 4.01522C2.35928 4.64647 3 5 3 5C3 5 3.64072 4.64647 4.48693 4.01522C5.6352 3.15252 6.71498 1.31348 5.38972 0.274032Z"
      />
    </svg>
  );
};
