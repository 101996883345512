import pick from 'lodash/pick';
import get from 'lodash/get';
import { DependencyList, EffectCallback, useEffect, useRef } from 'react';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { Profile } from 'redux-saga/interfaces';
import { CvInfo } from 'components/organisms/Onboarding/interface';
import { SOCIAL_MEDIAS } from 'config/constants';

export const A4_HEIGHT_PX = 3508;
export const A4_WIDTH_PX = 2480;

export const useNonInitialEffect = (effect: EffectCallback, deps?: DependencyList) => {
  const initialRender = useRef(true);

  useEffect(() => {
    let effectReturns: void | (() => void | undefined) = () => {
      //
    };

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }

    if (effectReturns && typeof effectReturns === 'function') {
      return effectReturns;
    }

    return null;
  }, deps);
};

export const validateEmailCommon = (email) => {
  const regExEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regExEmail.test(String(email).toLowerCase())) return true;
  return false;
};

/**
 * @param charLimit Object containing size limit for each prop
 * @returns Function to validate prop value's length using charLimit
 */
export const validateMaxChar = (charLimit) => {
  return (prop, value) => {
    if (charLimit[prop] && value) {
      return charLimit[prop] < `${value}`.length;
    }
    return false;
  };
};

export const scrollIntoView = (id: string, yOffset = 0): void => {
  const e = document.getElementById(id);
  if (e) {
    e.scrollIntoView(true);
    window.scrollBy(0, yOffset);
  }
};

export const scrollToTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0);
  }
};

export const convertProfileDataToProfileInfo = (data: Profile): Profile => {
  return pick(data, [
    'avatar',
    'email',
    'first_name',
    'last_name',
    'language',
    'last_change_password',
    'mobile_number',
    'onboarding',
    'onboarding_urbancv',
    'phone_country_code',
    'pk',
    'referral_code',
    'skype',
    'social_login',
    'socket',
    'type',
    'username',
    'verified',
    'show_avatar',
    'show_proficiency',
  ]);
};

export const convertProfileDataToCvInfo = (data: Profile): CvInfo => {
  const countryCode = (data.mobile_number && isValidPhoneNumber(data.mobile_number)
    ? get(parsePhoneNumber(data.mobile_number), 'countryCallingCode', '')
    : '') as string;
  const socialMedia = [];
  const dateBirth = data.candidate.date_of_birth;

  if (data.candidate.linkedin) {
    socialMedia.push({
      type: SOCIAL_MEDIAS[0],
      link: data.candidate.linkedin,
    });
  }

  if (data.candidate.twitter) {
    socialMedia.push({
      type: SOCIAL_MEDIAS[1],
      link: data.candidate.twitter,
    });
  }

  if (data.candidate.facebook) {
    socialMedia.push({
      type: SOCIAL_MEDIAS[2],
      link: data.candidate.facebook,
    });
  }

  if (data.candidate.website) {
    socialMedia.push({
      type: SOCIAL_MEDIAS[3],
      link: data.candidate.website,
    });
  }

  if (data.candidate.instagram) {
    socialMedia.push({
      type: SOCIAL_MEDIAS[4],
      link: data.candidate.instagram,
    });
  }

  if (data.candidate.github) {
    socialMedia.push({
      type: SOCIAL_MEDIAS[5],
      link: data.candidate.github,
    });
  }

  if (data.candidate.dribbble) {
    socialMedia.push({
      type: SOCIAL_MEDIAS[6],
      link: data.candidate.dribbble,
    });
  }

  const result: CvInfo = {
    basicInfo: {
      avatar: data.candidate.avatar,
      first_name: data.candidate.first_name,
      last_name: data.candidate.last_name,
      role: data.candidate.role,
      date_of_birth: dateBirth ? new Date(dateBirth) : null,
      address: data.candidate.address,
      nationality: data.candidate.nationality,
      country: data.candidate.country,
      city: data.candidate.city,
      phone_country_code: countryCode,
      mobile_number: data.mobile_number,
      cv_email: data.candidate.cv_email,
      skype: data.skype,
      summary: data.candidate.summary,
      socialMedia,
      show_avatar: data.show_avatar,
      show_proficiency: data.show_proficiency,
    },
    experience: data.candidate.work_experiences,
    education: data.candidate.educations,
    reference: data.candidate.reference || [],
    skills: data.candidate.skill.map((e) => ({
      name: e.name,
      points: e.points,
      experience: e.experience,
    })),
    interest: data.candidate.interest || [],
    certifications: data.candidate.certifications || [],
    languages: data.candidate.languages || [],
    volunteers: data.candidate.volunteers || [],
  };

  return result;
};

export const downloadFile = (name, dataUrl, callback: Function) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = dataUrl;
  link.click();
  setTimeout(callback, 1000);
};

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const reduceImageSize = async (
  dataUrl,
  type,
  pages = 1,
  width = A4_WIDTH_PX,
  height = A4_HEIGHT_PX,
  maxSize = 2097152,
) => {
  const base64Length = dataUrl.length - (dataUrl.indexOf(',') + 1);
  const padding = dataUrl.charAt(dataUrl.length - 2) === '=' ? 2 : dataUrl.charAt(dataUrl.length - 1) === '=' ? 1 : 0;
  const fileSize = base64Length * 0.75 - padding;
  if (fileSize > maxSize) {
    const fullHeight = height * pages;
    const img = new Image();
    img.src = dataUrl;
    await img.decode();
    const exceedRatio = maxSize / fileSize;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = fullHeight;
    canvas.getContext('2d').drawImage(img, 0, 0, width, fullHeight);
    return canvas.toDataURL(`image/${type}`, exceedRatio);
  }
  return dataUrl;
};

export const uuid = () => {
  const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};

export const isOverflown = (element) => {
  return element ? element.scrollHeight > element.clientHeight : false;
};

/**
 * Splits image into multiple pages
 * @param dataUrl image data
 * @param pages number of pages
 * @param height height of page
 * @param width width of page
 * @returns array of images split into specified dimensions
 */
export const pageSplitter = async (dataUrl, pages, height = A4_HEIGHT_PX, width = A4_WIDTH_PX) => {
  const imgArray = [];
  const img = new Image();
  img.src = dataUrl;
  await img.decode();
  for (let i = 0; i < pages; i++) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    context.drawImage(img, 0, i * height, width, height, 0, 0, canvas.width, canvas.height);
    imgArray.push(canvas.toDataURL('image/jpeg'));
  }
  return imgArray;
};

export function validURL(str: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
}

export const getCurrentDomain = () => {
  if (typeof window !== 'undefined') {
    return window.origin;
  }
  return '';
};
