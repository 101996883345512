import { useEffect, useLayoutEffect, useState } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import { getLocalStorage } from 'utils/store';
import { OCVProps, previewType } from 'components/organisms/CV';
import { languageScale } from 'components/organisms/Onboarding/Additionals/OLanguage';
import { AppState } from 'redux-saga/interfaces';
import { getYearOrExample } from 'utils/date';
import config from 'config/common';
import { isOverflown, uuid, validURL } from './common';

export const getTime = ({
  from_year,
  to_year,
  finished,
}: {
  from_year: string;
  to_year: string;
  finished: boolean;
}) => {
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  const time = `${getYearOrExample(from_year)} - ${leaveDate}`;
  return { leaveDate, time };
};

export const getCVAvatar = (avatar = '', preview = previewType.cvDemoThumbnail) => {
  if (!avatar) return config.defaultCVImage;
  if (validURL(avatar) && preview === previewType.downloadCvImg) {
    return getLocalStorage('base64Avatar');
  }
  return avatar;
};

export const getLanguageLevel = (index: number) => {
  return get(languageScale, [index - 1, 'level'], '');
};

export const useCvOneLayout = ({ components, props }: { components: JSX.Element[]; props: OCVProps }) => {
  const { cvInfo, isActive = 0, isShowAvatar = true } = props;
  const {
    cvSettings: { currentCvColor, currentCvFontSize, currentCvFont },
  } = useSelector((state: AppState) => state);
  // all content to render
  const [content, setContent] = useState([]);
  // actual content rendered
  const [rendered, setRendered] = useState([]);
  const [isDone, setDone] = useState(false);
  const pageId = `FREE_${uuid()}`;

  useEffect(() => {
    setContent(components);
  }, []);

  useLayoutEffect(() => {
    if (!isDone && content.length >= 1) {
      const element = document.getElementById(pageId);
      if (!isOverflown(element)) {
        // there is still space to render more
        const currentContent = [...content];
        setRendered([...rendered, currentContent.shift()]);
        setContent(currentContent);
      } else {
        // content overflowed, remove the latest one added and pass it to the back page
        const currentRendered = [...rendered];
        const overflowedJsx = currentRendered.splice(-1, 1);
        setRendered(currentRendered);
        setContent([...overflowedJsx, ...content]);
        setDone(true);
      }
    } else if (content.length === 0 && rendered.length >= 1) {
      // all content has been rendered
      setDone(true);
    }
  }, [content]);

  useEffect(() => {
    setRendered([]);
    setContent(components);
    setDone(false);
  }, [cvInfo, isActive, isShowAvatar, currentCvColor, currentCvFontSize, currentCvFont]);

  const isShowOtherPage = isDone && !!content.length;

  return [isShowOtherPage, rendered, pageId, content] as const;
};

export const useCvOneLayoutBack = ({ breakComponent, props }: { breakComponent: JSX.Element; props: OCVProps }) => {
  const { preview, fontSizeRatio, overflowedContent, textSize } = props;
  const [content, setContent] = useState([]);
  const [rendered, setRendered] = useState([]);
  const [isDone, setDone] = useState(false);
  const pageId = `FREE_BACK-${uuid()}`;

  useEffect(() => {
    setContent(overflowedContent);
  }, [overflowedContent]);

  useLayoutEffect(() => {
    if (!isDone && content.length >= 1) {
      const element = document.getElementById(pageId);
      if (!isOverflown(element)) {
        // there is still space to render more
        const currentContent = [...content];
        setRendered([...rendered, currentContent.shift()]);
        setContent(currentContent);
      } else if (rendered.length > 1) {
        // content overflowed, remove the latest one added and pass it to the back page
        const currentRendered = [...rendered];
        const overflowedJsx = currentRendered.splice(-1, 1);
        setRendered(currentRendered);
        setContent([...overflowedJsx, ...content]);
        setDone(true);
      } else {
        // the item being rendered is too big to fit in a page and will keep being passed down to the next page
        // Replace it with warning text
        setRendered([breakComponent]);
        setDone(true);
      }
    } else if (content.length === 0 && rendered.length >= 1) {
      // all content has been rendered
      setDone(true);
    }
  }, [content]);

  const isShowOtherPage = isDone && !!content.length;

  return [isShowOtherPage, pageId, fontSizeRatio, preview, rendered, content, textSize] as const;
};

export const useCvTwoLayout = ({
  leftComponents,
  rightComponents,
  props,
}: {
  leftComponents: JSX.Element[];
  rightComponents: JSX.Element[];
  props: OCVProps;
}) => {
  const {
    cvSettings: { currentCvColor, currentCvFontSize, currentCvFont },
  } = useSelector((state: AppState) => state);
  const { cvInfo, isActive = 0, isShowAvatar = true } = props;
  const [contentLeft, setContentLeft] = useState([]);
  const [contentRight, setContentRight] = useState([]);
  const [renderedLeft, setRenderedLeft] = useState([]);
  const [renderedRight, setRenderedRight] = useState([]);
  const [isDoneLeft, setDoneLeft] = useState(false);
  const [isDoneRight, setDoneRight] = useState(true);

  const idLeft = `left-${uuid()}`;
  const idRight = `right-${uuid()}`;

  useEffect(() => {
    setContentLeft(leftComponents);
    setContentRight(rightComponents);
  }, []);

  useEffect(() => {
    setRenderedLeft([]);
    setRenderedRight([]);
    setContentLeft(leftComponents);
    setContentRight(rightComponents);
    setDoneLeft(false);
    setDoneRight(false);
  }, [cvInfo, isActive, isShowAvatar, currentCvColor, currentCvFontSize, currentCvFont]);

  useEffect(() => {
    const element = document.getElementById(idLeft);
    if ((!isDoneLeft && contentLeft.length >= 1) || isOverflown(element)) {
      if (!isOverflown(element)) {
        // there is still space to render more
        const currentContentLeft = [...contentLeft];
        setRenderedLeft([...renderedLeft, currentContentLeft.shift()]);
        setContentLeft(currentContentLeft);
      } else {
        // contentLeft overflowed, remove the latest one added and pass it to the back page
        const currentRendered = [...renderedLeft];
        const overflowedJsx = currentRendered.splice(-1, 1);
        setRenderedLeft(currentRendered);
        setContentLeft([...overflowedJsx, ...contentLeft]);
        setDoneLeft(true);
      }
    } else if (contentLeft.length === 0 && renderedLeft.length >= 1) {
      // all contentLeft has been rendered
      setDoneLeft(true);
    }
  }, [contentLeft]);

  useEffect(() => {
    const element = document.getElementById(idRight);
    if ((!isDoneRight && contentRight.length >= 1) || isOverflown(element)) {
      if (!isOverflown(element)) {
        // there is still space to render more
        const currentContentRight = [...contentRight];
        setRenderedRight([...renderedRight, currentContentRight.shift()]);
        setContentRight(currentContentRight);
      } else {
        // contentRight overflowed, remove the latest one added and pass it to the back page
        const currentRendered = [...renderedRight];
        const overflowedJsx = currentRendered.splice(-1, 1);
        setRenderedRight(currentRendered);
        setContentRight([...overflowedJsx, ...contentRight]);
        setDoneRight(true);
      }
    } else if (contentRight.length === 0 && renderedRight.length >= 1) {
      // all contentRight has been rendered
      setDoneRight(true);
    }
  }, [contentRight]);

  const isShowOtherPage = isDoneLeft && isDoneRight && (!!contentLeft.length || !!contentRight.length);

  return [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] as const;
};

export const useCvTwoLayoutBack = ({ breakComponent, props }: { breakComponent: JSX.Element; props: OCVProps }) => {
  const { preview, overflowedContentLeft, overflowedContentRight, savedCvColour, fontSizeRatio, textSize } = props;
  const [contentLeft, setContentLeft] = useState([]);
  const [renderedLeft, setRenderedLeft] = useState([]);
  const [isDoneLeft, setDoneLeft] = useState(!overflowedContentLeft.length);
  const idLeft = `CV_BACK_LEFT-${uuid()}`;
  const [contentRight, setContentRight] = useState([]);
  const [renderedRight, setRenderedRight] = useState([]);
  const [isDoneRight, setDoneRight] = useState(!overflowedContentRight.length);
  const idRight = `CV_BACK_RIGHT-${uuid()}`;

  useEffect(() => {
    setContentLeft(overflowedContentLeft);
  }, [overflowedContentLeft]);

  useEffect(() => {
    const element = document.getElementById(idLeft);

    if ((!isDoneLeft && contentLeft.length >= 1) || isOverflown(element)) {
      if (!isOverflown(element)) {
        // there is still space to render more
        const currentContentLeft = [...contentLeft];
        setRenderedLeft([...renderedLeft, currentContentLeft.shift()]);
        setContentLeft(currentContentLeft);
      } else if (renderedLeft.length > 1) {
        // contentLeft overflowed, remove the latest one added and pass it to the back page
        const currentRenderedLeft = [...renderedLeft];
        const overflowedJsx = currentRenderedLeft.splice(-1, 1);
        setRenderedLeft(currentRenderedLeft);
        setContentLeft([...overflowedJsx, ...contentLeft]);
        setDoneLeft(true);
      } else {
        // the item being renderedLeft is too big to fit in a page and will keep being passed down to the next page
        // Replace it with warning text
        setRenderedLeft([breakComponent]);
        setDoneLeft(true);
      }
    } else if (contentLeft.length === 0 && renderedLeft.length >= 1) {
      // all contentLeft has been renderedLeft
      setDoneLeft(true);
    }
  }, [contentLeft]);

  useEffect(() => {
    setContentRight(overflowedContentRight);
  }, [overflowedContentRight]);

  useEffect(() => {
    const element = document.getElementById(idRight);

    if ((!isDoneRight && contentRight.length >= 1) || isOverflown(element)) {
      if (!isOverflown(element)) {
        // there is still space to render more
        const currentContentRight = [...contentRight];
        setRenderedRight([...renderedRight, currentContentRight.shift()]);
        setContentRight(currentContentRight);
      } else if (renderedRight.length > 1) {
        // contentRight overflowed, remove the latest one added and pass it to the back page
        const currentRenderedRight = [...renderedRight];
        const overflowedJsx = currentRenderedRight.splice(-1, 1);
        setRenderedRight(currentRenderedRight);
        setContentRight([...overflowedJsx, ...contentRight]);
        setDoneRight(true);
      } else {
        // the item being renderedRight is too big to fit in a page and will keep being passed down to the next page
        // Replace it with warning text
        setRenderedRight([breakComponent]);
        setDoneRight(true);
      }
    } else if (contentRight.length === 0 && renderedRight.length >= 1) {
      // all contentRight has been renderedRight
      setDoneRight(true);
    }
  }, [contentRight]);

  const isShowOtherPage = isDoneLeft && isDoneRight && (!!contentLeft.length || !!contentRight.length);

  return [
    isShowOtherPage,
    renderedLeft,
    renderedRight,
    idLeft,
    idRight,
    contentLeft,
    contentRight,
    preview,
    savedCvColour,
    fontSizeRatio,
    textSize,
  ] as const;
};

export const useCVHelper = (props: OCVProps) => {
  const { t } = useTranslation('common');
  const {
    cvSettings: { currentCvFontSize, currentCvColor },
  } = useSelector((state: AppState) => state);
  const { textSize, savedCvFontSize, savedCvColour = null } = props;
  const fontSizeRatio = Math.round(((savedCvFontSize || currentCvFontSize) / 16) * textSize);
  const cvColor = savedCvColour || currentCvColor;
  return [fontSizeRatio, cvColor, t] as const;
};

export const useCVThreeLayout = (listItemCanChangedHeightOfDiv: unknown[]) => {
  const [mockHeight, setMockHeight] = useState(0);
  const mockId = uuid();
  useEffect(() => {
    const element = !!document && document.getElementById(mockId);
    setMockHeight(element ? element.offsetHeight : 0);
  }, [listItemCanChangedHeightOfDiv]);
  return [mockId, mockHeight] as const;
};
