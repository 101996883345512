import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { getCVAvatar, getLanguageLevel, useCVHelper, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import {
  ActiveColors,
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
  ProfileReference,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AAddress,
  ADribbble,
  AEmail,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  AWebsite,
  ISocialIcon,
} from 'components/atoms/icons';

import PREMIUM_0014_BACK from './back';
import { OCVProps, previewType } from '../../index';

const SkillItem: FC<{
  title: string;
  percent: number;
  color: string;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
}> = ({ title, percent, color, isShowProficiency, activeColors }) => {
  if (isShowProficiency) {
    return (
      <Box p=".4em 12% .4em 14%" display="flex" flexDirection="column" gridGap=".4em" {...activeColors}>
        <Box fontSize="0.5em" width="100%" className="u-text-uppercase">
          {title}
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          height=".45em"
          position="relative"
          border={`1px solid ${color}`}
        >
          <Box
            bgcolor={color}
            width={`calc(${percent}% + 2px)`}
            height="calc(100% + 2px)"
            position="absolute"
            top="-1px"
            left="-1px"
          />
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" p=".4em 12% .4em 14%" {...activeColors}>
      <Box bgcolor={color} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; color: string; activeColors: ActiveColors }> = ({
  cvExperience,
  color,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities, company, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box fontWeight={400} display="flex" alignItems="baseline" p=".6em .8em" {...activeColors}>
      <Box minWidth=".8em" minHeight=".8em" bgcolor={color} alignSelf="flex-start" />
      <Box m="0 .4em" minWidth="4em" display="flex" flexDirection="column" gridGap=".4em">
        <Box fontSize=".5em">{`${joinDate} - ${leaveDate}`}</Box>
        <Box fontSize=".5em">
          {company}, {location}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gridGap=".4em">
        <Box fontSize=".6em" fontWeight={700}>
          {role}
        </Box>
        <AQuillContent fontSize=".4em" content={activities} />
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; color: string; activeColors: ActiveColors }> = ({
  cvEducation,
  color,
  activeColors,
}) => {
  const { to_year, finished, from_year, activities, degree } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box fontWeight={400} display="flex" alignItems="baseline" p="0 1.3em .8em .8em" {...activeColors}>
      <Box minWidth=".8em" minHeight=".8em" bgcolor={color} alignSelf="flex-start" />
      <Box m="0 .4em" minWidth="4em">
        <Box fontSize=".5em">{`${joinDate} - ${leaveDate}`}</Box>
      </Box>
      <Box display="flex" flexDirection="column" gridGap=".4em">
        <Box fontSize=".6em">{degree}</Box>
        <AQuillContent fontSize=".4em" content={activities} />
      </Box>
    </Box>
  );
};

const UserCertification: FC<{ data?: ProfileCertificate; color: string; activeColors: ActiveColors }> = ({
  data,
  color,
  activeColors,
}) => {
  const { certificate, date, description } = data;
  const year = moment(date, 'YYYY-MM-DD').year();

  return (
    <Box fontWeight={400} display="flex" alignItems="baseline" p=".6em .8em" {...activeColors}>
      <Box minWidth=".8em" minHeight=".8em" bgcolor={color} alignSelf="flex-start" />
      <Box m="0 .4em" minWidth="4em">
        <Box whiteSpace="nowrap" fontSize=".5em">
          {year}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gridGap=".4em">
        <Box fontSize=".6em">{certificate}</Box>
        <AQuillContent fontSize=".4em" content={description} />
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ img: string; link: string; activeColors: ActiveColors }> = ({ img, link, activeColors }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    Instagram: AOutlinedInstagram,
    LinkedIn: AOutlinedLinkedin,
    Github: AGithub,
    Dribbble: ADribbble,
  };
  const svgIcons = () => {
    const SVG: FC<ISocialIcon> = componentIcons[img];
    return <SVG fill="#242427" stroke="" strokeWidth="0" width="100%" height="100%" />;
  };
  return (
    <Box display="flex" gridGap=".4em" alignItems="center" p=".4em 12% .4em 14%" {...activeColors}>
      <Box className="u-flex-center" width="1em" height="1em" bgcolor="white">
        <Box width="60%" height="100%">
          {img && svgIcons()}
        </Box>
      </Box>
      <Box fontWeight={700} width="100%" fontSize=".5em">
        {link}
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string; color: string; activeColors: ActiveColors }> = ({
  type,
  link,
  color,
  activeColors,
}) => {
  const componentIcons = {
    email: AEmail,
    phone: APhone,
    address: AAddress,
    Website: AWebsite,
  };
  const svgIcon = () => {
    const SVG = componentIcons[type];
    return <SVG fill={color} strokeWidth="0" width="1em" height="1em" />;
  };
  return (
    <Box display="flex" gridGap=".8em" alignItems="center" fontWeight={400} p=".4em 12% .4em 14%" {...activeColors}>
      <Box>{type && svgIcon()}</Box>
      <Box display="flex" flexDirection="column" gridGap=".3em" width="100%">
        <Box fontSize=".6em" fontWeight={700} className="u-text-uppercase">
          {type}
        </Box>
        <Box fontWeight={400} fontSize=".5em">
          {link}
        </Box>
      </Box>
    </Box>
  );
};

const SubTitle: FC<{ name: string; color: string; right?: boolean } & BoxProps> = ({
  name,
  color,
  right,
  ...props
}) => {
  if (right) {
    return (
      <Box width="100%" border={`1px solid ${color}`} p=".3em .8em" fontWeight={400}>
        <Box fontSize="1em">{name}</Box>
      </Box>
    );
  }
  return (
    <Box
      color="#242427"
      width="100%"
      fontWeight={400}
      bgcolor={color}
      p=".3em 0 .3em 2em"
      whiteSpace="nowrap"
      {...props}
    >
      <Box fontSize="1em">{name}</Box>
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage; activeColors: ActiveColors }> = ({
  language,
  activeColors,
}) => {
  const { name, speaking, writing } = language;
  return (
    <Box display="flex" justifyContent="space-between" p=".4em 12% .4em 14%" {...activeColors}>
      <Box fontWeight={700} flex={50} fontSize="0.5em">
        {name}
      </Box>
      <Box fontSize=".5em" flex={50} fontWeight={400}>
        Write: {getLanguageLevel(writing)}
        <br />
        Spoken: {getLanguageLevel(speaking)}
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  cvReference,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box display="flex" flexDirection="column" gridGap=".4em" minWidth="40%" p=".4em 12% .4em 14%" {...activeColors}>
      <Box display="flex" flexDirection="column" gridGap=".2em">
        <Box fontSize=".5em" fontWeight={700}>
          {name}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          {position}, {company}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gridGap=".2em">
        <Box fontSize=".5em" fontWeight={400}>
          <Box component="span" mr=".2em">
            Email:
          </Box>
          {email}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          <Box component="span" mr=".2em">
            Phone:
          </Box>
          {mobile}
        </Box>
      </Box>
    </Box>
  );
};

const PREMIUM_0014: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, certifications, languages, reference } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'KELLY WHITE';
  const userLastName = last_name || ' ';
  const userEmail = cv_email || 'contactme@email.com  ';

  const userRole = role || 'ART DIRECTOR';
  const userAddress = address || 'street name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';
  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'Chinese', speaking: 4, writing: 3 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(3).fill({
    school: 'University of Tech',
    degree: 'Bachelor Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(2).fill({
    skill: 'ADOBE PHOTOSHOP',
    name: 'ADOBE PHOTOSHOP',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'marksmith' },
    { type: 'Instagram', link: 'marksmith' },
    { type: 'LinkedIn', link: 'marksmith' },
    { type: 'Twitter', link: 'marksmith' },
  ];
  const pickWebsite = keyBy(userSocialMedia, 'type').Website;
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (pickWebsite) {
    contacts.push(pickWebsite);
  }

  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  let userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);
  const ignoredSocialMedia = ['Website'];
  userSocialMediaShowed = userSocialMediaShowed.filter((item) => !ignoredSocialMedia.includes(item.type));

  const dummyExperiences = Array(3).fill({
    role: 'Junior Data',
    company: 'Tech Company',
    location: 'Location',
    from_year: '01-01-2016',
    to_year: '01-01-2018',
    finished: false,
    activities: `Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;
  const dummyCertifications: ProfileCertificate[] = Array(3).fill({
    date: '2021-02-02',
    organizer: 'AWS',
    certificate: 'AWS Certification Professional',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium.',
  });
  const userCertifications = certifications ?? dummyCertifications;
  const dummyReference: ProfileReference[] = Array(1).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    company: 'Company / Organization Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e8e8e8', color: 'black !important' } : {};
  };

  const renderContacts = () => {
    if (isEmpty(contacts)) return [];
    return [
      <Box key="contact" p=".4em 12% .4em 14%" {...activeColors(1)}>
        <SubTitle name="CONTACT" textAlign="center" color={cvColor} right />
      </Box>,
      ...contacts.map(
        ({ link, type }, idx) =>
          !!link.length && (
            <UserContactsBox key={idx + link} color={cvColor} type={type} link={link} activeColors={activeColors(1)} />
          ),
      ),
    ];
  };

  const renderReferences = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box key="reference" p=".6em 12% .4em 14%" {...activeColors(5)}>
        <SubTitle right name="REFERENCES" color={cvColor} />
      </Box>,
      ...userReference.map((item, idx) => (
        <UserReference key={idx + item.company} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skills" p=".6em 12% .4em 14%" {...activeColors(4)}>
        <SubTitle right name="SKILLS" color={cvColor} />
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          isShowProficiency={isShowProficiency}
          key={`skill${index}${points}${name}`}
          title={name}
          percent={(points * 100) / 5}
          color={cvColor}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const renderLanguages = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="languages" p=".6em 12% .4em 14%" {...activeColors(5)}>
        <SubTitle right name="LANGUAGES" color={cvColor} />
      </Box>,
      ...userLanguages.map((item, idx) => (
        <UserLanguageInfo key={idx + item.name} language={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      ...userSocialMediaShowed.map(({ type, link }, idx) => (
        <UserSocialBox key={idx + type} img={type} link={link} activeColors={activeColors(1)} />
      )),
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experience" p=".8em 1.3em 0.42em .8em" {...activeColors(2)}>
        <SubTitle name="EXPERIENCE" color={cvColor} />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo
          key={`${item.companyInfo}${index}`}
          cvExperience={item}
          color={cvColor}
          activeColors={activeColors(2)}
        />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education" p="0 1.3em .8em .8em" {...activeColors(3)}>
        <SubTitle name="EDUCATION" color={cvColor} />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo
          key={`${item.school}${index}`}
          cvEducation={item}
          color={cvColor}
          activeColors={activeColors(3)}
        />
      )),
    ];
  };

  const renderCertification = () => {
    if (isEmpty(userCertifications)) return [];

    return [
      <Box key="certification" p=".8em 1.3em 0.42em .8em" {...activeColors(5)}>
        <SubTitle name="CERTIFICATION" color={cvColor} />
      </Box>,
      ...userCertifications.map((item, index) => (
        <UserCertification
          key={`${item.certificate}${index}`}
          data={item}
          color={cvColor}
          activeColors={activeColors(5)}
        />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderEducation(), ...renderExperience(), ...renderCertification()],
    rightComponents: [
      ...renderContacts(),
      ...renderSkills(),
      ...renderLanguages(),
      ...renderReferences(),
      ...renderSocialMedia(),
    ],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont}>
      <Box minHeight="54.164em" height="54.164em" display="flex" flexDirection="column" position="relative">
        <Box
          height="24%"
          position="absolute"
          p={isShowAvatar ? '.8em 0 0 .8em' : 0}
          display="flex"
          alignItems="center"
          width="100%"
        >
          {isShowAvatar && (
            <Box
              zIndex={2}
              minWidth="12.8em"
              height="12.8em"
              borderRadius="50%"
              bgcolor={isActive === 1 ? '#e8e8e8' : '#242427'}
              className="u-flex-center"
            >
              <img
                height="60%"
                width="60%"
                src={userAvatar}
                alt="profile-pict"
                className="u-border-rounded o-base-cv__avatar"
              />
            </Box>
          )}
          <Box
            ml={isShowAvatar ? '-1em' : 0}
            zIndex={1}
            width="100%"
            height="6.8em"
            bgcolor={isActive === 1 ? '#e8e8e8' : cvColor}
            color={isActive === 1 ? 'black' : 'white'}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            py=".8em"
            className="u-text-uppercase"
          >
            <Box ml="3em" fontWeight={700} mb=".4em">
              <Box fontSize="2em">
                {userFirstName} {userLastName}
              </Box>
            </Box>
            <Box ml="3em" fontWeight={400}>
              <Box fontSize="1.2em">{userRole}</Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flex={1} height="54.164em" maxHeight="100%" fontSize={fontSizeRatio}>
          <Box width="62%" borderBottom={`1.6em solid ${cvColor}`} id={idLeft}>
            <Box height="28%" />
            {renderedLeft}
          </Box>
          <Box width="38%" bgcolor="#242427" color="white" id={idRight} fontSize={fontSizeRatio}>
            <Box height="21%" />
            {renderedRight}
            <Box height="1em" />
          </Box>
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0014_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0014;
