import { FC } from 'react';
import Box from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, getLanguageLevel, useCVHelper, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import {
  ActiveColors,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
  ProfileReference,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  ADribbble,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  AWebsite,
} from 'components/atoms/icons';
import { ISocialIcon } from 'components/atoms/icons/ASkype';
import PREMIUM_0017_BACK from './back';
import { OCVProps, previewType } from '../../index';

const TopDecoration: FC<{ width: string; height: string; color: string }> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 181 171" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.041 18.3907C179.83 24.6923 178.609 31.4197 174.196 35.9006C167.8 42.3915 157.402 41.8591 148.928 45.1342C142.121 47.7823 136.394 53.162 133.308 59.8301C130.373 66.155 129.552 73.8164 124.482 78.557C118.989 83.7002 110.645 83.523 103.251 84.7761C95.8573 86.0413 87.4307 91.0898 88.182 98.5973C88.8155 104.886 95.3869 108.433 99.5891 113.127C106.736 121.096 107.042 133.699 102.371 143.346C97.6998 152.993 88.8974 160.04 79.4966 165.101C75.1305 167.453 70.4945 169.475 65.5888 170.101C53.5943 171.638 41.0721 163.599 37.3983 152C35.1102 144.776 35.5798 135.992 29.9578 130.944C25.2168 126.688 18.5028 127.149 12.1064 128.981L0 134.769V0H180.074L179.935 13.7205C180.088 15.2695 180.1 16.8546 180.041 18.3907Z"
        fill={color}
      />
    </svg>
  );
};

const SkillItem: FC<{
  title: string;
  point: number;
  theme: string;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
  fontSizeRatio: number;
}> = ({ title, point, theme, isShowProficiency, activeColors, fontSizeRatio }) => {
  if (isShowProficiency) {
    const size = Math.ceil(fontSizeRatio * 0.4);
    return (
      <Box px="8%" display="flex" alignItems="center" justifyContent="space-between" color="#272726" {...activeColors}>
        <Box py=".8em" fontSize=".5em">
          {title}
        </Box>
        <Box display="flex" gridGap=".36em" flexShrink="0">
          {Array.from(Array(5), (_, index) => (
            <Box
              key={index + title}
              borderRadius="50%"
              bgcolor={index + 1 <= parseFloat(`${point}`) ? theme : '#CBCCCB'}
              width={size}
              height={size}
              border={`1px solid ${theme}`}
            />
          ))}
        </Box>
      </Box>
    );
  }
  return (
    <Box p="0 8% .2em" display="flex" gridGap=".2em" alignItems="center" {...activeColors}>
      <Box bgcolor={theme} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; theme: string; activeColors: ActiveColors }> = ({
  cvExperience,
  theme,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities, company } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box p=".1em 8% .1em 0" color="#5B5C5C" fontSize=".6em" fontWeight={400} {...activeColors}>
      <Box display="flex" gridGap=".4em">
        <Box color={theme}>{role}</Box>
        <Box width=".1em" bgcolor="#4FADA9" />
        <Box>{`${joinDate} - ${leaveDate}`}</Box>
      </Box>
      <Box color="black">{company}</Box>
      <Box mt=".4em" mb=".8em">
        <AQuillContent color="#737874" content={activities} fontWeight={400} />
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; theme: string; activeColors: ActiveColors }> = ({
  cvEducation,
  theme,
  activeColors,
}) => {
  const { school, to_year, finished, from_year, degree } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box p=".4em 8% .4em 0" color="#5B5C5C" fontWeight="400" fontSize=".6em" {...activeColors}>
      <Box display="flex" gridGap=".4em">
        <Box color={theme}>{degree}</Box>
        <Box width=".1em" bgcolor="#4FADA9" />
        <Box>{`${joinDate} - ${leaveDate}`}</Box>
      </Box>
      <Box color="black" mt=".2em">
        {school}
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    LinkedIn: AOutlinedLinkedin,
    Instagram: AOutlinedInstagram,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG: FC<ISocialIcon> = componentIcons[type];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="60%" height="60%" />;
  };
  const username = link.substring(link.lastIndexOf('/') + 1);
  return (
    <Box display="flex" gridGap=".5em" alignItems="center">
      <Box flexShrink="0" borderRadius="50%" bgcolor="#000000" className="u-flex-center" width="1em" height="1em">
        {type && svgIcons()}
      </Box>
      <Box fontWeight={400} fontSize=".4em" whiteSpace="noWrap">
        {username}
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string; theme: string }> = ({ type, link, theme }) => {
  const label = {
    email: 'E-mail',
    phone: 'Phone Number',
    address: 'Address',
    Skype: 'Skype',
  };

  return (
    <Box mt=".6em" fontWeight={400} mb=".8em" fontSize=".6em" alignItems="center">
      <Box color={theme}>
        {type === 'email' && <Box>{label.email}</Box>}
        {type === 'phone' && <Box>{label.phone}</Box>}
        {type === 'address' && <Box>{label.address}</Box>}
        {type === 'Skype' && <Box>{label.Skype}</Box>}
      </Box>
      <Box className="u-word-break-all" dangerouslySetInnerHTML={{ __html: link }} />
    </Box>
  );
};

const SectionTitle: FC<{ label: string; theme }> = ({ label, theme }) => {
  return (
    <Box display="flex" gridGap="1px" pb=".4em">
      <Box width=".2em" bgcolor={theme} />
      <Box bgcolor={theme} color="#FFFFFF" padding=".2em 8% .2em .8em" borderRadius="0 10em 10em 0">
        {label}
      </Box>
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage }> = ({ language }) => {
  const { name, speaking, writing } = language;
  return (
    <Box fontWeight={400} fontSize=".6em" gridGap="2em" display="flex">
      <Box minWidth="max-content">{name}</Box>
      <Box display="flex" flexWrap="wrap" gridGap="0 2em">
        <Box>Written: {getLanguageLevel(writing)}</Box>
        <Box>Spoken: {getLanguageLevel(speaking)}</Box>
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; theme: string; activeColors: ActiveColors }> = ({
  cvReference,
  theme,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box
      p=".2em 8% .4em 0"
      fontSize=".6em"
      display="flex"
      flexDirection="column"
      gridGap=".6em"
      minWidth="40%"
      {...activeColors}
    >
      <Box display="flex" gridGap=".4em">
        <Box color={theme}>{name}</Box>
        <Box width=".1em" bgcolor="#4FADA9" />
        <Box>{`${position}, ${company}`}</Box>
      </Box>
      <Box>Phone Number: {mobile}</Box>
      <Box>Email: {email}</Box>
    </Box>
  );
};

const PREMIUM_0017: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;

  const { basicInfo, experience, education, skills, languages, reference } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    socialMedia,
    skype,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'HENRY SILLY';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userSkype = skype ?? 'username skype';
  const userAboutMe =
    summary ||
    `Remaining essentially unchanged. It was popularised in the printer took a galley of type and scrambled it to make a type printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown men book. It has survived not only ﬁve centuries, but also the leap`;

  const userRole = role || 'WEB DESIGN & DEVELOPER';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';

  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(3).fill({
    school: 'UNIVERSITY NAME',
    degree: 'Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities: 'quis pul vinar mellu Lorem ipsum dolor: sit amet consectet gelit. it ellentesque eleife ornare ',
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(6).fill({
    skill: 'PHP',
    name: 'PHP',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'facebook.com/username' },
    { type: 'Instagram', link: 'instagram.com/username' },
    { type: 'LinkedIn', link: 'linkedin.com/username' },
    { type: 'Twitter', link: 'twitter.com/username' },
    { type: 'Website', link: 'Henrysilly.com' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
    { type: 'Skype', link: userSkype },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(1).fill({
    role: 'Business Owner',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;
  const dummyReference: ProfileReference[] = Array(1).fill({
    name: 'Name Here',
    position: 'Job Title',
    company: 'Company Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e8e8e8' } : {};
  };

  const renderAvatar = () => {
    return [
      <Box key="avatar">
        {isShowAvatar && (
          <Box
            p=".6em"
            left="50%"
            top="3.8em"
            position="absolute"
            height="9.2em"
            minWidth="9.2em"
            maxWidth="9.2em"
            className="u-border-rounded"
            border={`1px solid ${cvColor}`}
            style={{ transform: 'translate(-50%, 0)' }}
            zIndex="100"
            bgcolor="white"
            {...activeColors(1)}
            fontSize={textSize}
          >
            <img
              height="100%"
              width="100%"
              src={userAvatar}
              alt="profile-pict"
              className="u-border-rounded o-base-cv__avatar"
            />
          </Box>
        )}
      </Box>,
    ];
  };

  const renderContact = () => {
    if (isEmpty(contacts)) return [];
    return [
      <Box key="contact" mt="18em" p="1em 8% .2em" bgcolor="white" {...activeColors(1)}>
        <SectionTitle label="Contact" theme={cvColor} />
        {contacts.map(
          (item, index) =>
            !!item.link.length && <UserContactsBox key={index} type={item.type} link={item.link} theme={cvColor} />,
        )}
      </Box>,
    ];
  };

  const renderLanguage = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="language" p=".4em 8%" {...activeColors(5)}>
        <SectionTitle label="Languages" theme={cvColor} />
        <Box mt=".4em" display="flex" flexDirection="column" gridGap=".8em">
          {userLanguages.map((item, index) => (
            <UserLanguageInfo key={`${item.name}${index}`} language={item} />
          ))}
        </Box>
      </Box>,
    ];
  };

  const renderSkill = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skills" p=".6em 8% .3em" bgcolor="white" {...activeColors(4)}>
        <SectionTitle label="Skills" theme={cvColor} />
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          key={`skill${name}${index}${points}`}
          title={name}
          point={points}
          isShowProficiency={isShowProficiency}
          theme={cvColor}
          fontSizeRatio={fontSizeRatio}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const renderProfile = () => {
    return [
      <Box
        key="profile"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p="1.6em 8% 0 0"
        {...activeColors(1)}
      >
        <Box textAlign="center" fontSize="3.2em" mt=".2em">
          <Box>{userFirstName}</Box>
          <Box color={cvColor}>{userLastName}</Box>
        </Box>
        <Box
          textAlign="center"
          className="u-text-uppercase"
          borderRadius="10em"
          bgcolor="rgba(79, 173, 169, .3)"
          p=".4em"
          minWidth="16.2em"
          fontSize={textSize}
        >
          {userRole}
        </Box>
        {!!userSocialMediaShowed.length && (
          <Box p=".6em 0" mt=".9em" display="flex" flexWrap="wrap" flexDirection="row" gridGap=".9em">
            {userSocialMediaShowed.map((item, idx) => (
              <UserSocialBox key={idx + item.link} type={item.type} link={item.link} />
            ))}
          </Box>
        )}
      </Box>,
    ];
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box key="summary" p=".2em 8% .6em 0em" {...activeColors(1)}>
        <Box fontSize="1.2em" fontWeight="400" lineHeight="1.4em" color={cvColor}>
          About me
        </Box>
        <Box mt=".7em">
          <AQuillContent content={userAboutMe} fontWeight={400} fontSize=".5em" />
        </Box>
      </Box>,
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education" p=".4em 8% .2em 0" {...activeColors(3)}>
        <SectionTitle label="Education" theme={cvColor} />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo
          key={`${item.school}${index}`}
          cvEducation={item}
          theme={cvColor}
          activeColors={activeColors(3)}
        />
      )),
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experience" mt=".8em" p=".4em 8% .4em 0" {...activeColors(2)}>
        <SectionTitle label="Experience" theme={cvColor} />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo
          key={`${item.companyInfo}${index}`}
          cvExperience={item}
          theme={cvColor}
          activeColors={activeColors(2)}
        />
      )),
    ];
  };

  const renderReference = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box key="reference" p=".4em 8% .2em 0" {...activeColors(5)}>
        <SectionTitle label="References" theme={cvColor} />
      </Box>,
      ...userReference.map((item, index) => (
        <UserReference
          key={`${item.position}${index}`}
          cvReference={item}
          theme={cvColor}
          activeColors={activeColors(5)}
        />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderAvatar(), ...renderContact(), ...renderLanguage(), ...renderSkill()],
    rightComponents: [
      ...renderProfile(),
      ...renderSummary(),
      ...renderEducation(),
      ...renderExperience(),
      ...renderReference(),
    ],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont}>
      <Box minHeight="54.164em" height="54.164em" position="relative" display="flex">
        <Box position="absolute" top={0} left={0} width="18em" height="17em">
          <TopDecoration color={cvColor} width="100%" height="100%" />
        </Box>
        <Box
          height="100%"
          overflow="hidden"
          fontSize={fontSizeRatio}
          id={idLeft}
          flex={1}
          position="relative"
          maxHeight="100%"
        >
          {renderedLeft}
          <Box height="1.6em" />
        </Box>
        <Box
          height="100%"
          overflow="hidden"
          minWidth="52%"
          maxWidth="52%"
          id={idRight}
          fontSize={fontSizeRatio}
          maxHeight="100%"
        >
          {renderedRight}
          <Box height="1.6em" />
        </Box>
        <Box width="100%" height="1.2em" bottom={0} position="absolute" bgcolor={cvColor} />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0017_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0017;
