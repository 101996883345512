import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AWebsite: FC<ISocialIcon> = ({
  fill = 'black',
  width = '6',
  height = '6',
  stroke = 'black',
  strokeWidth = '.01em',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke={stroke}
        fill={fill}
        strokeWidth={strokeWidth}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.31963 3.59755C4.35046 3.38678 4.35046 3.2068 4.35046 2.99747C4.35046 2.78814 4.31963 2.60818 4.31963 2.39741H5.33874C5.39894 2.57738 5.42976 2.78814 5.42976 2.99747C5.42976 3.2068 5.39894 3.41709 5.33874 3.59755H4.31963ZM3.77926 5.27458C3.95938 4.94543 4.11013 4.585 4.19923 4.1957H5.06951C4.80029 4.70531 4.31963 5.06574 3.77926 5.27458ZM3.68968 3.59755H2.30984C2.27902 3.38678 2.24964 3.2068 2.24964 2.99747C2.24964 2.78814 2.27902 2.60818 2.30984 2.39741H3.7205C3.74988 2.60818 3.77926 2.78814 3.77926 2.99747C3.77926 3.2068 3.7205 3.38678 3.68968 3.59755ZM3 5.39489C2.75919 5.0359 2.55017 4.64515 2.42976 4.1957H3.56975C3.44935 4.6158 3.24033 5.0359 3 5.39489ZM1.80029 1.79877H0.930005C1.23005 1.28916 1.67988 0.928729 2.22074 0.719882C2.04013 1.07839 1.88939 1.40946 1.80029 1.79877ZM0.930005 4.1957H1.80029C1.88939 4.585 2.04013 4.91608 2.22074 5.27458C1.67988 5.06574 1.19971 4.70531 0.930005 4.1957ZM0.689677 3.59755C0.629956 3.41709 0.600579 3.2068 0.600579 2.99747C0.600579 2.78814 0.629956 2.57738 0.689677 2.39741H1.70926C1.67988 2.60818 1.67988 2.78814 1.67988 2.99747C1.67988 3.2068 1.70926 3.38678 1.70926 3.59755H0.689677ZM3 0.600064C3.24033 0.958568 3.44935 1.34931 3.56975 1.79877H2.42976C2.55017 1.37867 2.75919 0.958568 3 0.600064ZM5.06951 1.79877H4.19923C4.11013 1.40946 3.95938 1.07839 3.77926 0.719882C4.31963 0.928729 4.80029 1.28916 5.06951 1.79877ZM3 0C1.35046 0 0 1.34931 0 2.99747C0 4.64514 1.35046 5.99495 3 5.99495C4.64906 5.99495 6 4.64514 6 2.99747C6 1.34931 4.64906 0 3 0Z"
      />
    </svg>
  );
};
