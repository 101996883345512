import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AEmail: FC<ISocialIcon> = ({
  fill = 'black',
  width = '8',
  height = '6',
  stroke = 'black',
  strokeWidth = '0',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.473668 0.333008L3.96743 3.28944L7.46119 0.333008H0.473668ZM0 0.730379V5.82636H7.93482V0.730379L4.16456 3.92211C4.05091 4.01827 3.88401 4.01827 3.77036 3.92211L0 0.730379Z"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
