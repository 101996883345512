import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';

import { isEmpty } from 'lodash';
import { getCVAvatar, getLanguageLevel, useCVThreeLayout, useCvTwoLayout, useCVHelper } from 'helpers';
import { getYearOrExample } from 'utils/date';
import {
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
  ProfileReference,
  ActiveColors,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AAddress,
  ADribbble,
  AEmail,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  ASkype,
  AWebsite,
} from 'components/atoms/icons';

import PREMIUM_0019_BACK from './back';
import { OCVProps, previewType } from '../../index';

const ProgressBox: FC<{ title: string; percent: number; theme: string; activeColors: ActiveColors }> = ({
  title,
  percent,
  theme,
  activeColors,
}) => {
  return (
    <Box pt=".8em" px="1.2em" {...activeColors}>
      <Box mb=".4em">
        <Box fontSize="0.5em" width="100%" className="u-text-uppercase">
          {title}
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        height=".45em"
        position="relative"
        border={`1px solid ${theme}`}
      >
        <Box
          bgcolor={theme}
          width={`calc(${percent}% + 2px)`}
          height="calc(100% + 2px)"
          position="absolute"
          top="-1px"
          left="-1px"
        />
      </Box>
    </Box>
  );
};
const SkillItem: FC<{
  title: string;
  point: number;
  theme: string;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
}> = ({ title, point, theme, isShowProficiency, activeColors }) => {
  if (isShowProficiency) {
    return <ProgressBox title={title} percent={(point * 100) / 5} theme={theme} activeColors={activeColors} />;
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" pt=".8em" px="1.2em" {...activeColors}>
      <Box bgcolor={theme} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities, company, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box display="flex" flexDirection="column" gridGap=".4em" pt=".8em" px="1.6em" {...activeColors}>
      <Box color="#484545" fontSize=".5em" fontWeight={400}>{`${joinDate} - ${leaveDate}`}</Box>
      <Box fontSize=".5em" fontWeight={400} color="#484545">
        {company} | {location}
      </Box>
      <Box fontWeight={600} fontSize=".6em" color="#212121">
        {role}
      </Box>
      <AQuillContent color="#757575" fontWeight={400} fontSize=".5em" content={activities} />
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, to_year, finished, from_year, degree, grade } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box fontWeight={400} display="flex" flexDirection="column" gridGap=".2em" pt=".8em" px="1.2em" {...activeColors}>
      <Box fontWeight={700} fontSize=".6em" color="#212121">
        {degree}
      </Box>
      <Box fontSize=".5em" color="#212121">
        {`${joinDate} - ${leaveDate}`} / {school}
      </Box>
      <Box fontSize=".5em" color="#212121">
        Grade: {grade}
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ img: string; link: string }> = ({ img, link }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    Instagram: AOutlinedInstagram,
    LinkedIn: AOutlinedLinkedin,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG = componentIcons[img];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="60%" height="60%" />;
  };
  return (
    <Box width="30%" display="flex" gridGap=".6em" alignItems="center" color="#272826">
      <Box borderRadius="50%" bgcolor="#272826" className="u-flex-center" width=".8em" height=".8em">
        <Box width="100%" height="100%" className="u-flex-center">
          {img && svgIcons()}
        </Box>
      </Box>
      <Box fontWeight={400} fontSize=".5em">
        {link}
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string; activeColors: ActiveColors }> = ({
  type,
  link,
  activeColors,
}) => {
  const componentIcons = {
    email: AEmail,
    phone: APhone,
    address: AAddress,
    Skype: ASkype,
  };
  const svgIcon = () => {
    const SVG = componentIcons[type];
    return <SVG fill="#132A39" strokeWidth="0" width=".8em" height=".8em" />;
  };
  return (
    <Box
      display="flex"
      color="#494946"
      gridGap=".8em"
      alignItems="center"
      fontWeight={400}
      px="1.2em"
      pt=".6em"
      {...activeColors}
    >
      <Box>{type && svgIcon()}</Box>
      <Box fontSize=".6em">{link}</Box>
    </Box>
  );
};

const SubTitle: FC<{ name: string } & BoxProps> = ({ name, ...props }) => {
  return (
    <Box color="#212121" fontSize=".8em" whiteSpace="nowrap" {...props} fontWeight={700}>
      {name}
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage; activeColors: ActiveColors }> = ({
  language,
  activeColors,
}) => {
  const { name, speaking, writing } = language;
  return (
    <Box
      fontWeight={400}
      color="#333333"
      display="flex"
      justifyContent="space-between"
      px="1.2em"
      pt=".6em"
      {...activeColors}
    >
      <Box fontSize=".6em" fontWeight={600} width="50%">
        {name}
      </Box>
      <Box fontSize=".5em" width="50%">
        Write: {getLanguageLevel(writing)} | Spoken: {getLanguageLevel(speaking)}
      </Box>
    </Box>
  );
};

const UserCertification: FC<{ data?: ProfileCertificate; activeColors: ActiveColors }> = ({ data, activeColors }) => {
  const { certificate, date, organizer, expiration_date, does_not_expire } = data;
  const joinDate = getYearOrExample(date);
  const leaveDate = does_not_expire ? 'Present' : getYearOrExample(expiration_date, '2020');

  return (
    <Box fontWeight={400} display="flex" flexDirection="column" gridGap=".2em" px="1.6em" pt=".8em" {...activeColors}>
      <Box fontWeight={700} fontSize=".6em" color="#333333">
        {certificate}
      </Box>
      <Box fontWeight={400} fontSize=".5em">
        {organizer} | {`${joinDate} - ${leaveDate}`}
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  cvReference,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box display="flex" flexDirection="column" gridGap=".2em" minWidth="40%" pt=".8em" px="1.2em" {...activeColors}>
      <Box fontSize=".6em" fontWeight={700}>
        {name}
      </Box>
      <Box fontSize=".5em" fontWeight={400} color="#484545">
        {position} | {company}
      </Box>
      <Box fontStyle="italic" fontSize=".5em" fontWeight={400} color="#484545">
        <Box component="span" mr=".2em">
          Phone:
        </Box>
        {mobile}
      </Box>
      <Box fontStyle="italic" fontSize=".5em" fontWeight={400} color="#484545">
        <Box component="span" mr=".2em">
          Email:
        </Box>
        {email}
      </Box>
    </Box>
  );
};

const PREMIUM_0019: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, certifications, languages, reference } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    skype,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'Letha Schimel';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userSkype = skype ?? 'username skype';
  const userAboutMe =
    summary ??
    `Viverra augue nisi, vestibulum sit nec. Facilisi duis a, vitae pellentesque nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. `;

  const userRole = role || 'WEB DEVELOPER';
  const userAddress = address || '123 Street Name, State/City';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';

  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University of Tech',
    degree: 'Bachelor Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'PHP',
    name: 'PHP',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'username facebook' },
    { type: 'Instagram', link: 'username instagram' },
    { type: 'LinkedIn', link: 'username linkedin' },
    { type: 'Twitter', link: 'username twitter' },
    { type: 'Website', link: 'www.website.com' },
    { type: 'Github', link: 'username github' },
    { type: 'Dribbble', link: 'username dribbble' },
  ];
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'Skype', link: userSkype },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(3).fill({
    role: 'Junior Data',
    company: 'Tech Company',
    location: 'Location',
    from_year: '01-01-2016',
    to_year: '01-01-2018',
    finished: false,
    activities: `Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;
  const dummyCertifications: ProfileCertificate[] = Array(4).fill({
    certificate_id: 'Certification ID',
    date: '2021-02-02',
    organizer: 'AWS',
    certificate: 'AWS Certification Professional',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium.',
  });
  const userCertifications = certifications ?? dummyCertifications;
  const dummyReference: ProfileReference[] = Array(1).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    company: 'Company / Organization Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e8e8e8' } : {};
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];

    return [
      <Box key="summary" p=".8em 1.6em" {...activeColors(1)}>
        <SubTitle name="ABOUT ME" mb=".8em" />
        <AQuillContent content={userAboutMe} fontWeight={400} fontSize=".5em" />
      </Box>,
    ];
  };

  const renderName = () => {
    return [
      <Box key="name" pr="1.6em" {...activeColors(1)}>
        <Box p=".8em 1.6em" display="flex" alignItems="center" gridGap="1em" bgcolor={cvColor} {...activeColors(1)}>
          {isShowAvatar && (
            <Box height="4em" minWidth="4em" maxWidth="4em">
              <img
                height="100%"
                width="100%"
                src={userAvatar}
                alt="profile-pict"
                className="u-border-rounded o-base-cv__avatar"
              />
            </Box>
          )}
          <Box>
            <Box fontSize="1.2em" fontWeight={400} color="white">{`${userFirstName} ${userLastName}`}</Box>
            <Box color="white" fontSize=".5em" fontWeight={400} p=".4em 0 1.6em 0">
              {userRole}
            </Box>
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];

    return [
      <Box key="experience" mt=".8em" px="1.6em" {...activeColors(2)}>
        <SubTitle name="WORK EXPERIENCE" />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`experience${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderCertificate = () => {
    if (isEmpty(userExperiences)) return [];

    return [
      <Box key="certificate" p=".4em 1.6em 0" {...activeColors(5)} mt=".8em">
        <SubTitle name="CERTIFICATION" />
      </Box>,
      ...userCertifications.map((item, index) => (
        <UserCertification key={`${item.certificate}${index}`} data={item} activeColors={activeColors(5)} />
      )),
    ];
  };
  const renderContact = () => {
    if (isEmpty(contacts)) return [];
    return [
      <Box key="contact" p=".8em 1.2em 0" {...activeColors(1)}>
        <SubTitle name="CONTACT" />
      </Box>,
      ...contacts.map(
        (item, idx) =>
          !!item.link.length && (
            <UserContactsBox key={idx} type={item.type} link={item.link} activeColors={activeColors(1)} />
          ),
      ),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education" p=".4em 1.2em 0" {...activeColors(3)}>
        <SubTitle name="EDUCATION" />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderSkill = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skill" p=".2em 1.2em 0" mt=".2em" {...activeColors(4)}>
        <SubTitle name="SKILLS" />
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          isShowProficiency={isShowProficiency}
          key={`skill${name}${index}${points}`}
          title={name}
          point={points}
          theme={cvColor}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const renderLanguage = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="language" p=".2em 1.2em .2em" mt=".2em" {...activeColors(5)}>
        <SubTitle name="LANGUAGE" />
      </Box>,
      ...userLanguages.map((item, index) => (
        <UserLanguageInfo key={`${item.name}${index}`} language={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderReference = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box key="reference" p=".2em 1.2em 0" mt=".2em" {...activeColors(5)}>
        <SubTitle name="REFERENCES" />
        <Box display="flex" flexDirection="column" gridGap=".4em" />
      </Box>,
      ...userReference.map((item, idx) => (
        <UserReference key={`reference${idx}`} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];

    return [
      <Box key="socialMedia" display="flex" p=".4em 1.6em" flexWrap="wrap" gridGap=".4em" {...activeColors(1)}>
        {userSocialMediaShowed.map((item, idx) => (
          <UserSocialBox key={idx} img={item.type} link={item.link} />
        ))}
      </Box>,
    ];
  };

  const [mockId, mockHeight] = useCVThreeLayout([userSocialMediaShowed]);

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    rightComponents: [
      ...renderContact(),
      ...renderEducation(),
      ...renderSkill(),
      ...renderLanguage(),
      ...renderReference(),
    ],
    leftComponents: [...renderSummary(), ...renderName(), ...renderExperience(), ...renderCertificate()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont} overflow="hidden">
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        color="#71726E"
        minHeight="54.164em"
        maxHeight="54.164em"
      >
        {!!mockHeight && (
          <Box>
            <Box display="flex" flex={1} height={`calc(54.164em - ${mockHeight}px)`}>
              <Box width="58%" id={idLeft} height="100%" maxHeight="100%" fontSize={fontSizeRatio || 'inherit'}>
                {renderedLeft}
                <Box height="1.2em" />
              </Box>
              <Box id={idRight} height="100%" maxHeight="100%" fontSize={fontSizeRatio || 'inherit'} width="42%">
                {renderedRight}
                <Box height="1.2em" />
              </Box>
            </Box>
          </Box>
        )}
        <Box id={mockId}>
          {renderSocialMedia()}
          <Box height="1.2em" />
        </Box>
      </Box>

      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={800}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0019_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0019;
