import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';

import { getCVAvatar, getLanguageLevel, useCvTwoLayout, useCVHelper } from 'helpers';
import { getYearOrExample } from 'utils/date';
import {
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
  ProfileReference,
  ActiveColors,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AAddress,
  ADribbble,
  AEmail,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  ASkype,
  AWebsite,
} from 'components/atoms/icons';

import PREMIUM_0021_BACK from './back';
import { OCVProps, previewType } from '../../index';

const ProgressBox: FC<{ title: string; percent: number; theme: string; activeColors: ActiveColors }> = ({
  title,
  percent,
  theme,
  activeColors,
}) => {
  return (
    <Box {...activeColors} px="8%" pb=".8em">
      <Box mb=".4em">
        <Box fontSize="0.5em" width="100%" className="u-text-uppercase">
          {title}
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        height=".45em"
        position="relative"
        border={`1px solid ${theme}`}
      >
        <Box
          bgcolor={theme}
          width={`calc(${percent}% + 2px)`}
          height="calc(100% + 2px)"
          position="absolute"
          top="-1px"
          left="-1px"
        />
      </Box>
    </Box>
  );
};

const SkillItem: FC<{
  title: string;
  point: number;
  theme: string;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
}> = ({ title, point, theme, isShowProficiency, activeColors }) => {
  if (isShowProficiency) {
    return <ProgressBox theme={theme} title={title} percent={(point * 100) / 5} activeColors={activeColors} />;
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" {...activeColors} px="8%" pb=".8em">
      <Box bgcolor={theme} width=".2em" height=".1em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities, company, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      gridGap=".4em"
      px="8%"
      pb=".8em"
      {...activeColors}
    >
      <Box
        color="#484545"
        fontSize=".5em"
        fontWeight={400}
      >{`${joinDate} - ${leaveDate} | ${company} | ${location}`}</Box>
      <Box color="#484545" fontSize=".5em" fontWeight={400}>{`${company}, ${location}`}</Box>
      <Box color="#484545" fontSize=".5em" fontWeight={700}>
        {role}
        <Box width="100%" mb=".3em" mt=".1em" borderBottom=".15em solid" borderColor="white" />
      </Box>
      <AQuillContent color="#757575" textAlign="start" fontWeight={400} fontSize=".5em" content={activities} />
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, to_year, finished, from_year, degree } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box
      fontWeight={400}
      display="flex"
      flexDirection="column"
      alignItems="end"
      gridGap=".2em"
      px="8%"
      pb=".8em"
      {...activeColors}
    >
      <Box display="flex" flexDirection="column" width="100%" justifyContent="flex-start" alignItems="flex-start">
        <Box fontWeight={700} fontSize=".6em" color="#484545">
          {degree}
        </Box>
        <Box fontSize=".5em" color="#484545">
          {`${joinDate} - ${leaveDate}`}
        </Box>
        <Box fontSize=".5em" color="#484545">
          {school}
        </Box>
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ img: string; link: string; type: string }> = ({ img, link }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    Instagram: AOutlinedInstagram,
    LinkedIn: AOutlinedLinkedin,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG = componentIcons[img];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="80%" height="80%" />;
  };
  return (
    <Box width="calc(50% - .4em)" display="flex" gridGap=".8em" alignItems="center" mb=".3em">
      <Box borderRadius="50%" bgcolor="#272826" className="u-flex-center" width=".5em" height=".5em">
        <Box width="100%" height="100%" className="u-flex-center">
          {img && svgIcons()}
        </Box>
      </Box>
      <Box>
        <Box fontWeight={400} fontSize=".5em">
          {link}
        </Box>
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const componentIcons = {
    email: AEmail,
    phone: APhone,
    address: AAddress,
    Skype: ASkype,
  };
  const svgIcon = () => {
    const SVG = componentIcons[type];
    return <SVG fill="#132A39" strokeWidth="0" width=".5em" height=".5em" />;
  };
  return (
    <Box display="flex" color="#494946" height="25%" gridGap=".2em" alignItems="center" fontWeight={400}>
      <Box>{type && svgIcon()}</Box>
      <Box fontSize=".5em">{link}</Box>
    </Box>
  );
};

const SubTitle: FC<{ name: string } & BoxProps> = ({ name, ...props }) => {
  return (
    <Box fontSize=".8em" whiteSpace="nowrap" {...props} fontWeight={700}>
      {name}
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage; activeColors: ActiveColors }> = ({
  language,
  activeColors,
}) => {
  const { name, speaking, writing } = language;
  return (
    <Box
      fontWeight={400}
      color="#ffffff"
      display="flex"
      gridGap={2}
      flexDirection="row"
      justifyContent="space-between"
      px="8%"
      pb=".6em"
      {...activeColors}
    >
      <Box fontSize=".6em" fontWeight={600} whiteSpace="pre">
        {name}
      </Box>
      <Box display="flex" alignItems="center">
        <Box fontSize=".5em" mr=".2em">
          Write: {getLanguageLevel(writing)} {'| '}
        </Box>
        <Box fontSize=".5em">Spoken: {getLanguageLevel(speaking)}</Box>
      </Box>
    </Box>
  );
};

const UserCertification: FC<{ data?: ProfileCertificate; activeColors: ActiveColors }> = ({ data, activeColors }) => {
  const { certificate, date, organizer, expiration_date, does_not_expire } = data;
  const joinDate = getYearOrExample(date);
  const leaveDate = does_not_expire ? 'Present' : getYearOrExample(expiration_date, '2020');
  return (
    <Box fontWeight={400} display="flex" flexDirection="column" gridGap=".2em" px="8%" pb=".8em" {...activeColors}>
      <Box fontWeight={400} fontSize=".5em">
        {organizer} | {`${joinDate} - ${leaveDate}`}
      </Box>
      <Box fontWeight={700} fontSize=".6em" color="#333333">
        {certificate}
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  activeColors,
  cvReference,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box display="flex" flexDirection="column" gridGap=".2em" minWidth="40%" px="8%" pb=".8em" {...activeColors}>
      <Box fontSize=".6em" fontWeight={700}>
        {name}
      </Box>
      <Box fontSize=".5em" fontWeight={400}>
        {position} | {company}
      </Box>

      <Box fontStyle="italic" fontSize=".5em" fontWeight={400}>
        <Box component="span" mr=".2em">
          Phone:
        </Box>
        {mobile}
      </Box>
      <Box fontStyle="italic" fontSize=".5em" fontWeight={400}>
        <Box component="span" mr=".2em">
          Email:
        </Box>
        {email}
      </Box>
    </Box>
  );
};

const PREMIUM_0021: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, certifications, languages, reference, interest } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    skype,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'ADAM ZULAUF';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userSkype = skype ?? 'username skype';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie sed risus porta in elit rhoncus.
    Purus scelerisque curabitur enim in turpis. Sed condimentum eu diam lorem. Text of the printing and typesetting industry. Molestie sed risus porta in elit rhoncus.`;

  const userRole = role || 'DIRECTOR OF MARKETING';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';

  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(3).fill({
    school: 'University of Tech',
    degree: 'Bachelor Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(4).fill({
    skill: 'Javascript',
    name: 'Javascript',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;
  const userInterest = interest ?? [
    'Project Management',
    'Graphic Design',
    'Computer Language',
    'Mobile & Cloud Solution',
    'Event Planning',
  ];
  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'facebook' },
    { type: 'Instagram', link: 'instagram' },
    { type: 'LinkedIn', link: 'linkedin' },
    { type: 'Twitter', link: 'twitter' },
    { type: 'Github', link: 'github' },
  ];
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'Skype', link: userSkype },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(3).fill({
    role: 'Junior Data',
    company: 'Tech Company',
    location: 'Location',
    from_year: '01-01-2016',
    to_year: '01-01-2018',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyCertifications: ProfileCertificate[] = Array(2).fill({
    certificate_id: 'Certification ID',
    date: '2021-02-02',
    organizer: 'AWS',
    certificate: 'AWS Certification Professional',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium.',
  });
  const userCertifications = certifications ?? dummyCertifications;

  const dummyReference: ProfileReference[] = Array(1).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    company: 'Company / Organization Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e8e8e8', color: 'black' } : {};
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box key="summary" {...activeColors(1)}>
        <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start" width="100%" p="1em 8%">
          <SubTitle name="About Me" fontWeight={700} />
          <Box width="100%" mb=".5em" mt=".1em" borderBottom=".15em solid" />
          <AQuillContent textAlign="left" content={userAboutMe} fontWeight={400} lineHeight="1.5em" fontSize=".5em" />
        </Box>
        <Box borderBottom=".3em solid" color="gray" />
      </Box>,
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];

    return [
      <Box key="skills" {...activeColors(4)}>
        <Box width="100%" p="1em 8% .5em">
          <SubTitle name="Skills" fontWeight={700} />
          <Box width="100%" mt=".1em" borderBottom=".15em solid" borderColor="white" />
        </Box>
      </Box>,
      ...userSkills.map(({ name, points }, idx) => (
        <SkillItem
          isShowProficiency={isShowProficiency}
          key={`skill${name}${idx}${points}`}
          title={name}
          point={points}
          theme={cvColor}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const renderInterests = () => {
    if (isEmpty(userInterest)) return [];

    return [
      <Box key="interests" {...activeColors(5)} mt=".2em">
        {userSkills && <Box borderBottom=".3em solid" color="gray" />}
        <Box width="100%" p="1em 8%">
          <SubTitle name="Interests" fontWeight={700} />
          <Box width="100%" mb=".5em" mt=".1em" borderBottom=".15em solid" borderColor="white" />
          <Box
            fontWeight={400}
            flexWrap="wrap"
            alignItems="center"
            mt=".8em"
            display="flex"
            gridGap=".4em"
            justifyContent="space-between"
          >
            {userInterest.map((item, index) => (
              <Box width="45%" key={index} display="flex" gridGap=".5em" alignItems="center">
                <Box bgcolor="#fff" width=".2em" minWidth=".2em" height=".2em" borderRadius="50%" />
                <Box fontSize=".4em">{item}</Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderLanguages = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="languages" {...activeColors(5)}>
        <Box borderBottom=".3em solid" color="gray" />
        <Box width="100%" p="1em 8% .5em">
          <SubTitle name="Languages" fontWeight={700} />
          <Box width="100%" mt=".1em" borderBottom=".15em solid" borderColor="white" />
        </Box>
      </Box>,
      ...userLanguages.map((item, index) => (
        <UserLanguageInfo key={`${item.name}${index}`} language={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderReferences = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box key="reference" {...activeColors(5)} pt=".4em">
        <Box borderBottom=".3em solid" color="gray" />
        <Box width="100%" p="1em 8% .8em">
          <SubTitle name="References" fontWeight={700} />
          <Box width="100%" mt=".1em" borderBottom=".15em solid" borderColor="white" />
        </Box>
      </Box>,
      ...userReference.map((item, index) => (
        <UserReference key={`${item.position}${index}`} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box key="socialMedia" {...activeColors(1)} mt=".8em">
        <Box borderBottom=".3em solid" color="gray" />

        <Box display="flex" width="100%" p="1em 8%" justifyContent="space-between" flexWrap="wrap" gridGap=".4em">
          {userSocialMediaShowed.map((item, idx) => (
            <UserSocialBox key={idx + item.type} type={item.type} img={item.type} link={item.link} />
          ))}
        </Box>
      </Box>,
    ];
  };

  const renderExperiences = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experience" {...activeColors(2)}>
        <Box p="1em 8% .4em">
          <Box>
            <SubTitle name="Work Experience" color="#484545" />
            <Box width="100%" mb=".5em" mt=".1em" borderBottom=".15em solid" borderColor="white" />
          </Box>
        </Box>
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`${item.companyInfo}${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education" {...activeColors(3)} mt=".2em" pb=".5em">
        <Box px="8%">
          <Box>
            <SubTitle name="Education" color="#484545" />
            <Box width="100%" mb=".5em" mt=".1em" borderBottom=".15em solid" borderColor="white" />
          </Box>
        </Box>
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderCertifications = () => {
    if (isEmpty(userCertifications)) return [];
    return [
      <Box key="certification" {...activeColors(5)}>
        <Box p="1em 8% .5em">
          <SubTitle name="Certification" color="#484545" />
          <Box width="100%" mt=".1em" borderBottom=".15em solid" borderColor="white" />
        </Box>
      </Box>,
      ...userCertifications.map((item, index) => (
        <UserCertification key={index} data={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [
      ...renderSummary(),
      ...renderSkills(),
      ...renderInterests(),
      ...renderLanguages(),
      ...renderReferences(),
      ...renderSocialMedia(),
    ],
    rightComponents: [...renderExperiences(), ...renderEducation(), ...renderCertifications()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont}>
      <Box
        minHeight="54.164em"
        height="54.164em"
        width="100%"
        display="flex"
        flexDirection="column"
        position="relative"
        color="#71726E"
        className="o-premium-0021"
      >
        <Box display="flex" flex={1} maxHeight="100%" height="100%">
          <Box width="50%" maxHeight="100%" height="100%" bgcolor="#171818" id={idLeft}>
            <Box mt="1.4em" position="absolute" height="4em" width="100%" fontSize={textSize} {...activeColors(1)}>
              <Box display="flex" alignItems="center" height="4em" gridGap="1em">
                <Box
                  bgcolor={cvColor}
                  width="55%"
                  height="4em"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  p="0 8%"
                  {...activeColors(1)}
                >
                  <Box fontSize="1.2em" fontWeight={700} color="#212121">{`${userFirstName} ${userLastName}`}</Box>
                  <Box color="#212121" fontSize=".5em" fontWeight={700} mt="0.3em">
                    {userRole}
                  </Box>
                </Box>
                {isShowAvatar && (
                  <Box bgcolor="#171818" p="0.5em">
                    <Box height="3em" minWidth="3em" maxWidth="4em">
                      <img
                        height="100%"
                        width="100%"
                        src={userAvatar}
                        alt="profile-pict"
                        className="u-border-rounded o-base-cv__avatar"
                      />
                    </Box>
                  </Box>
                )}
                {!!UserContactsBox.length && (
                  <Box height="4em" {...activeColors(1)}>
                    <Box display="flex" flexDirection="column" flex={1} height="100%" justifyContent="center">
                      {contacts.map(
                        (item, idx) =>
                          !!item.link.length && <UserContactsBox key={idx} type={item.type} link={item.link} />,
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box height="5.5em" />
            <Box color="#FFFFFF" fontSize={fontSizeRatio}>
              {renderedLeft}
              <Box height="1em" />
            </Box>
          </Box>

          <Box width="50%" id={idRight} maxHeight="100%" height="100%">
            <Box height="5.5em" />
            <Box fontSize={fontSizeRatio}>{renderedRight}</Box>
            <Box height="1em" />
          </Box>
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0021_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0021;
