import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, getLanguageLevel, useCVHelper, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import {
  ActiveColors,
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
  ProfileReference,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AAddress,
  ADribbble,
  AEmail,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  ASkype,
  AWebsite,
} from 'components/atoms/icons';

import PREMIUM_0022_BACK from './back';
import { OCVProps, previewType } from '../../index';

const SkillItem: FC<{
  title: string;
  point: number;
  theme: string;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
}> = ({ title, point, theme, isShowProficiency, activeColors }) => {
  if (isShowProficiency) {
    const width = `${(point * 100) / 5}%`;
    return (
      <Box p=".5em 8%" {...activeColors}>
        <Box mb=".4em">
          <Box fontSize="0.5em" width="100%" className="u-text-uppercase">
            {title}
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          height=".3em"
          minHeight="3px"
          position="relative"
          border="1px solid #3e3e3e"
        >
          <Box
            bgcolor="#3e3e3e"
            width={`calc(${width} + 2px)`}
            height="calc(100% + 2px)"
            position="absolute"
            top="-1px"
            left="-1px"
          />
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" {...activeColors} p=".5em 8%">
      <Box bgcolor={theme} width=".2em" height=".1em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities, company, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      gridGap=".2em"
      p=".5em 8% .5em 12%"
      {...activeColors}
    >
      <Box color="#484545" fontSize=".5em" fontWeight={400}>{`${joinDate} - ${leaveDate}`}</Box>
      <Box color="#484545" fontSize=".5em" fontWeight={700} className="u-text-uppercase" width="100%">
        {role}
      </Box>
      <Box color="#484545" fontSize=".5em" fontWeight={400} mb=".2em">{`${company}, ${location}`}</Box>
      <AQuillContent
        color="#757575"
        textAlign="start"
        fontWeight={400}
        fontSize=".5em"
        content={activities}
        mt=".2em"
        pl="4%"
      />
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, to_year, finished, from_year, degree } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box fontWeight={400} p=".2em 8% .2em 12%" {...activeColors} display="flex" flexDirection="column" gridGap="1px">
      <Box fontSize=".5em" color="#484545">
        {`${joinDate} - ${leaveDate}`}
      </Box>
      <Box fontWeight={700} fontSize=".6em" color="#484545">
        {degree}
      </Box>
      <Box fontSize=".5em" color="#484545">
        {school}
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ img: string; link: string; type: string; activeColors: ActiveColors }> = ({
  img,
  link,
  activeColors,
}) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    Instagram: AOutlinedInstagram,
    LinkedIn: AOutlinedLinkedin,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG = componentIcons[img];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="80%" height="80%" />;
  };
  return (
    <Box width="50%" display="inline-flex" gridGap=".5em" alignItems="center" {...activeColors} p=".4em 8%">
      <Box borderRadius="50%" bgcolor="#272826" className="u-flex-center" width=".5em" height=".5em">
        <Box width="100%" height="100%" className="u-flex-center">
          {img && svgIcons()}
        </Box>
      </Box>
      <Box>
        <Box fontWeight={400} fontSize=".5em">
          {link}
        </Box>
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string; activeColors: ActiveColors }> = ({
  type,
  link,
  activeColors,
}) => {
  const componentIcons = {
    email: <AEmail fill="black" strokeWidth="0" width=".5em" height=".5em" />,
    phone: <APhone fill="black" strokeWidth="0" width=".5em" height=".5em" />,
    address: <AAddress fill="black" strokeWidth="0" width=".5em" height=".5em" pl="0" />,
    Skype: <ASkype fill="black" strokeWidth="0" width=".5em" height=".5em" />,
  };
  return (
    <Box px="8%" {...activeColors}>
      <Box display="flex" gridGap=".3em" alignItems="center" fontWeight={400}>
        <Box>{type && componentIcons[type]}</Box>
        <Box fontSize=".5em">{link}</Box>
      </Box>
      <Box height="2px" bgcolor="#3e3e3e" />
    </Box>
  );
};

const SubTitleLeft: FC<{ name: string; activeColors: ActiveColors; bgText: string } & BoxProps> = ({
  name,
  activeColors,
  bgText,
  ...props
}) => {
  return (
    <Box fontSize=".8em" whiteSpace="nowrap" position="relative" fontWeight={600} {...props} {...activeColors}>
      <Box color="transparent">{name}</Box>
      <Box
        bgcolor={bgText}
        height=".4em"
        position="absolute"
        top="50%"
        left="50%"
        className="u-center-content u-text-uppercase"
        width="100%"
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        px=".4em"
        bgcolor="#F8F8F8"
        minWidth="50%"
        textAlign="center"
        className="u-center-content u-text-uppercase"
        letterSpacing="1px"
        color="black"
      >
        {name}
      </Box>
    </Box>
  );
};

const SubTitleRight: FC<{ name: string } & BoxProps> = ({ name, ...props }) => {
  return (
    <Box
      color="#FFFFFF"
      fontSize=".8em"
      whiteSpace="nowrap"
      className="u-text-uppercase"
      fontWeight={600}
      p=".4em .8em"
      letterSpacing="1px"
      width="100%"
      {...props}
    >
      {name}
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage; activeColors: ActiveColors }> = ({
  language,
  activeColors,
}) => {
  const { name, speaking, writing } = language;
  return (
    <Box
      fontWeight={400}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      p="0em 8% .6em"
      {...activeColors}
    >
      <Box fontSize=".6em" fontWeight={600}>
        {name}
      </Box>
      <Box display="flex" alignItems="center">
        <Box fontSize=".5em" mr=".2em">
          Write: {getLanguageLevel(writing)} {'| '}
        </Box>
        <Box fontSize=".5em">Spoken: {getLanguageLevel(speaking)}</Box>
      </Box>
    </Box>
  );
};

const UserCertification: FC<{ data?: ProfileCertificate; activeColors: ActiveColors }> = ({ data, activeColors }) => {
  const { certificate, date, organizer, expiration_date, does_not_expire } = data;
  const joinDate = getYearOrExample(date);
  const leaveDate = does_not_expire ? 'Present' : getYearOrExample(expiration_date, '2020');

  return (
    <Box fontWeight={400} display="flex" flexDirection="column" gridGap=".2em" p=".5em 8% .5em 12%" {...activeColors}>
      <Box fontWeight={400} fontSize=".5em">
        {`${joinDate} - ${leaveDate}`}
      </Box>
      <Box fontWeight={400} fontSize=".5em">
        {organizer}
      </Box>
      <Box fontWeight={700} fontSize=".6em" color="#333333">
        {certificate}
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  cvReference,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box display="flex" flexDirection="column" gridGap=".3em" minWidth="40%" p=".5em 8%" {...activeColors}>
      <Box fontSize=".6em" fontWeight={700}>
        {name}
      </Box>
      <Box fontSize=".5em" fontWeight={400}>
        {position} | {company}
      </Box>

      <Box fontStyle="italic" fontSize=".5em" fontWeight={400}>
        <Box component="span" mr=".2em">
          Phone:
        </Box>
        {mobile}
      </Box>
      <Box fontStyle="italic" fontSize=".5em" fontWeight={400}>
        <Box component="span" mr=".2em">
          Email:
        </Box>
        {email}
      </Box>
    </Box>
  );
};
const PREMIUM_0022: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, certifications, languages, reference, interest } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    skype,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'Herminia Lowe';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userSkype = skype ?? 'username skype';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie sed risus porta in elit rhoncus.
Purus scelerisque curabitur enim in turpis. Sed condimentum eu diam lorem.`;

  const userRole = role || 'DIRECTOR OF MARKETING';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';

  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(3).fill({
    school: 'University of Tech',
    degree: 'Bachelor Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'Javascript',
    name: 'Javascript',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;
  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'facebook' },
    { type: 'Instagram', link: 'instagram' },
    { type: 'LinkedIn', link: 'linkedin' },
    { type: 'Twitter', link: 'twitter' },
    { type: 'Github', link: 'github' },
  ];
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'Skype', link: userSkype },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  const userInterest = interest ?? [
    'Project Management',
    'Graphic Design',
    'Computer Language',
    'Mobile & Cloud Solution',
    'Event Planning',
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(2).fill({
    role: 'Junior Data',
    company: 'Tech Company',
    location: 'Location',
    from_year: '01-01-2016',
    to_year: '01-01-2018',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyCertifications: ProfileCertificate[] = Array(2).fill({
    certificate_id: 'Certification ID',
    date: '2021-02-02',
    organizer: 'AWS',
    certificate: 'AWS Certification Professional',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium.',
  });
  const userCertifications = certifications ?? dummyCertifications;

  const dummyReference: ProfileReference[] = Array(1).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    company: 'Company / Organization Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e8e8e8' } : {};
  };

  const renderPhoto = () => {
    if (!isShowAvatar) return [];
    return [
      <Box key="photo" {...activeColors(1)}>
        <Box display="flex" justifyContent="center" pt="1.2em">
          <Box height="4.5em" minWidth="4.5em" maxWidth="5.5em">
            <img
              height="100%"
              width="100%"
              src={userAvatar}
              alt="profile-pict"
              className="u-border-rounded o-base-cv__avatar"
            />
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box
        key="summary"
        {...activeColors(1)}
        display="flex"
        flexDirection="column"
        justifyContent="start"
        alignItems="start"
        width="100%"
        p="1em 8%"
      >
        <SubTitleRight bgcolor={cvColor} name="About Me" fontWeight={700} />
        <AQuillContent
          textAlign="left"
          content={userAboutMe}
          fontWeight={400}
          lineHeight="1.5em"
          fontSize=".5em"
          mt="5%"
          ml="4%"
        />
      </Box>,
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skills" width="100%" p="1em 8% .4em" {...activeColors(4)}>
        <SubTitleLeft bgText={cvColor} name="Skills" activeColors={activeColors(4)} />
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          isShowProficiency={isShowProficiency}
          key={`skill${name}${index}${points}`}
          title={name}
          point={points}
          theme={cvColor}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const renderContact = () => {
    if (isEmpty(contacts)) return [];
    return [
      <Box key="contact" p="1em 8%" {...activeColors(1)}>
        <Box width="100%">
          <SubTitleLeft bgText={cvColor} name="Contact" activeColors={activeColors(1)} />
        </Box>
      </Box>,
      ...contacts.map(
        ({ type, link }, index) =>
          !!link.length && (
            <UserContactsBox key={`${type}${index}`} type={type} link={link} activeColors={activeColors(1)} />
          ),
      ),
    ];
  };

  const renderLanguages = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="languages" {...activeColors(5)} width="100%" p="1em 8%">
        <SubTitleLeft bgText={cvColor} name="Languages" activeColors={activeColors(5)} />
      </Box>,
      ...userLanguages.map((item, index) => (
        <UserLanguageInfo key={`${item.name}${index}`} language={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderReference = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box key="references" {...activeColors(5)} width="100%" p=".8em 8% .4em">
        <SubTitleLeft bgText={cvColor} name="References" activeColors={activeColors(5)} />
      </Box>,
      ...userReference.map((item, index) => (
        <UserReference key={`${item.name}${index}`} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderInterest = () => {
    if (isEmpty(userInterest)) return [];
    return [
      <Box key="interests" p=".6em 8% .8em" {...activeColors(5)}>
        <SubTitleLeft bgText={cvColor} name="INTERESTS" color="#484545" activeColors={activeColors(3)} />
      </Box>,
      ...userInterest.map((item, index) => (
        <Box
          key={index + item}
          p=".2em 8%"
          display="flex"
          gridGap=".4em"
          alignItems="center"
          bgcolor={isActive === 5 ? '#e8e8e8' : ''}
        >
          <Box bgcolor="currentColor" width=".2em" height=".2em" borderRadius="50%" />
          <Box fontSize=".5em">{item}</Box>
        </Box>
      )),
    ];
  };

  const renderSocialMedias = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box key="socialMedias" width="100%" p=".4em 8% 0" />,
      ...userSocialMediaShowed.map((item, idx) => (
        <UserSocialBox
          key={`${item.type}${idx}`}
          type={item.type}
          img={item.type}
          link={item.link}
          activeColors={activeColors(1)}
        />
      )),
    ];
  };

  const renderName = () => {
    return [
      <Box
        key="name"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        {...activeColors(1)}
      >
        <Box mt="1.3em" width="100%">
          <Box fontSize="1.7em" fontWeight={700} color="#212121" px="8%">{`${userFirstName} ${userLastName}`}</Box>
          <Box
            width="100%"
            height="1.8em"
            mt=".2em"
            color="#4E4B4B"
            fontSize=".7em"
            fontWeight={700}
            px="8%"
            className="u-text-uppercase"
          >
            {userRole}
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderExperiences = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experiences" {...activeColors(2)} p=".4em 8%">
        <SubTitleRight bgcolor={cvColor} name="Work Experience" />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`${item.companyInfo}${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderEducations = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="educations" {...activeColors(3)} p=".5em 8%">
        <SubTitleRight bgcolor={cvColor} name="Education" />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderCertifications = () => {
    if (isEmpty(userCertifications)) return [];
    return [
      <Box key="certifications" {...activeColors(5)} p="1em 8% .4em">
        <SubTitleRight bgcolor={cvColor} name="Certification" />
      </Box>,
      ...userCertifications.map((item, index) => (
        <UserCertification key={`${item.certificate}${index}`} data={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [
      ...renderPhoto(),
      ...renderContact(),
      ...renderSkills(),
      ...renderLanguages(),
      ...renderInterest(),
      ...renderReference(),
      ...renderSocialMedias(),
    ],
    rightComponents: [
      ...renderName(),
      ...renderSummary(),
      ...renderExperiences(),
      ...renderEducations(),
      ...renderCertifications(),
    ],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont || 'inherit'}>
      <Box minHeight="54.164em" height="54.164em" display="flex" position="relative" color="#71726E" width="100%">
        <Box
          maxHeight="100%"
          height="100%"
          id={idLeft}
          overflow="hidden"
          bgcolor="#F8F8F8"
          flex={50}
          color="black"
          fontSize={fontSizeRatio}
        >
          {renderedLeft}
          <Box height="1.6em" />
        </Box>
        <Box flex={50} maxHeight="100%" height="100%" id={idRight} overflow="hidden" fontSize={fontSizeRatio}>
          {renderedRight}
          <Box height="1.6em" />
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0022_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0022;
