import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, getLanguageLevel, useCVHelper, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import { ActiveColors, ProfileEducation, ProfileExperience, ProfileLanguage } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  ADribbble,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  AWebsite,
  ISocialIcon,
} from 'components/atoms/icons';
import PREMIUM_0003_BACK from './back';
import { OCVProps, previewType } from '../../index';

const SkillItem: FC<{ title: string; activeColor: ActiveColors }> = ({ title, activeColor }) => {
  return (
    <Box p="0.8em 2em 1.5em 2.6em" fontSize="0.72em" display="flex" alignItems="center" height="1.5em" {...activeColor}>
      <Box bgcolor="white" width=".25em" height=".25em" borderRadius="50%" mr=".5em" />
      <Box>{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{
  cvExperience?: ProfileExperience;
  activeColor: ActiveColors;
  isHiddenLine?: boolean;
}> = ({ cvExperience, activeColor, isHiddenLine }) => {
  const { role, company, from_year, to_year, finished, activities, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box display="flex" flexDirection="column" gridGap=".6em" p=".5em 1.36em .8em 1.36em" {...activeColor}>
      <Box fontWeight={700} fontSize="0.8em" color="#484545">
        {role}
      </Box>
      <Box fontSize="0.72em" color="#484545" lineHeight="1">
        {company}, {location} | {joinDate} - {leaveDate}
      </Box>
      <Box>
        <AQuillContent content={activities} fontSize="0.72em" color="#757575" />
      </Box>
      {!isHiddenLine && <Box mt=".7em" borderBottom="1px solid #9E9E9E" />}
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage; activeColor: ActiveColors }> = ({ language, activeColor }) => {
  const { name, speaking, writing } = language;
  return (
    <Box display="flex" justifyContent="space-between" p=".5em 1.36em .5em 1.36em" {...activeColor}>
      <Box fontWeight={600} fontSize="0.6em" color="#333333">
        {name}
      </Box>
      <Box display="flex">
        <Box fontWeight={400} pr=".5em" mr=".5em" fontSize=".5em" borderRight="1px solid #333333">
          Write: {getLanguageLevel(writing)}
        </Box>
        <Box fontWeight={400} fontSize=".5em">
          Spoken: {getLanguageLevel(speaking)}
        </Box>
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; isHiddenLine?: boolean; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
  isHiddenLine,
}) => {
  const { school, to_year, finished, major, degree, from_year, activities } = cvEducation;
  const joinDate = getYearOrExample(from_year, '2020');
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2021');

  return (
    <Box display="flex" flexDirection="column" gridGap=".4em" p=".5em 1.36em .8em 1.36em" {...activeColors}>
      <Box fontWeight={700} className="u-text-uppercase" fontSize="0.75em" color="#484545">
        {`${degree} ${major}`}
      </Box>
      <Box fontSize="0.72em">{`${school} | ${joinDate} - ${leaveDate}`}</Box>
      <AQuillContent content={activities} fontSize="0.72em" />
      {!isHiddenLine && <Box mt="0.8em" borderBottom="1px solid #9E9E9E" />}
    </Box>
  );
};

const UserSocialBox: FC<{ info: string; img: string; activeColors: ActiveColors }> = ({ info, img, activeColors }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    LinkedIn: AOutlinedLinkedin,
    Instagram: AOutlinedInstagram,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };
  const svgIcons = () => {
    const SVG: FC<ISocialIcon> = componentIcons[img];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="100%" height="100%" />;
  };
  return (
    <Box width="50%" display="inline-block" pt=".8em" {...activeColors}>
      <Box m="auto" width="1em" height="1em" display="flex" alignItems="center">
        {img && svgIcons()}
      </Box>
      <Box mt=".3em">
        <Box textAlign="center" fontSize="0.72em">
          {info}
        </Box>
      </Box>
    </Box>
  );
};

const SubTitle: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box fontSize=".9em" fontWeight={600} {...props}>
      {children}
    </Box>
  );
};

const PREMIUM_0003: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
  } = props;
  const { basicInfo, experience, education, skills, languages } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    skype,
    socialMedia,
  } = basicInfo as CvBasicInfo;

  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'DANIEL CHEVAL';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'yourmail@domain.com';

  const userSkype = skype ?? 'username skypeyy';
  const userAboutMe =
    summary ??
    `Lacus maecenas leo etiam in commodo vitae eget vitae. Enim convallis vel lectus egestas at eget. Risus, quisque nullam ac diam, molestie. Lacus maecenas leo etiam in commodo vitae eget vitae.`;

  const userRole = role || 'INFORMATION OFFICER';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+123 4567 8910';

  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
    { name: 'Arabic', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;

  let userSocialMedia = socialMedia || [
    { type: 'LinkedIn', link: 'your username' },
    { type: 'Facebook', link: 'your username' },
    { type: 'Twitter', link: 'your username' },
    { type: 'Instagram', link: 'your username' },
    { type: 'Website', link: 'your website' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];
  const contacts = [{ Phone: userPhoneNumber }, { Email: userEmail }, { Skype: userSkype }];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const signColor = '#d2d2d2';

  const dummyExperiences = Array(2).fill({
    role: 'Business Owner',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University / College / Institute',
    degree: 'Master',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2020',
    to_year: '01-01-2021',
    finished: false,
    activities:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In felis lacus volutpat mi varius cras venenatis. Placerat donec purus egestas augue amet, amet nunc.',
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(2).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: signColor, color: 'black' } : {};
  };

  const [fontSizeRatio, cvColor, t] = useCVHelper(props);

  const renderProfile = () => {
    return [
      <Box key="profile" p="1.36em 1.36em .8em 1.36em" {...activeColors(1)} fontSize={textSize}>
        <Box borderTop=".2em solid #E0B93C" borderBottom=".2em solid #E0B93C">
          <Box display="flex" mt=".8em" justifyContent="space-between" alignItems="center">
            {isShowAvatar && (
              <Box height="5.5em" minWidth="5.5em" maxWidth="5.5em">
                <img
                  height="100%"
                  width="100%"
                  src={userAvatar}
                  alt="profile-pict"
                  className="u-border-rounded o-base-cv__avatar"
                />
              </Box>
            )}
            <Box width="100%">
              <Box textAlign="right" color="#E0B93C" fontSize="2em" fontWeight="bold" className="u-text-uppercase">
                {userFirstName} {userLastName}
              </Box>
              <Box
                mb=".8em"
                mt=".5em"
                fontWeight={600}
                fontSize=".72em"
                ml="auto"
                whiteSpace="nowrap"
                textAlign="right"
                className="u-text-uppercase"
              >
                {userRole}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box key="summary">
        <Box p=".6em 1.36em .6em" {...activeColors(1)}>
          <Box color="#E0B93C" fontWeight="bold" fontSize="2em">
            Hi!
          </Box>
          <AQuillContent mt=".5em" content={userAboutMe} fontSize="0.6em" />
          <Box borderBottom="1px solid white" mt=".8em" />
        </Box>
      </Box>,
    ];
  };

  const renderContact = () => {
    return [
      <Box key="contact" p=".6em 1.36em .6em" {...activeColors(1)}>
        <SubTitle mb="1em">CONTACTS</SubTitle>
        <Box display="flex" flexDirection="column" gridGap="0.7em">
          {contacts.map((contact) => {
            return Object.entries(contact).map(
              (itemContact, idx) =>
                !!itemContact[1].length && (
                  <Box pl=".5em" key={idx} display="flex" fontSize=".8em" borderLeft="1px solid white">
                    <Box className="u-word-no-wrap" pr={2}>
                      {itemContact[0]}:
                    </Box>
                    <Box className="u-word-break-all">{itemContact[1]}</Box>
                  </Box>
                ),
            );
          })}
          <Box pl=".5em" display="flex" alignItems="baseline">
            <Box width="1em" height="1em" mr=".5em">
              <img width="100%" height="100%" src="/assets/svg/address_cv.svg" alt="icon location" />
            </Box>
            <Box className="u-word-break-all" fontSize=".8em">
              {userAddress} <br />
              {`${userCity}, ${userCountry}`}
            </Box>
          </Box>
        </Box>
        <Box borderBottom="1px solid white" mt=".8em" />
      </Box>,
    ];
  };

  const renderSkill = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skills" p=".6em 1.36em .8em" {...activeColors(4)}>
        <SubTitle>SKILLS</SubTitle>
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem key={`skill${name}${index}${points}`} title={name} activeColor={activeColors(4)} />
      )),
      <Box key="skillBottom" m=".3em 1.36em .5em" borderBottom="1px solid white" />,
    ];
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box key="socialMedia" p=".6em 1.36em .6em" {...activeColors(1)}>
        <SubTitle>SOCIAL MEDIA</SubTitle>
      </Box>,
      ...userSocialMediaShowed.map((item) => (
        <UserSocialBox key={item.type} img={item.type} info={item.link} activeColors={activeColors(1)} />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education" p="1.36em 1.36em .4em 1.36em" {...activeColors(3)}>
        <SubTitle>EDUCATION</SubTitle>
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo
          key={`${item.school}${index}`}
          activeColors={activeColors(3)}
          cvEducation={item}
          isHiddenLine={index === userEducation.length - 1}
        />
      )),
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experience" p="1.04em 1.36em .4em 1.36em" {...activeColors(2)}>
        <SubTitle>WORK EXPERIENCE</SubTitle>
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo
          key={`${item.companyInfo}${index}`}
          cvExperience={item}
          activeColor={activeColors(2)}
          isHiddenLine={index === userExperiences.length - 1}
        />
      )),
    ];
  };

  const renderLanguage = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="language">
        <Box p="1em 1.36em .4em 1.36em" {...activeColors(5)}>
          <SubTitle pt="0">LANGUAGE</SubTitle>
        </Box>
      </Box>,
      ...userLanguages.map((item, index) => (
        <UserLanguageInfo activeColor={activeColors(5)} key={`${item.name}${index}`} language={item} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [
      ...renderProfile(),
      ...renderSummary(),
      ...renderContact(),
      ...renderSkill(),
      ...renderSocialMedia(),
    ],
    rightComponents: [...renderEducation(), ...renderExperience(), ...renderLanguage()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont}>
      <Box minHeight="54.164em" height="54.164em" display="flex" position="relative">
        <Box
          id={idLeft}
          bgcolor={cvColor}
          minWidth="47%"
          maxWidth="47%"
          maxHeight="100%"
          height="100%"
          color="white"
          overflow="hidden"
          fontSize={fontSizeRatio}
        >
          {renderedLeft}
          <Box height="1.6em" />
        </Box>
        <Box id={idRight} flex={1} overflow="hidden" height="100%" maxHeight="100%" fontSize={fontSizeRatio}>
          {renderedRight}
          <Box height="1.6em" />
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0003_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0003;
