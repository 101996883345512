import { FC } from 'react';
import Box from '@material-ui/core/Box';
import useTranslation from 'next-translate/useTranslation';
import { useCvTwoLayoutBack } from 'helpers';
import { OCVProps, previewType } from '../../index';

const PREMIUM_0022_BACK: FC<OCVProps> = (props) => {
  const { t } = useTranslation('common');
  const [
    isShowOtherPage,
    renderedLeft,
    renderedRight,
    idLeft,
    idRight,
    contentLeft,
    contentRight,
    preview,
    savedCvColour,
    fontSizeRatio,
  ] = useCvTwoLayoutBack({
    breakComponent: <Box key="too-large">{t('common.contentTooLong')}</Box>,
    props,
  });

  return (
    <>
      <Box minHeight="54.164em" height="54.164em" position="relative">
        <Box flex={1} width="100%" height="54.164em" overflow="hidden" display="flex" color="#71726E">
          <Box
            width="100%"
            maxHeight="100%"
            height="100%"
            id={idLeft}
            overflow="hidden"
            bgcolor="#171818"
            flex={50}
            fontSize={fontSizeRatio}
          >
            {renderedLeft}
          </Box>
          <Box flex={50} maxHeight="100%" height="100%" id={idRight} overflow="hidden" fontSize={fontSizeRatio}>
            {renderedRight}
          </Box>
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0022_BACK
            preview={preview}
            savedCvColour={savedCvColour}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </>
  );
};

export default PREMIUM_0022_BACK;
