import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, getLanguageLevel, useCVHelper, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import {
  ActiveColors,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
  ProfileReference,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  ADribbble,
  AEmail,
  AGithub,
  AHome,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  ASkype,
  AWebsite,
} from 'components/atoms/icons';
import { ISocialIcon } from 'components/atoms/icons/ASkype';
import PREMIUM_0018_BACK from './back';
import { OCVProps, previewType } from '../../index';

const SkillItem: FC<{
  title: string;
  point: number;
  theme: string;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
  fontSizeRatio: number;
}> = ({ title, point, theme, activeColors, isShowProficiency, fontSizeRatio }) => {
  if (isShowProficiency) {
    const size = Math.ceil(fontSizeRatio * 0.4);
    return (
      <Box display="flex" justifyContent="space-between" color="#272726" p=".4em 8%" {...activeColors}>
        <Box fontSize=".5em">{title}</Box>
        <Box display="flex" gridGap=".36em" minWidth="max-content">
          {Array.from(Array(10), (_, index) => (
            <Box
              key={`${title}${index}`}
              minWidth={size}
              width={size}
              height={size}
              m="0.2em"
              borderRadius="100%"
              bgcolor={index <= point * 2 ? '#737874' : undefined}
              border={index > point * 2 ? '1px solid #737874' : undefined}
            />
          ))}
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" p=".4em 8%" {...activeColors}>
      <Box bgcolor={theme} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities, company, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box fontWeight={400} p=".4em 8% .4em 4%" {...activeColors}>
      <Box color="#737874" fontSize=".6em">{`${joinDate} - ${leaveDate} | ${company}, ${location}`}</Box>
      <Box mt=".4em">
        <Box fontSize=".6em" className="u-text-uppercase" whiteSpace="nowrap" fontWeight={700}>
          {role}
        </Box>
      </Box>
      <Box mt=".4em">
        <AQuillContent color="#3B5159" fontSize=".6em" content={activities} />
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, to_year, finished, from_year, degree } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box fontWeight={400} p=".4em 8% .4em 4%" {...activeColors}>
      <Box color="#737874" fontSize=".6em">{`${joinDate} - ${leaveDate}`}</Box>
      <Box mt=".4em">
        <Box fontSize=".6em" fontWeight={700} whiteSpace="nowrap">
          {`${degree} at ${school}`}
        </Box>
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ type: string; link: string; index: number; activeColors: ActiveColors }> = ({
  type,
  link,
  index,
  activeColors,
}) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    LinkedIn: AOutlinedLinkedin,
    Instagram: AOutlinedInstagram,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG: FC<ISocialIcon> = componentIcons[type];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="60%" height="60%" />;
  };
  return (
    <Box
      display="inline-flex"
      py=".4em"
      gridGap=".5em"
      alignItems="center"
      width="50%"
      pl={index % 2 ? '.4em' : '8%'}
      pr={!(index % 2) ? 0 : '8%'}
      {...activeColors}
    >
      <Box borderRadius="50%" bgcolor="#000000" className="u-flex-center" minWidth=".8em" width=".8em" height=".8em">
        {type && svgIcons()}
      </Box>
      <Box fontWeight={400} fontSize=".4em">
        {link}
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const componentIcons = {
    email: AEmail,
    phone: APhone,
    address: AHome,
    Skype: ASkype,
  };
  const svgIcon = () => {
    const SVG = componentIcons[type];
    return <SVG className="contact-item" fill="currentColor" strokeWidth="0" width="1.2em" height="1.2em" />;
  };
  return (
    <Box
      color="currentColor"
      fontWeight={400}
      mt=".5em"
      fontSize=".6em"
      display="flex"
      gridGap=".6em"
      alignItems="center"
    >
      <Box>{type && svgIcon()}</Box>
      <Box className="u-word-break-all" dangerouslySetInnerHTML={{ __html: link }} />
    </Box>
  );
};

const SubTitle: FC<{ name: string } & BoxProps> = ({ name }) => {
  return (
    <Box flex={1} fontSize=".8em">
      {name}
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage; activeColors: ActiveColors }> = ({
  language,
  activeColors,
}) => {
  const { name, speaking, writing } = language;
  return (
    <Box fontWeight={400} display="flex" justifyContent="space-between" p=".4em 8%" {...activeColors}>
      <Box fontSize=".5em">{name}</Box>
      <Box display="flex" fontSize=".5em">
        <Box mr=".8em">Written: {getLanguageLevel(writing)}</Box>
        <Box>Spoken: {getLanguageLevel(speaking)}</Box>
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  cvReference,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box display="flex" flexDirection="row" gridGap=".6em" minWidth="40%" p=".4em 8%" {...activeColors}>
      <Box display="flex" flexDirection="column" gridGap=".2em">
        <Box fontSize=".5em" fontWeight={700}>
          {name}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          {position}, {company}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gridGap=".2em">
        <Box fontSize=".5em" fontWeight={400}>
          <Box component="span" mr=".2em">
            Phone :
          </Box>
          {mobile}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          <Box component="span" mr=".2em">
            Email :
          </Box>
          {email}
        </Box>
      </Box>
    </Box>
  );
};

const PREMIUM_0018: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, languages, reference } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    role,
    summary,
    mobile_number,
    socialMedia,
    skype,
    address,
    city,
    country,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'HENRY SILLY';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userSkype = skype ?? 'jhon Dee';
  const userAboutMe =
    summary ??
    `My Name Is Henry Silly Lorem Ipsum is simply dummy text of the into electronic typesetting, remaining essentially unchanged. It was popularised in the printer took a galley of type and scrambled it to make a type printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown men book. It has survived not only ﬁve centuries, but also the leap`;

  const userRole = role || 'WEB DESIGN & DEVELOPER';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';

  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'UNIVERSITY NAME',
    degree: 'Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      'Lorem ipsum enunc pulvinati ncidunt. quis pul vinar mellu Lorem ipsum dolor: sit amet consectet gelit. it ellentesque eleife ornare ',
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'PHP',
    name: 'PHP',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'facebook.com/username' },
    { type: 'Instagram', link: 'instagram.com/username' },
    { type: 'LinkedIn', link: 'linkedin.com/username' },
    { type: 'Twitter', link: 'twitter.com/username' },
    { type: 'Website', link: 'Henrysilly.com' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
    { type: 'Skype', link: userSkype },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(1).fill({
    role: 'Business Owner',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;
  const dummyReference: ProfileReference[] = Array(1).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    company: 'Company / Organization Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#adcfff', color: '#000' } : {};
  };

  const renderAboutMe = () => {
    return [
      <Box key="about-me" p=".2em 8% .2em 8%" {...activeColors(1)}>
        <SubTitle name="ABOUT ME" whiteSpace="nowrap" />
        <Box mt=".7em">
          <AQuillContent color="#737874" content={userAboutMe} fontWeight={400} fontSize=".5em" />
        </Box>
      </Box>,
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="userSkills" p=".6em 8% .6em 8%" {...activeColors(4)}>
        <SubTitle name="SKILLS" whiteSpace="nowrap" />
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          theme={cvColor}
          isShowProficiency={isShowProficiency}
          key={`skill${name}${index}${points}`}
          title={name}
          point={points}
          activeColors={activeColors(4)}
          fontSizeRatio={fontSizeRatio}
        />
      )),
    ];
  };

  const renderLanguages = () => {
    if (isEmpty(userLanguages)) return [];
    return [
      <Box key="userLanguages" {...activeColors(5)} p=".6em 8% .6em 8%">
        <SubTitle name="LANGUAGES" />
      </Box>,
      ...userLanguages.map((item, index) => (
        <UserLanguageInfo key={index + item.name} language={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderReference = () => {
    if (isEmpty(userReference)) return [];
    return [
      <Box key="userReference" p=".4em 8% .2em 8%" {...activeColors(5)}>
        <SubTitle name="REFERENCES" />
      </Box>,
      ...userReference.map((item, index) => (
        <UserReference key={index + item.name} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      ...userSocialMediaShowed.map((item, idx) => (
        <UserSocialBox key={idx} type={item.type} link={item.link} index={idx} activeColors={activeColors(1)} />
      )),
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="userExperiences" p=".6em 8% .6em 4%" {...activeColors(2)}>
        <SubTitle name="WORK EXPERIENCE" whiteSpace="nowrap" />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={index} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="educations" p=".4em 8% .4em 4%" {...activeColors(3)}>
        <SubTitle name="EDUCATION" padding=".8em" width="60%" />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderEducation(), ...renderExperience()],
    rightComponents: [
      ...renderAboutMe(),
      ...renderSocialMedia(),
      ...renderSkills(),
      ...renderLanguages(),
      ...renderReference(),
    ],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} bgcolor="#E1E1E1" fontFamily={savedCvFont}>
      <Box minHeight="54.164em" height="100%" display="flex" flexDirection="column" position="relative">
        {isShowAvatar && (
          <Box
            left="50%"
            top="1.9em"
            position="absolute"
            height="7.2em"
            minWidth="7.2em"
            maxWidth="7.2em"
            className="u-border-rounded"
            style={{ transform: 'translate(-50%, 0)' }}
            zIndex="100"
            bgcolor={isActive === 1 ? '#adcfff' : 'white'}
          >
            <img
              height="100%"
              width="100%"
              src={userAvatar}
              alt="profile-pict"
              className="u-border-rounded o-base-cv__avatar"
            />
          </Box>
        )}
        <Box
          {...activeColors(1)}
          display="flex"
          position="absolute"
          top="10.7em"
          left="0"
          right="0"
          minHeight="7.6em"
          fontSize={textSize}
          ml="2%"
          pr="2%"
        >
          <Box flex={1} color="#FFFFFF" className="u-flex-center" flexDirection="column" {...activeColors(1)}>
            <Box>
              <Box className="u-text-line" mb=".1em" fontSize="3.6em">
                {userFirstName} {userLastName}
              </Box>
              <Box className="u-text-uppercase" fontWeight={400} fontSize="1.2em" textAlign="center">
                {userRole}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" height="54.164em">
          <Box flex={1} height="54.164em">
            <Box ml="4%" width="96%" height="58%" bgcolor={cvColor} color="white">
              <Box height="70%" />
              <Box p="2.4em" display="flex" justifyContent="center" {...activeColors(1)}>
                <Box>
                  {contacts.map(
                    ({ link, type }, idx) => !!link.length && <UserContactsBox key={idx} type={type} link={link} />,
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              borderRight="1px solid #737874"
              mt=".8em"
              id={idLeft}
              height="calc(42% - 1.6em)"
              fontSize={fontSizeRatio || 'inherit'}
            >
              {renderedLeft}
            </Box>
          </Box>
          <Box flex={1} id={idRight} height="100%" fontSize={fontSizeRatio || 'inherit'}>
            <Box height="34.7%" />
            {renderedRight}
          </Box>
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0018_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0018;
