import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AContactMe: FC<ISocialIcon> = ({ width = '6', height = '8', stroke = 'black' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.36822 3.58425C3.6851 3.58425 3.97104 3.39332 4.09229 3.10019C4.21385 2.80737 4.14666 2.47019 3.9226 2.24579C3.69822 2.02142 3.36104 1.95454 3.0682 2.07579C2.77507 2.19704 2.58414 2.48297 2.58414 2.80017C2.58414 3.23329 2.93507 3.58423 3.3682 3.58423L3.36822 3.58425ZM3.36822 2.33609C3.55573 2.33609 3.7251 2.44922 3.79666 2.62265C3.86854 2.79609 3.82885 2.99547 3.69635 3.12828C3.56354 3.26109 3.36386 3.30077 3.19042 3.2289C3.01729 3.15703 2.90417 2.98797 2.90417 2.80015C2.90417 2.5439 3.11198 2.33609 3.36823 2.33609L3.36822 2.33609Z"
        fill={stroke}
      />
      <path
        d="M2.46448 5.98376H4.27232C4.53763 5.98376 4.75232 5.76876 4.75232 5.50376V4.93564C4.7517 4.42532 4.40107 3.9822 3.90448 3.8638C3.8448 3.85036 3.78261 3.87192 3.74448 3.91973C3.65667 4.03411 3.52073 4.10098 3.37636 4.10098C3.23229 4.10098 3.09636 4.03411 3.00854 3.91973C2.9701 3.87192 2.90792 3.85036 2.84854 3.8638C2.34229 3.97036 1.98102 4.41848 1.98446 4.93564V5.50376C1.98446 5.76876 2.19915 5.98376 2.46446 5.98376H2.46448ZM2.30448 4.9356C2.30261 4.60873 2.50824 4.31654 2.81636 4.20779C2.96386 4.35216 3.16198 4.43248 3.36854 4.43154C3.57479 4.43342 3.77354 4.35279 3.92041 4.20779C4.22572 4.3206 4.42947 4.61028 4.43228 4.9356V5.50373C4.43228 5.54623 4.41541 5.58686 4.38541 5.61686C4.35541 5.64686 4.31478 5.66373 4.27228 5.66373H2.46444C2.376 5.66373 2.30444 5.59217 2.30444 5.50373L2.30448 4.9356Z"
        fill={stroke}
      />
      <path
        d="M1.39156 7.00794H4.95972C5.1719 7.00794 5.37534 6.92356 5.52534 6.77356C5.67534 6.62356 5.75972 6.42012 5.75972 6.20794V1.7917C5.75972 1.57952 5.67534 1.37608 5.52534 1.22608C5.37534 1.07608 5.1719 0.991699 4.95972 0.991699H1.39156C0.949997 0.991699 0.591557 1.35014 0.591557 1.7917V2.27983H0.399685C0.311245 2.27983 0.239685 2.35139 0.239685 2.43983C0.239685 2.52826 0.311247 2.59983 0.399685 2.59983H0.591557V3.83983H0.399685C0.311245 3.83983 0.239685 3.91139 0.239685 3.99983C0.239685 4.08826 0.311247 4.15983 0.399685 4.15983H0.591557V5.39983H0.399685C0.311245 5.39983 0.239685 5.47139 0.239685 5.55983C0.239685 5.64826 0.311247 5.71983 0.399685 5.71983H0.591557V6.20796C0.591557 6.42014 0.675933 6.62358 0.825933 6.77358C0.975933 6.92358 1.17937 7.00796 1.39156 7.00796V7.00794ZM0.911557 4.15978H1.13562C1.22406 4.15978 1.29562 4.08822 1.29562 3.99978C1.29562 3.91134 1.22406 3.83978 1.13562 3.83978H0.911557V2.59978H1.05562C1.14406 2.59978 1.21562 2.52822 1.21562 2.43978C1.21562 2.35134 1.14406 2.27978 1.05562 2.27978H0.911557V1.79165C0.911557 1.52665 1.12656 1.31165 1.39156 1.31165H4.95972C5.22472 1.31165 5.43972 1.52665 5.43972 1.79165V6.20789C5.43972 6.47289 5.22472 6.68789 4.95972 6.68789H1.39156C1.12656 6.68789 0.911557 6.47289 0.911557 6.20789V5.71976H1.13562C1.22406 5.71976 1.29562 5.6482 1.29562 5.55976C1.29562 5.47133 1.22406 5.39976 1.13562 5.39976H0.911557V4.15978Z"
        fill={stroke}
      />
    </svg>
  );
};
