import { FC } from 'react';
import Box from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, getTime, useCVHelper, useCvTwoLayout } from 'helpers';
import { ActiveColors, ProfileEducation, ProfileExperience } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import MCvWatermark from 'components/molecules/MCvWatermark';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  ADribbble,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  AWebsite,
} from 'components/atoms/icons';
import FREE_0003_BACK from './back';
import { OCVProps, previewType } from '../../index';

const box6em = (title: string) => (
  <Box component="span" fontSize="0.6em" width="100%">
    {title}
  </Box>
);

const UserSocialMediaInfo: FC<{ type: string; link: string; activeColors: ActiveColors }> = ({
  type,
  link,
  activeColors,
}) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    Instagram: AOutlinedInstagram,
    LinkedIn: AOutlinedLinkedin,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG = componentIcons[type];
    return <SVG strokeWidth="0" width="60%" height="60%" />;
  };
  return (
    <Box display="inline-flex" width={link.length > 10 ? '100%' : '50%'} py="0.5em" pl="1em" {...activeColors}>
      <Box height="1.5em" width="2em" border="1px solid black" className="u-flex-center" mr="0.5em">
        {type && svgIcons()}
      </Box>
      <Box width="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <Box fontSize="0.6em" className="u-text-uppercase" fontWeight={700}>
          {type}
        </Box>
        <Box fontSize="0.6em" fontStyle="italic">
          {link}
        </Box>
      </Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, company, activities, location } = cvExperience;
  const { time } = getTime(cvExperience);
  const companyInfo = `${company} / ${location}`;
  return (
    <Box p=".4em 8%" display="flex" lineHeight="1.6em" {...activeColors}>
      <Box>
        <Box lineHeight="1em" fontWeight={600} className="u-text-uppercase">
          {box6em(role)}
        </Box>
        <Box lineHeight="1em" mt="0.2em" color="#757575">
          {box6em(`${companyInfo} | ${time}`)}
        </Box>
        <Box lineHeight="1em" mt="0.2em">
          <AQuillContent content={activities} fontSize="0.6em" />
        </Box>
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, activities, major } = cvEducation;
  const { time } = getTime(cvEducation);
  return (
    <Box display="flex" lineHeight="1.6em" p=".4em 8% 0em" {...activeColors}>
      <Box>
        <Box lineHeight="1em" fontWeight={600} className="u-text-uppercase">
          {box6em(major)}
        </Box>
        <Box lineHeight="1em" mt="0.2em" color="#757575">
          {box6em(`${school} | ${time}`)}
        </Box>
        <Box lineHeight="1em" mt="0.2em">
          <AQuillContent content={activities} fontSize="0.6em" />
        </Box>
      </Box>
    </Box>
  );
};

const SkillItem: FC<{
  title: string;
  point: number;
  isShowProficiency: boolean;
  color: string;
  activeColors: ActiveColors;
}> = ({ title, point, isShowProficiency, color, activeColors }) => {
  if (isShowProficiency) {
    const width = `${(point * 100) / 5}%`;
    return (
      <Box display="flex" p=".4em 8%" alignItems="center" justifyContent="space-between" {...activeColors}>
        <Box flexGrow={1}>{box6em(title)}</Box>
        <Box
          minWidth="50%"
          width="50%"
          display="flex"
          alignItems="center"
          height=".3em"
          m="0.2em"
          position="relative"
          border="1px solid #dfdfdf"
          borderRadius="10px"
          bgcolor="#dfdfdf"
        >
          <Box
            bgcolor="#292222"
            width={`calc(${width} + 2px)`}
            height="calc(100% + 2px)"
            position="absolute"
            top="-1px"
            left="-1px"
            borderRadius="10px"
          />
        </Box>
      </Box>
    );
  }
  return (
    <Box p=".3em 8%" gridGap=".2em" alignItems="center" {...activeColors}>
      <Box bgcolor={color} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const FREE_0003: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    preview = previewType.cvDemoThumbnail,
    savedCvFont,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills } = cvInfo || { basicInfo: {} };
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'JHON DEE';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'yourmail@domain.com';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum id rhoncus eget placerat cras viverra sollicitudin. Vitae amet enim in a. Dolor nec pharetra, malesuada netus massa risus. Arcu vitae consectetur suspendisse ut elementum proin.`;

  const userRole = role || 'Digital Marketer';
  const userAddress = address || 'Number street';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';

  const userPhoneNumber = mobile_number || '+67-0890-231';

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'username' },
    { type: 'Instagram', link: 'username' },
    { type: 'LinkedIn', link: 'username' },
    { type: 'Twitter', link: 'username' },
    { type: 'Website', link: 'username' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];

  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, [(e) => e.link.length], ['asc']);

  const dummyExperiences = Array(2).fill({
    role: 'ENTER JOB TITLE HERE',
    company: 'Company / Institute Name',
    location: 'San Francisco',
    from_year: '01-01-2016',
    to_year: '01-01-2018',
    finished: true,
    activities: `- Sollicitudin ultrices duis ac turpis quam ultricies leo vel. At velit pharetra ut ut duis. Molestie sit integer sit morbi.
- Sollicitudin ultrices duis ac turpis quam ultricies leo vel. At velit pharetra ut ut duis. Molestie sit integer sit morbi.
- Sollicitudin ultrices duis ac turpis quam ultricies leo vel. At velit pharetra ut ut duis. Molestie sit integer sit morbi.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(3).fill({
    school: 'University / Institute Name',
    degree: 'ENTER YOUR DEGREE',
    grade: '4.75',
    major: 'ENTER YOUR BACHELORS DEGREE',
    from_year: '01-05-2014',
    to_year: '01-05-2018',
    finished: true,
    activities: `- Responsible for creating, improving and developing IT Projects.
- Make the appearance according to the project or product requirements.`,
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#CCEAFC' } : {};
  };

  const renderProfile = () => {
    return [
      <Box key="profile" {...activeColors(1)} p="1em 8% 0em" className="u-text-uppercase">
        <Box display="flex" justifyContent="flex-start">
          {isShowAvatar && (
            <Box height="4em" minWidth="4em" maxWidth="4em" mr="0.6em">
              <img height="100%" width="100%" src={userAvatar} alt="" className="u-border-rounded o-base-cv__avatar" />
            </Box>
          )}
          <Box flex={1}>
            <Box fontWeight={700} letterSpacing="0.05em">
              <Box fontSize="1.5em">{userFirstName}</Box>
            </Box>
            <Box fontWeight={700} letterSpacing=".05em">
              <Box fontSize="1.5em">{userLastName}</Box>
            </Box>
            <Box lineHeight="1.6em" mt="0.4em">
              <Box fontSize="0.8em">{userRole}</Box>
            </Box>
          </Box>
        </Box>
        <Box display="inline-flex" height="0.2em" width="100%" bgcolor="black" mt="0.4em" />
      </Box>,
    ];
  };
  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box key="summary" {...activeColors(1)} p="0em 8% .6em">
        <Box fontWeight={600} pt="1.2em">
          ABOUT ME
        </Box>
        <Box height="0.1em" width="100%" bgcolor="black" my="0.4em" />
        <AQuillContent content={userAboutMe} fontSize="0.6em" my=".4em" />
      </Box>,
    ];
  };

  const renderContact = () => {
    return [
      <Box key="contact" {...activeColors(1)} p=".6em 8%">
        <Box fontWeight={600}>CONTACT</Box>
        <Box height="0.1em" width="100%" bgcolor="black" my="0.4em" />
        <Box fontSize="0.6em">
          <Box mt="1em">
            <Box component="span" width="5em" fontWeight={600}>
              Phone
            </Box>
            <Box component="span" width="1em">
              :
            </Box>
            <Box component="span">{userPhoneNumber}</Box>
          </Box>
          <Box mt="1em" display="flex">
            <Box component="span" width="5em" fontWeight={600}>
              Email
            </Box>
            <Box component="span" width="1em">
              :
            </Box>
            <Box component="span" className="u-word-break-all">
              {userEmail}
            </Box>
          </Box>
          <Box mt="1em" display="flex">
            <Box component="span" width="5em" minWidth="5em" fontWeight={600}>
              Address
            </Box>
            <Box component="span" width="1em">
              :
            </Box>
            <Box>{`${userAddress}, ${userCity}, ${userCountry}`}</Box>
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderSocialMedia = () => {
    if (isEmpty(userSocialMediaShowed)) return [];
    return [
      <Box key="socialMedia" {...activeColors(1)} p=".6em 8% .3em">
        <Box fontWeight={600}>SOCIAL MEDIA</Box>
        <Box height="0.1em" width="100%" bgcolor="black" my="0.4em" />
        <Box />
      </Box>,
      ...userSocialMediaShowed.map((e) => (
        <UserSocialMediaInfo key={e.type} type={e.type} link={e.link} activeColors={activeColors(1)} />
      )),
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box p=".6em 8% .3em" key="userSkills" {...activeColors(4)}>
        <Box fontWeight={600}>SKILLS</Box>
        <Box height=".1em" width="100%" bgcolor="black" />
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          key={`skill${name}${index}${points}`}
          title={name}
          point={points}
          isShowProficiency={isShowProficiency}
          color={cvColor}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box p=".4em 8%" key="userExperiences" {...activeColors(2)}>
        <Box lineHeight="1.6em" mt="1em" fontWeight={600}>
          <Box fontSize="1.2em">WORK EXPERIENCE</Box>
        </Box>
        <Box height="0.1em" width="100%" bgcolor="black" mt="0.4em" />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo
          activeColors={activeColors(2)}
          key={`experience-${index}${item.companyInfo}`}
          cvExperience={item}
        />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box p="1.2em 8% .1em" key="userEducation" {...activeColors(3)}>
        <Box lineHeight="1.6em" fontWeight={600}>
          <Box fontSize="1.2em">EDUCATION</Box>
        </Box>
        <Box height="0.1em" width="100%" bgcolor="black" my="0.4em" />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`education-${index}${item.school}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [
      ...renderProfile(),
      ...renderSummary(),
      ...renderContact(),
      ...renderSocialMedia(),
      ...renderSkills(),
    ],
    rightComponents: [...renderExperience(), ...renderEducation()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont}>
      <Box minHeight="54.164em" height="54.164em" position="relative" display="flex">
        <Box
          id={idLeft}
          width="46.7%"
          minWidth="46.7%"
          overflow="hidden"
          bgcolor={cvColor}
          textAlign="left"
          color="black"
          height="100%"
          fontSize={fontSizeRatio}
        >
          {renderedLeft}
        </Box>
        <Box flexGrow={1} id={idRight} overflow="hidden" fontSize={fontSizeRatio} height="100%">
          {renderedRight}
          <Box height="2.6em" fontSize={textSize} />
        </Box>
        <MCvWatermark />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height={30} bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <FREE_0003_BACK
            textSize={textSize}
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default FREE_0003;
