import { FC } from 'react';
import Box from '@material-ui/core/Box';
import useTranslation from 'next-translate/useTranslation';

import MCvWatermark from 'components/molecules/MCvWatermark';
import { useCvTwoLayoutBack } from 'helpers';
import { OCVProps, previewType } from '../../index';

const FREE_0003_BACK: FC<OCVProps> = (props) => {
  const { t } = useTranslation('common');
  const [
    isShowOtherPage,
    renderedLeft,
    renderedRight,
    idLeft,
    idRight,
    contentLeft,
    contentRight,
    preview,
    savedCvColour,
    fontSizeRatio,
    textSize,
  ] = useCvTwoLayoutBack({
    breakComponent: <Box key="too-large">{t('common.contentTooLong')}</Box>,
    props,
  });

  return (
    <>
      <Box height="54.164em" overflow="hidden" display="flex" position="relative">
        <Box
          id={idLeft}
          width="46.7%"
          minWidth="46.7%"
          bgcolor={savedCvColour}
          textAlign="left"
          color="black"
          fontSize={fontSizeRatio}
          height="100%"
        >
          <Box height=".6em" />
          {renderedLeft}
          <Box height="1em" fontSize={textSize} />
        </Box>
        <Box flexGrow={1} id={idRight} overflow="hidden" fontSize={fontSizeRatio} height="100%">
          {renderedRight}
          <Box height="2.6em" fontSize={textSize} />
        </Box>
        <MCvWatermark />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height={30} bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <FREE_0003_BACK
            preview={preview}
            savedCvColour={savedCvColour}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
            textSize={textSize}
          />
        </>
      )}
    </>
  );
};

export default FREE_0003_BACK;
