import { FC } from 'react';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import useTranslation from 'next-translate/useTranslation';
import { formatDate } from 'utils/date';
import { getCVAvatar, getTime, useCvTwoLayout } from 'helpers';
import { ActiveColors, AppState, ProfileEducation, ProfileExperience } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import MCvWatermark from 'components/molecules/MCvWatermark';
import FREE_0006_BACK from './back';
import { OCVProps, previewType } from '../../index';

const box6em = (title: string) => (
  <Box component="span" fontSize="0.6em" width="100%">
    {title}
  </Box>
);

const box8em = (title: string) => (
  <Box component="span" fontSize="0.6em" width="100%">
    {title}
  </Box>
);

const UserLinkInfo: FC<{ title: string; info: string; extraInfo?: string }> = ({ title, info, extraInfo }) => {
  return (
    <>
      <Box display="flex" mt="0.6em" flexGrow={1} lineHeight="0.817em">
        <Box fontWeight={600} minWidth="4.7em" display="flex" justifyContent="space-between">
          {box6em(title)}
          <Box fontSize="0.6em" pr="1em">
            :
          </Box>
        </Box>
        {box6em(info)}
      </Box>
      {extraInfo && <Box ml="4.7em">{box6em(extraInfo)}</Box>}
    </>
  );
};

const UserSocialMediaInfo: FC<{ title: string; info: string }> = ({ title, info }) => {
  return (
    <Box display="flex" mt="0.6em" flexGrow={1} lineHeight="0.817em">
      <Box fontWeight={600} minWidth="3.7em">
        {box6em(title)}
      </Box>
      <Box color="#757575">{box6em(info)}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, company, activities, location } = cvExperience;
  const { time } = getTime(cvExperience);
  const companyInfo = `${company} | ${location}`;
  return (
    <Box p=".4em 1.6em" {...activeColors}>
      <Box lineHeight="1em" fontWeight={600} className="u-text-uppercase">
        {box8em(role)}
      </Box>
      <Box lineHeight="1em" color="#757575">
        {box6em(`${companyInfo} | ${time}`)}
      </Box>
      <Box lineHeight="0.9em" mt="0.2em" pr="1.5em">
        <AQuillContent content={activities} fontSize="0.6em" />
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, activities, major } = cvEducation;
  const { time } = getTime(cvEducation);
  return (
    <Box p=".4em 1.6em" {...activeColors}>
      <Box lineHeight="1em" fontWeight={600} className="u-text-uppercase">
        {box8em(major)}
      </Box>
      <Box lineHeight="1em" color="#757575">
        {box6em(`${school} | ${time}`)}
      </Box>
      <Box lineHeight="1em" mt="0.2em" mb="0.2em">
        <AQuillContent content={activities} fontSize="0.6em" />
      </Box>
    </Box>
  );
};

const ProgressBox: FC<{ title: string; percent: number; color: string; activeColors: ActiveColors }> = ({
  title,
  percent,
  color,
  activeColors,
}) => {
  return (
    <Box display="flex" alignContent="center" p="0 1.2em" {...activeColors}>
      <Box flexGrow={1} fontWeight="700">
        {box6em(title)}
      </Box>
      <Box minWidth="40%" display="flex" flexDirection="column" justifyContent="center" color="red">
        <Box height="0.4em" borderRadius="0.4em" minHeight="4px" bgcolor="#e9e9e9">
          <Box height="0.4em" borderRadius="0.4em" minHeight="4px" width={`${percent}%`} bgcolor={color} />
        </Box>
      </Box>
    </Box>
  );
};

const CvTitle: FC<{ title: string }> = ({ title }) => {
  return (
    <Box lineHeight="1.6em" fontWeight={600} border="1px solid #9E9E9E" width="8em">
      <Box fontSize="1em" textAlign="center">
        {title}
      </Box>
    </Box>
  );
};

const SkillItem: FC<{
  title: string;
  point: number;
  isShowProficiency: boolean;
  activeColors: ActiveColors;
  color: string;
}> = ({ title, point, isShowProficiency, activeColors, color }) => {
  if (isShowProficiency) {
    return <ProgressBox title={title} percent={(point * 100) / 5} activeColors={activeColors} color={color} />;
  }
  return (
    <Box display="flex" p="0 1.2em" gridGap=".2em" alignItems="center" {...activeColors}>
      <Box bgcolor={color} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const FREE_0006: FC<OCVProps> = (props) => {
  const {
    cvSettings: { currentCvColor, currentCvFontSize },
  } = useSelector((state: AppState) => state);
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvColour = null,
    savedCvFontSize,
    savedCvFont,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const cvColor = savedCvColour || currentCvColor;
  const { basicInfo, experience, education, skills } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    date_of_birth,
    cv_email,
    skype,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const { t } = useTranslation('common');
  const fontSizeRatio = Math.round(((savedCvFontSize || currentCvFontSize) / 16) * textSize);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'JHON DEE';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'yourmail@domain.com';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Placerat amet enim augue dapibus amet, egestas molestie. Ac nisl, ornare diam tempus vel nulla tellus. Sit blandit quam eget nibh commodo tortor. Id auctor diam ultricies sit facilisis commodo. Lorem ipsum dolor sit amet.`;
  let userDateOfBirth = date_of_birth === undefined ? '16 July 1994' : date_of_birth;
  userDateOfBirth = userDateOfBirth ? formatDate(userDateOfBirth, 'DD MMMM YYYY') : userDateOfBirth;
  const userRole = role || 'Digital Marketer';
  const userSkype = skype ?? 'username skype';
  const userAddress = address || 'Melbourne Street Number 4';
  const userCity = city || 'California, New York City';
  const userCountry = country || 'US';
  const userPhoneNumber = mobile_number || '+67-0890-231';

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'www.linkedin.com/username' },
    { type: 'Instagram', link: 'www.instagram.com/username' },
    { type: 'LinkedIn', link: 'www.linkedin.com/username' },
    { type: 'Twitter', link: 'www.twitter.com/username' },
    { type: 'Website', link: 'www.your-website.com' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];

  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, [(e) => e.link.length], ['asc']);

  const dummyExperiences = Array(2).fill({
    role: 'ENTER JOB TITLE HERE',
    company: 'Company / Institute Name',
    location: 'Location',
    from_year: '01-01-2016',
    to_year: '01-01-2018',
    finished: true,
    activities: `• Lorem ipsum dolor sit amet, consectetur adipiscing elit.
• Donec lorem arcu mattis et.
• Sollicitudin ultrices duis ac turpis quam.
• At velit pharetra ut ut duis. Molestie sit integer sit morbi venenatis.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University / Institute Name',
    degree: 'ENTER YOUR DEGREE',
    grade: '4.75',
    major: 'ENTER YOUR BACHELORS DEGREE',
    from_year: '01-05-2014',
    to_year: '01-05-2018',
    finished: true,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lorem arcu mattis et. Sollicitudin ultrices duis ac turpis quam ultricies leo vel. At velit pharetra ut ut duis.`,
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#CCEAFC' } : {};
  };

  const renderPersonalInfo = () => {
    return [
      <Box key="personalInfo" p="1.2em 1em 0.4em" {...activeColors(1)}>
        {isShowAvatar && (
          <Box className="u-flex-center" mb="1.2em">
            <Box height="3.5em" minWidth="3.5em" maxWidth="3.5em">
              <img height="100%" width="100%" src={userAvatar} alt="" className="u-border-rounded o-base-cv__avatar" />
            </Box>
          </Box>
        )}
        <Box mb="1em">
          <Box fontWeight={600}>PERSONAL INFO</Box>
          {userDateOfBirth && <UserLinkInfo title="Date of birth" info={userDateOfBirth as string} />}
          <UserLinkInfo title="Address" info={`${userAddress}, ${userCity}, ${userCountry}`} />
        </Box>
        <Box fontWeight={600}>CONTACT</Box>
        <UserLinkInfo title="Phone" info={userPhoneNumber} />
        <UserLinkInfo title="Email" info={userEmail} />
        {!!userSkype.length && <UserLinkInfo title="Skype" info={userSkype} />}
        {!!userSocialMediaShowed.length && (
          <>
            <Box fontWeight={600} mt="1.4em">
              SOCIAL MEDIA
            </Box>
            {userSocialMediaShowed.map((e) => (
              <UserSocialMediaInfo key={e.type} title={e.type} info={e.link} />
            ))}
          </>
        )}
      </Box>,
    ];
  };

  const renderSkill = () => {
    if (isEmpty(userSkills)) return [];

    return [
      <Box key="skills" p=".8em 1em" {...activeColors(4)}>
        <Box fontWeight={600}>SKILLS</Box>
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          key={`skill${name}${index}${points}`}
          title={name}
          point={points}
          isShowProficiency={isShowProficiency}
          activeColors={activeColors(4)}
          color={cvColor}
        />
      )),
    ];
  };

  const renderExperience = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experiences" p="0.4em 1.6em" {...activeColors(2)}>
        <CvTitle title="EXPERIENCE" />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`${item.companyInfo}${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderEducation = () => {
    if (isEmpty(userEducation)) return [];
    return [
      <Box key="education" p="0.4em 1.6em" {...activeColors(3)}>
        <CvTitle title="EDUCATION" />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderProfile = () => {
    return [
      <Box key="profile" p=".8em 1.6em" {...activeColors(1)}>
        <Box fontWeight={600} letterSpacing="0.05em" maxWidth="14em" zIndex={2} position="relative">
          <Box fontSize="1.5em">{`${userFirstName} ${userLastName}`}</Box>
        </Box>
        <Box mt="0.8em">
          <Box fontSize="0.6em" color="#757575">
            {userRole.toUpperCase()}
          </Box>
        </Box>
      </Box>,
    ];
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];

    return [
      <Box key="summary" p=".4em 1.6em 0.8em" {...activeColors(1)}>
        <CvTitle title="PROFILE" />
        <Box lineHeight="0.817em" mt="0.8em">
          <Box className="u-pre-line">
            <AQuillContent content={userAboutMe} fontSize="0.6em" />
          </Box>
        </Box>
      </Box>,
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderPersonalInfo(), ...renderSkill()],
    rightComponents: [...renderProfile(), ...renderSummary(), ...renderExperience(), ...renderEducation()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont}>
      <Box minHeight="54.164em" height="54.164em" position="relative" display="flex">
        <Box p="1.5em 0em 1.5em 1.5em" height="54.164em" minWidth="48%" maxWidth="48%">
          <Box
            id={idLeft}
            overflow="hidden"
            textAlign="left"
            color="black"
            border="1px solid #9E9E9E"
            height="100%"
            fontSize={fontSizeRatio}
          >
            {renderedLeft}
            <Box height="1em" fontSize={textSize} />
          </Box>
        </Box>
        <Box p="1.5em 1.5em 1.5em 0" flexGrow={1} id={idRight} height="100%" fontSize={fontSizeRatio}>
          {renderedRight}
          <Box height="2.6em" fontSize={textSize} />
        </Box>
        <MCvWatermark />
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <FREE_0006_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
            textSize={textSize}
          />
        </>
      )}
    </Box>
  );
};

export default FREE_0006;
