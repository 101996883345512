import { FC } from 'react';
import Box from '@material-ui/core/Box';

interface MCvWatermarkProps {
  fontSize?: number;
}

const MCvWatermark: FC<MCvWatermarkProps> = ({ fontSize }) => {
  return (
    <Box
      fontSize={fontSize}
      position="absolute"
      bottom=".5em"
      right=".5em"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      className="m-cv-watermark"
    >
      <Box fontSize="0.6em">{`CV Builder by `}</Box>
      <Box height="2em" width="7em">
        <img height="100%" width="100%" src="/assets/cv_watermark.png" alt="UrbanCV" />
      </Box>
    </Box>
  );
};

export default MCvWatermark;
