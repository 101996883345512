import { FC, ReactElement } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';

import { getCVAvatar, getLanguageLevel, useCVHelper, useCvTwoLayout } from 'helpers';
import { getYearOrExample } from 'utils/date';
import { ActiveColors, ProfileEducation, ProfileExperience, ProfileLanguage } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AAddress,
  ADribbble,
  AEducation,
  AEmail,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  AProfile,
  ASkillsSecond,
  AWebsite,
  AWork,
} from 'components/atoms/icons';
import { ISocialIcon } from 'components/atoms/icons/ASkype';
import PREMIUM_0012_BACK from './back';
import { OCVProps, previewType } from '../../index';

const SkillItem: FC<{ title: string; point: number; isShowProficiency: boolean; activeColors: ActiveColors }> = ({
  title,
  point,
  isShowProficiency,
  activeColors,
}) => {
  if (isShowProficiency) {
    return (
      <Box width="40%" display="inline-flex" justifyContent="space-between" p=".4em 0 .4em 2.8em" {...activeColors}>
        <Box fontSize=".5em">{title}</Box>
        <Box display="flex" gridGap=".36em">
          {Array.from(Array(5), (_, index) => (
            <Box
              key={index + title}
              borderRadius="50%"
              height=".4em"
              bgcolor={index + 1 <= parseFloat(`${point}`) ? 'black' : '#CBCCCB'}
              width=".4em"
              minWidth=".4em"
              minHeight=".4em"
            />
          ))}
        </Box>
      </Box>
    );
  }
  return (
    <Box width="30%" display="flex" alignItems="center" gridGap=".2em" p=".4em 0 .4em 2.8em" {...activeColors}>
      <Box bgcolor="black" width=".2em" height=".2em" borderRadius="1em" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, from_year, to_year, finished, activities } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box fontWeight={400} p=".4em 2.6em .4em 2.8em" {...activeColors}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box fontSize=".51em" className="u-text-uppercase" whiteSpace="nowrap">
          {`> ${role}`}
        </Box>
        <Box fontSize=".5em">{`${joinDate} - ${leaveDate}`}</Box>
      </Box>
      <Box mt=".3em">
        <AQuillContent color="#71726E" fontSize=".5em" content={activities} />
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation; activeColors: ActiveColors }> = ({
  cvEducation,
  activeColors,
}) => {
  const { school, to_year, finished, from_year, activities } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box fontWeight={400} p=".4em 2.6em .4em 2.8em" {...activeColors}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box fontSize=".51em" className="u-text-uppercase" whiteSpace="nowrap">
          {`> ${school}`}
        </Box>
        <Box fontSize=".5em">{`${joinDate} - ${leaveDate}`}</Box>
      </Box>
      <Box mt=".3em">
        <AQuillContent color="#71726E" fontSize=".5em" content={activities} />
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    LinkedIn: AOutlinedLinkedin,
    Instagram: AOutlinedInstagram,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG: FC<ISocialIcon> = componentIcons[type];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="60%" height="60%" />;
  };
  return (
    <Box display="flex" gridGap=".5em" alignItems="center">
      <Box borderRadius="50%" bgcolor="#343534" className="u-flex-center" width="1em" height="1em">
        {type && svgIcons()}
      </Box>
      <Box fontWeight={400} fontSize=".5em">
        {link}
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const componentIcons = {
    email: AEmail,
    phone: APhone,
    address: AAddress,
  };
  const svgIcon = () => {
    const SVG = componentIcons[type];
    return <SVG fill="#343534" strokeWidth="0" width="1.2em" height="1.2em" />;
  };
  return (
    <Box fontWeight={400} fontSize=".5em" display="flex" gridGap=".6em" alignItems="center">
      <Box>{type && svgIcon()}</Box>
      <Box className="u-word-break-all" dangerouslySetInnerHTML={{ __html: link }} textAlign="left" />
    </Box>
  );
};

const SubTitle: FC<{ name: string; right?: boolean; theme?: string; icon?: ReactElement } & BoxProps> = ({
  name,
  right,
  theme,
  icon,
  ...props
}) => {
  if (right) {
    return (
      <Box display="flex" gridGap=".56em">
        <Box width="1.2em" height="1.2em" borderRadius="50%" className="u-flex-center" bgcolor={theme}>
          {icon}
        </Box>
        <Box flex={1} fontSize=".6em" borderBottom="1px solid black">
          {name}
        </Box>
      </Box>
    );
  }
  return (
    <Box fontSize=".6em" width="100%" color="white" bgcolor="#343534" textAlign="center" p=".4em" {...props}>
      {name}
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage }> = ({ language }) => {
  const { name, speaking, writing } = language;
  return (
    <Box fontWeight={400} fontSize=".5em">
      <Box mb=".4em" className="u-text-uppercase">
        {name}
      </Box>
      <Box mb=".2em">Write: {getLanguageLevel(writing)}</Box>
      <Box>Spoken: {getLanguageLevel(speaking)}</Box>
    </Box>
  );
};

const PREMIUM_0012: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    preview = previewType.cvDemoThumbnail,
    isActive = 0,
    textSize,
    isShowAvatar = true,
    savedCvFont,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, languages, interest } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'HENRY SILLY';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userAboutMe =
    summary ??
    `My Name Is Henry Silly Lorem Ipsum is simply dummy text of the into electronic typesetting, remaining essentially unchanged. It was popularised in the printer took a galley of type and scrambled it to make a type printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown men book. It has survived not only ﬁve centuries, but also the leap`;

  const userRole = role || 'WEB DESIGN & DEVELOPER';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';

  const dummyLanguages = [{ name: 'English', speaking: 5, writing: 3 }];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(3).fill({
    school: 'UNIVERSITY NAME',
    degree: 'Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      'Lorem ipsum enunc pulvinati ncidunt. quis pul vinar mellu Lorem ipsum dolor: sit amet consectet gelit. it ellentesque eleife ornare ',
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(6).fill({
    skill: 'PHP',
    name: 'PHP',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;
  const userInterest = interest ?? [
    'Project Management',
    'Graphic Design',
    'Computer Language',
    'Mobile & Cloud Solution',
    'Event Planning',
  ];

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'facebook.com/username' },
    { type: 'Instagram', link: 'instagram.com/username' },
    { type: 'LinkedIn', link: 'linkedin.com/username' },
    { type: 'Twitter', link: 'twitter.com/username' },
    { type: 'Website', link: 'Henrysilly.com' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(3).fill({
    role: 'Business Owner',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: '#e8e8e8', color: 'black' } : {};
  };

  const renderProfile = () => {
    return [
      <Box
        key="profile"
        className="u-text-uppercase"
        textAlign="center"
        p="2.6em 2em 3.5em 1.3em"
        bgcolor={isActive === 1 ? '#e8e8e8' : ''}
      >
        {isShowAvatar && (
          <Box mx="auto" mb="1.2em" height="6.4em" minWidth="6.4em" maxWidth="6.4em">
            <img
              height="100%"
              width="100%"
              src={userAvatar}
              alt="profile-pict"
              className="u-border-rounded o-base-cv__avatar"
            />
          </Box>
        )}
        <Box mb=".4em" fontSize="1.2em" lineHeight="1.4em">
          {userFirstName} {userLastName}
        </Box>
        <Box fontWeight={400} mt=".4em" fontSize=".5em">
          {userRole}
        </Box>
      </Box>,
      <Box position="relative" key="social">
        <Box {...activeColors(1)} p=".5em 2em">
          <SubTitle name="CONTACT" mt="-2em" />
          <Box mt=".9em" display="flex" flexDirection="column" gridGap=".9em">
            {contacts.map((item, idx) => (
              <UserContactsBox key={idx + item.type} type={item.type} link={item.link} />
            ))}
          </Box>
        </Box>
        {!!userSocialMediaShowed.length && (
          <Box {...activeColors(1)} p=".5em 2em">
            <SubTitle name="SOCIAL MEDIA" />
            <Box mt=".9em" display="flex" flexDirection="column" gridGap=".9em">
              {userSocialMediaShowed.map((item, idx) => (
                <UserSocialBox key={idx + item.type} type={item.type} link={item.link} />
              ))}
            </Box>
          </Box>
        )}
      </Box>,
    ];
  };

  const renderCertification = () => {
    return [
      <Box position="relative" key="certification" {...activeColors(5)}>
        {!!userLanguages.length && (
          <Box {...activeColors(5)} p=".5em 2em">
            <SubTitle name="LANGUAGE" />
            <Box mt=".9em" display="flex" flexDirection="column" gridGap=".9em" textAlign="left">
              {userLanguages.map((item, index) => (
                <UserLanguageInfo key={index} language={item} />
              ))}
            </Box>
          </Box>
        )}
        {!!userInterest.length && (
          <Box {...activeColors(5)} p=".5em 2em 3em">
            <SubTitle name="INTERESTS" />
            <Box
              fontWeight={400}
              fontSize=".5em"
              flexWrap="wrap"
              alignItems="center"
              mt="1.8em"
              display="flex"
              gridGap=".9em"
            >
              {userInterest.map((item, index) => (
                <Box width="45%" key={index} textAlign="left">
                  {item}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>,
    ];
  };

  const renderSummary = () => {
    if (!userAboutMe.trim()) return [];
    return [
      <Box key="summary" p="3em 2.6em 1.2em 2.8em" {...activeColors(1)}>
        <SubTitle
          name="ABOUT ME"
          icon={<AProfile fill="#272726" width="60%" height="60%" stroke="" />}
          theme={cvColor}
          whiteSpace="nowrap"
          right
        />
        <Box mt=".8em">
          <AQuillContent content={userAboutMe} fontWeight={400} fontSize=".5em" />
        </Box>
      </Box>,
    ];
  };

  const renderEducation = () => {
    if (!userEducation.length) return [];
    return [
      <Box key="education" p="1.2em 2.6em 1.2em 2.8em" {...activeColors(3)}>
        <SubTitle
          name="EDUCATION"
          icon={<AEducation fill="#272726" width="60%" height="60%" stroke="" />}
          theme={cvColor}
          whiteSpace="nowrap"
          right
        />
      </Box>,
      ...userEducation.map((item, index) => (
        <UserEducationInfo key={`${item.school}${index}`} cvEducation={item} activeColors={activeColors(3)} />
      )),
    ];
  };

  const renderExperience = () => {
    if (!userExperiences.length) return [];
    return [
      <Box key="experience" p="1.2em 2.6em 1.2em 2.8em" {...activeColors(2)}>
        <SubTitle
          name="EXPERIENCE"
          icon={<AWork fill="#292926" width="60%" height="60%" stroke="" />}
          theme={cvColor}
          whiteSpace="nowrap"
          right
        />
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`${item.companyInfo}${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const renderSkills = () => {
    if (!userSkills.length) return [];
    return [
      <Box key="skills" p="1.2em 2.6em 1.2em 2.8em" {...activeColors(4)}>
        <SubTitle
          name="SKILLS"
          icon={<ASkillsSecond fill="#272726" width="60%" height="60%" stroke="" />}
          theme={cvColor}
          whiteSpace="nowrap"
          right
        />
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          key={`skill${name}${index}${points}`}
          title={name}
          point={points}
          isShowProficiency={isShowProficiency}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderProfile(), ...renderCertification()],
    rightComponents: [...renderSummary(), ...renderEducation(), ...renderExperience(), ...renderSkills()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont || 'inherit'}>
      <Box minHeight="54.164em" height="54.164em" position="relative" display="flex">
        <Box
          minWidth="31.85%"
          maxWidth="31.85%"
          maxHeight="100%"
          height="100%"
          id={idLeft}
          overflow="hidden"
          textAlign="center"
          bgcolor={cvColor}
          fontSize={fontSizeRatio || 'inherit'}
        >
          {renderedLeft}
          <Box height="1.6em" />
        </Box>
        <Box
          flexGrow={1}
          maxHeight="100%"
          height="100%"
          id={idRight}
          overflow="hidden"
          fontSize={fontSizeRatio || 'inherit'}
        >
          {renderedRight}
          <Box height="1.6em" />
        </Box>
      </Box>
      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0012_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0012;
