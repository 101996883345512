import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AOutlinedInstagram: FC<ISocialIcon> = ({
  fill = 'black',
  width = '9',
  height = '9',
  stroke = 'black',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.83373 0.438372H5.98305C7.19687 0.438372 8.19005 1.43179 8.19005 2.6455V5.79481C8.19005 7.00864 7.19688 8.00182 5.98305 8.00182H2.83373C1.62002 8.00182 0.626605 7.00864 0.626605 5.79481V2.6455C0.626605 1.43179 1.62003 0.438372 2.83373 0.438372ZM6.72047 5.80192C6.41619 5.80192 6.16975 6.04819 6.16975 6.35252C6.16975 6.65682 6.41616 6.90324 6.72047 6.90324C7.0248 6.90324 7.27106 6.6568 7.27106 6.35252C7.27106 6.04834 7.02465 5.80192 6.72047 5.80192ZM4.40827 2.21389C3.30036 2.21389 2.40225 3.11214 2.40225 4.22003C2.40225 5.32792 3.30023 6.22618 4.40827 6.22618C5.52244 6.22618 6.41428 5.32116 6.41428 4.22003C6.41428 3.11214 5.51617 2.21389 4.40827 2.21389ZM5.54488 4.22003C5.54488 4.84771 5.03594 5.35665 4.40827 5.35665C3.78059 5.35665 3.27165 4.84759 3.27165 4.22003C3.27165 3.59247 3.78059 3.08342 4.40827 3.08342C5.03594 3.08342 5.54488 3.59236 5.54488 4.22003Z"
        stroke={stroke}
        fill={fill}
        strokeWidth=".01em"
      />
    </svg>
  );
};
