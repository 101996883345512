import { FC } from 'react';
import Box from '@material-ui/core/Box';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

export interface InputProps {
  id: string;
  title?: string;
  customWidth?: boolean;
  helperText?: string;
  isMinHeight?: boolean;
  showHelperText?: boolean;
  hiddenTitle?: boolean;
}

const MTextField: FC<InputProps & TextFieldProps> = ({
  title,
  id,
  hiddenTitle = false,
  type = 'text',
  customWidth = false,
  isMinHeight = true,
  showHelperText = false,
  variant = 'outlined',
  error,
  helperText = '',
  value,
  ...other
}) => (
  <div className={`m-text-field ${isMinHeight ? '' : 'm-text-field--no-height'}`}>
    {!!title && (
      <Box mb={5}>
        <label htmlFor={id} className={error ? 'u-color-error' : ''}>
          {title}
        </label>
      </Box>
    )}
    {hiddenTitle && <Box height={29} />}
    <TextField
      className="c-input"
      fullWidth={!customWidth}
      variant={variant}
      id={id}
      type={type}
      error={error}
      helperText={showHelperText || error ? helperText : ''}
      value={value || ''}
      {...other}
    />
  </div>
);

export default MTextField;
