import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';

import { getCVAvatar, getLanguageLevel } from 'helpers';
import { getYearOrExample } from 'utils/date';
import {
  AppState,
  ProfileCertificate,
  ProfileEducation,
  ProfileExperience,
  ProfileLanguage,
} from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AAddress,
  ADribbble,
  AEmail,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  ASkype,
  AWebsite,
} from 'components/atoms/icons';

import { OCVProps, previewType } from '../index';

const ProgressBox: FC<{ title: string; percent: number; theme: string }> = ({ title, percent, theme }) => {
  return (
    <Box>
      <Box mb=".4em">
        <Box fontSize="0.5em" width="100%" className="u-text-uppercase">
          {title}
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        height=".45em"
        position="relative"
        border={`1px solid ${theme}`}
      >
        <Box
          bgcolor={theme}
          width={`calc(${percent}% + 2px)`}
          height="calc(100% + 2px)"
          position="absolute"
          top="-1px"
          left="-1px"
        />
      </Box>
    </Box>
  );
};
const SkillItem: FC<{ title: string; point: number; theme: string; isShowProficiency: boolean }> = ({
  title,
  point,
  theme,
  isShowProficiency,
}) => {
  if (isShowProficiency) {
    return <ProgressBox theme={theme} title={title} percent={(point * 100) / 5} />;
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center">
      <Box bgcolor={theme} width=".2em" height=".2em" borderRadius="50%" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience }> = ({ cvExperience }) => {
  const { role, from_year, to_year, finished, activities, company, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box display="flex" alignItems="center" flexDirection="column" gridGap=".4em">
      <Box
        color="#484545"
        fontSize=".5em"
        fontWeight={600}
      >{`${role}, ${joinDate} - ${leaveDate} | ${company} | ${location}`}</Box>
      <AQuillContent color="#757575" textAlign="center" fontWeight={400} fontSize=".5em" content={activities} />
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation }> = ({ cvEducation }) => {
  const { school, to_year, finished, from_year, degree, grade } = cvEducation;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year, '2020');

  return (
    <Box fontWeight={400} display="flex" flexDirection="column" alignItems="end" gridGap=".2em">
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
        <Box fontSize=".5em" color="#212121">
          {`${joinDate} - ${leaveDate}`}
        </Box>
        <Box borderTop=".12em solid" minWidth="25%" flex={1} mx=".5em" />
        <Box fontWeight={700} fontSize=".6em" color="#212121" textAlign="right">
          {degree}
        </Box>
      </Box>
      <Box fontSize=".5em" color="#212121">
        {school}
      </Box>
      <Box fontSize=".5em" color="#212121">
        Grade: {grade}
      </Box>
    </Box>
  );
};

const UserSocialBox: FC<{ img: string; link: string; type: string }> = ({ img, link, type }) => {
  const componentIcons = {
    Twitter: AOutlinedTwitter,
    Facebook: AOutlinedFacebook,
    Instagram: AOutlinedInstagram,
    LinkedIn: AOutlinedLinkedin,
    Website: AWebsite,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const svgIcons = () => {
    const SVG = componentIcons[img];
    return <SVG fill="white" stroke="white" strokeWidth="0" width="80%" height="80%" />;
  };
  return (
    <Box width="23%" display="flex" gridGap=".8em" alignItems="center" color="#272826" mb=".3em">
      <Box borderRadius="50%" bgcolor="#272826" className="u-flex-center" width="1em" height="1em">
        <Box width="100%" height="100%" className="u-flex-center">
          {img && svgIcons()}
        </Box>
      </Box>
      <Box>
        <Box fontWeight={600} fontSize=".7em">
          {type}
        </Box>
        <Box fontWeight={400} fontSize=".5em">
          {link}
        </Box>
      </Box>
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const componentIcons = {
    email: AEmail,
    phone: APhone,
    address: AAddress,
    Skype: ASkype,
  };
  const svgIcon = () => {
    const SVG = componentIcons[type];
    return <SVG fill="#132A39" strokeWidth="0" width=".7em" height=".7em" />;
  };
  return (
    <Box display="flex" color="#494946" gridGap=".5em" alignItems="center" fontWeight={400}>
      <Box>{type && svgIcon()}</Box>
      <Box fontSize=".6em">{link}</Box>
    </Box>
  );
};

const SubTitle: FC<{ name: string } & BoxProps> = ({ name, ...props }) => {
  return (
    <Box color="#212121" fontSize=".8em" whiteSpace="nowrap" {...props} fontWeight={700}>
      {name}
    </Box>
  );
};

const UserLanguageInfo: FC<{ language: ProfileLanguage }> = ({ language }) => {
  const { name, speaking, writing } = language;
  return (
    <Box fontWeight={400} color="#333333" display="flex" flexDirection="column ">
      <Box fontSize=".6em" fontWeight={600}>
        {name}
      </Box>
      <Box mt=".5em" fontSize=".5em">
        Write: {getLanguageLevel(writing)}
      </Box>
      <Box mt=".3em" fontSize=".5em">
        Spoken: {getLanguageLevel(speaking)}
      </Box>
    </Box>
  );
};

const UserCertification: FC<{ data?: ProfileCertificate }> = ({ data }) => {
  const { certificate, date, organizer, expiration_date, does_not_expire } = data;
  const joinDate = getYearOrExample(date);
  const leaveDate = does_not_expire ? 'Present' : getYearOrExample(expiration_date, '2020');

  return (
    <Box fontWeight={400} display="flex" flexDirection="column" gridGap=".2em">
      <Box fontWeight={700} fontSize=".6em" color="#333333">
        {certificate}
      </Box>
      <Box fontWeight={400} fontSize=".5em">
        {organizer} | {`${joinDate} - ${leaveDate}`}
      </Box>
    </Box>
  );
};

const PREMIUM_0020: FC<OCVProps> = (props) => {
  const {
    cvSettings: { currentCvColor, currentCvFontSize },
  } = useSelector((state: AppState) => state);
  const {
    cvInfo,
    isActive = 0,
    textSize,
    savedCvColour = null,
    savedCvFontSize,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
  } = props;
  const { basicInfo, experience, education, skills, certifications, languages } = cvInfo || {
    basicInfo: {},
  };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    skype,
    mobile_number,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const fontSize = Math.round(((savedCvFontSize || currentCvFontSize) / 16) * textSize);
  const cvColor = savedCvColour || currentCvColor;
  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'LEONARD OLIVEIRA';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'Yourname@gmail.com';
  const userSkype = skype ?? 'username skype';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie sed risus porta in elit rhoncus.
    Purus scelerisque curabitur enim in turpis. Sed condimentum eu diam lorem. Sed condimentum eu diam lorem. Sed condimentum eu diam lorem. Elit felis morbi amet, non congue libero mauris volutpat diam. Sed condimentum eu diam lorem. Elit felis morbi amet, non congue libero mauris volutpat diam.`;

  const userRole = role || 'DIRECTOR OF MARKETING';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+012 345 678 900';

  const dummyLanguages = [
    { name: 'English', speaking: 5, writing: 3 },
    { name: 'French', speaking: 5, writing: 1 },
  ];
  const userLanguages = languages ?? dummyLanguages;
  const dummyEducation: ProfileEducation[] = Array(3).fill({
    school: 'University of Tech',
    degree: 'Bachelor Degree',
    grade: '4.75',
    major: 'business management',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: true,
    activities:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  });
  const userEducation = education ?? dummyEducation;
  const dummySkills: CvSkills[] = Array(5).fill({
    skill: 'Javascript',
    name: 'Javascript',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'username facebook' },
    { type: 'Instagram', link: 'username instagram' },
    { type: 'LinkedIn', link: 'username linkedin' },
    { type: 'Twitter', link: 'username twitter' },
    { type: 'Website', link: 'www.website.com' },
    { type: 'Github', link: 'username github' },
    { type: 'Dribbble', link: 'username dribbble' },
  ];
  const contacts = [
    { type: 'phone', link: userPhoneNumber },
    { type: 'email', link: userEmail },
    { type: 'Skype', link: userSkype },
    { type: 'address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  const userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);

  const dummyExperiences = Array(3).fill({
    role: 'Junior Data',
    company: 'Tech Company',
    location: 'Location',
    from_year: '01-01-2016',
    to_year: '01-01-2018',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus.`,
  });
  const userExperiences = experience ?? dummyExperiences;
  const dummyCertifications: ProfileCertificate[] = Array(2).fill({
    certificate_id: 'Certification ID',
    date: '2021-02-02',
    organizer: 'AWS',
    certificate: 'AWS Certification Professional',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus amet lobortis risus, ultrices in sit pretium.',
  });
  const userCertifications = certifications ?? dummyCertifications;

  return (
    <Box className="o-base-cv" fontSize={textSize}>
      <Box
        minHeight="54.164em"
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        position="relative"
        fontSize={fontSize || 'inherit'}
        color="#71726E"
      >
        <Box display="flex" width="100%">
          <Box width="100%">
            <Box width="100%" bgcolor={isActive === 1 ? '#B7D8CB' : ''}>
              <Box
                px="4em"
                py="0.8em"
                display="flex"
                alignItems="center"
                gridGap="1em"
                bgcolor={isActive === 1 ? '#B7D8CB' : cvColor}
              >
                <Box
                  py="0.8em"
                  px="3.4375em"
                  border=".12em solid"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                >
                  {isShowAvatar && (
                    <Box height="3em" minWidth="3em" maxWidth="4em">
                      <img
                        height="100%"
                        width="100%"
                        src={userAvatar}
                        alt="profile-pict"
                        className="u-border-rounded o-base-cv__avatar"
                      />
                    </Box>
                  )}
                  <Box pl="0.5em">
                    <Box fontSize="1.2em" fontWeight={700} color="#212121">{`${userFirstName} ${userLastName}`}</Box>
                    <Box color="#212121" fontSize=".5em" fontWeight={400} mt="0.5em">
                      {userRole}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            {userAboutMe.trim() && (
              <Box bgcolor={isActive === 1 ? '#e8e8e8' : ''}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  p="1em 1.6em"
                >
                  <SubTitle name="ABOUT ME" fontWeight={700} />
                  <Box width="2.5em" mb=".5em" mt=".3em" borderBottom=".12em solid" />
                  <AQuillContent
                    textAlign="center"
                    content={userAboutMe}
                    fontWeight={400}
                    lineHeight="1.5em"
                    fontSize=".5em"
                  />
                </Box>
                <Box mx="1.6em" borderBottom=".12em solid" />
              </Box>
            )}

            <Box>
              <Box display="flex" width="100%">
                {!!userEducation.length && (
                  <Box
                    width="50%"
                    display="flex"
                    flexDirection="column"
                    alignItems="end"
                    p="1em 1.6em"
                    bgcolor={isActive === 3 ? '#e8e8e8' : ''}
                  >
                    <SubTitle name="EDUCATION" />
                    <Box display="flex" width="100%" flexDirection="column" gridGap=".8em" mt=".8em">
                      {userEducation.map((item, index) => (
                        <UserEducationInfo key={index} cvEducation={item} />
                      ))}
                    </Box>
                  </Box>
                )}
                <Box borderRight=".12em solid" m="1em 0" />
                {!!userSkills.length && (
                  <Box width="50%" p="1em 1.6em" bgcolor={isActive === 4 ? '#e8e8e8' : ''}>
                    <SubTitle name="SKILLS" mb=".8em" />
                    <Box display="flex" flexDirection="column" gridGap=".8em">
                      {userSkills.map((item, idx) => (
                        <SkillItem
                          isShowProficiency={isShowProficiency}
                          key={idx}
                          title={item.name}
                          point={item.points}
                          theme={cvColor}
                        />
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
              <Box mx="1.6em" borderBottom=".12em solid" />
            </Box>

            {!!userExperiences.length && (
              <Box bgcolor={isActive === 2 ? '#e8e8e8' : ''}>
                <Box p="1em 1.6em">
                  <Box display="flex" justifyContent="center">
                    <SubTitle name="WORK EXPERIENCE" />
                  </Box>
                  <Box display="flex" flexDirection="column" gridGap=".8em" mt=".8em">
                    {userExperiences.map((item, index) => (
                      <UserExperienceInfo key={index} cvExperience={item} />
                    ))}
                  </Box>
                </Box>
                <Box mx="1.6em" borderBottom=".12em solid" />
              </Box>
            )}

            <Box>
              <Box display="flex" width="100%">
                {!!userLanguages.length && (
                  <Box width="33.3%" bgcolor={isActive === 5 ? '#e8e8e8' : ''} p="1em 1.6em">
                    <Box display="flex" justifyContent="flex-start">
                      <SubTitle name="LANGUAGE" mb=".8em" />
                    </Box>
                    <Box display="flex" flexDirection="column" gridGap=".6em">
                      {userLanguages.map((item, index) => (
                        <UserLanguageInfo key={index} language={item} />
                      ))}
                    </Box>
                  </Box>
                )}
                <Box borderRight=".12em solid" m="1em 0" />
                {!!UserContactsBox.length && (
                  <Box width="33.3%" bgcolor={isActive === 1 ? '#e8e8e8' : ''} p="1em 1.6em">
                    <SubTitle name="CONTACT" mb=".8em" />
                    <Box display="flex" flexDirection="column" gridGap=".3em">
                      {contacts.map(
                        (item, idx) =>
                          !!item.link.length && <UserContactsBox key={idx} type={item.type} link={item.link} />,
                      )}
                    </Box>
                  </Box>
                )}
                <Box borderRight=".12em solid" m="1em 0" />
                {!!userCertifications.length && (
                  <Box width="33.3%" bgcolor={isActive === 5 ? '#e8e8e8' : ''} p="1em 1.6em">
                    <SubTitle name="CERTIFICATION" mb=".8em" />
                    <Box display="flex" flexDirection="column" gridGap=".8em">
                      {userCertifications.map((item, index) => (
                        <UserCertification key={index} data={item} />
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
              <Box mx="1.6em" borderBottom=".12em solid" />
            </Box>
          </Box>
        </Box>

        <Box>
          {!!userSocialMediaShowed.length && (
            <Box
              display="flex"
              width="100%"
              p="1em 1.6em"
              bgcolor={isActive === 1 ? '#e8e8e8' : ''}
              justifyContent="flex-start"
              flexWrap="wrap"
              gridGap=".4em"
            >
              {userSocialMediaShowed.map((item, idx) => (
                <UserSocialBox key={idx} type={item.type} img={item.type} link={item.link} />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PREMIUM_0020;
