import { FC } from 'react';
import { ISocialIcon } from './ASkype';

export const AHome: FC<ISocialIcon> = ({
  fill = 'black',
  width = '12',
  height = '12',
  stroke = 'black',
  strokeWidth = '.01em',
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.55822 12V9.7876L6.00055 8.05324L8.44178 9.7876V12H12V4.26374L6.00055 0L0 4.26374V12H3.55822Z"
        stroke={stroke}
        fill={fill}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
