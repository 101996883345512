import { FC } from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import { getCVAvatar, useCvTwoLayout, useCVThreeLayout, useCVHelper } from 'helpers';
import { getYearOrExample } from 'utils/date';
import { ProfileEducation, ProfileExperience, ProfileReference, ActiveColors } from 'redux-saga/interfaces';
import { CvBasicInfo, CvSkills } from 'components/organisms/Onboarding/interface';
import AQuillContent from 'components/atoms/AQuillContent';
import {
  AAddress,
  ADribbble,
  AEmail,
  AGithub,
  AOutlinedFacebook,
  AOutlinedInstagram,
  AOutlinedLinkedin,
  AOutlinedTwitter,
  APhone,
  ASkype,
  ISocialIcon,
} from 'components/atoms/icons';
import PREMIUM_0009_BACK from './back';
import { OCVProps, previewType } from '../../index';

const ProgessBox: FC<{ title: string; percent: number; color: string; activeColors: ActiveColors }> = ({
  title,
  percent,
  color,
  activeColors,
}) => {
  return (
    <Box pt=".7em" px="1.5em" {...activeColors}>
      <Box fontSize="0.5em" width="100%" mb=".2em">
        {title}
      </Box>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        height="0.45em"
        position="relative"
        border={`1px solid ${color}`}
      >
        <Box
          bgcolor={color}
          width={`calc(${percent}% + 2px)`}
          height="calc(100% + 2px)"
          position="absolute"
          top="-1px"
          left="-1px"
        />
      </Box>
    </Box>
  );
};
const SkillItem: FC<{
  title: string;
  point: number;
  isShowProficiency: boolean;
  color: string;
  activeColors: ActiveColors;
}> = ({ title, point, isShowProficiency, color, activeColors }) => {
  if (isShowProficiency) {
    return <ProgessBox title={title} percent={(point * 100) / 5} color={color} activeColors={activeColors} />;
  }
  return (
    <Box display="flex" gridGap=".2em" alignItems="center" pt=".7em" px="1.5em" {...activeColors}>
      <Box bgcolor={color} width=".25em" height=".25em" borderRadius="50%" mr=".5em" />
      <Box fontSize=".5em">{title}</Box>
    </Box>
  );
};

const UserExperienceInfo: FC<{ cvExperience?: ProfileExperience; activeColors: ActiveColors }> = ({
  cvExperience,
  activeColors,
}) => {
  const { role, company, from_year, to_year, finished, activities, location } = cvExperience;
  const joinDate = getYearOrExample(from_year);
  const leaveDate = !finished ? 'Present' : getYearOrExample(to_year);
  return (
    <Box pt=".9em" px="1.5em" {...activeColors}>
      <Box fontWeight={700} fontSize="0.65em" mb=".3em" className="u-text-line">
        {role}
      </Box>
      <Box fontSize="0.5em" color="#9E9E9E" lineHeight="1" mb="1em" className="u-text-line">
        {company} | {location} | {joinDate} - {leaveDate}
      </Box>
      <Box>
        <AQuillContent content={activities} fontSize="0.5em" color="#484545" />
      </Box>
    </Box>
  );
};

const UserEducationInfo: FC<{ cvEducation?: ProfileEducation }> = ({ cvEducation }) => {
  const { school, major, degree, grade } = cvEducation;

  return (
    <Box display="flex" flexDirection="column" gridGap=".3em">
      <Box fontWeight="bold" className="u-text-line" fontSize="0.65em">
        {`${degree} ${major}`}
      </Box>
      <Box color="#484545" fontSize="0.5em" fontWeight={400}>
        {school}
      </Box>
      <Box color="#484545" fontSize="0.5em" fontWeight={400}>{`Grade: ${grade}`}</Box>
    </Box>
  );
};

const UserSocialBox: FC<{ info: string; img: string }> = ({ info, img }) => {
  const svgLogo = {
    Facebook: AOutlinedFacebook,
    Instagram: AOutlinedInstagram,
    LinkedIn: AOutlinedLinkedin,
    Twitter: AOutlinedTwitter,
    Github: AGithub,
    Dribbble: ADribbble,
  };

  const SVG: FC<ISocialIcon> = svgLogo[img];

  return (
    <Box display="flex" alignItems="center" gridGap=".36em">
      <Box width=".7em" height=".7em" className="u-flex-center">
        {img && (
          <SVG
            strokeWidth=".01em"
            stroke="#484545"
            fill={['Github', 'Dribbble'].includes(img) ? '#484545' : 'white'}
            width="100%"
            height="100%"
          />
        )}
      </Box>
      <Box color="#484545" fontSize="0.5em" fontWeight={400}>
        {info}
      </Box>
    </Box>
  );
};

const SubTitle: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box fontWeight={700} fontSize=".9em" {...props}>
      {children}
    </Box>
  );
};

const UserContactsBox: FC<{ type: string; link: string }> = ({ type, link }) => {
  const contact = {
    Phone: APhone,
    Email: AEmail,
    Address: AAddress,
    Skype: ASkype,
  };

  const Icon: FC<ISocialIcon> = contact[type];
  return (
    <Box borderBottom="1px solid #757575" py=".5em" display="flex" gridGap=".5em" alignItems="center">
      <Box width=".8em" height=".8em" className="u-flex-center">
        <Icon height="100%" width="100%" fill="#212121" />
      </Box>
      <Box className="u-word-break-all" fontSize=".5em" flex={1}>
        {link}
      </Box>
    </Box>
  );
};

const UserReference: FC<{ cvReference?: ProfileReference; activeColors: ActiveColors }> = ({
  cvReference,
  activeColors,
}) => {
  const { name, position, company, email, mobile } = cvReference;
  return (
    <Box display="flex" flexDirection="column" gridGap=".6em" minWidth="40%" px="1.5em" pt=".8em" {...activeColors}>
      <Box display="flex" flexDirection="column" gridGap=".2em">
        <Box fontSize=".6em" fontWeight={600}>
          {name}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          {position}
        </Box>
        <Box fontSize=".5em" fontWeight={400}>
          {company}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gridGap=".2em">
        <Box fontSize=".5em" fontWeight={400} whiteSpace="pre">
          <Box component="span" fontWeight={700} mr=".2em">
            P |
          </Box>
          {mobile}
        </Box>
        <Box fontSize=".5em" fontWeight={400} whiteSpace="pre-line" className="u-word-break-all">
          <Box component="span" fontWeight={700} mr=".2em">
            E |
          </Box>
          {email}
        </Box>
      </Box>
    </Box>
  );
};

const PREMIUM_0009: FC<OCVProps> = (props) => {
  const {
    cvInfo,
    isActive = 0,
    textSize,
    preview = previewType.cvDemoThumbnail,
    isShowAvatar = true,
    isShowProficiency = true,
    savedCvFont,
  } = props;
  const { basicInfo, experience, education, skills, reference, interest } = cvInfo || { basicInfo: {} };
  const {
    avatar,
    first_name,
    last_name,
    cv_email,
    address,
    city,
    country,
    role,
    summary,
    mobile_number,
    skype,
    socialMedia,
  } = basicInfo as CvBasicInfo;
  const [fontSizeRatio, cvColor, t] = useCVHelper(props);

  const userAvatar = getCVAvatar(avatar, preview);
  const userFirstName = first_name || 'NATH COLLIN';
  const userLastName = last_name || '';
  const userEmail = cv_email || 'yourmail@domain.com';
  const userSkype = skype ?? 'username skype';
  const userAboutMe =
    summary ??
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie sed risus porta in elit rhoncus.
    Purus scelerisque curabitur enim in turpis. Sed condimentum eu diam lorem. Elit felis morbi amet, non congue libero mauris volutpat diam. Sed condimentum eu diam lorem. Elit felis morbi amet, non congue libero mauris volutpat diam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie sed risus porta in elit rhoncus.
    Purus scelerisque curabitur enim in turpis. Sed condimentum eu diam lorem. Elit felis morbi amet, non congue libero mauris volutpat diam. Sed condimentum eu diam lorem. Elit felis morbi amet, non congue libero mauris volutpat diam.`;

  const userRole = role || 'Business Owner';
  const userAddress = address || '123 Street Name';
  const userCity = city || 'State/City';
  const userCountry = country || 'Country';
  const userPhoneNumber = mobile_number || '+123 4567 8910';

  const userInterest = interest ?? [
    'Project Management',
    'Graphic Design',
    'Computer Language',
    'Mobile & Cloud Solution',
    'Event Planning',
  ];

  let userSocialMedia = socialMedia || [
    { type: 'Facebook', link: 'facebook.com/username' },
    { type: 'Instagram', link: 'instagram.com/username' },
    { type: 'LinkedIn', link: 'linkedin.com/username' },
    { type: 'Twitter', link: 'twitter.com/username' },
    { type: 'Github', link: 'username' },
    { type: 'Dribbble', link: 'username' },
  ];
  const contacts = [
    { type: 'Phone', link: userPhoneNumber },
    { type: 'Email', link: userEmail },
    { type: 'Skype', link: userSkype },
    { type: 'Address', link: `${userAddress}, ${userCity}, ${userCountry}` },
  ];
  if (isActive === 1 && userSocialMedia.length) {
    userSocialMedia = userSocialMedia.map((i) => ({ ...i, link: '' }));
    const objectKeyByType = keyBy(socialMedia, 'type');
    userSocialMedia = userSocialMedia.map((i) => {
      const socialMediaData = objectKeyByType[i.type];
      if (socialMediaData && socialMediaData.type === i.type && !!socialMediaData.link.trim()) {
        return { ...i, link: socialMediaData.link };
      }
      return i;
    });
  }

  let userSocialMediaShowed = orderBy(userSocialMedia, ['link'], ['desc']);
  const ignoredSocialMedia = ['Website'];
  userSocialMediaShowed = userSocialMediaShowed.filter((item) => !ignoredSocialMedia.includes(item.type));

  const signColor = '#e8e8e8';

  const dummyReference: ProfileReference[] = Array(2).fill({
    name: 'Reference Full Name',
    position: 'Job Position',
    company: 'Company / Organization Name',
    email: 'yourmail@example.com',
    mobile: '+123 4567 8910',
  });
  const userReference = reference ?? dummyReference;

  const dummyExperiences = Array(4).fill({
    role: 'Business Owner',
    company: 'Company Name',
    location: 'Location',
    from_year: '01-01-2018',
    to_year: '01-01-2020',
    finished: false,
    activities: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac. In mi turpis tincidunt tincidunt pellentesque lorem. Odio pharetra faucibus eu nibh eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pretium urna pellentesque sed facilisis. Pharetra lacus tincidunt mauris diam ac.`,
  });
  const userExperiences = experience ?? dummyExperiences;

  const dummyEducation: ProfileEducation[] = Array(2).fill({
    school: 'University / College / Institute ',
    degree: 'Master',
    grade: '4.75',
    major: 'business management',
    from_year: '',
    to_year: '01-01-2020',
    finished: true,
    activities: '',
  });
  const userEducation = education ?? dummyEducation;

  const dummySkills: CvSkills[] = Array(3).fill({
    skill: 'HTML, CSS',
    name: 'HTML, CSS',
    points: 3.5,
    experience: 1,
  });
  const userSkills = skills ?? dummySkills;

  const activeColors = (activeNumber: number): ActiveColors => {
    return isActive === activeNumber ? { bgcolor: signColor } : {};
  };

  const renderReferences = () => {
    if (isEmpty(userReference)) return [];

    return [
      <Box key="references" p=".7em 1.5em 0" {...activeColors(5)}>
        <SubTitle>REFERENCE</SubTitle>
      </Box>,
      ...userReference.map((item, index) => (
        <UserReference key={`reference${index}`} cvReference={item} activeColors={activeColors(5)} />
      )),
    ];
  };

  const renderInterest = () => {
    if (isEmpty(userInterest)) return [];
    return [
      <Box key="interest" p=".7em 1.5em 0" {...activeColors(5)}>
        <SubTitle>INTEREST</SubTitle>
      </Box>,
      ...userInterest.map((item, index) => (
        <Box key={index + item} p=".7em 1.5em 0" display="flex" gridGap=".2em" alignItems="center" {...activeColors(5)}>
          <Box bgcolor="currentColor" width=".25em" height=".25em" borderRadius="50%" mr=".5em" />
          <Box fontSize=".4em">{item}</Box>
        </Box>
      )),
    ];
  };

  const renderSkills = () => {
    if (isEmpty(userSkills)) return [];
    return [
      <Box key="skills" p=".2em 1.5em 0" {...activeColors(4)}>
        <SubTitle>SKILLS</SubTitle>
      </Box>,
      ...userSkills.map(({ name, points }, index) => (
        <SkillItem
          key={`skill${name}${index}${points}`}
          title={name}
          point={points}
          isShowProficiency={isShowProficiency}
          color={cvColor}
          activeColors={activeColors(4)}
        />
      )),
    ];
  };

  const renderExperiences = () => {
    if (isEmpty(userExperiences)) return [];
    return [
      <Box key="experiences" p="1.5em 1.5em 0" {...activeColors(2)}>
        <SubTitle>WORK EXPERIENCE</SubTitle>
      </Box>,
      ...userExperiences.map((item, index) => (
        <UserExperienceInfo key={`experience${index}`} cvExperience={item} activeColors={activeColors(2)} />
      )),
    ];
  };

  const [mockId, mockHeight] = useCVThreeLayout([userAboutMe]);

  const renderContactsEducation = () => {
    return [
      <Box key="contactsneducation" display="flex">
        <Box key="contacts" flex={1} p=".2em 1.5em" {...activeColors(1)}>
          <SubTitle pb=".8em">CONTACTS</SubTitle>
          <Box className="o-premium-0009__wrapper">
            {contacts.map(
              (item, idx) => !!item.link.length && <UserContactsBox key={idx} type={item.type} link={item.link} />,
            )}
          </Box>
        </Box>
        <Box key="education" flex={1} p=".2em 1.5em" borderLeft=".13em solid #484545" {...activeColors(3)}>
          <SubTitle pb=".8em">EDUCATIONS</SubTitle>
          <Box display="flex" gridGap="1.1em" flexDirection="column">
            {userEducation.map((item, index) => (
              <UserEducationInfo key={index} cvEducation={item} />
            ))}
          </Box>
        </Box>
      </Box>,
    ];
  };

  const [isShowOtherPage, renderedLeft, renderedRight, idLeft, idRight, contentLeft, contentRight] = useCvTwoLayout({
    leftComponents: [...renderContactsEducation(), ...renderExperiences()],
    rightComponents: [...renderSkills(), ...renderInterest(), ...renderReferences()],
    props,
  });

  return (
    <Box className="o-base-cv o-base-cv__multiple__page" fontSize={textSize} fontFamily={savedCvFont} overflow="hidden">
      <Box minHeight="54.164em" height="54.164em" display="flex" flexDirection="column" position="relative">
        <Box height="100%">
          <Box width="100%" height="2.7em">
            <img width="100%" height="100%" src="/assets/cv_9_decoration.png" alt="" />
          </Box>
          <Box id={mockId}>
            <Box>
              <Box px="1.5em" {...activeColors(1)}>
                <Box
                  border=".32px solid #212121"
                  m="-1.6em auto 0"
                  position="relative"
                  p=".3em 1.5em"
                  gridGap="1em"
                  className="u-flex-center"
                  minWidth="15.8em"
                  width="fit-content"
                  whiteSpace="pre"
                  {...activeColors(1)}
                >
                  {isShowAvatar && (
                    <Box height="3em" width="3em" minWidth="3em">
                      <img
                        height="100%"
                        width="100%"
                        src={userAvatar}
                        alt="profile-pict"
                        className="u-border-rounded o-base-cv__avatar"
                      />
                    </Box>
                  )}
                  <Box>
                    <Box fontSize="1.2em" fontWeight={700} whiteSpace="no-wrap" className="u-text-uppercase">
                      {userFirstName} {userLastName}
                    </Box>
                    <Box fontSize=".65em" fontWeight={400} whiteSpace="no-wrap" className="u-text-uppercase">
                      {userRole}
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box pb="1em" {...activeColors(1)}>
                {!!userSocialMediaShowed.length && (
                  <Box
                    p="1.1em 1.5em 0"
                    className="u-flex-center"
                    gridGap="1.2em"
                    flexWrap="wrap"
                    fontSize={fontSizeRatio}
                  >
                    {userSocialMediaShowed.map((item) => (
                      <UserSocialBox key={item.type} img={item.type} info={item.link} />
                    ))}
                  </Box>
                )}

                {userAboutMe.trim() && (
                  <Box p="1.1em 1.5em 0" fontSize={fontSizeRatio} overflow="hidden">
                    <SubTitle textAlign="center" mb=".8em">
                      PROFILE
                    </SubTitle>
                    <AQuillContent
                      content={userAboutMe}
                      textAlign="center"
                      fontSize=".5em"
                      fontWeight={400}
                      color="#484545"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {!!mockHeight && (
            <Box display="flex" flex={1} height={`calc(51.454em - ${mockHeight}px)`}>
              <Box flex={2} id={idLeft} fontSize={fontSizeRatio}>
                {renderedLeft}
                <Box height="1em" />
              </Box>
              <Box flex={1} borderLeft=".13em solid #484545" id={idRight} fontSize={fontSizeRatio}>
                {renderedRight}
                <Box height="1em" />
              </Box>
            </Box>
          )}
          <Box width="100%" height="1em" bottom={0} position="absolute" bgcolor={cvColor} />
        </Box>
      </Box>

      {isShowOtherPage && (
        <>
          {preview !== previewType.downloadCvImg && (
            <Box height="3em" bgcolor="#eeeeee" className="u-flex-center" fontWeight={700}>
              <Box fontSize={14}>{t('common.pageBreak')}</Box>
            </Box>
          )}
          <PREMIUM_0009_BACK
            preview={preview}
            savedCvColour={cvColor}
            fontSizeRatio={fontSizeRatio}
            overflowedContentLeft={contentLeft}
            overflowedContentRight={contentRight}
          />
        </>
      )}
    </Box>
  );
};

export default PREMIUM_0009;
