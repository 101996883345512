import Box from '@material-ui/core/Box';
import { CSSProperties, FC, MouseEventHandler } from 'react';

export const Arrow: FC<{
  left?: boolean;
  className?: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLElement>;
}> = (props) => {
  const { className, style, onClick, left } = props;
  return (
    <Box onClick={onClick} className={className} style={{ ...style, transform: `rotate(${left ? 0 : 180}deg)` }}>
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_5355:90852)">
          <circle cx="28" cy="26" r="24" fill="white" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4973 26.2671C19.5008 26.2765 19.5008 26.2859 19.5051 26.2953C19.5051 26.2971 19.5051 26.2979 19.5059 26.2996C19.5573 26.4213 19.6328 26.5242 19.7202 26.6168C19.7296 26.6271 19.7331 26.6399 19.7425 26.6502L26.2979 33.1456C26.5113 33.3565 26.7908 33.4619 27.0702 33.4619C27.3496 33.4619 27.6282 33.3565 27.8416 33.1456C28.2685 32.7231 28.2685 32.0391 27.8416 31.6165L23.1496 26.9665H35.8053C36.4088 26.9665 36.8965 26.4831 36.8965 25.8848C36.8965 25.2882 36.4088 24.8039 35.8053 24.8039H23.1505L27.8425 20.1308C28.2693 19.7091 28.2693 19.0242 27.8425 18.6025C27.4165 18.1799 26.7248 18.1799 26.2979 18.6025L19.7425 25.1202C19.6482 25.2136 19.5805 25.3242 19.5273 25.4408C19.5102 25.4768 19.5008 25.5119 19.4888 25.5479C19.4596 25.6353 19.4416 25.7228 19.4356 25.8153C19.4331 25.8531 19.4279 25.8882 19.4296 25.9259C19.4339 26.0425 19.4545 26.1556 19.4973 26.2671Z"
          fill="#283583"
        />
        <defs>
          <filter
            id="filter0_d_5355:90852"
            x="0"
            y="0"
            width="56"
            height="56"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5355:90852" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5355:90852" result="shape" />
          </filter>
        </defs>
      </svg>
    </Box>
  );
};
